export default {
  CCU_ID: { name: "ccu_id", label: "ccu_id" },
  PROFILE: {
    name: "profile",
    label: "profile",
    scopes: [
      { name: "gender", label: "gender" },
      { name: "given_name", label: "given_name" },
      { name: "family_name", label: "family_name" },
      { name: "preferred_username", label: "preferred_username" },
      { name: "birthdate", label: "birthdate" },
    ],
  },
  BIRTH: {
    name: "birth",
    label: "birth",
    scopes: [
      { name: "birthplace", label: "birthplace" },
      { name: "birthcountry", label: "birthcountry" },
      { name: "birthplace_label", label: "birthplace_label" },
      { name: "birthcountry_iso", label: "birthcountry_iso" },
      { name: "birthcountry_label", label: "birthcountry_label" },
    ],
  },
  CONTACT_INFORMATION: {
    name: "contact_information",
    label: "contact_information",
    scopes: [
      { name: "email", label: "email" },
      { name: "phone", label: "phone" },
      { name: "splitted_phone", label: "splitted_phone" },
    ],
  },
  NATIONALITY: { name: "nationality", label: "nationality" },
  SPLITTED_GIVEN_NAME: {
    name: "splitted_given_name",
    label: "splitted_given_name",
  },
  DIGITAL_IDENTITY_METADATA: {
    name: "digital_identity_metadata",
    label: "digital_identity_metadata",
    scopes: [
      {
        name: "digital_identity_creation_date",
        label: "digital_identity_creation_date",
      },
      {
        name: "digital_identity_expiration_date",
        label: "digital_identity_expiration_date",
      },
    ],
  },
  MAJORITY: { name: "majority", label: "majority" },
};
