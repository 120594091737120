import * as Yup from "yup";

const RibCompleteSchema = Yup.object().shape({
  bankCode: Yup.string()
    .required("Données de facturation : Le code banque n'est pas renseigné")
    .typeError("Données de facturation : Le code banque n'est pas renseigné"),
  counterCode: Yup.string()
    .required("Données de facturation : Le code guichet n'est pas renseigné")
    .typeError("Données de facturation : Le code guichet n'est pas renseigné"),
  accountNumber: Yup.string()
    .required(
      "Données de facturation : Le numéro de compte n'est pas renseigné",
    )
    .typeError(
      "Données de facturation : Le numéro de compte n'est pas renseigné",
    ),
  key: Yup.string()
    .required("Données de facturation : La clé n'est pas renseignée")
    .typeError("Données de facturation : La clé n'est pas renseignée"),
});

export default RibCompleteSchema;
