import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Autocomplete, Grid, TextField } from "@mui/material";
import AddressShape from "models/shapes/AddressShape";
import CountryEnum from "models/enums/CountryEnum";
import Form from "components/Form";
import InputField from "components/InputField";
import { isRequired } from "utils/enum";

const AddressForm = (props) => {
  const {
    address,
    namePrefix,
    touched,
    errors,
    schema,
    handleChange,
    handleBlur,
    ...others
  } = props;

  const handleChangeCountry = useCallback(
    (e, value) => {
      handleChange({
        ...e,
        target: {
          ...e.target,
          name: `${namePrefix}country`,
          value,
        },
      });
    },
    [handleChange, namePrefix],
  );

  return (
    <Form
      content={[
        {
          key: "department",
          firstElement: (
            <InputField title="Service (optionnel)">
              <TextField
                variant="outlined"
                InputProps={{
                  sx: { width: { sm: "25.5rem" } },
                  inputProps: {
                    "aria-labelledby": "service_(optionnel)",
                    "aria-describedby": "ht_service",
                    "aria-invalid": Boolean(touched.department && errors.department),
                    "aria-required": isRequired(schema, `${namePrefix}department`),
                  },
                }}
                name={`${namePrefix}department`}
                value={address.department || ""}
                error={touched.department && Boolean(errors.department)}
                helperText={touched.department && errors.department}
                onChange={handleChange}
                onBlur={handleBlur}
                FormHelperTextProps={{
                  id: "ht_service",
                }}
              />
            </InputField>
          ),
        },
        {
          key: "streetNb/street",
          firstElement: (
            <Grid container>
              <Grid item xs={12} sm="auto">
                <InputField title="N°">
                  <TextField
                    variant="outlined"
                    InputProps={{
                      sx: {
                        mr: "1.5rem",
                        width: { sm: "4.125rem" },
                      },
                      inputProps: {
                        "aria-labelledby": "n°",
                        "aria-describedby": "ht_numero",
                        "aria-invalid": Boolean(touched.streetNb && errors.streetNb),
                        "aria-required": isRequired(schema, `${namePrefix}streetNb`),
                      },
                    }}
                    name={`${namePrefix}streetNb`}
                    value={address.streetNb || ""}
                    error={touched.streetNb && Boolean(errors.streetNb)}
                    helperText={touched.streetNb && errors.streetNb}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    FormHelperTextProps={{
                      id: "ht_numero",
                    }}
                  />
                </InputField>
              </Grid>
              <Grid item xs={12} sm="auto">
                <InputField title="Rue">
                  <TextField
                    variant="outlined"
                    InputProps={{
                      sx: { width: { sm: "19.875rem" } },
                      inputProps: {
                        "aria-labelledby": "rue",
                        "aria-describedby": "ht_rue",
                        "aria-invalid": Boolean(touched.street && errors.street),
                        "aria-required": isRequired(schema, `${namePrefix}street`),
                      },
                    }}
                    name={`${namePrefix}street`}
                    value={address.street || ""}
                    error={touched.street && Boolean(errors.street)}
                    helperText={touched.street && errors.street}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    FormHelperTextProps={{
                      id: "ht_rue",
                    }}
                  />
                </InputField>
              </Grid>
            </Grid>
          ),
        },
        {
          key: "zipCode/city",
          firstElement: (
            <Grid container>
              <Grid item xs={12} sm="auto">
                <InputField title="Code postal">
                  <TextField
                    variant="outlined"
                    InputProps={{
                      sx: {
                        marginRight: "1.5rem",
                        width: { sm: "7.125rem" },
                      },
                      inputProps: {
                        "aria-labelledby": "code_postal",
                        "aria-describedby": "ht_cp",
                        "aria-invalid": Boolean(touched.zipCode && errors.zipCode),
                        "aria-required": isRequired(schema, `${namePrefix}zipCode`),
                      },
                    }}
                    name={`${namePrefix}zipCode`}
                    value={address.zipCode || ""}
                    error={touched.zipCode && Boolean(errors.zipCode)}
                    helperText={touched.zipCode && errors.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    FormHelperTextProps={{
                      id: "ht_cp",
                    }}
                  />
                </InputField>
              </Grid>
              <Grid item xs={12} sm="auto">
                <InputField title="Ville">
                  <TextField
                    variant="outlined"
                    InputProps={{
                      sx: { width: { sm: "16.875rem" } },
                      inputProps: {
                        "aria-labelledby": "ville",
                        "aria-describedby": "ht_ville",
                        "aria-invalid": Boolean(touched.city && errors.city),
                        "aria-required": isRequired(schema, `${namePrefix}city`),
                      },
                    }}
                    name={`${namePrefix}city`}
                    value={address.city || ""}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    FormHelperTextProps={{
                      id: "ht_ville",
                    }}
                  />
                </InputField>
              </Grid>
            </Grid>
          ),
        },
        {
          key: "country",
          firstElement: (
            <Autocomplete
              options={[...Object.values(CountryEnum), ""]}
              onChange={handleChangeCountry}
              onBlur={handleBlur}
              value={address.country || null}
              disableClearable
              noOptionsText="Aucun pays correspondant"
              sx={{
                "& svg": {
                  fontSize: "1.45em",
                },
              }}
              renderInput={(params) => (
                <InputField title="Pays">
                  <TextField
                    {...params}
                    variant="outlined"
                    sx={{ width: { sm: "19.875rem" } }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "nope",
                      "aria-labelledby": "pays",
                      "aria-describedby": "ht_pays",
                      "aria-invalid": Boolean(touched.country && errors.country),
                      "aria-required": isRequired(schema, `${namePrefix}country`),
                    }}
                    error={touched.country && Boolean(errors.country)}
                    helperText={touched.country && errors.country}
                    FormHelperTextProps={{
                      id: "ht_pays",
                    }}
                  />
                </InputField>
              )}
            />
          ),
        },
      ]}
      notTwoColumns
      {...others}
    />
  );
};

AddressForm.propTypes = {
  address: AddressShape.isRequired,
  namePrefix: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]),
  errors: PropTypes.shape(),
  schema: PropTypes.shape(),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

AddressForm.defaultProps = {
  namePrefix: "",
  touched: {},
  errors: {},
  schema: {},
};

export default AddressForm;
