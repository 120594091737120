import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const StyledIcon = (props) => {
  const { Ico, iconStyle } = props;

  return (
    <Grid
      item
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: "1.23em",
        ...iconStyle,
      }}
    >
      <Ico />
    </Grid>
  );
};

StyledIcon.propTypes = {
  Ico: PropTypes.elementType.isRequired,
  iconStyle: PropTypes.shape().isRequired,
};

const IconLabel = (props) => {
  const { Icon, iconPosition, iconStyle, ariaLabel, label, ...others } = props;

  return (
    <Typography component="div" {...others}>
      <Grid
        container
        alignItems="center"
        spacing={1}
        direction={iconPosition === "start" ? "row" : "row-reverse"}
      >
        <Grid item xs="auto">
          {Icon && <StyledIcon Ico={Icon} iconStyle={iconStyle} />}
        </Grid>
        <Grid item xs="auto" aria-label={ariaLabel || null}>
          {label}
        </Grid>
      </Grid>
    </Typography>
  );
};

IconLabel.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  iconPosition: PropTypes.oneOf(["start", "end"]),
  iconStyle: PropTypes.shape(),
  ariaLabel: PropTypes.string,
  label: PropTypes.string.isRequired,
};

IconLabel.defaultProps = {
  iconPosition: "start",
  iconStyle: {},
  ariaLabel: null,
};

export default IconLabel;
