import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Box, Button, Divider, IconButton, Typography } from "@mui/material";

import { orderBy } from "lodash";

import AclBack from "apps/back/AclBack";
import Snackbar from "components/Snackbar";
import IconLabel from "components/IconLabel";
import TableListing from "components/TabListing";
import IconAdd from "icons/add";
import IcoUserManagement from "icons/user management";
import IconWrite from "icons/write";
import UserShape from "models/shapes/UserShape";
import UserFactory from "models/factories/UserFactory";
import Acl, { withAccess } from "utils/Acl";
import CreateUserForm from "./CreateUserForm";
import EditUserForm from "./EditUserForm";

const headCells = [
  { id: "lastName", sortable: true, isDate: false, label: "Nom" },
  { id: "firstName", sortable: true, isDate: false, label: "Prénom" },
  { id: "job", sortable: true, isDate: false, label: "Poste" },
  {
    id: "isAssociated",
    sortable: true,
    isDate: false,
    label: "Identité Numérique",
  },
  { id: "email", sortable: true, isDate: false, label: "Email" },
  {
    id: `roles.${process.env.REACT_APP_keycloak_front_client_id}`,
    sortable: true,
    isDate: false,
    label: "Droit",
  },
  { id: "enabled", sortable: true, isDate: false, label: "Statut" },
];

const Users = (props) => {
  const { partnerId, users, getPartner } = props;
  const canUpdate = Acl.hasAccess(AclBack.PARTNERS_USERS, Acl.UPDATE);
  const [sorting, setSorting] = useState({
    sortBy: "lastName",
    sortByLabel: "Nom",
    order: "asc",
  });
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });
  const rowsPerPageOptions = [10, 25, 50];
  const [snackbar, setSnackbar] = useState({
    variant: null,
    message: "",
    open: false,
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const { page, rowsPerPage } = pagination;

  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const [openUpdateUserModal, setOpenUpdateUserModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const sortedUsers = useMemo(
    () => orderBy(users, [sorting.sortBy], [sorting.order]),
    [users, sorting],
  );

  const currentUsers = useMemo(
    () =>
      Array.isArray(sortedUsers) &&
      sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, sortedUsers],
  );

  const handleCloseNewUserModal = () => {
    getPartner();
    setOpenNewUserModal(false);
  };

  const handleCloseUpdateUserModal = () => {
    getPartner();
    setOpenUpdateUserModal(false);
  };

  const handleUpdateUser = (data) => {
    setCurrentUser(data);
    setOpenUpdateUserModal(true);
  };

  useEffect(() => {
    if (!openUpdateUserModal) {
      setCurrentUser(null);
    }
  }, [openUpdateUserModal]);

  return (
    <Box sx={{ marginTop: (theme) => theme.spacing(7) }}>
      <Typography
        variant="h2"
        sx={(theme) => ({
          color: theme.palette["secondary-ultramarine"],
          margin: theme.spacing(0, 0, 2, 3.5),
          [theme.breakpoints.down("md")]: {
            marginLeft: 0,
          },
        })}
        id="utilisateurs-de-la-plateforme"
      >
        <IconButton
          color="secondary"
          tabIndex={-1}
          sx={(theme) => ({
            pointerEvents: "none",
            marginRight: theme.spacing(2),
          })}
          size="large"
          aria-hidden="true"
        >
          <IcoUserManagement />
        </IconButton>
        Utilisateurs de la plateforme
      </Typography>
      <Divider />
      {Acl.hasAccess(AclBack.PARTNERS_USERS, Acl.CREATE) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: (theme) => theme.spacing(3, 0),
          }}
        >
          <Button onClick={() => setOpenNewUserModal(true)}>
            <IconLabel
              Icon={IconAdd}
              iconPosition="start"
              label="Ajouter un utilisateur"
              variant="h5"
              color="primary"
              sx={{
                fontWeight: 600,
              }}
            />
          </Button>
        </Box>
      )}
      <CreateUserForm
        openUsersModal={openNewUserModal}
        handleCloseUserModal={handleCloseNewUserModal}
        handleCancel={() => setOpenNewUserModal(false)}
        setSnackbar={setSnackbar}
        partnerId={partnerId}
        keycloakClientId={process.env.REACT_APP_keycloak_front_client_id}
      />
      <EditUserForm
        currentUser={UserFactory.createFromPersonalData({
          ...currentUser,
          partnerId,
        })}
        openUsersModal={openUpdateUserModal}
        setSnackbar={setSnackbar}
        editPartnerUser
        handleCloseUserModal={handleCloseUpdateUserModal}
        handleCancel={() => setOpenUpdateUserModal(false)}
        keycloakClientId={process.env.REACT_APP_keycloak_front_client_id}
      />
      {Array.isArray(users) && users.length > 0 ? (
        <TableListing
          headCells={headCells}
          liste={currentUsers}
          onClick={canUpdate ? handleUpdateUser : null}
          icon={
            canUpdate ? (
              <IconWrite
                sx={(theme) => ({
                  height: theme.spacing(3),
                  width: theme.spacing(3),
                  verticalAlign: "middle",
                  color: theme.palette["secondary-ultramarine"],
                })}
              />
            ) : null
          }
          clickOnIcon
          pagination={pagination}
          setPagination={setPagination}
          sorting={sorting}
          setSorting={setSorting}
          totalElements={users.length}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPageLabel="Utilisateurs"
          partnersTable={false}
          caption="Liste d'utilisateurs"
        />
      ) : (
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={(theme) => ({
            color: theme.palette["secondary-ultramarine"],
            margin: theme.spacing(2.5, 0),
          })}
        >
          Aucun utilisateur sur cette plateforme
        </Typography>
      )}
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </Box>
  );
};

Users.propTypes = {
  partnerId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(UserShape).isRequired,
  getPartner: PropTypes.func.isRequired,
};

export default withAccess(AclBack.PARTNERS_USERS, Acl.READ)(Users);
