import axios from "axios";

const provider = process.env.REACT_APP_identity_provider;

const removeAttributesArrays = (user) => ({
  ...user,
  attributes: user.attributes && {
    ...Object.keys(user.attributes).reduce((attributes, key) => {
      const value = user.attributes[key];
      return {
        ...attributes,
        [key]: Array.isArray(value) && value.length === 1 ? value[0] : value,
      };
    }, {}),
  },
});

export default class UserService {
  static createUser = (object) => {
    return axios
      .post(
        `${process.env.REACT_APP_keycloak_url}/auth/admin/realms/${process.env.REACT_APP_keycloak_realm}/users`,
        object,
      )
      .then((response) => {
        return response && response.data;
      });
  };

  static getUserIdentityProviders = () => {
    return axios
      .get(`${process.env.REACT_APP_api_url}/kc-users/me/linked-accounts`)
      .then((response) => {
        return response && response.data;
      })
      .catch((error) => {
        return error;
      });
  };

  static createPartnerUser = (partnerId, clientId, object) => {
    return axios
      .post(`${process.env.REACT_APP_api_url}/kc-users`, object, {
        params: { partnerId, clientId },
      })
      .then((response) => {
        return response && response.data;
      });
  };

  static updateUser = (user) => {
    const { id, ...data } = user;
    return axios
      .put(
        `${process.env.REACT_APP_keycloak_url}/auth/admin/realms/${process.env.REACT_APP_keycloak_realm}/users/${id}`,
        data,
      )
      .then((response) => {
        return response && response.data;
      });
  };

  static updatePartnerUser = (userId, partnerId, clientId, object) => {
    return axios
      .put(`${process.env.REACT_APP_api_url}/kc-users/${userId}`, object, {
        params: { partnerId, clientId },
      })
      .then((response) => {
        return response && response.data;
      });
  };

  static getAdminUsers = (params = {}) => {
    return axios
      .get(`${process.env.REACT_APP_api_url}/kc-users/administrator`, params)
      .then((response) => {
        return (
          Array.isArray(response?.data) &&
          response.data.map(removeAttributesArrays)
        );
      });
  };

  static getUsers = (params = {}) => {
    return axios
      .get(
        `${process.env.REACT_APP_keycloak_url}/auth/admin/realms/${process.env.REACT_APP_keycloak_realm}/users`,
        params,
      )
      .then((response) => {
        return (
          Array.isArray(response?.data) &&
          response.data.map(removeAttributesArrays)
        );
      });
  };

  static getUsersByRoles = (roles = [], clientId, params = {}) => {
    return UserService.getClients({
      params: {
        clientId,
      },
    }).then((clients) => {
      const [client] = clients;
      const promises = [];
      roles.forEach((role) => {
        promises.push(
          axios.get(
            `${process.env.REACT_APP_keycloak_url}/auth/admin/realms/${process.env.REACT_APP_keycloak_realm}/clients/${client?.id}/roles/${role}/users`,
            params,
          ),
        );
      });
      return Promise.all(promises).then((responses) => {
        const allUsers = [];
        responses.forEach((response, index) => {
          const role = roles[index];
          response.data.forEach((user) => {
            const existingUser = allUsers.find((u) => u.id === user.id);
            if (!existingUser) {
              allUsers.push({ ...user, roles: { [clientId]: [role] } });
            } else {
              existingUser.roles[clientId].push(role);
            }
          });
        });
        return allUsers.map(removeAttributesArrays);
      });
    });
  };

  static getPartnerUsers = (partnerId, clientId) => {
    return axios
      .get(`${process.env.REACT_APP_api_url}/kc-users`, {
        params: { partnerId, clientId },
      })
      .then((response) => {
        return response && response.data;
      });
  };

  static getClients = (params = {}) => {
    return axios
      .get(
        `${process.env.REACT_APP_keycloak_url}/auth/admin/realms/${process.env.REACT_APP_keycloak_realm}/clients`,
        params,
      )
      .then((response) => {
        return response && response.data;
      });
  };

  static getClientRoles = (clientUuid, params = {}) => {
    return axios
      .get(
        `${process.env.REACT_APP_keycloak_url}/auth/admin/realms/${process.env.REACT_APP_keycloak_realm}/clients/${clientUuid}/roles`,
        params,
      )
      .then((response) => {
        return response && response.data;
      });
  };

  static addRoleForUser = ({ userId, clientUuid, data }) => {
    return axios
      .post(
        `${process.env.REACT_APP_keycloak_url}/auth/admin/realms/${process.env.REACT_APP_keycloak_realm}/users/${userId}/role-mappings/clients/${clientUuid}`,
        data,
      )
      .then(() => {
        return true;
      });
  };

  static deleteRoleForUser = ({ userId, clientUuid, data }) => {
    return axios
      .delete(
        `${process.env.REACT_APP_keycloak_url}/auth/admin/realms/${process.env.REACT_APP_keycloak_realm}/users/${userId}/role-mappings/clients/${clientUuid}`,
        data,
      )
      .then(() => {
        return true;
      });
  };

  static sendLinkToIdpAccountEmail = (userId, params) => {
    return axios
      .get(
        `${process.env.REACT_APP_api_url}/kc-users/${userId}/link-to-idp-account-email`,
        params,
      )
      .then((response) => {
        return response && response.data;
      });
  };

  static unlinkPartnerUserFromLIN = (userId, params) => {
    return axios
      .delete(
        `${process.env.REACT_APP_api_url}/kc-users/${userId}/federated-identities`,
        params,
      )
      .then((response) => {
        return response && response.data;
      })
      .catch((error) => {
        return error;
      });
  };

  static unlinkFromLIN = (userId) => {
    return axios
      .delete(
        `${process.env.REACT_APP_keycloak_url}/auth/admin/realms/${process.env.REACT_APP_keycloak_realm}/users/${userId}/federated-identity/${provider}`,
      )
      .then((response) => {
        return response && response.data;
      })
      .catch((error) => {
        return error;
      });
  };
}
