export const FrontUserRoleEnum = {
  METIER: { name: "METIER", label: "Métier" },
  TECHNIQUE: { name: "TECHNIQUE", label: "Technique" },
  ADMINISTRATEUR: { name: "ADMINISTRATEUR", label: "Responsable contrat" },
};

export const BackUserRoleEnum = {
  ADMIN_LIN: { name: "ADMIN_LIN", label: "Responsable métier" },
  EXPERT: { name: "EXPERT", label: "Expert Solution" },
  SUPERADMIN: { name: "SUPERADMIN", label: "Administrateur" },
};

export default {
  ...FrontUserRoleEnum,
  ...BackUserRoleEnum,
};
