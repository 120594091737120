import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, Grid } from "@mui/material";
import AclBack from "apps/back/AclBack";
import Acl, { withAccess } from "utils/Acl";
import { BackDashboardStatUsers } from "components/stats/StatUsers";
import StatSales from "components/stats/StatSales";
import StatBilling from "components/stats/StatBilling";
import StatPartner from "components/stats/StatPartner";
import PageTitle from "components/PageTitle";
import Paper from "components/Paper";
import PeriodsPicker from "components/PeriodsPicker";
import { BackDashboardStatConso } from "components/stats/StatConso";
import FiltersCard from "components/FiltersCard";
import Loader from "components/Loader";
import StatService from "services/StatService";
import { getDefaultPeriod } from "components/PeriodsPicker/PeriodUtil";
import { BackDashboardApplicationsActivities } from "components/stats/ApplicationsActivities";
import { BackDashboardApplicationsClassification } from "components/stats/ApplicationsClassification";
import {
  filterStat,
  getApplicationsFromStats,
  getBusinessLinesFromStats,
} from "utils/StatsUtil";
import { BackDashboardStatTitleWithExport } from "components/stats/StatTitleWithExport";
import { formatDateToYYYYMMDD } from "utils/date";

const Dashboard = () => {
  const theme = useTheme();

  const global = Acl.hasAccess(AclBack.DASHBOARD_GLOBAL, Acl.READ);

  const defaultPeriod = getDefaultPeriod();
  const [periods, setPeriods] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState([]);

  const [applicationsFilter, setApplicationsFilter] = useState([]);
  const [businessLinesFilter, setBusinessLinesFilter] = useState([]);

  const getStats = useCallback(() => {
    setIsLoading(true);
    if (Array.isArray(periods) && periods.length > 0) {
      const resultsPromise = [];
      periods.forEach((period) => {
        if (Object.keys(period).length > 0) {
          resultsPromise.push(
            StatService.getStats({
              params: {
                type: "use",
                lowerDate: formatDateToYYYYMMDD(period.startDate),
                upperDate: formatDateToYYYYMMDD(period.endDate),
                timezone: "Europe/Paris",
                fullData: true,
              },
            }),
          );
        }
      });
      Promise.all(resultsPromise)
        .then((results) => {
          setIsLoading(false);
          setStats(results);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [periods]);

  useEffect(() => {
    getStats();
  }, [getStats]);

  const statsFiltered = useMemo(
    () =>
      stats.map((stat) =>
        filterStat({
          stat,
          applications: applicationsFilter,
          businessLines: businessLinesFilter,
        }),
      ),
    [stats, applicationsFilter, businessLinesFilter],
  );

  const handleChangePeriods = (changedPeriods) => {
    setPeriods(changedPeriods);
  };

  const handleChangeApplicationsFilter = (event, values) => {
    setApplicationsFilter(values);
  };

  const handleChangeBusinessLinesFilter = (event, values) => {
    setBusinessLinesFilter(values);
  };

  const statBilling = (
    <StatBilling
      stats={statsFiltered}
      sx={{ height: "100%", minHeight: theme.spacing(60) }}
    />
  );

  const statPartner = (
    <StatPartner
      stats={statsFiltered}
      sx={{ height: "100%", minHeight: theme.spacing(60) }}
    />
  );

  const applicationsActivities = (
    <BackDashboardApplicationsActivities
      stats={statsFiltered}
      label="Nombre de partenaires par secteur d'activité"
      sx={{ height: "100%", minHeight: theme.spacing(60) }}
    />
  );

  return (
    <>
      <BackDashboardStatTitleWithExport
        statTitle={<PageTitle>Tableau de bord</PageTitle>}
        statAdditionalParams={{ fullData: true }}
        isDashboard
        periods={periods}
        handleSetIsLoading={setIsLoading}
      />
      <Box mb={2} component={Paper}>
        <Box
          sx={{
            p: theme.spacing(4),
            [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
              p: theme.spacing(2),
            },
          }}
        >
          <PeriodsPicker
            periods={periods}
            onPeriodsChange={handleChangePeriods}
            defaultPeriod={defaultPeriod}
            isDashboard
          />
        </Box>
        <Divider />
        <FiltersCard
          listOfApplications={getApplicationsFromStats(
            stats.map((stat) =>
              // Consolidate filter
              filterStat({
                stat,
                applications: [],
                businessLines: businessLinesFilter,
              }),
            ),
          )}
          businessLinesList={getBusinessLinesFromStats(stats)}
          businessLinesFilter={businessLinesFilter}
          applicationsFilter={applicationsFilter}
          handleChangeApplicationsFilter={handleChangeApplicationsFilter}
          handleChangeBusinessLinesFilter={handleChangeBusinessLinesFilter}
          isAdminDashboard
          isDashboard
        />
      </Box>
      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <Loader
            sx={{
              background: "rgba(255,255,255,0.5)",
            }}
          />
        </Box>
      )}
      <BackDashboardStatConso
        stats={statsFiltered}
        isPartner={false}
        isDashboard
      />
      <BackDashboardStatUsers
        stats={statsFiltered}
        sx={{ marginTop: theme.spacing(3) }}
        periods={periods}
        isPartner={false}
      />
      {global && (
        <Grid container spacing={3} sx={{ mt: theme.spacing(1) }}>
          <Grid item xs={12} md={6}>
            <StatSales
              stats={statsFiltered}
              sx={{ height: "100%", minHeight: theme.spacing(60) }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {statBilling}
          </Grid>
        </Grid>
      )}
      {!global && <Box sx={{ mt: theme.spacing(3) }}>{statPartner}</Box>}
      {global && (
        <Grid container spacing={4} sx={{ mt: theme.spacing(1) }}>
          <Grid item xs={12} md={6}>
            {statPartner}
          </Grid>
          <Grid item xs={12} md={6}>
            {applicationsActivities}
          </Grid>
        </Grid>
      )}
      {!global && (
        <Grid container spacing={4} sx={{ mt: theme.spacing(1) }}>
          <Grid item xs={12} md={6}>
            {statBilling}
          </Grid>
          <Grid item xs={12} md={6}>
            {applicationsActivities}
          </Grid>
        </Grid>
      )}
      <BackDashboardApplicationsClassification
        stats={statsFiltered}
        sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }}
        isPartner={false}
      />
    </>
  );
};

export default withAccess(AclBack.DASHBOARD_INDIRECT_SALE, Acl.READ)(Dashboard);
