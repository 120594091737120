export default class BusinessLineStatFactory {
  constructor({ title = "", applicationIds = [], ...otherAttributes }) {
    this.id = title;
    this.title = title;
    this.applicationIds = Array.isArray(applicationIds) ? applicationIds : [];
    Object.keys(otherAttributes).forEach((key) => {
      this[key] = otherAttributes[key];
    });
  }
}
