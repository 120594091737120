import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IconLeft from "icons/arrow_left";
import IconRight from "icons/arrow_right";
import AclFront from "apps/front/AclFront";
import AclBack from "apps/back/AclBack";
import Acl, { withAccess } from "utils/Acl";
import Paper from "components/Paper";
import BarSeriesChartLabel from "components/BarSeriesChartLabel";
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Legend,
  Tooltip,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { EventTracker, Stack, ValueScale } from "@devexpress/dx-react-chart";
import { getFullDateFromKey, monthsNames } from "utils/date";
import StatShape from "models/shapes/StatShape";
import StatPartnerShape from "models/shapes/StatPartnerShape";
import { PeriodShape } from "components/PeriodsPicker/PeriodUtil";

const Marker = (props) => {
  const { className, color } = props;
  return (
    <svg className={className} fill={color} width="10" height="10">
      <rect x={0} y={0} width={10} height={10} />
    </svg>
  );
};

Marker.propTypes = {
  className: PropTypes.shape(),
  color: PropTypes.string.isRequired,
};

Marker.defaultProps = {
  className: {},
};

const filters = {
  TOUT: "Tout",
  UTILISATEURS_UNIQUES: "Utilisateurs uniques",
  UTILISATIONS: "Utilisations",
};

const defaultStatLine = { uses: 0, unique_users: 0, fullDate: "" };

const getFirstStat = (stat = {}, isPartner) => {
  const data = isPartner ? stat.uses : stat.applications;
  return Array.isArray(data) && data[0];
};

const getDataStatsAtIndex = (
  data,
  date,
  index,
  byMonth,
  isComparative = false,
) => {
  if (Array.isArray(data)) {
    return data.reduce((dataStats, stat) => {
      const { by_date: byDate = {} } = stat;
      let key;
      if (byMonth) {
        const dateArray = date.split("_");
        const currentYear = Number(dateArray[1]);
        const currentMonth =
          monthsNames.findIndex((month) => month === dateArray[0]) + 1;
        key = isComparative
          ? Object.keys(byDate).find(
              (byDateKey) =>
                byDateKey.replace(/[0-9]{4}-/i, "") === `${currentMonth}`,
            )
          : `${currentYear}-${currentMonth}`;
      } else {
        const month = Object.keys(byDate)[0].replace(/-[0-9]{1,2}/i, "");
        key = `${month}-${index + 1}`;
      }
      const stats = byDate[key] || {};
      return {
        uses: dataStats.uses + (stats.uses || 0),
        unique_users: dataStats.unique_users + (stats.unique_users || 0),
        fullDate: dataStats.fullDate || getFullDateFromKey(key),
      };
    }, defaultStatLine);
  }
  return defaultStatLine;
};

const StatUsers = (props) => {
  const { stats, periods, isPartner, ...otherProps } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileXs = useMediaQuery(theme.breakpoints.down(450));

  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState(filters.TOUT);

  const barsColors = useMemo(
    () => [
      {
        uses: {
          color: theme.palette["bleu-10"],
          labelBackground: theme.palette["bleu-80"],
          labelColor: theme.palette["secondary-white"],
        },
        unique_users: {
          color: theme.palette["bleu-80"],
          labelBackground: theme.palette["bleu-80"],
          labelColor: theme.palette["secondary-white"],
        },
      },
      {
        uses: {
          color: theme.palette["jaune-50"],
          labelBackground: theme.palette["primaire-jaune"],
          labelColor: theme.palette["secondary-ultramarine"],
        },
        unique_users: {
          color: theme.palette["primaire-jaune"],
          labelBackground: theme.palette["primaire-jaune"],
          labelColor: theme.palette["secondary-ultramarine"],
        },
      },
    ],
    [theme],
  );

  const byMonth = useMemo(() => {
    const firstValues =
      getFirstStat(stats[0], isPartner) ||
      (stats[1] && getFirstStat(stats[1], isPartner));
    if (typeof firstValues === "object" && firstValues.by_date) {
      return /^[0-9]{4}-[0-9]{1,2}$/i.test(Object.keys(firstValues.by_date)[0]);
    }
    return false;
  }, [isPartner, stats]);

  const dates = useMemo(() => {
    let dateArray = [];
    if (byMonth) {
      if (stats.length === 2) {
        dateArray = dateArray.concat(monthsNames.map((month) => `${month}_0`));
      } else {
        const startDate =
          Array.isArray(periods) && new Date(periods[0]?.startDate);
        const startDateYear = startDate?.getFullYear();
        const endDate = Array.isArray(periods) && new Date(periods[0]?.endDate);
        const endDateYear = endDate?.getFullYear();
        const yearsRange =
          startDate && endDate
            ? Number(endDate.getFullYear()) -
              Number(startDate.getFullYear()) +
              1
            : 1;
        const startDateMonth = Number(startDate?.getMonth());
        const endDateMonth = Number(endDate?.getMonth());

        for (let i = startDateMonth; i < 12; i += 1) {
          dateArray.push(`${monthsNames[i]}_${startDateYear}`);
        }
        if (
          yearsRange === 1 ||
          (yearsRange === 2 && endDateMonth < startDateMonth)
        ) {
          for (let i = 0; i < startDateMonth; i += 1) {
            dateArray.push(`${monthsNames[i]}_${startDateYear + 1}`);
          }
        } else {
          for (let i = 1; i < yearsRange - 1; i += 1) {
            dateArray = dateArray.concat(
              monthsNames.map((month) => `${month}_${startDateYear + i}`),
            );
          }
          for (let i = 0; i <= endDateMonth; i += 1) {
            dateArray.push(`${monthsNames[i]}_${endDateYear}`);
          }
        }
      }
    } else {
      for (let i = 1; i <= 31; i += 1) {
        dateArray = dateArray.concat(String(i));
      }
    }
    return dateArray;
  }, [byMonth, periods, stats.length]);

  useEffect(() => {
    setPage(0);
  }, [stats]);

  const statUsersData = useMemo(() => {
    if (Array.isArray(stats) && stats.length > 0) {
      return dates.map((date, index) => {
        return {
          date,
          ...stats.reduce((statsData, stat, periodIndex) => {
            const data = isPartner ? stat.uses : stat.applications;
            const {
              uses,
              unique_users: uniqueUsers,
              fullDate,
            } = getDataStatsAtIndex(
              data,
              date,
              index,
              byMonth,
              stats.length === 2,
            );
            return {
              ...statsData,
              [`uses_${periodIndex}`]: uses,
              [`unique_users_${periodIndex}`]: uniqueUsers,
              [`full_date_${periodIndex}`]: fullDate,
            };
          }, {}),
        };
      });
    }
    return null;
  }, [isPartner, byMonth, dates, stats]);

  const hasMore = useMemo(() => {
    if (Array.isArray(dates) && byMonth) {
      return dates.length > page + 12;
    }
    if (isMobile) {
      return dates.length > page + 15;
    }
    return false;
  }, [isMobile, dates, page, byMonth]);

  const barSeries = useMemo(() => {
    const series = [];

    stats.forEach((stat, index) => {
      if ([filters.TOUT, filters.UTILISATIONS].includes(filter)) {
        series.push({
          valueField: `uses_${index}`,
          valueLabel: `Utilisations`,
          argumentField: "date",
          labelField: `full_date_${index}`,
          name: `Utilisations période ${index + 1}`,
          ...barsColors[index % barsColors.length].uses,
        });
      }
      if ([filters.TOUT, filters.UTILISATEURS_UNIQUES].includes(filter)) {
        series.push({
          valueField: `unique_users_${index}`,
          valueLabel: `Uniques`,
          argumentField: "date",
          labelField: `full_date_${index}`,
          name: `Utilisateurs uniques période ${index + 1}`,
          ...barsColors[index % barsColors.length].unique_users,
        });
      }
    });
    return series;
  }, [stats, filter, barsColors]);

  const maxValue = useMemo(() => {
    if (!Array.isArray(statUsersData)) {
      return 100;
    }
    const max = Math.max(
      ...statUsersData.map((data) =>
        Math.max(
          ...stats.map((stat, index) => {
            if (filter === filters.TOUT) {
              return data[`unique_users_${index}`] + data[`uses_${index}`];
            }
            if (filter === filters.UTILISATIONS) {
              return data[`uses_${index}`];
            }
            return data[`unique_users_${index}`];
          }),
        ),
      ),
    );
    return max !== -Infinity ? max : 100;
  }, [statUsersData, filter, stats]);

  const stacks = useMemo(
    () =>
      stats.map((stat, index) => ({
        series: [
          `Utilisations période ${index + 1}`,
          `Utilisateurs uniques période ${index + 1}`,
        ],
      })),
    [stats],
  );

  const handleClickLeft = useCallback(() => {
    setPage(page - 1);
  }, [page]);

  const handleClickRight = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const legendRootBase = ({ ...restProps }) => (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: theme.spacing(2),
        }}
      >
        <IconButton
          aria-label="Déplacer vers la gauche"
          disabled={page === 0}
          onClick={handleClickLeft}
          size="large"
        >
          <IconLeft style={{ fontSize: "1.5rem" }} />
        </IconButton>
        <IconButton
          aria-label="Déplacer vers la droite"
          disabled={!hasMore}
          onClick={handleClickRight}
          size="large"
        >
          <IconRight style={{ fontSize: "1.5rem" }} />
        </IconButton>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex" }}>
        <Legend.Root
          {...restProps}
          aria-hidden="true"
          sx={{
            display: "flex",
            margin: "auto",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "85%",
            justifyContent: "center",
            "& .MuiListItemText-root": {
              width: "max-content",
            },
          }}
        />
      </Grid>
    </Grid>
  );

  const getPath = (x, width, y, y1) => `M ${x} ${y1}
  L ${width + x} ${y1}
  L ${width + x} ${y}
  L ${x} ${y}
  Z`;

  const getPathStrokeColor = (val, color) => {
    if (val) {
      if (color === theme.palette["bleu-10"]) {
        return theme.palette["bleu-80"];
      }
      if (color === theme.palette["jaune-50"]) {
        return theme.palette["primaire-jaune"];
      }
    }
    return null;
  };

  const Item = ({ ...restProps }) => (
    <Legend.Item {...restProps} sx={{ width: "auto" }} />
  );

  const handleFilter = useCallback((event, newFilter) => {
    setFilter(newFilter);
  }, []);

  const paginatedData = useMemo(() => {
    if (byMonth) {
      return (
        Array.isArray(statUsersData) && statUsersData.slice(page, page + 12)
      );
    }
    if (isMobile) {
      return (
        Array.isArray(statUsersData) && statUsersData.slice(page, page + 15)
      );
    }
    return statUsersData;
  }, [isMobile, statUsersData, page, byMonth]);

  const BarWithLabel = ({
    index,
    name,
    valueField,
    labelField,
    arg,
    barWidth,
    maxBarWidth,
    val,
    startVal,
    color,
  }) => {
    const width = maxBarWidth * barWidth;
    const currentData = paginatedData && paginatedData[index];

    return (
      <path
        aria-label={
          currentData[valueField]
            ? `${currentData[labelField]}, nombre d'${name}, ${currentData[valueField]}`
            : ""
        }
        d={getPath(arg - width / 2, width, val, startVal)}
        stroke={getPathStrokeColor(val - startVal, color)}
        strokeWidth="2px"
        fill={color}
      />
    );
  };

  BarWithLabel.propTypes = {
    name: PropTypes.string,
    valueField: PropTypes.string,
    labelField: PropTypes.string,
    index: PropTypes.number,
    arg: PropTypes.number,
    barWidth: PropTypes.number,
    maxBarWidth: PropTypes.number,
    val: PropTypes.number,
    startVal: PropTypes.number,
    color: PropTypes.string,
  };

  BarWithLabel.defaultProps = {
    name: "",
    labelField: "",
    valueField: "",
    arg: 0,
    index: 0,
    barWidth: 0,
    maxBarWidth: 0,
    val: 0,
    startVal: 0,
    color: "",
  };

  const getAxisLabel = (date) => {
    if (date) {
      if (byMonth) {
        const arrayDate = date.split("_");
        let month = arrayDate[0];
        if (isMobileXs) {
          month = month && month[0];
        }
        return isMobile ? month : `${month} ${arrayDate[1]?.substring(2, 4)}`;
      }
      return date;
    }
    return "";
  };

  return (
    <Paper {...otherProps}>
      <Box p={3}>
        <Typography component="h2" variant="h3">
          Évolution du nombre d’utilisateurs uniques et du nombre d’utilisations
        </Typography>
      </Box>
      <Divider />
      {stats.length === 0 && (
        <Box sx={{ p: theme.spacing(4) }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 400,
              color: theme.palette["cobalt-marque-blanche-second"],
              fontSize: "1.125rem",
            }}
          >
            Aucune donnée disponible
          </Typography>
        </Box>
      )}
      {stats.length > 1 && (
        <Box
          sx={{
            margin: theme.spacing(4, 0),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ToggleButtonGroup exclusive value={filter} onChange={handleFilter}>
            {Object.values(filters).map((filterValue) => (
              <ToggleButton value={filterValue} key={filterValue}>
                {filterValue}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      )}
      {Array.isArray(paginatedData) && (
        <Chart
          data={paginatedData}
          key={barSeries.length}
          aria-label="Graphique en barres"
        >
          <ArgumentAxis
            labelComponent={(labelProps) => (
              <ArgumentAxis.Label
                {...labelProps}
                text={getAxisLabel(labelProps.text)}
              />
            )}
            rootComponent={(rootProps) => (
              <ArgumentAxis.Root {...rootProps} aria-hidden="true" />
            )}
          />
          <ValueAxis
            rootComponent={(rootProps) => (
              <ValueAxis.Root {...rootProps} aria-hidden="true" />
            )}
          />
          <ValueScale modifyDomain={() => [0, maxValue]} />
          <EventTracker />
          <Tooltip
            overlayComponent={(overlayProps) => (
              <Tooltip.Overlay {...overlayProps}>
                {overlayProps.children &&
                  overlayProps.children.props &&
                  overlayProps.children.props.children}
              </Tooltip.Overlay>
            )}
            contentComponent={(info) => (
              <BarSeriesChartLabel
                info={info}
                series={barSeries}
                data={paginatedData}
                stacks={stacks}
              />
            )}
          />
          {barSeries.map((serie) => (
            <BarSeries
              key={serie.name}
              argumentField={serie.argumentField}
              valueField={serie.valueField}
              name={serie.name}
              color={serie.color}
              pointComponent={(values, index) => {
                return (
                  <BarWithLabel
                    index={index}
                    name={serie.name}
                    labelField={serie.labelField}
                    valueField={serie.valueField}
                    {...values}
                  />
                );
              }}
            />
          ))}
          <Legend
            position="bottom"
            rootComponent={legendRootBase}
            markerComponent={Marker}
            itemComponent={Item}
          />
          <Stack stacks={stacks} offset={() => 0} />
        </Chart>
      )}
    </Paper>
  );
};

StatUsers.propTypes = {
  periods: PropTypes.arrayOf(PeriodShape),
  stats: PropTypes.oneOfType([
    PropTypes.arrayOf(StatShape),
    PropTypes.arrayOf(StatPartnerShape),
  ]).isRequired,
  isPartner: PropTypes.bool.isRequired,
};

StatUsers.defaultProps = {
  periods: [],
};

export const DashboardStatUsers = withAccess(AclFront.DASHBOARD)(StatUsers);
export const PartnerStatUsers = withAccess(
  AclBack.PARTNERS_CONSO,
  Acl.READ,
)(StatUsers);
export const BackDashboardStatUsers = withAccess(
  AclBack.DASHBOARD_INDIRECT_SALE,
  Acl.READ,
)(StatUsers);
