import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path d="M18.043 11.524c-.162 0-.317-.058-.439-.164-.121-.106-.2-.252-.222-.412-.168-1.22-.752-2.343-1.653-3.182-.9-.839-2.064-1.34-3.292-1.421-1.228-.081-2.447.265-3.45.978-1.002.714-1.728 1.752-2.054 2.939-.047.17-.16.316-.313.403-.154.088-.336.111-.507.064-.17-.046-.315-.159-.403-.312-.088-.154-.11-.336-.064-.507.407-1.483 1.314-2.78 2.567-3.672 1.254-.892 2.776-1.324 4.311-1.224 1.535.101 2.988.729 4.114 1.777s1.855 2.453 2.065 3.976c.012.087.007.175-.015.26-.023.085-.061.164-.114.234-.053.07-.12.128-.195.173-.076.044-.16.072-.246.084-.03.005-.06.007-.09.006z" />
    <path d="M18.24 12.21c-.126 0-.25-.036-.357-.105L15.057 10.3c-.075-.047-.139-.108-.19-.18-.05-.071-.087-.153-.106-.238-.019-.086-.021-.175-.006-.262.015-.086.048-.169.095-.243.047-.074.109-.138.18-.189.073-.05.154-.085.24-.104.086-.019.175-.02.262-.004.086.016.169.048.242.096l2.209 1.411 1.198-2.33c.084-.15.224-.262.39-.312.164-.05.342-.033.495.046.154.079.27.214.326.377.056.164.047.342-.026.498l-1.532 2.982c-.043.083-.102.155-.174.214-.072.058-.156.101-.245.125-.057.016-.115.023-.174.023zM12.353 18.747c-1.613-.004-3.17-.59-4.385-1.65-1.215-1.06-2.007-2.524-2.23-4.121-.015-.088-.011-.178.01-.264.02-.087.059-.168.111-.24.053-.071.12-.131.197-.177.076-.045.161-.075.25-.087.088-.012.177-.006.263.017.086.023.167.063.237.118.07.055.128.123.172.2.043.079.07.164.08.253.169 1.219.752 2.343 1.653 3.182.901.838 2.064 1.34 3.293 1.42 1.228.082 2.446-.264 3.45-.978 1.002-.714 1.728-1.752 2.054-2.939.047-.17.16-.315.313-.403.153-.087.335-.11.506-.064.17.047.316.16.403.313.088.153.111.336.064.506-.389 1.41-1.229 2.655-2.392 3.542-1.164.888-2.586 1.37-4.049 1.372z" />
    <path d="M4.667 15.85c-.114 0-.227-.03-.326-.085-.1-.056-.184-.137-.243-.234-.06-.098-.093-.21-.098-.323-.004-.114.021-.228.074-.33l1.533-2.982c.042-.082.102-.154.174-.212.072-.058.156-.1.245-.124.09-.024.183-.03.275-.015.091.014.179.047.257.098l2.826 1.8c.074.047.139.108.19.18.05.072.086.153.105.239.02.086.022.174.006.261-.015.087-.047.17-.094.244-.048.074-.11.138-.181.188-.072.05-.154.086-.24.104-.086.019-.175.02-.261.005-.087-.016-.17-.049-.243-.097L6.458 13.16l-1.198 2.33c-.056.108-.141.2-.246.263-.104.064-.225.098-.347.098z" />
  </SvgIcon>
);
