import generateCRUD from "services/generateCRUD";
import axios from "axios";
import ScopesTechDataEnum from "../models/enums/ScopesTechDataEnum";

const name = "partners";
const PartnersCRUD = generateCRUD(name);

const transformScopes = (techData) => {
  return techData.scopes.filter((scope) =>
    Object.values(ScopesTechDataEnum)
      .map((value) => value.name)
      .some((enumName) => enumName === scope),
  );
};

export default class PartnerService extends PartnersCRUD {
  static update = async (partner) => {
    const { esUserId, subscriptionId, okapiStatus, ...object } = partner;
    if (object.contract) {
      delete object.contract.serviceStartDate;
    }
    return axios
      .patch(`${process.env.REACT_APP_api_url}/${name}/${object.id}`, {
        ...object,
        id: undefined,
      })
      .then((response) => {
        return response && response.data;
      });
  };

  static create = async (partner) => {
    return axios
      .post(`${process.env.REACT_APP_api_url}/${name}`, partner)
      .then((response) => {
        return response && response.data;
      });
  };

  static delete = async (partnerId) => {
    return axios
      .delete(`${process.env.REACT_APP_api_url}/${name}/${partnerId}`)
      .then((response) => {
        return response;
      });
  };

  static getTechDatas = (partnerId, useId, options = {}) => {
    return axios
      .get(
        `${process.env.REACT_APP_api_url}/${name}/${partnerId}/uses/${useId}/techDatas`,
        options,
      )
      .then((response) => {
        const resp = response && response.data;
        // If unknown scopes are provided by API (from Partners API or DB), remove it to avoid errors in console
        if (resp) {
          resp.data = resp.data.map((techData) => {
            return (
              techData && {
                ...techData,
                scopes: transformScopes(techData),
              }
            );
          });
        }
        return resp;
      });
  };

  static getUses = (partnerId, params) => {
    return axios
      .get(`${process.env.REACT_APP_api_url}/${name}/${partnerId}/uses`, params)
      .then((response) => {
        const resp = response && response.data;
        if (resp) {
          resp.data = resp.data.map((use) => {
            return (
              use && {
                ...use,
                techdatas: use.techDatas?.map((techData) => {
                  return (
                    techData && {
                      ...techData,
                      scopes: transformScopes(techData),
                    }
                  );
                }),
              }
            );
          });
        }
        return resp;
      });
  };

  static deleteUse = ({ partnerId, useId }) => {
    return axios
      .delete(
        `${process.env.REACT_APP_api_url}/${name}/${partnerId}/uses/${useId}`,
      )
      .then((response) => {
        return response;
      });
  };

  static createUse = ({ partnerId, useName }) => {
    return axios
      .post(`${process.env.REACT_APP_api_url}/${name}/${partnerId}/uses`, {
        useName,
      })
      .then((response) => {
        return response && response.data;
      });
  };

  static editUse = ({ id, partnerId, ...data }) => {
    return axios
      .patch(
        `${process.env.REACT_APP_api_url}/${name}/${partnerId}/uses/${id}`,
        data,
      )
      .then((response) => {
        return response && response.data;
      });
  };

  static getTechData = (partnerId, useId, techDataId) => {
    return axios
      .get(
        `${process.env.REACT_APP_api_url}/${name}/${partnerId}/uses/${useId}/techDatas/${techDataId}`,
      )
      .then((response) => {
        return response && response.data;
      });
  };

  static createTechData = ({
    partnerId,
    useId,
    useName,
    environmentType,
    ...techData
  }) => {
    return axios
      .post(
        `${process.env.REACT_APP_api_url}/${name}/${partnerId}/uses/${useId}/techDatas`,
        techData,
      )
      .then((response) => {
        return response && response.data;
      });
  };

  static editTechData = ({
    partnerId,
    useId,
    id,
    useName,
    environmentType,
    ...techData
  }) => {
    return axios
      .patch(
        `${process.env.REACT_APP_api_url}/${name}/${partnerId}/uses/${useId}/techDatas/${id}`,
        techData,
      )
      .then((response) => {
        return response && response.data;
      });
  };
}
