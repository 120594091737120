import PropTypes from "prop-types";
import CountryEnum from "models/enums/CountryEnum";

const AddressShape = PropTypes.shape({
  department: PropTypes.string,
  streetNb: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  zipCode: PropTypes.string,
  country: PropTypes.oneOf([...Object.values(CountryEnum), null, ""]),
});

export default AddressShape;
