import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M18.476 17.5H5.524V4.75c0-.414-.341-.75-.762-.75-.42 0-.762.336-.762.75V17.5c0 .828.682 1.5 1.524 1.5h12.952c.421 0 .762-.336.762-.75s-.341-.75-.762-.75z"
      clipRule="evenodd"
    />
    <path d="M14.174 13.044l-.442.442c.12.12.285.187.456.183.17-.004.332-.077.447-.202l-.461-.423zm5.5-6h.625c0-.345-.28-.625-.625-.625v.625zM7.732 13.602c-.244.244-.244.64 0 .884s.64.244.884 0l-.884-.884zm3.942-3.058l.442-.442c-.244-.244-.64-.244-.884 0l.442.442zm5-4.125c-.345 0-.625.28-.625.625s.28.625.625.625V6.42zm2.375 3.625c0 .345.28.625.625.625s.625-.28.625-.625h-1.25zm-4.414 3.423l5.5-6-.922-.845-5.5 6 .922.845zm-6.019 1.02l3.5-3.5-.884-.885-3.5 3.5.884.884zm2.616-3.5l2.5 2.5.884-.885-2.5-2.5-.884.884zm8.442-4.568h-3v1.25h3V6.42zm-.625.625v3h1.25v-3h-1.25z" />
  </SvgIcon>
);
