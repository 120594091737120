import React from "react";
import PropTypes from "prop-types";
import AclBack from "apps/back/AclBack";
import AclFront from "apps/front/AclFront";
import Acl from "utils/Acl";
import UserFactory from "models/factories/UserFactory";
import UserService from "services/UserService";
import UserForm from "./UserForm";

const CreateUserForm = (props) => {
  const {
    openUsersModal,
    setSnackbar,
    handleCloseUserModal,
    partnerId,
    handleCancel,
    keycloakClientId,
  } = props;

  const newUser = new UserFactory({
    attributes: { partnerId },
    roles: {
      [keycloakClientId]: [],
    },
  });

  if (
    (partnerId &&
      !Acl.hasAccess(AclBack.PARTNERS_USERS, Acl.CREATE) &&
      !Acl.hasAccess(AclFront.USERS, Acl.CREATE)) ||
    (!partnerId && !Acl.hasAccess(AclBack.ADMIN_USERS, Acl.CREATE))
  ) {
    return null;
  }

  const handleOpenSuccessSnackbar = (
    message = "L'utilisateur a été créé avec succès.",
  ) => {
    setSnackbar({
      open: true,
      variant: "success",
      message,
    });
  };

  const handleOpenErrorSnackbar = (
    message = "Une erreur est survenue lors de la création d'un utilisateur.",
  ) => {
    setSnackbar({
      open: true,
      variant: "error",
      message,
    });
  };

  const sendLinkToIdpAccountEmail = (userId) => {
    UserService.sendLinkToIdpAccountEmail(userId, {
      params: {
        clientId: partnerId
          ? process.env.REACT_APP_keycloak_front_client_id
          : process.env.REACT_APP_keycloak_client_id,
      },
    })
      .then(() => {
        handleOpenSuccessSnackbar(
          "Le mail d'association du compte a été envoyé.",
        );
      })
      .catch(() => {
        handleOpenErrorSnackbar(
          "Une erreur est survenue lors de l'envoi du mail.",
        );
      });
  };

  const handleValidateUser = async (values) => {
    const body = {
      username: values.email,
    };
    const keysNotInUser = ["roles", "isAssociated"];
    Object.keys(values).forEach((key) => {
      if (!keysNotInUser.includes(key)) {
        body[key] = values[key];
      } else if (partnerId) {
        body.clientRoles = values.roles;
      }
    });
    if (partnerId) {
      await UserService.createPartnerUser(partnerId, keycloakClientId, body)
        .then((userId) => {
          handleOpenSuccessSnackbar();
          sendLinkToIdpAccountEmail(userId);
          handleCloseUserModal();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            handleOpenErrorSnackbar(
              "Un utilisateur avec cette adresse mail existe déjà.",
            );
          } else {
            handleOpenErrorSnackbar();
          }
        });
    } else {
      await UserService.createUser(body)
        .then(async () => {
          await UserService.getUsers({
            params: {
              username: values.email,
            },
          }).then(async (user) => {
            const userId = user[0]?.id;
            await UserService.getClients({
              params: {
                clientId: keycloakClientId,
              },
            })
              .then(async (client) => {
                const clientUuid = client[0].id;
                await UserService.getClientRoles(clientUuid)
                  .then(async (roles) => {
                    const rolesToAdd = roles.filter(
                      (role) =>
                        Array.isArray(values.roles[keycloakClientId]) &&
                        values.roles[keycloakClientId].includes(role.name),
                    );
                    await UserService.addRoleForUser({
                      userId,
                      clientUuid,
                      data: rolesToAdd,
                    })
                      .then(() => {
                        handleOpenSuccessSnackbar();
                      })
                      .catch(() => {
                        handleOpenErrorSnackbar();
                      });
                  })
                  .catch(() => {
                    handleOpenErrorSnackbar();
                  });
              })
              .catch(() => {
                handleOpenErrorSnackbar();
              });
            sendLinkToIdpAccountEmail(userId);
          });
          handleCloseUserModal();
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errorMessage ===
              "User exists with same username"
          ) {
            handleOpenErrorSnackbar(
              "Un utilisateur avec cette adresse mail existe déjà.",
            );
          } else {
            handleOpenErrorSnackbar();
          }
        });
    }
  };

  return (
    <>
      <UserForm
        title="Ajouter un utilisateur"
        currentUser={newUser}
        handleValidateUser={handleValidateUser}
        openUsersModal={openUsersModal}
        handleCloseUserModal={handleCancel}
        keycloakClientId={keycloakClientId}
      />
    </>
  );
};

CreateUserForm.propTypes = {
  openUsersModal: PropTypes.bool,
  handleCloseUserModal: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  partnerId: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  keycloakClientId: PropTypes.string.isRequired,
};

CreateUserForm.defaultProps = {
  openUsersModal: false,
  partnerId: null,
};

export default CreateUserForm;
