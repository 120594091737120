import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const StyledStats = styled(Box)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(0.75, 1.5),
  backgroundColor: theme.palette["secondary-white"],
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
}));

const StyledStat = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(0.5, 0),
}));

const ChartLabel = (props) => {
  const { title, color, backgroundColor, stats } = props;

  return (
    <Paper
      sx={{
        borderRadius: "8px",
        border: `1.5px solid ${backgroundColor}`,
        boxShadow: "none",
        backgroundColor,
      }}
      clr={color}
      bgcolor={backgroundColor}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: (theme) => theme.spacing(1, 3),
        }}
      >
        <Typography
          variant="h5"
          component="span"
          sx={{ color, fontWeight: 600 }}
        >
          {title}
        </Typography>
      </Box>
      <StyledStats>
        {Array.isArray(stats) &&
          stats.map((stat) => (
            <StyledStat key={stat.label}>
              <Typography
                component="span"
                sx={(theme) => ({
                  fontFamily: "Barlow",
                  fontSize: "0.75rem",
                  color: theme.palette["cobalt-marque-blanche-main"],
                  marginRight: theme.spacing(2),
                })}
              >
                {stat.label}{" "}
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  color: (theme) => theme.palette["cobalt-marque-blanche-main"],
                }}
              >
                {stat.value}
              </Typography>
            </StyledStat>
          ))}
      </StyledStats>
    </Paper>
  );
};

ChartLabel.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

ChartLabel.defaultProps = {
  backgroundColor: "#3333ff",
  color: "#fff",
};

export default ChartLabel;
