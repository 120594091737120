import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { InputAdornment, TextField } from "@mui/material/";

const InputWithIcon = (props) => {
  const { startAdornment, adornment, children, ...others } = props;

  const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
    color: theme.palette["secondary-ultramarine"],
  }));

  return (
    <TextField
      variant="standard"
      {...others}
      InputProps={{
        startAdornment:
          adornment && startAdornment ? (
            <StyledInputAdornment position="start">
              {adornment}
            </StyledInputAdornment>
          ) : null,
        endAdornment:
          adornment && !startAdornment ? (
            <StyledInputAdornment position="end">
              {adornment}
            </StyledInputAdornment>
          ) : null,
      }}
    >
      {children}
    </TextField>
  );
};

InputWithIcon.propTypes = {
  startAdornment: PropTypes.bool,
  adornment: PropTypes.node,
  children: PropTypes.node,
};

InputWithIcon.defaultProps = {
  startAdornment: true,
  adornment: null,
  children: null,
};

export default InputWithIcon;
