import React from "react";

let roles = [];
let mapping = null;

export default class Acl {
  static READ = "READ";

  static CREATE = "CREATE";

  static UPDATE = "UPDATE";

  static DELETE = "DELETE";

  static ALL = "ALL";

  static setRoles = (r) => {
    roles = r;
  };

  static setMapping = (m) => {
    mapping = m;
  };

  static hasAccess = (functionnality, crud = Acl.ALL) => {
    if (!mapping || !Array.isArray(roles)) {
      return false;
    }
    return roles.some(
      (role) =>
        mapping[role] &&
        mapping[role][functionnality] &&
        (mapping[role][functionnality].includes(crud) ||
          mapping[role][functionnality].includes(Acl.ALL)),
    );
  };
}

export const withAccess = (functionnality, crud) => (Component) => (props) => {
  if (Acl.hasAccess(functionnality, crud)) {
    return <Component {...props} />;
  }
  return null;
};
