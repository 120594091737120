import React, { useState } from "react";
import PropTypes from "prop-types";
import AclBack from "apps/back/AclBack";
import AclFront from "apps/front/AclFront";
import Acl from "utils/Acl";
import UserService from "services/UserService";
import UserShape from "models/shapes/UserShape";
import UserForm from "apps/back/pages/partners/UserForm";
import ModalHeader from "components/ModalHeader";
import Modal from "components/Modal";
import { Box, Button, Divider, Typography } from "@mui/material";

const EditUserForm = (props) => {
  const {
    currentUser,
    editPartnerUser,
    setSnackbar,
    openUsersModal,
    handleCloseUserModal,
    handleCancel,
    keycloakClientId,
  } = props;

  const handleOpenSuccessSnackbar = (
    message = "L'utilisateur a été modifié avec succès.",
  ) => {
    setSnackbar({
      open: true,
      variant: "success",
      message,
    });
  };

  const handleOpenErrorSnackbar = (
    message = "Une erreur est survenue lors de la modification d'un utilisateur.",
  ) => {
    setSnackbar({
      open: true,
      variant: "error",
      message,
    });
  };

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const linkToLIN = () => {
    UserService.sendLinkToIdpAccountEmail(currentUser.id, {
      params: {
        clientId: editPartnerUser
          ? process.env.REACT_APP_keycloak_front_client_id
          : process.env.REACT_APP_keycloak_client_id,
      },
    })
      .then(() => {
        handleOpenSuccessSnackbar(
          "Le mail d'association du compte a été envoyé.",
        );
      })
      .catch(() => {
        handleOpenErrorSnackbar(
          "Une erreur est survenue lors de l'envoi du mail.",
        );
      });
    handleCloseUserModal();
  };

  const unlinkFromLIN = async () => {
    if (editPartnerUser) {
      await UserService.unlinkPartnerUserFromLIN(currentUser.id, {
        params: {
          clientId: process.env.REACT_APP_keycloak_front_client_id,
          provider: process.env.REACT_APP_identity_provider,
        },
      })
        .then(async () => {
          await UserService.updatePartnerUser(
            currentUser.id,
            currentUser.attributes?.partnerId,
            process.env.REACT_APP_keycloak_front_client_id,
            {
              enabled: false,
            },
          );
          handleOpenSuccessSnackbar(
            "Le compte de l'utilisateur a été dissocié.",
          );
        })
        .catch(() => {
          handleOpenErrorSnackbar(
            "Une erreur est survenue lors de la dissociation du compte de l'utilisateur.",
          );
        });
    } else {
      await UserService.unlinkFromLIN(currentUser.id)
        .then(async () => {
          await UserService.updateUser({
            id: currentUser.id,
            enabled: false,
          });
          handleOpenSuccessSnackbar(
            "Le compte de l'utilisateur a été dissocié.",
          );
        })
        .catch(() => {
          handleOpenErrorSnackbar(
            "Une erreur est survenue lors de la dissociation du compte de l'utilisateur.",
          );
        });
    }
    handleCloseConfirmationModal();
    handleCloseUserModal();
  };

  if (
    (editPartnerUser &&
      !Acl.hasAccess(AclBack.PARTNERS_USERS, Acl.UPDATE) &&
      !Acl.hasAccess(AclFront.USERS, Acl.UPDATE)) ||
    (!editPartnerUser && !Acl.hasAccess(AclBack.ADMIN_USERS, Acl.UPDATE))
  ) {
    return null;
  }

  const handleValidateUser = async (values) => {
    const body = {};
    const keysNotInUser = ["attributes", "roles", "isAssociated"];
    Object.keys(values).forEach((key) => {
      if (!keysNotInUser.includes(key) && values[key] !== currentUser[key]) {
        body[key] = values[key];
      } else if (key === "roles" && editPartnerUser) {
        body.clientRoles = values.roles;
      }
    });
    if (
      Object.keys(body).length > 0 ||
      values.attributes?.poste !== currentUser.attributes?.poste ||
      values.attributes?.phoneNumber !== currentUser.attributes?.phoneNumber
    ) {
      body.attributes = values.attributes;
    }
    if (editPartnerUser) {
      await UserService.updatePartnerUser(
        currentUser.id,
        currentUser.attributes?.partnerId,
        keycloakClientId,
        body,
      )
        .then(() => {
          handleOpenSuccessSnackbar();
          handleCloseUserModal();
        })
        .catch(() => {
          handleOpenErrorSnackbar();
        });
    } else {
      let isSuccess = true;
      if (
        (currentUser.roles[keycloakClientId] &&
          values.roles[keycloakClientId] !==
            currentUser.roles[keycloakClientId]) ||
        !currentUser.roles[keycloakClientId]
      ) {
        await UserService.getClients({
          params: {
            clientId: keycloakClientId,
          },
        })
          .then(async (client) => {
            const clientUuid = client[0].id;
            await UserService.getClientRoles(clientUuid)
              .then(async (roles) => {
                const rolesToAdd = roles.filter(
                  (role) =>
                    values.roles[keycloakClientId] &&
                    values.roles[keycloakClientId].includes(role.name) &&
                    ((currentUser.roles[keycloakClientId] &&
                      !currentUser.roles[keycloakClientId].includes(
                        role.name,
                      )) ||
                      !currentUser.roles[keycloakClientId]),
                );
                const rolesToDelete = roles.filter(
                  (role) =>
                    currentUser.roles[keycloakClientId] &&
                    currentUser.roles[keycloakClientId].includes(role.name) &&
                    ((values.roles[keycloakClientId] &&
                      !values.roles[keycloakClientId].includes(role.name)) ||
                      !values.roles[keycloakClientId]),
                );
                if (rolesToDelete && rolesToDelete.length > 0) {
                  await UserService.deleteRoleForUser({
                    userId: currentUser && currentUser.id,
                    clientUuid,
                    data: { data: rolesToDelete },
                  }).catch(() => {
                    isSuccess = false;
                  });
                }
                if (rolesToAdd && rolesToAdd.length > 0) {
                  await UserService.addRoleForUser({
                    userId: currentUser && currentUser.id,
                    clientUuid,
                    data: rolesToAdd,
                  }).catch(() => {
                    isSuccess = false;
                  });
                }
              })
              .catch(() => {
                isSuccess = false;
              });
          })
          .catch(() => {
            isSuccess = false;
          });
      }
      if (body && Object.keys(body).length !== 0) {
        await UserService.updateUser({ ...body, id: currentUser.id })
          .then(() => {
            if (isSuccess) {
              handleOpenSuccessSnackbar();
            } else {
              handleOpenErrorSnackbar();
            }
          })
          .catch(() => {
            handleOpenErrorSnackbar();
          });
      }
      handleCloseUserModal();
    }
  };

  return (
    <>
      {currentUser && (
        <>
          <UserForm
            title="Modifier un utilisateur"
            edit
            currentUser={currentUser}
            handleValidateUser={handleValidateUser}
            handleLinkToLIN={linkToLIN}
            handleOpenLINConfirmationModal={() =>
              setOpenConfirmationModal(true)
            }
            openUsersModal={openUsersModal}
            handleCloseUserModal={handleCancel}
            keycloakClientId={keycloakClientId}
          />
          <Modal
            open={openConfirmationModal}
            disableBackdropClick
            onClose={handleCloseConfirmationModal}
            size="sm"
          >
            <Box>
              <ModalHeader
                handleCloseModal={handleCloseConfirmationModal}
                title={`Êtes-vous sûr de vouloir dissocier L'Identité Numérique de ${currentUser.firstName} ${currentUser.lastName} ?`}
              />
              <Divider />
              <Box
                sx={{
                  p: (theme) => theme.spacing(4, 3),
                }}
              >
                <Typography
                  component="div"
                  variant="h6"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 400,
                  }}
                >
                  {`En cliquant sur confirmer, ${currentUser.firstName} ${currentUser.lastName} ne pourra plus utiliser son Identité Numérique pour se connecter à son espace partenaire business.`}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  p: (theme) => theme.spacing(3, 4),
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{ color: (theme) => theme.palette["primaire-bleu"] }}
                  variant="text.primary"
                  onClick={handleCloseConfirmationModal}
                >
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={unlinkFromLIN}
                >
                  Confirmer
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

EditUserForm.propTypes = {
  currentUser: UserShape,
  editPartnerUser: PropTypes.bool,
  setSnackbar: PropTypes.func.isRequired,
  openUsersModal: PropTypes.bool,
  handleCloseUserModal: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  keycloakClientId: PropTypes.string.isRequired,
};

EditUserForm.defaultProps = {
  currentUser: null,
  editPartnerUser: false,
  openUsersModal: false,
};

export default EditUserForm;
