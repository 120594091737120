import React from "react";
import PropTypes from "prop-types";

import { styled, useTheme } from "@mui/material/styles";
import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import AclBack from "apps/back/AclBack";
import ArrowRightIcon from "icons/arrow_right";
import Acl from "utils/Acl";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: theme.spacing(35.125),
    height: theme.spacing(8),
  },
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: "inherit",
}));

const RankingButtonGroup = (props) => {
  const { changeFilter, filters, filter } = props;

  const theme = useTheme();

  const handleFilter = (event, newFilter) => {
    changeFilter(newFilter);
  };

  return (
    <ToggleButtonGroup
      exclusive
      orientation="vertical"
      value={filter}
      onChange={handleFilter}
      role="menubar"
      aria-orientation="vertical"
    >
      {filters &&
        Array.isArray(filters) &&
        filters.map(
          (uniqueFilter, index) =>
            index <= 3 &&
            uniqueFilter && (
              <StyledToggleButton
                key={uniqueFilter.sentence}
                value={uniqueFilter.sentence}
                selected={uniqueFilter.sentence === filter.sentence}
                role="menuitem"
                aria-label={`Classement des ${uniqueFilter.title} par ${uniqueFilter.subtitle}`}
              >
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={11}>
                    <Typography
                      align="left"
                      variant="h4"
                      component="div"
                      color="inherit"
                      sx={{ pb: theme.spacing(1) }}
                    >
                      {uniqueFilter.title}
                    </Typography>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      component="div"
                      sx={{
                        color:
                          uniqueFilter.sentence === filter.sentence
                            ? "inherit"
                            : theme.palette["secondary-lynch"],
                      }}
                    >
                      {uniqueFilter.subtitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography color="inherit">
                      <ArrowRightIcon />
                    </Typography>
                  </Grid>
                </Grid>
              </StyledToggleButton>
            ),
        )}

      {Acl.hasAccess(AclBack.DASHBOARD_GLOBAL, Acl.READ) && filter && (
        <StyledToggleButton
          value={filters[4].sentence}
          selected={filters[4].sentence === filter.sentence}
          role="menuitem"
          aria-label={`Classement des ${filters[4].title} par ${filters[4].subtitle}`}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={11}>
              <Typography
                align="left"
                variant="h4"
                component="div"
                color="inherit"
                sx={{ pb: theme.spacing(1) }}
              >
                {filters[4].title}
              </Typography>
              <Typography
                align="left"
                variant="subtitle2"
                component="div"
                sx={{
                  color:
                    filters[4].sentence === filter.sentence
                      ? "inherit"
                      : theme.palette["secondary-lynch"],
                }}
              >
                {filters[4].subtitle}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color="inherit">
                <ArrowRightIcon />
              </Typography>
            </Grid>
          </Grid>
        </StyledToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

RankingButtonGroup.propTypes = {
  changeFilter: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      ressource: PropTypes.string,
      sentence: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
  ).isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string,
    ressource: PropTypes.string,
    sentence: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
};

export default RankingButtonGroup;
