import * as Yup from "yup";
import { isEmpty } from "utils/data";

const RibSchema = Yup.object().shape(
  {
    bankCode: Yup.string()
      .nullable()
      .test(
        "len",
        "5 caractères requis",
        (val) => !val || (val && (val.length === 0 || val.length === 5)),
      )
      .when(
        ["counterCode", "accountNumber", "key"],
        (counterCode, accountNumber, key, fieldSchema) => {
          return isEmpty([counterCode, accountNumber, key])
            ? fieldSchema
            : fieldSchema.required("Champ requis");
        },
      ),
    counterCode: Yup.string()
      .nullable()
      .test(
        "len",
        "5 caractères requis",
        (val) => !val || (val && (val.length === 0 || val.length === 5)),
      )
      .when(
        ["bankCode", "accountNumber", "key"],
        (bankCode, accountNumber, key, fieldSchema) => {
          return isEmpty([bankCode, accountNumber, key])
            ? fieldSchema
            : fieldSchema.required("Champ requis");
        },
      ),
    accountNumber: Yup.string()
      .nullable()
      .test(
        "len",
        "11 caractères requis",
        (val) => !val || (val && (val.length === 0 || val.length === 11)),
      )
      .when(
        ["bankCode", "counterCode", "key"],
        (bankCode, counterCode, key, fieldSchema) => {
          return isEmpty([bankCode, counterCode, key])
            ? fieldSchema
            : fieldSchema.required("Champ requis");
        },
      ),
    key: Yup.string()
      .nullable()
      .test(
        "len",
        "2 caractères requis",
        (val) => !val || (val && (val.length === 0 || val.length === 2)),
      )
      .when(
        ["bankCode", "counterCode", "accountNumber"],
        (bankCode, counterCode, accountNumber, fieldSchema) => {
          return isEmpty([bankCode, counterCode, accountNumber])
            ? fieldSchema
            : fieldSchema.required("Champ requis");
        },
      ),
  },
  [
    ["bankCode", "key"],
    ["bankCode", "counterCode"],
    ["bankCode", "accountNumber"],
    ["counterCode", "accountNumber"],
    ["counterCode", "key"],
    ["accountNumber", "key"],
  ],
);

export default RibSchema;
