import axios from "axios";
import StatPartnerFactory from "models/factories/StatPartnerFactory";
import StatFactory from "models/factories/StatFactory";

export default class StatService {
  static getStats = (params, csv = false) => {
    return axios
      .get(`${process.env.REACT_APP_api_url}/statistics`, params)
      .then((response) => {
        if (csv) {
          return response;
        }
        const data = response ? response.data : {};
        const { onlyUses } = params?.params || {};
        return onlyUses ? new StatPartnerFactory(data) : new StatFactory(data);
      });
  };
}
