import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 148 148"
    fill="none"
    width="148"
    height="148"
  >
    <circle cx="74" cy="74" r="74" fill="#F5F8FF" />
    <path
      d="M104.693 69.6501C105.256 73.6232 105.048 77.703 104.039 81.6603C102.097 89.2732 97.3377 95.8649 90.7222 100.103C84.1068 104.341 76.129 105.909 68.4016 104.49C60.6743 103.071 53.7739 98.7714 49.0955 92.4597C44.4172 86.148 42.3098 78.2954 43.1995 70.4895C44.0892 62.6835 47.9095 55.5065 53.8882 50.4095C59.8669 45.3124 67.5579 42.6755 75.4063 43.0319C79.7672 43.23 83.9959 44.3439 87.8351 46.2585"
      stroke="#0000FF"
      strokeWidth="3.50133"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.373 49.1211L73.8231 81.6205L64.9458 72.757"
      stroke="#FFCD00"
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
