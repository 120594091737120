import PropTypes from "prop-types";

const ContactShape = PropTypes.shape({
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  roleInCompany: PropTypes.string,
});

export default ContactShape;
