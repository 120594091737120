import Acl from "utils/Acl";

const functionnalities = {
  DASHBOARD_INDIRECT_SALE: "DASHBOARD_INDIRECT_SALE",
  DASHBOARD_GLOBAL: "DASHBOARD_GLOBAL",
  SUIVI_CA: "SUIVI_CA",
  PARTNERS_LISTING_INDIRECT_SALE: "PARTNERS_LISTING_INDIRECT_SALE",
  PARTNERS_LISTING_GLOBAL: "PARTNERS_LISTING_GLOBAL",
  PARTNERS_DETAILS: "PARTNERS_DETAILS",
  PARTNERS_CONTACT_INFO: "PARTNERS_CONTACT_INFO",
  PARTNERS_CONSO: "PARTNERS_CONSO",
  PARTNERS_USERS: "PARTNERS_USERS",
  PARTNERS_INVOICE: "PARTNERS_INVOICE",
  PARTNERS_TECHNICAL_INFO: "PARTNERS_TECHNICAL_INFO",
  PARTNERS_STATUS: "PARTNERS_STATUS",
  ADMIN_USERS: "ADMIN_USERS",
};

const functionnalitiesLabels = {
  DASHBOARD_INDIRECT_SALE:
    "Tableau de bord (clients Docaposte vente indirecte uniquement)",
  DASHBOARD_GLOBAL: "Tableau de bord global",
  ACCESS_PARTNERS_LISTING_INDIRECT_SALE:
    "Accès au listing des partenaires (clients Docaposte vente indirecte uniquement)",
  ACCESS_PARTNERS_LISTING_GLOBAL: "Accès au listing des partenaires global",
  ACCESS_PARTNERS_DETAILS: "Accès fiche détaillé partenaire",
  MANAGE_PARTNERS_DETAILS: "Création et gestion compte partenaire",
  ACCESS_PARTNERS_USERS:
    "Accès au listing des utilisateurs rattachés au compte partenaire",
  MANAGE_PARTNERS_USERS:
    "Gestion des utilisateurs rattachés au compte partenaire",
  ACCESS_PARTNERS_TECHNICAL_INFO: "Accès données techniques",
  MANAGE_PARTNERS_TECHNICAL_INFO: "Paramétrage technique du compte partenaire",
  UPDATE_PARTNERS_STATUS: "Modification du statut d'un compte partenaire",
  ACCESS_ADMIN_USERS: "Accès au listing des comptes utilisateurs du BO",
  MANAGE_ADMIN_USERS: "Gestion des comptes utilisateurs du BO",
};

export default functionnalities;

export const profiles = {
  // Code mis en commentaire pour le ticket 185, car il s'agit de modification temporaire (commentaire à supprimer une fois le code remis en place
  // RESPONSABLE_DOCAPOSTE: {
  //   [functionnalities.DASHBOARD_INDIRECT_SALE]: [Acl.READ],
  //   [functionnalities.DASHBOARD_GLOBAL]: [Acl.READ],
  //   [functionnalities.PARTNERS_LISTING_INDIRECT_SALE]: [Acl.READ],
  //   [functionnalities.PARTNERS_LISTING_GLOBAL]: [Acl.READ],
  //   [functionnalities.PARTNERS_DETAILS]: [Acl.READ],
  //   [functionnalities.PARTNERS_USERS]: [Acl.READ],
  //   [functionnalities.PARTNERS_CONTACT_INFO]: [Acl.READ],
  //   [functionnalities.PARTNERS_CONSO]: [Acl.READ],
  //   [functionnalities.PARTNERS_INVOICE]: [Acl.READ],
  // },
  // RESPONSABLE_VENTE: {
  //   [functionnalities.DASHBOARD_INDIRECT_SALE]: [Acl.READ],
  //   [functionnalities.PARTNERS_LISTING_INDIRECT_SALE]: [Acl.READ],
  //   [functionnalities.PARTNERS_DETAILS]: [Acl.READ],
  //   [functionnalities.PARTNERS_USERS]: [Acl.READ],
  //   [functionnalities.PARTNERS_CONTACT_INFO]: [Acl.READ],
  //   [functionnalities.PARTNERS_CONSO]: [Acl.READ],
  //   [functionnalities.PARTNERS_INVOICE]: [Acl.READ],
  // },
  ADMIN_LIN: {
    [functionnalities.DASHBOARD_INDIRECT_SALE]: [Acl.READ],
    [functionnalities.DASHBOARD_GLOBAL]: [Acl.READ],
    [functionnalities.SUIVI_CA]: [Acl.READ],
    [functionnalities.PARTNERS_LISTING_INDIRECT_SALE]: [Acl.READ],
    [functionnalities.PARTNERS_LISTING_GLOBAL]: [Acl.READ],
    [functionnalities.PARTNERS_USERS]: [Acl.READ],
    [functionnalities.PARTNERS_DETAILS]: [Acl.READ],
    [functionnalities.PARTNERS_CONTACT_INFO]: [Acl.READ],
    [functionnalities.PARTNERS_CONSO]: [Acl.READ],
    [functionnalities.PARTNERS_INVOICE]: [Acl.READ],
  },
  EXPERT: {
    [functionnalities.DASHBOARD_INDIRECT_SALE]: [Acl.READ],
    [functionnalities.DASHBOARD_GLOBAL]: [Acl.READ],
    [functionnalities.SUIVI_CA]: [Acl.READ],
    [functionnalities.PARTNERS_LISTING_INDIRECT_SALE]: [Acl.READ],
    [functionnalities.PARTNERS_LISTING_GLOBAL]: [Acl.READ],
    [functionnalities.PARTNERS_DETAILS]: [Acl.READ, Acl.CREATE, Acl.UPDATE],
    [functionnalities.PARTNERS_CONTACT_INFO]: [Acl.READ, Acl.UPDATE],
    [functionnalities.PARTNERS_CONSO]: [Acl.READ],
    [functionnalities.PARTNERS_USERS]: [Acl.CREATE, Acl.READ, Acl.UPDATE],
    [functionnalities.PARTNERS_INVOICE]: [Acl.READ, Acl.UPDATE],
    [functionnalities.PARTNERS_TECHNICAL_INFO]: [
      Acl.READ,
      Acl.UPDATE,
      Acl.CREATE,
    ],
    [functionnalities.PARTNERS_STATUS]: [Acl.READ, Acl.UPDATE],
    [functionnalities.ADMIN_USERS]: [Acl.READ],
  },
  SUPERADMIN: {
    [functionnalities.DASHBOARD_INDIRECT_SALE]: [Acl.READ],
    [functionnalities.DASHBOARD_GLOBAL]: [Acl.READ],
    [functionnalities.SUIVI_CA]: [Acl.READ],
    [functionnalities.PARTNERS_LISTING_INDIRECT_SALE]: [Acl.READ],
    [functionnalities.PARTNERS_LISTING_GLOBAL]: [Acl.READ],
    [functionnalities.PARTNERS_DETAILS]: [Acl.READ, Acl.CREATE, Acl.UPDATE],
    [functionnalities.PARTNERS_CONTACT_INFO]: [Acl.READ, Acl.UPDATE],
    [functionnalities.PARTNERS_CONSO]: [Acl.READ],
    [functionnalities.PARTNERS_USERS]: [
      Acl.READ,
      Acl.CREATE,
      Acl.UPDATE,
      Acl.DELETE,
    ],
    [functionnalities.PARTNERS_INVOICE]: [Acl.READ, Acl.UPDATE],
    [functionnalities.PARTNERS_TECHNICAL_INFO]: [
      Acl.READ,
      Acl.UPDATE,
      Acl.CREATE,
    ],
    [functionnalities.PARTNERS_STATUS]: [Acl.READ, Acl.UPDATE],
    [functionnalities.ADMIN_USERS]: [Acl.ALL],
  },
};

export const getHigherLevelFunctionnality = (functionnality) => {
  switch (functionnality) {
    case functionnalitiesLabels.ACCESS_PARTNERS_TECHNICAL_INFO:
      return functionnalitiesLabels.MANAGE_PARTNERS_TECHNICAL_INFO;
    case functionnalitiesLabels.ACCESS_PARTNERS_USERS:
      return functionnalitiesLabels.MANAGE_PARTNERS_USERS;
    case functionnalitiesLabels.ACCESS_ADMIN_USERS:
      return functionnalitiesLabels.MANAGE_PARTNERS_USERS;
    case functionnalitiesLabels.DASHBOARD_INDIRECT_SALE:
      return functionnalitiesLabels.DASHBOARD_GLOBAL;
    case functionnalitiesLabels.ACCESS_PARTNERS_LISTING_INDIRECT_SALE:
      return functionnalitiesLabels.ACCESS_PARTNERS_LISTING_GLOBAL;
    case functionnalitiesLabels.ACCESS_PARTNERS_DETAILS:
      return functionnalitiesLabels.MANAGE_PARTNERS_DETAILS;
    default:
      return null;
  }
};

export const getFunctionnalityLabel = (functionnality, aclLevel) => {
  switch (functionnality) {
    case functionnalities.DASHBOARD_GLOBAL:
      if (aclLevel === Acl.READ) {
        return functionnalitiesLabels.DASHBOARD_GLOBAL;
      }
      return "";
    case functionnalities.DASHBOARD_INDIRECT_SALE:
      if (aclLevel === Acl.READ) {
        return functionnalitiesLabels.DASHBOARD_INDIRECT_SALE;
      }
      return "";
    case functionnalities.PARTNERS_LISTING_INDIRECT_SALE:
      if (aclLevel === Acl.READ) {
        return functionnalitiesLabels.ACCESS_PARTNERS_LISTING_INDIRECT_SALE;
      }
      return "";
    case functionnalities.PARTNERS_LISTING_GLOBAL:
      if (aclLevel === Acl.READ) {
        return functionnalitiesLabels.ACCESS_PARTNERS_LISTING_GLOBAL;
      }
      return "";
    case functionnalities.PARTNERS_DETAILS:
      if (aclLevel === Acl.READ) {
        return functionnalitiesLabels.ACCESS_PARTNERS_DETAILS;
      }
      return functionnalitiesLabels.MANAGE_PARTNERS_DETAILS;
    case functionnalities.PARTNERS_TECHNICAL_INFO:
      if (aclLevel === Acl.READ) {
        return functionnalitiesLabels.ACCESS_PARTNERS_TECHNICAL_INFO;
      }
      return functionnalitiesLabels.MANAGE_PARTNERS_TECHNICAL_INFO;
    case functionnalities.PARTNERS_STATUS:
      if (aclLevel === Acl.UPDATE) {
        return functionnalitiesLabels.UPDATE_PARTNERS_STATUS;
      }
      return "";
    case functionnalities.PARTNERS_USERS:
      if (aclLevel === Acl.READ) {
        return functionnalitiesLabels.ACCESS_PARTNERS_USERS;
      }
      return functionnalitiesLabels.MANAGE_PARTNERS_USERS;
    case functionnalities.ADMIN_USERS:
      if (aclLevel === Acl.READ) {
        return functionnalitiesLabels.ACCESS_ADMIN_USERS;
      }
      return functionnalitiesLabels.MANAGE_ADMIN_USERS;
    default:
      return "";
  }
};
