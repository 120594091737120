export const formatDateToDDMMYYYY = (date, separator = ".") => {
  if (date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day < 10 ? "0" : ""}${day}${separator}${
      month < 10 ? "0" : ""
    }${month}${separator}${year}`;
  }
  return "";
};

export const formatDateToYYYYMMDD = (date) => {
  const dateOptions = { year: "numeric", month: "numeric", day: "numeric" };
  return date.toLocaleDateString("fr-CA", dateOptions); // fr-CA returns YYYY-MM-DD date format
};

export const convertToMonth = (d1, d2) => {
  if (d1 && d2) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const yearsDiff = date2.getFullYear() - date1.getFullYear();
    return yearsDiff * 12 + (date2.getMonth() - date1.getMonth());
  }
  return null;
};

export const monthFullNames = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export const monthsNames = [
  "JAN",
  "FEV",
  "MAR",
  "AVR",
  "MAI",
  "JUIN",
  "JUI",
  "AOU",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const getShortDateFromKey = (key = "") => {
  if (/^[0-9]{4}-[0-9]{1,2}$/i.test) {
    const [, monthNumber] = key.split("-");
    return monthsNames[monthNumber - 1];
  }
  if (/^[0-9]{4}-[0-9]{1,2}$/i.test) {
    const [, dayNumber] = key.split("-");
    return dayNumber;
  }
  return key;
};

export const getFullDateFromKey = (key = "") => {
  if (/^[0-9]{4}-[0-9]{1,2}$/i.test(key)) {
    const [year, monthNumber] = key.split("-");
    return `${monthFullNames[monthNumber - 1]} ${year}`;
  }
  if (/^[0-9]{1,2}-[0-9]{1,2}$/i.test(key)) {
    const [monthNumber, dayNumber] = key.split("-");
    return `${dayNumber} ${monthFullNames[monthNumber - 1]}`;
  }
  return key;
};

export const getFileNameDateFormat = () => {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  return `${year}${month < 10 ? "0" : ""}${month}${day < 10 ? "0" : ""}${day}`;
};
