import PropTypes from "prop-types";

const RouteShape = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  hasAccess: PropTypes.func,
};

RouteShape.parent = PropTypes.shape(RouteShape);

export default PropTypes.shape(RouteShape);
