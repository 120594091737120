import React, { useCallback } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconUniqueUsers from "icons/profile_1";
import IconActiveApplications from "icons/user group";
import IconUsage from "icons/see";
import IconAverage from "icons/analytics";
import AclFront from "apps/front/AclFront";
import AclBack from "apps/back/AclBack";
import Acl, { withAccess } from "utils/Acl";
import Paper from "components/Paper";
import Stat from "components/stats/Stat";
import StatShape from "models/shapes/StatShape";
import StatPartnerShape from "models/shapes/StatPartnerShape";

const StyledGrid = styled(Grid)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
    borderRight: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StatConso = (props) => {
  const { stats, isConsolidated, isPartner, isDashboard } = props;

  const extendedVersion = !isPartner || (isDashboard && isConsolidated);

  const getStats = useCallback(
    (statsName) => {
      const ressource = isPartner ? "uses" : "applications";
      switch (statsName) {
        case "applications_number":
          return stats.map((stat) => stat.applications.length);
        case "uses_number":
          return stats.map((stat) => stat.uses.length);
        case "uses":
        case "unique_users":
          return stats.map((stat) =>
            stat[ressource].reduce(
              (total, app) => total + parseInt(app[statsName] || 0, 10),
              0,
            ),
          );
        case "average":
          /* eslint-disable no-case-declarations */
          const usesStats = getStats("uses");
          const uniqueUsersStats = getStats("unique_users");
          /* eslint-enable no-case-declarations */
          return uniqueUsersStats.map((uniqueUser, index) =>
            uniqueUser
              ? Math.round((usesStats[index] * 100) / uniqueUser) / 100
              : undefined,
          );
        default:
          return stats.map(() => undefined);
      }
    },
    [isPartner, stats],
  );

  return (
    <Paper>
      <Box p={3}>
        <Typography component="h2" variant="h3">
          Les chiffres de la consommation de L&apos;Identité Numérique
        </Typography>
      </Box>
      <Divider />
      <Grid container>
        {extendedVersion && (
          <StyledGrid item xs={12} md={6}>
            <Stat
              Icon={IconActiveApplications}
              label={`Nombre ${isPartner ? "d'usages" : "de partenaires"}`}
              stats={getStats(
                isPartner ? "uses_number" : "applications_number",
              )}
              isDashboard={isDashboard}
              extendedVersion={extendedVersion}
            />
          </StyledGrid>
        )}
        <StyledGrid item xs={12} md={extendedVersion ? 6 : 4}>
          <Stat
            Icon={IconUniqueUsers}
            label="Utilisateurs uniques"
            stats={getStats("unique_users")}
            isDashboard={isDashboard}
            extendedVersion={extendedVersion}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={extendedVersion ? 6 : 4}>
          <Stat
            Icon={IconUsage}
            label="Utilisations"
            stats={getStats("uses")}
            isDashboard={isDashboard}
            extendedVersion={extendedVersion}
          />
        </StyledGrid>
        <Grid item xs={12} md={extendedVersion ? 6 : 4}>
          <Stat
            Icon={IconAverage}
            label="Moyenne"
            stats={getStats("average")}
            isDashboard={isDashboard}
            extendedVersion={extendedVersion}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

StatConso.propTypes = {
  stats: PropTypes.oneOfType([
    PropTypes.arrayOf(StatShape),
    PropTypes.arrayOf(StatPartnerShape),
  ]),
  isPartner: PropTypes.bool.isRequired,
  isConsolidated: PropTypes.bool,
  isDashboard: PropTypes.bool,
};

StatConso.defaultProps = {
  stats: [],
  isConsolidated: false,
  isDashboard: false,
};

export const DashboardStatConso = withAccess(AclFront.DASHBOARD)(StatConso);
export const PartnerStatConso = withAccess(
  AclBack.PARTNERS_CONSO,
  Acl.READ,
)(StatConso);
export const BackDashboardStatConso = withAccess(
  AclBack.DASHBOARD_INDIRECT_SALE,
  Acl.READ,
)(StatConso);
