export default class RibFactory {
  constructor({
    bankCode = "",
    counterCode = "",
    accountNumber = "",
    key = "",
    ...additionalAttributes
  } = {}) {
    this.bankCode = bankCode;
    this.counterCode = counterCode;
    this.accountNumber = accountNumber;
    this.key = key;
    Object.keys(additionalAttributes).forEach((objectKey) => {
      this[objectKey] = this[objectKey] || additionalAttributes[objectKey];
    });
  }
}
