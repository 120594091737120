import * as Yup from "yup";
import { siretLength, validateSiret } from "utils/SiretUtil";
import ContractSchema from "models/schemas/ContractSchema";
import AddressSchema from "models/schemas/AddressSchema";
import ContactSchema from "models/schemas/ContactSchema";
import BillingSchema from "models/schemas/BillingSchema";
import SalesChannelEnum from "models/enums/SalesChannelEnum";
import PartnerEnterpriseTypeEnum from "models/enums/PartnerEnterpriseTypeEnum";
import PartnerTypologyEnum from "models/enums/PartnerTypologyEnum";
import BusinessLineEnum from "models/enums/BusinessLineEnum";

const PartnerSchema = Yup.object().shape({
  issuerCode: Yup.string().nullable(),
  companyName: Yup.string()
    .nullable()
    .required("Champ requis"),
  siretNumber: Yup.string()
    .nullable()
    .required("Champ requis")
    .length(
      siretLength,
      `Le siret doit comporter ${siretLength} chiffres (sans espace)`,
    )
    .test("is-siret", "Siret invalide", validateSiret),
  code: Yup.string().nullable(),
  salesChannel: Yup.string()
    .oneOf([
      ...Object.values(SalesChannelEnum).map((value) => value.name),
      null,
      "",
    ])
    .nullable(),
  businessLine: Yup.string()
    .oneOf([
      ...Object.values(BusinessLineEnum).map((value) => value.name),
      null,
      "",
    ])
    .nullable(),
  enterpriseType: Yup.string()
    .oneOf([...Object.values(PartnerEnterpriseTypeEnum), null, ""])
    .nullable(),
  typology: Yup.string()
    .oneOf([
      ...Object.values(PartnerTypologyEnum).map((value) => value.name),
      null,
      "",
    ])
    .nullable(),
  address: AddressSchema,
  contact: ContactSchema,
  contract: ContractSchema,
  billing: BillingSchema,
});

export default PartnerSchema;
