import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette["secondary-ultramarine"],
  margin: theme.spacing(3, 0, 1, 0),
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 400,
}));

const MentionsLegales = () => {
  return (
    <>
      <Typography
        variant="h1"
        sx={(theme) => ({
          color: theme.palette["secondary-ultramarine"],
          textAlign: "center",
          marginBottom: theme.spacing(3),
          fontSize: "2rem",
        })}
      >
        Mentions Légales Plateforme 2B
      </Typography>
      <StyledSubtitle variant="h3" component="h2">
        Informations légales
      </StyledSubtitle>
      <StyledParagraph>
        Conformément aux dispositions de la loi n°2004-575 du 21 juin 2004 pour
        la Confiance dans l&apos;économie numérique dite L.C.E.N. complétée plus
        tard par le décret du 9 mai 2007, il est porté à la connaissance des
        utilisateurs et visiteurs de la plateforme les présentes informations
        légales.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        Nom de domaine
      </StyledSubtitle>
      <StyledParagraph>
        <a href="https://business.lidentitenumerique.laposte.fr/">
          business.lidentitenumerique.laposte.fr/
        </a>
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        Éditeur du site
      </StyledSubtitle>
      <StyledParagraph>
        La Poste
        <br />
        SA au capital de 3 800 000 000 euros
        <br />
        356 000 000 RCS Paris
        <br />
        Siège social : 9 RUE DU COLONEL PIERRE AVIA – 75015 PARIS
        <br />
        Tél : 01 55 44 00 00
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        Hébergement
      </StyledSubtitle>
      <StyledParagraph>
        DOCAPOSTE
        <br />
        SAS au capital de 85 843 520€
        <br />
        Tél : 08 00 710 720
        <br />
        Siège social : 10 avenue Charles de Gaulle - 94220 CHARENTON LE PONT
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        Développeur de la plateforme
      </StyledSubtitle>
      <StyledParagraph>Docaposte Agility</StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        Directeur de la publication
      </StyledSubtitle>
      <StyledParagraph>
        Candice Dauge
        <br />
        Directeur adjoint de la publication : Antoine d&apos;Hébrail
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        Accessibilité du site
      </StyledSubtitle>
      <StyledParagraph>
        Le site est en principe accessible aux internautes 24h/24 et 7j/7, sauf
        interruption, programmée ou non, pour des besoins de maintenance ou en
        cas de force majeure.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        Propriété intellectuelle
      </StyledSubtitle>
      <StyledParagraph>
        L&apos;intégralité du site, notamment les marques, logos, signes et tout
        autre contenu fait l’objet d’une protection par les législations
        françaises et internationales relative à la propriété intellectuelle.
        <br />
        Le site et tous les éléments qui le composent sont et restent la
        propriété exclusive de la Société. Toute reproduction ou représentation,
        intégrale ou partielle, publication, copie des différents contenus du
        site sont interdites sans l’autorisation expresse de la Société, sauf
        exception légale.
        <br />
        Le non-respect de ce droit est susceptible de constituer une contrefaçon
        engageant la responsabilité civile ou pénale de l&apos;auteur de la
        contrefaçon.
        <br />
        L&apos;Utilisateur s&apos;engage à une utilisation des contenus du site
        dans un cadre strictement privé. Une utilisation des contenus à des fins
        commerciales est strictement interdite.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        Responsabilité de l&apos;Éditeur
      </StyledSubtitle>
      <StyledParagraph>
        Les informations diffusées sur le site et/ou accessibles par ce site
        proviennent de sources qui sont réputées fiables. Toutefois, ces
        informations sont susceptibles de contenir des inexactitudes techniques
        et des erreurs typographiques. La Société se réserve le droit de les
        corriger, dès que ces erreurs sont portées à sa connaissance. Il est
        fortement recommandé de vérifier l’exactitude et la pertinence des
        informations mis à disposition sur le site. Les informations sont
        susceptibles d&apos;être modifiées à tout moment, et peuvent avoir fait
        l&apos;objet de mises à jour. Ainsi, l&apos;Utilisateur assume seul
        l&apos;entière responsabilité de l&apos;utilisation des informations et
        contenus du présent site. La Société ne pourra en aucun cas être tenue
        responsable de tout dommage de quelque nature qu’il soit résultant de
        l’interprétation ou de l’utilisation des informations et contenus
        disponibles sur ce site.
      </StyledParagraph>
    </>
  );
};

export default MentionsLegales;
