import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";

const FormReadOnly = (props) => {
  const theme = useTheme();
  const { title, firstLevel, data, ...others } = props;

  const isNotEmpty = (values) => {
    if (values && Array.isArray(values)) {
      return values.some((val) => !!val && val.trim());
    }
    return false;
  };

  const getColumns = (sectionData, twoColumns) => {
    const filteredData = sectionData?.filter((field) => {
      const { value } = field || {};
      return !!value && (!Array.isArray(value) || isNotEmpty(value));
    });
    const dataArrayMiddle = Math.round(filteredData?.length / 2);
    const firstColumn = twoColumns
      ? filteredData?.slice(0, dataArrayMiddle)
      : filteredData;
    const secondColumn = twoColumns
      ? filteredData?.slice(dataArrayMiddle)
      : null;
    return [firstColumn, secondColumn];
  };

  const displayValueElement = (val, index = 0) => {
    if (Array.isArray(val)) {
      if (isNotEmpty(val)) {
        return val.map(displayValueElement, index + 1);
      }
      return displayValueElement(undefined, index + 1);
    }
    return (
      <Typography
        variant="subtitle1"
        key={`${val}${index}`}
        variantMapping={{ subtitle1: "div" }}
        sx={{
          color: theme.palette["cobalt-marque-blanche-second"],
          lineHeight: "normal",
          overflowWrap: "break-word",
        }}
      >
        {val}
      </Typography>
    );
  };

  const getColumn = (column, twoColumns) => (
    <Grid
      item
      xs={12}
      sm={twoColumns ? 6 : 12}
      sx={{
        padding: theme.spacing(0, 6),
      }}
    >
      {column &&
        Array.isArray(column) &&
        column.map(
          (el) =>
            el &&
            el.value && (
              <Box sx={{ margin: theme.spacing(4, 0) }} key={el.category}>
                {el.category === "node" && el.value}
                {el.category !== "node" && (
                  <>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{ marginBottom: theme.spacing(1) }}
                    >
                      {el.category}
                    </Typography>
                    {displayValueElement(el.value)}
                  </>
                )}
              </Box>
            ),
        )}
    </Grid>
  );

  return (
    <Paper
      sx={{
        margin: theme.spacing(3, 0),
        borderRadius: "8px",
        boxShadow:
          "0 1px 10px 0 rgba(0, 0, 45, 0.06), 0 1px 4px 0 rgba(0, 0, 45, 0.1), 0 0 0 0 rgba(0, 0, 46, 0.06)",
        backgroundColor: theme.palette["secondary-white"],
      }}
      {...others}
    >
      <Typography
        variant="h3"
        component={`h${firstLevel}`}
        sx={{ padding: theme.spacing(3.125, 6) }}
      >
        {title}
      </Typography>
      <Divider
        sx={{
          width: "100%",
          height: "1px",
          margin: 0,
          padding: 0,
          borderColor: theme.palette["grey-20"],
        }}
      />
      {data.map((section) => {
        const twoColumns = section.twoColumns !== false;
        const columns = getColumns(section.data, twoColumns);
        return (
          <Box
            key={section.title || 0}
            sx={{ padding: theme.spacing(1, 0, 2, 0) }}
          >
            {section.title && (
              <Typography
                variant="h4"
                component={`h${firstLevel + 1}`}
                sx={{
                  marginLeft: theme.spacing(6),
                  fontWeight: "bold",
                  fontSize: "1.125rem",
                }}
              >
                {section.title}
              </Typography>
            )}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {getColumn(columns[0], twoColumns)}
              {twoColumns && getColumn(columns[1], true)}
            </Grid>
          </Box>
        );
      })}
    </Paper>
  );
};

FormReadOnly.propTypes = {
  title: PropTypes.node.isRequired,
  firstLevel: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape()),
};

FormReadOnly.defaultProps = {
  firstLevel: 1,
  data: null,
};

export default FormReadOnly;
