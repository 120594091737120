export const getUsesFromStats = (stats) => {
  if (!Array.isArray(stats)) {
    return false;
  }
  const allUses = [];
  stats.forEach((stat) => {
    const { uses } = stat;
    uses.forEach((use) => {
      if (!allUses.includes(use.name)) {
        allUses.push(use.name);
      }
    });
  });
  return allUses;
};

export const getBusinessLinesFromStats = (stats) => {
  if (!Array.isArray(stats)) {
    return false;
  }
  const allBusinessLines = [];
  stats.forEach((stat) => {
    const { business_lines: businessLines } = stat;
    businessLines.forEach((businessLine) => {
      if (!allBusinessLines.some((b) => b.title === businessLine.title)) {
        allBusinessLines.push(businessLine);
      }
    });
  });
  return allBusinessLines;
};

export const getApplicationsFromStats = (stats) => {
  if (!Array.isArray(stats)) {
    return false;
  }
  const allApplications = [];
  stats.forEach((stat) => {
    const { applications } = stat;
    applications.forEach((application) => {
      if (!allApplications.some((app) => app.id === application.id)) {
        allApplications.push(application);
      }
    });
  });
  return allApplications;
};

export const filterStat = ({ stat, applications, businessLines }) => {
  const applicationsFilterIds = applications.map((app) => app.id);
  const businessLinesFilterIds = businessLines.map((b) => b.id);
  return {
    ...stat,
    applications: stat.applications.filter((app) => {
      const isInAppFilters =
        !applicationsFilterIds.length || applicationsFilterIds.includes(app.id);

      const isInBusinessLineFilters =
        !businessLinesFilterIds.length ||
        businessLinesFilterIds.some((id) => app.business_lines.includes(id));
      return isInAppFilters && isInBusinessLineFilters;
    }),
    business_lines: stat.business_lines
      .filter(
        (businessLine) =>
          !businessLinesFilterIds.length ||
          businessLinesFilterIds.includes(businessLine.id),
      )
      .map((businessLine) => ({
        ...businessLine,
        applicationIds: businessLine.applicationIds.filter(
          (id) =>
            !applicationsFilterIds.length || applicationsFilterIds.includes(id),
        ),
      })),
  };
};

export default {
  getUsesFromStats,
  filterStat,
  getApplicationsFromStats,
  getBusinessLinesFromStats,
};
