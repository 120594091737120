import PropTypes from "prop-types";
import AttributesShape from "./AttributesShape";

const UserShape = PropTypes.shape({
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  roles: PropTypes.shape().isRequired,
  attributes: AttributesShape,
  isAssociated: PropTypes.bool.isRequired,
});

export default UserShape;
