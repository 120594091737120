import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import HelpInfosEnum from "models/enums/HelpInfosEnum";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import PlusIcon from "icons/plus";
import CloseIcon from "icons/close";
import HelpIcon from "icons/help";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import TcWrapper from "react-tag-commander";
import { Section } from "../../../components/partners/UsesTechnicalData";

const StyledContentTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette["grey-subtitle"],
}));

const NeedHelp = () => {
  const [expanded, setExpanded] = useState([]);

  const wrapper = TcWrapper.getInstance();

  const handleChange = (panel) => (event, newExpanded) => {
    wrapper.triggerEvent("click", event.target, {
      clic_type: "action",
      name: `${panel}`.replace(/ /g, "_"),
      chapter1: "informations_techniques",
      chapter2: "besoin_aide",
    });
    if (newExpanded) {
      setExpanded(expanded.concat(panel));
    } else {
      setExpanded(expanded.filter((item) => item !== panel));
    }
  };

  return (
    <Section title="Besoin d'aide ?" icon={<HelpIcon />}>
      {Object.values(HelpInfosEnum).map((info, index) => (
        <Accordion
          key={info.title}
          square
          expanded={expanded.includes(info.title)}
          onChange={handleChange(info.title)}
          disableGutters
        >
          <AccordionSummary
            expandIcon={
              expanded.includes(info.title) ? (
                <CloseIcon
                  sx={(theme) => ({
                    width: "32px",
                    height: "32px",
                    color: theme.palette["secondary-ultramarine"],
                  })}
                />
              ) : (
                <PlusIcon
                  sx={(theme) => ({
                    width: "32px",
                    height: "32px",
                    color: theme.palette["secondary-ultramarine"],
                  })}
                />
              )
            }
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs="auto" sm="auto" md="auto">
                <Typography
                  variant="h4"
                  component="h3"
                  align="center"
                  sx={(theme) => ({
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: "50%",
                    width: theme.spacing(4),
                    height: theme.spacing(4),
                    padding: "7px 0",
                  })}
                >
                  {index + 1}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h4" component="h3">
                  {info.title}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="center">
              <Grid
                item
                sm={12}
                md={10}
                sx={(theme) => ({
                  borderLeft: `2px solid ${theme.palette.secondary.main}`,
                  marginLeft: theme.spacing(2.3),
                  paddingLeft: theme.spacing(6),
                  width: "100%",
                })}
              >
                <StyledContentTypo variant="subtitle1" component="div">
                  {info.content}
                </StyledContentTypo>
                {info.link && (
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Link href={info.link} underline="always">
                      {info.link}
                    </Link>
                  </Typography>
                )}
                {info.liste && (
                  <List>
                    {info.liste.map((item) => (
                      <ListItem key={item}>
                        <ListItemIcon>
                          <Typography
                            sx={(theme) => ({
                              borderRadius: "50%",
                              width: theme.spacing(1),
                              height: theme.spacing(1),
                              backgroundColor: theme.palette.primary.main,
                            })}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <StyledContentTypo
                            variant="subtitle1"
                            component="div"
                          >
                            {item}
                          </StyledContentTypo>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Section>
  );
};

export default NeedHelp;
