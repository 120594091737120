import PropTypes from "prop-types";
import AddressShape from "models/shapes/AddressShape";
import RibShape from "models/shapes/RibShape";
import BillingIssuingFrequencyEnum from "models/enums/BillingIssuingFrequencyEnum";
import BillingTypeEnum from "models/enums/BillingTypeEnum";
import BillingSendModeEnum from "models/enums/BillingSendModeEnum";
import TermsOfPaymentEnum from "models/enums/TermsOfPaymentEnum";
import ModeOfPaymentEnum from "models/enums/ModeOfPaymentEnum";

const BillingShape = PropTypes.shape({
  vatApplication: PropTypes.bool,
  intracommunityVatNumber: PropTypes.string,
  termsOfPayment: PropTypes.oneOf([
    ...Object.values(TermsOfPaymentEnum).map((value) => value.name),
    null,
    "",
  ]),
  modeOfPayment: PropTypes.oneOf([
    ...Object.values(ModeOfPaymentEnum).map((value) => value.name),
    null,
    "",
  ]),
  rib: RibShape,
  bic: PropTypes.string,
  bankDomiciliation: PropTypes.string,
  issuingFrequency: PropTypes.oneOf([
    ...Object.values(BillingIssuingFrequencyEnum).map((value) => value.name),
    null,
    "",
  ]),
  copiesNumber: PropTypes.number,
  sendMode: PropTypes.oneOf([
    ...Object.values(BillingSendModeEnum).map((value) => value.name),
    null,
    "",
  ]),
  billingSendEmail: PropTypes.string,
  typeOfBilling: PropTypes.oneOf([
    ...Object.values(BillingTypeEnum).map((value) => value.name),
    null,
    "",
  ]),
  annualCommitmentVolume: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  orderReference: PropTypes.string,
  purchaseReference: PropTypes.string,
  otherReferences: PropTypes.string,
  address: AddressShape,
});

export default BillingShape;
