import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AclBack from "apps/back/AclBack";
import Acl, { withAccess } from "utils/Acl";
import PartnerService from "services/PartnerService";
import Card from "components/Card";
import { formatDateToDDMMYYYY } from "utils/date";
import InnerNavigation from "components/InnerNavigation";
import IconTools from "icons/tools";
import IcoUserManagement from "icons/user management";
import IconAnalytics from "icons/analytics";
import IconSign from "icons/icon_doca_sign";
import UpdateStatusIcon from "icons/write";
import IconButton from "@mui/material/IconButton";
import Breadcrumb from "components/Breadcrumb";
import PartnerFactory from "models/factories/PartnerFactory";
import SalesChannelEnum from "models/enums/SalesChannelEnum";
import BillingTypeEnum from "models/enums/BillingTypeEnum";
import StatusEnum from "models/enums/StatusEnum";
import { getLabelRepresentation } from "utils/enum";
import Users from "apps/back/pages/partners/Users";
import ContractInformation from "components/partners/ContractInformation";
import { BackUsesTechnicalData } from "components/partners/UsesTechnicalData";
import ConsumptionMonitoring from "apps/back/pages/partners/ConsumptionMonitoring";
import CurrentRouteContext from "components/CurrentRouteContext";
import Modal from "components/Modal";
import PartnerStatusForm from "apps/back/pages/partners/PartnerStatusForm";
import Snackbar from "components/Snackbar";

const Partner = (props) => {
  const { match } = props;

  const { partnerId } = (match && match.params) || {};
  const [partner, setPartner] = useState(new PartnerFactory());
  const [modalEditStatusOpened, setModalEditStatusOpened] = useState(false);
  const { setTitle } = useContext(CurrentRouteContext);
  const [uses, setUses] = useState([]);
  const [usesNb, setUsesNb] = useState(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const getUses = useCallback(() => {
    PartnerService.getUses(partnerId, { params: { join: "techDatas" } })
      .then((response) => {
        const { data = [], total = 0 } = response;
        setUses(data.sort((a, b) => new Date(a.created) - new Date(b.created)));
        setUsesNb(total);
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message:
            "Une erreur est survenue lors de la récupération des usages.",
          variant: "error",
        });
      });
  }, [partnerId]);

  const getPartner = useCallback(async () => {
    await PartnerService.get(partnerId, {
      params: {
        personalData: Acl.hasAccess(AclBack.PARTNERS_USERS, Acl.READ),
      },
    })
      .then((result) => {
        setPartner(new PartnerFactory(result || {}));
        if (Acl.hasAccess(AclBack.PARTNERS_TECHNICAL_INFO, Acl.READ)) {
          getUses();
        }
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message:
            "Une erreur est survenue lors de la récupération du compte partenaire.",
          variant: "error",
        });
      });
  }, [getUses, partnerId]);

  useEffect(() => {
    getPartner();
  }, [getPartner]);

  useEffect(() => {
    setTitle(partner ? partner.companyName : "");
  }, [setTitle, partner]);

  const handleClickEditStatus = useCallback(() => {
    setModalEditStatusOpened(true);
  }, []);

  const handleCancelEditStatus = useCallback(() => {
    setModalEditStatusOpened(false);
  }, []);

  const handleValidateStatus = () => {
    getPartner();
    setModalEditStatusOpened(false);
  };

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  const canEdit = Acl.hasAccess(AclBack.PARTNERS_DETAILS, Acl.UPDATE);

  return (
    <>
      <Modal
        data-testid="modal"
        open={modalEditStatusOpened}
        onClose={handleCancelEditStatus}
      >
        <div style={{ width: isMobile ? "auto" : 700 }}>
          <PartnerStatusForm
            partner={partner}
            onValidate={handleValidateStatus}
            onCancel={handleCancelEditStatus}
          />
        </div>
      </Modal>
      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid
          item
          sx={{
            width: "340px",
            paddingTop: theme.spacing(13),
            [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
              display: "none",
            },
          }}
        >
          <aside>
            <InnerNavigation container>
              <InnerNavigation
                section
                Icon={IconAnalytics}
                title="Suivi de la consommation"
                target="suivi-de-la-consommation"
              />
              {Acl.hasAccess(AclBack.PARTNERS_TECHNICAL_INFO, Acl.READ) && (
                <InnerNavigation
                  section
                  Icon={IconTools}
                  title="Usages"
                  target="usages"
                />
              )}
              <InnerNavigation
                section
                Icon={IconSign}
                title="Informations du contrat"
                target="informations-du-contrat"
              />
              <InnerNavigation
                section
                Icon={IcoUserManagement}
                title="Utilisateurs de la plateforme"
                target="utilisateurs-de-la-plateforme"
              />
            </InnerNavigation>
          </aside>
        </Grid>
        <Grid
          item
          xs
          sx={{
            [theme.breakpoints.up(theme.breakpoints.mainViewWidth)]: {
              maxWidth: "770px",
            },
            [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
              maxWidth: "100%",
            },
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              marginBottom: theme.spacing(1),
            }}
          >
            <Grid item xs="auto" sx={{ maxWidth: "100%" }}>
              <Typography
                variant="h2"
                component="h1"
                sx={{
                  color: theme.palette["secondary-ultramarine"],
                  wordBreak: "break-word",
                }}
              >
                {partner.companyName}
              </Typography>
            </Grid>
            <Grid
              item
              xs="auto"
              sx={{
                maxWidth: theme.spacing(40),
                textAlign: "right",
              }}
            >
              <Typography component="span" variant="h4">
                Code partenaire :{" "}
                {partner.code || <span aria-label="Non renseigné">-</span>}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: theme.spacing(4),
            }}
          >
            <Grid item xs="auto">
              <Breadcrumb
                parent={{
                  label: "Mes partenaires",
                  path: "/listing-partenaires",
                }}
                current={{
                  label: partner.companyName,
                }}
              />
            </Grid>
            <Grid item xs="auto">
              <Typography variant="h6" component="span">
                Date de création:
                {partner.created ? (
                  formatDateToDDMMYYYY(new Date(partner.created))
                ) : (
                  <span aria-label="Non renseigné">-</span>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={12} sm={4}>
              <Card
                category="Canal de vente"
                value={
                  partner.salesChannel &&
                  getLabelRepresentation(SalesChannelEnum, partner.salesChannel)
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card
                category="Type de facturation"
                value={
                  partner.billing &&
                  partner.billing.typeOfBilling &&
                  getLabelRepresentation(
                    BillingTypeEnum,
                    partner.billing.typeOfBilling,
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card
                category="Statut"
                value={getLabelRepresentation(StatusEnum, partner.status)}
                onClick={canEdit ? handleClickEditStatus : undefined}
                style={canEdit ? { cursor: "pointer" } : {}}
                icon={
                  canEdit ? (
                    <IconButton
                      aria-label="Mettre à jour le statut"
                      sx={{ fontSize: "1.5em", padding: 0 }}
                    >
                      <UpdateStatusIcon color="primary" />
                    </IconButton>
                  ) : null
                }
              />
            </Grid>
          </Grid>
          <ConsumptionMonitoring
            partner={partner}
            isConsolidated={
              uses.filter((use) => use.status === StatusEnum.ACTIVE.name)
                .length > 1
            }
          />
          <BackUsesTechnicalData
            partner={partner}
            uses={uses}
            usesNb={usesNb}
            getUses={getUses}
            getPartner={getPartner}
          />
          <ContractInformation partner={partner} backUser />
          <Users
            users={partner?.keycloakData || []}
            partnerId={partner?.id}
            getPartner={getPartner}
          />
        </Grid>
      </Grid>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </>
  );
};

Partner.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default withAccess(AclBack.PARTNERS_DETAILS, Acl.READ)(Partner);
