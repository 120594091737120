import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M10.327 10.373c0 .39-.314.704-.7.704-.389 0-.704-.315-.704-.704 0-.848.343-1.616.899-2.171.555-.555 1.322-.898 2.17-.898.85 0 1.617.343 2.172.898.555.555.899 1.323.899 2.171 0 .849-.344 1.616-.899 2.172-.396.396-.902.686-1.467.816v.305c0 .389-.315.703-.704.703-.39 0-.704-.314-.704-.703v-.927c0-.385.315-.7.704-.7.46 0 .877-.188 1.178-.488.3-.3.488-.718.488-1.178 0-.46-.188-.877-.489-1.177-.3-.3-.717-.488-1.177-.488-.46 0-.877.187-1.178.488-.3.3-.488.718-.488 1.177zm1.595 6.32c-.389 0-.704-.312-.704-.7 0-.39.315-.704.704-.704h.156c.389 0 .704.315.704.704 0 .388-.315.7-.704.7h-.156zm.078 2.9c2.097 0 3.996-.85 5.368-2.225 1.376-1.372 2.225-3.27 2.225-5.368 0-2.097-.85-3.996-2.225-5.372-1.372-1.372-3.27-2.224-5.368-2.224-2.097 0-3.996.852-5.372 2.224C5.256 8.004 4.404 9.903 4.404 12s.852 3.996 2.224 5.368c1.376 1.376 3.275 2.225 5.372 2.225zm6.362-1.231C16.735 19.992 14.482 21 12 21c-2.486 0-4.735-1.008-6.365-2.638C4.008 16.735 3 14.482 3 12c0-2.486 1.008-4.735 2.635-6.365C7.265 4.008 9.514 3 12 3c2.482 0 4.735 1.008 6.362 2.635C19.992 7.265 21 9.514 21 12c0 2.482-1.008 4.735-2.638 6.362z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
