import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LINIcon from "icons/LIN_Marqueur";
import { visuallyHidden } from "@mui/utils";

const IsAssociatedBadge = (props) => {
  const { isAssociated, ...others } = props;
  const theme = useTheme();

  return (
    <Box
      {...others}
      sx={{
        borderRadius: "50px",
        color: isAssociated
          ? theme.palette["secondary-white"]
          : theme.palette["dark-theme-light-bg"],
        width: "max-content",
        alignItems: "center",
        p: "7px 10px",
        display: "flex",
        backgroundColor: isAssociated ? "#0F8072" : "#EDEDEE",
      }}
    >
      <LINIcon
        style={{
          color: isAssociated
            ? theme.palette["secondary-white"]
            : theme.palette["dark-theme-light-bg"],
        }}
      />
      <Typography
        variant="h6"
        component="div"
        sx={{
          color: isAssociated
            ? theme.palette["secondary-white"]
            : theme.palette["dark-theme-light-bg"],
          marginLeft: "3px",
        }}
      >
        {isAssociated ? "Associée" : "Non associée"}
        {<span style={visuallyHidden}>à L&apos;Identité Numérique</span>}
      </Typography>
    </Box>
  );
};

export default IsAssociatedBadge;

IsAssociatedBadge.propTypes = {
  isAssociated: PropTypes.bool.isRequired,
};
