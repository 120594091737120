import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path d="M11.834 20.667c4.878 0 8.833-3.955 8.833-8.833C20.667 6.955 16.712 3 11.834 3 6.955 3 3 6.955 3 11.834c0 4.878 3.955 8.833 8.834 8.833z" />
    <path
      fill="#fff"
      d="M10.018 15.967c-.187 0-.366-.07-.503-.196l-3.37-3.105c-.146-.133-.233-.319-.241-.516-.009-.197.061-.39.195-.535.133-.145.319-.232.516-.24.197-.009.39.061.535.195l2.848 2.629 6.384-6.384c.139-.14.327-.217.524-.218.196 0 .385.078.524.217s.217.327.217.524c0 .196-.078.385-.217.524l-6.887 6.888c-.07.069-.151.123-.241.16-.09.038-.187.057-.284.057z"
    />
  </SvgIcon>
);
