/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";

import { Box, Modal as MuiModal, Paper } from "@mui/material";

const Modal = (props) => {
  const { children, onClose, size, disableBackdropClick, ...others } = props;

  return (
    <MuiModal onClose={onClose} {...others}>
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={disableBackdropClick ? null : onClose}
      >
        <Paper
          onClick={(e) => e.stopPropagation()}
          sx={{
            width: (theme) =>
              `${
                size && theme.breakpoints.values[size]
                  ? `${theme.breakpoints.values[size]}px`
                  : "auto"
              }`,
            maxWidth: `100%`,
            maxHeight: "95%",
            overflow: "auto",
          }}
        >
          {children}
        </Paper>
      </Box>
    </MuiModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

Modal.defaultProps = {
  className: "",
  disableBackdropClick: false,
  children: null,
  size: null,
};

export default Modal;
