const correspondenceTable = {
  A: "1",
  B: "2",
  C: "3",
  D: "4",
  E: "5",
  F: "6",
  G: "7",
  H: "8",
  I: "9",
  J: "1",
  K: "2",
  L: "3",
  M: "4",
  N: "5",
  O: "6",
  P: "7",
  Q: "8",
  R: "9",
  S: "2",
  T: "3",
  U: "4",
  V: "5",
  W: "6",
  X: "7",
  Y: "8",
  Z: "9",
};

const getNumericForm = (data) => {
  const numericFormData = [...data]
    .map((char) => {
      return char.match(/[A-Za-z]/i)
        ? correspondenceTable[char.toUpperCase()]
        : char;
    })
    .join("");
  return Number(numericFormData);
};

const getKeyValue = (rib) => {
  return (
    rib.bankCode &&
    rib.counterCode &&
    rib.accountNumber &&
    97 -
      ((89 * Number(rib.bankCode) +
        15 * Number(rib.counterCode) +
        3 * getNumericForm(rib.accountNumber)) %
        97)
  );
};

export const isValidRib = (rib) => {
  if (!rib.bankCode && !rib.counterCode && !rib.accountNumber && !rib.key) {
    return true;
  }
  const calculatedKey = getKeyValue(rib);
  return calculatedKey && rib.key && calculatedKey === Number(rib.key);
};

export default { isValidRib };
