import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      fill="none"
      stroke="currentColor"
      d="M9.75 2.25v4.382c0 .567-.16 1.122-.463 1.602l-5.855 9.273c-1.163 1.842.16 4.243 2.337 4.243h12.462c2.177 0 3.5-2.4 2.337-4.243l-5.855-9.273c-.303-.48-.463-1.035-.463-1.602V2.25m-6 0h7.5-7.5zm-2.719 12H18.47 5.53z"
    />
  </SvgIcon>
);
