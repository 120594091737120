import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import keycloak from "keycloak";
import "./index.css";
import App from "./App";

axios.interceptors.request.use(
  async (config) => {
    const configuration = config;
    configuration.headers.authorization = `Bearer ${keycloak.token}`;
    return configuration;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      keycloak.logout();
    }
    return Promise.reject(error);
  },
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
