import React from "react";
import { styled } from "@mui/material/styles";
import { Link, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette["secondary-ultramarine"],
  margin: theme.spacing(3, 0, 1, 0),
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 400,
}));

const StyledListItem = styled(Typography)({
  fontWeight: 400,
});

const StyledLinkText = styled(Link)({
  lineBreak: "anywhere",
});

const Accessibilite = () => {
  return (
    <div>
      <Typography
        variant="h1"
        sx={(theme) => ({
          color: theme.palette["secondary-ultramarine"],
          textAlign: "center",
          marginBottom: theme.spacing(3),
          fontSize: "2rem",
        })}
      >
        Déclaration d&apos;accessibilité
      </Typography>
      <StyledParagraph>
        Le Groupe La Poste ss&apos;engage à rendre ses sites internet, intranet,
        extranet, ses progiciels et ses applications mobiles accessibles
        conformément à l&apos;article 47 de la loi n°2005-102 du 11 février
        2005.
      </StyledParagraph>
      <StyledParagraph>
        À cette fin, Le Groupe La Poste met en œuvre la stratégie et les actions
        suivantes :
      </StyledParagraph>
      <ul>
        <li>
          <StyledListItem>
            Schéma pluriannuel de mise en accessibilité 2023-2025 (rédaction en
            cours, publication à venir);
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            Actions réalisées en 2021-2022 (rédaction en cours, publication à
            venir);
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            Plan d&apos;actions 2023-2025 (rédaction en cours, publication à
            venir).
          </StyledListItem>
        </li>
      </ul>
      <StyledParagraph>
        Cette déclaration d&apos;accessibilité s&apos;applique à{" "}
        <StyledLinkText
          href="https://business.lidentitenumerique.laposte.fr"
          underline="hover"
        >
          https://business.lidentitenumerique.laposte.fr
        </StyledLinkText>
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        État de conformité
      </StyledSubtitle>
      <StyledParagraph>
        Le site Partenaire Business de L&apos;Identité Numérique{" "}
        <StyledLinkText
          href="https://business.lidentitenumerique.laposte.fr/"
          underline="hover"
        >
          https://business.lidentitenumerique.laposte.fr/
        </StyledLinkText>
        est non conforme avec le référentiel général d&apos;amélioration de
        l&apos;accessibilité pour les administrations, RGAA version 4.0 car il
        n&apos;existe aucun résultat d&apos;audit en cours de validité
        permettant de mesurer le respect des critères.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        Retour d&apos;information et contact
      </StyledSubtitle>
      <StyledParagraph>
        Si vous n&apos;arrivez pas à accéder à un contenu ou à un service, vous
        pouvez contacter notre Support Relation Client pour être orienté vers
        une alternative accessible ou obtenir le contenu sous une autre forme.
        <br />
        Envoyer un message depuis notre portail Relation Client :{" "}
        <StyledLinkText
          href="https://jira.docaposte.fr/plugins/servlet/desk"
          target="_blank"
          underline="hover"
        >
          <div style={visuallyHidden}>
            Lien qui s&apos;ouvre dans un nouvel onglet:
          </div>
          https://jira.docaposte.fr/plugins/servlet/desk
        </StyledLinkText>
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        Voie de recours
      </StyledSubtitle>
      <StyledParagraph>
        Cette procédure est à utiliser dans le cas suivant.
        <br /> Vous avez signalé au responsable du site internet un défaut
        d&apos;accessibilité qui vous empêche d&apos;accéder à un contenu ou à
        un des services du portail et vous n&apos;avez pas obtenu de réponse
        satisfaisante.
      </StyledParagraph>
      <ul>
        <li>
          <StyledListItem>
            Écrire un message au Défenseur des droits :{" "}
            <StyledLinkText
              href="https://formulaire.defenseurdesdroits.fr/"
              target="_blank"
              underline="hover"
            >
              <div style={visuallyHidden}>
                Lien qui s&apos;ouvre dans un nouvel onglet:
              </div>
              https://formulaire.defenseurdesdroits.fr/
            </StyledLinkText>
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            Contacter le délégué du Défenseur des droits dans votre région{" "}
            <StyledLinkText
              href="https://www.defenseurdesdroits.fr/fr/saisir/delegues"
              target="_blank"
              underline="hover"
            >
              <div style={visuallyHidden}>
                Lien qui s&apos;ouvre dans un nouvel onglet:
              </div>
              https://www.defenseurdesdroits.fr/fr/saisir/delegues
            </StyledLinkText>
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre)
            :
            <br />
            <strong>
              Défenseur des droits
              <br /> Libre réponse 71120
              <br /> 75342 Paris CEDEX 07
            </strong>
          </StyledListItem>
        </li>
      </ul>
    </div>
  );
};

export default Accessibilite;
