import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, useMediaQuery } from "@mui/material";
import logo from "media/logo.svg";
import Footer from "components/Footer";

const LayoutNotConnected = (props) => {
  const { children } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const [mainHeight, setMainHeight] = useState(0);

  const handleResize = useCallback(() => {
    setMainHeight(
      window.innerHeight -
        headerRef?.current?.offsetHeight -
        footerRef?.current?.offsetHeight || 0,
    );
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <Box sx={{ margin: "0 auto" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          background: "#e0eaff",
          position: "relative",
          zIndex: 2,
        }}
        ref={headerRef}
        role="banner"
        aria-roledescription="Bandeau de navigation"
      >
        <Grid
          container
          sx={{
            maxWidth: "1440px",
            padding: theme.spacing(0, 6),
            height: "104px",
            [theme.breakpoints.down("xl")]: {
              padding: "0 24px",
            },
            [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
              height: "80px",
              padding: "0 16px",
              background: "#e0eaff",
            },
          }}
          alignItems="center"
          justify="space-between"
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
                order: 2,
              },
            }}
            item
          >
            <img
              src={logo}
              title="Logo L'Identité Numérique"
              alt="Logo L'Identité Numérique"
              width={isMobile ? "188" : "228"}
              height={isMobile ? "40" : "48"}
            />
          </Grid>
        </Grid>
      </div>
      <Grid
        sx={{
          backgroundColor: theme.palette["bleu-3"],
          justifyContent: "center",
          [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
            justifyContent: "flex-start",
          },
        }}
        container
      >
        <Grid
          sx={{
            minHeight: mainHeight,
            [theme.breakpoints.up(theme.breakpoints.mainViewWidth)]: {
              padding: theme.spacing(6.25, 2, 0, 2),
              width: theme.breakpoints.mainViewWidth,
            },
            [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
              padding: theme.spacing(3, 2),
              width: "100%",
            },
          }}
          item
          xs={12}
          sm="auto"
          role="main"
        >
          {children}
        </Grid>
        <div
          ref={footerRef}
          style={{ width: "100%" }}
          role="contentinfo"
          aria-roledescription="Bandeau de bas de page"
        >
          <Footer />
        </div>
      </Grid>
    </Box>
  );
};

LayoutNotConnected.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutNotConnected;
