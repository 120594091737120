import ContractFactory from "models/factories/ContractFactory";
import AddressFactory from "models/factories/AddressFactory";
import ContactFactory from "models/factories/ContactFactory";
import BillingFactory from "models/factories/BillingFactory";
import PersonalDataFactory from "./PersonalDataFactory";

export default class PartnerFactory {
  constructor({
    id = "",
    issuerCode = "",
    companyName = "",
    siretNumber = "",
    code = "",
    status = "test",
    salesChannel = null,
    businessLine = null,
    enterpriseType = null,
    typology = null,
    contract,
    address,
    contact,
    billing,
    keycloakData = [],
    ...additionalAttributes
  } = {}) {
    this.id = id;
    this.issuerCode = issuerCode;
    this.companyName = companyName;
    this.siretNumber = siretNumber;
    this.code = code;
    this.status = status;
    this.salesChannel = salesChannel;
    this.businessLine = businessLine;
    this.enterpriseType = enterpriseType;
    this.typology = typology;
    this.contract = new ContractFactory(contract);
    this.address = new AddressFactory(address);
    this.contact = new ContactFactory(contact);
    this.billing = new BillingFactory(billing);
    if (Array.isArray(keycloakData)) {
      this.keycloakData = keycloakData.map(
        (data) => new PersonalDataFactory(data),
      );
    }
    Object.keys(additionalAttributes).forEach((key) => {
      this[key] = this[key] || additionalAttributes[key];
    });
  }
}
