import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { matchPath, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import RouteShape from "models/shapes/RouteShape";

const CurrentRouteContext = React.createContext({
  currentRoute: {},
  title: "",
  setTitle: () => null,
});

export default CurrentRouteContext;

export const CurrentRouteContextProvider = (props) => {
  const { children, routes } = props;

  const { pathname } = useLocation();
  const currentRoute = routes.find((route) => matchPath(pathname, route));
  const [title, setTitle] = useState(currentRoute ? currentRoute.title : "");

  useEffect(() => {
    setTitle(currentRoute ? currentRoute.title : "");
  }, [currentRoute]);

  return (
    <HelmetProvider>
      <CurrentRouteContext.Provider
        value={{
          title,
          setTitle,
          currentRoute,
        }}
      >
        {children}
        <Helmet>
          <title>{title}</title>
        </Helmet>
      </CurrentRouteContext.Provider>
    </HelmetProvider>
  );
};

CurrentRouteContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  routes: PropTypes.arrayOf(RouteShape).isRequired,
};
