export default {
  BANQUE_FINANCE: { name: "Banque / Finance", label: "Banque / Finance" },
  ASSURANCE_MUTUELLE: {
    name: "Assurance / Mutuelle",
    label: "Assurance / Mutuelle",
  },
  SECTEUR_PUBLIC: { name: "Secteur public", label: "Secteur public" },
  PROFESSIONS_REGLEMENTEES: {
    name: "Professions réglementées",
    label: "Professions réglementées",
  },
  TELECOM: { name: "Telecom", label: "Télécom" },
  ECOMMERCE_RETAIL: {
    name: "E-commerce / Retail",
    label: "E-commerce / Retail",
  },
  JEUX_DARGENT: { name: "Jeux d'argent", label: "Jeux d'argent" },
  SANTE: { name: "Santé", label: "Santé" },
};
