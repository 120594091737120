import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AclBack from "apps/back/AclBack";
import Paper from "components/Paper";
import PeriodsPicker from "components/PeriodsPicker";
import { PartnerStatConso } from "components/stats/StatConso";
import { PartnerStatUsers } from "components/stats/StatUsers";
import { PartnerStatTitleWithExport } from "components/stats/StatTitleWithExport";
import Loader from "components/Loader";
import {
  getDefaultPeriod,
  getDefaultPeriodForLicenceBilling,
} from "components/PeriodsPicker/PeriodUtil";
import { PartnerApplicationsClassification } from "components/stats/ApplicationsClassification";
import UsesFilterCard from "components/UsesFilterCard";
import IconAnalytics from "icons/analytics";
import StatusEnum from "models/enums/StatusEnum";
import BillingTypeEnum from "models/enums/BillingTypeEnum";
import StatService from "services/StatService";
import Acl, { withAccess } from "utils/Acl";
import { getUsesFromStats } from "utils/StatsUtil";
import { formatDateToYYYYMMDD } from "utils/date";

const ConsumptionMonitoring = (props) => {
  const { partner, isConsolidated } = props;

  const theme = useTheme();

  const [periods, setPeriods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [usesFilter, setUsesFilter] = useState([]);

  useEffect(() => {
    if (partner && partner.id && Array.isArray(periods)) {
      setIsLoading(true);
      const resultsPromise = [];
      periods.forEach((period) => {
        if (Object.keys(period).length > 0) {
          resultsPromise.push(
            StatService.getStats({
              params: {
                type: "use",
                lowerDate: formatDateToYYYYMMDD(period.startDate),
                upperDate: formatDateToYYYYMMDD(period.endDate),
                timezone: "Europe/Paris",
                forcedPartnerId: partner.id,
                onlyUses: true,
              },
            }),
          );
        }
      });
      Promise.all(resultsPromise)
        .then((results) => {
          setIsLoading(false);
          setStats(results || []);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [partner, periods]);

  const handleChangeUsesFilter = (event, values) => {
    setUsesFilter(values);
  };

  const defaultPeriod =
    partner &&
    partner.contract &&
    partner.billing.typeOfBilling === BillingTypeEnum.LICENCE.name
      ? getDefaultPeriodForLicenceBilling(partner.contract.serviceStartDate)
      : getDefaultPeriod(partner.contract.serviceStartDate);

  const handleChangePeriods = (changedPeriods) => {
    setPeriods(changedPeriods);
  };

  const statsFiltered = useMemo(
    () =>
      stats.map((stat) => {
        const { uses: statUses } = stat;
        if (!usesFilter || usesFilter.length === 0) {
          return stat;
        }
        return {
          ...stat,
          uses: statUses.filter((use) => usesFilter.includes(use.name)),
        };
      }),
    [stats, usesFilter],
  );

  return (
    <Box sx={{ marginTop: theme.spacing(7) }}>
      <PartnerStatTitleWithExport
        statTitle={
          <Typography
            variant="h2"
            id="suivi-de-la-consommation"
            sx={{
              color: theme.palette["secondary-ultramarine"],
              margin: theme.spacing(0, 0, 2, 3.5),
              [theme.breakpoints.down("md")]: {
                marginLeft: 0,
              },
            }}
          >
            <IconButton
              color="secondary"
              sx={{
                marginRight: theme.spacing(2),
                pointerEvents: "none",
              }}
              tabIndex={-1}
              size="large"
              aria-hidden="true"
            >
              <IconAnalytics />
            </IconButton>
            Suivi de la consommation
          </Typography>
        }
        statAdditionalParams={{ forcedPartnerId: partner.id, onlyUses: true }}
        partner={partner}
        periods={periods}
        handleSetIsLoading={setIsLoading}
      />
      <Divider />
      {partner.status === StatusEnum.ACTIVE.name ||
      partner.activationDate !== null ? (
        <>
          <Box
            mb={2}
            component={Paper}
            sx={{
              margin: theme.spacing(3, 0),
              borderRadius: "8px",
              boxShadow:
                "0 1px 10px 0 rgba(0, 0, 45, 0.06), 0 1px 4px 0 rgba(0, 0, 45, 0.1), 0 0 0 0 rgba(0, 0, 46, 0.06)",
              backgroundColor: theme.palette["secondary-white"],
            }}
          >
            <Box p={4}>
              <PeriodsPicker
                periods={periods}
                onPeriodsChange={handleChangePeriods}
                defaultPeriod={defaultPeriod}
              />
            </Box>
            {isConsolidated ? (
              <Box>
                <Divider />
                <UsesFilterCard
                  listOfUses={getUsesFromStats(stats)}
                  usesFilter={usesFilter}
                  handleChangeUsesFilter={handleChangeUsesFilter}
                />
              </Box>
            ) : (
              <></>
            )}
          </Box>
          {isLoading && (
            <Box
              sx={{
                position: "fixed",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              }}
            >
              <Loader sx={{ background: "rgba(255,255,255,0.5)" }} />
            </Box>
          )}
          <PartnerStatConso
            stats={statsFiltered}
            isConsolidated={isConsolidated}
            isPartner
          />
          <PartnerStatUsers
            stats={statsFiltered}
            sx={{
              marginTop: theme.spacing(4),
            }}
            periods={periods}
            isPartner
          />
          {isConsolidated && (
            <>
              <PartnerApplicationsClassification
                stats={statsFiltered}
                sx={{ mt: theme.spacing(4) }}
                isPartner
              />
            </>
          )}
        </>
      ) : (
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{
            color: theme.palette["secondary-ultramarine"],
            margin: theme.spacing(5, 0),
          }}
        >
          Les données de consommation seront disponibles
          <br />
          lorsque le compte partenaire sera en production
        </Typography>
      )}
    </Box>
  );
};

ConsumptionMonitoring.propTypes = {
  partner: PropTypes.shape(),
  isConsolidated: PropTypes.bool.isRequired,
};

ConsumptionMonitoring.defaultProps = {
  partner: null,
};

export default withAccess(
  AclBack.PARTNERS_CONSO,
  Acl.READ,
)(ConsumptionMonitoring);
