import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddressShape from "models/shapes/AddressShape";
import AddressFactory from "models/factories/AddressFactory";
import AddressForm from "./AddressForm";

const BillingAddressForm = (props) => {
  const { companyAddress, address, handleChangeAddress, ...others } = props;

  const [customAddress, setCustomAddress] = useState(new AddressFactory());
  const [areEqual, setAreEqual] = useState(true);

  useEffect(() => {
    if (address && companyAddress) {
      let equal = true;
      Object.keys(address).forEach((key) => {
        if (address[key] !== companyAddress[key]) {
          equal = false;
        }
      });
      setAreEqual(equal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (areEqual) {
      handleChangeAddress(companyAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAddress]);

  useEffect(() => {
    if (address && !areEqual) {
      setCustomAddress(address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const handleClickCustomAddress = useCallback(
    (e) => {
      setAreEqual(!areEqual);
      if (e.target.checked) {
        handleChangeAddress(customAddress);
      } else {
        handleChangeAddress(companyAddress);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyAddress, customAddress, handleChangeAddress],
  );

  const columsOverride = {};

  if (areEqual) {
    columsOverride.content = [];
  }

  return (
    <AddressForm
      address={address || {}}
      additionalField={
        <FormControlLabel
          control={
            <Checkbox
              inputProps={{ tabIndex: -1 }}
              checked={!areEqual}
              tabindex="0"
              role="checkbox"
              aria-checked={!areEqual}
              onClick={() => setAreEqual(!areEqual)}
              onChange={handleClickCustomAddress}
            />
          }
          label="Utiliser une autre adresse que l’adresse de l’entreprise"
        />
      }
      {...columsOverride}
      {...others}
    />
  );
};

BillingAddressForm.propTypes = {
  companyAddress: AddressShape,
  address: AddressShape,
  handleChangeAddress: PropTypes.func.isRequired,
};

BillingAddressForm.defaultProps = {
  companyAddress: null,
  address: null,
};

export default BillingAddressForm;
