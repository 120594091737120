/* eslint-disable camelcase */
export default class ApplicationStatFactory {
  constructor({
    id,
    business_lines = [],
    by_date = {},
    name = "",
    contract_typology = "",
    partner_typology = "",
    sales_channel = "",
    type_of_billing = "",
    unique_users = 0,
    uses = 0,
    ...otherAttributes
  }) {
    this.id = id;
    this.business_lines = business_lines;
    this.by_date = by_date;
    this.name = name;
    this.contract_typology = contract_typology;
    this.partner_typology = partner_typology;
    this.sales_channel = sales_channel;
    this.type_of_billing = type_of_billing;
    this.unique_users = unique_users;
    this.uses = uses;

    Object.keys(otherAttributes).forEach((key) => {
      this[key] = otherAttributes[key];
    });
  }
}
