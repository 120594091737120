import * as Yup from "yup";
import { siretLength, validateSiret } from "utils/SiretUtil";
import ContactCompleteSchema from "models/schemas/ContactCompleteSchema";
import BillingCompleteSchema from "models/schemas/BillingCompleteSchema";

const PartnerCompleteSchema = Yup.object().shape({
  companyName: Yup.string()
    .required("La raison sociale de l'entreprise n'est pas renseignée")
    .typeError("La raison sociale de l'entreprise n'est pas renseignée"),
  siretNumber: Yup.string()
    .length(
      siretLength,
      `Le siret doit comporter ${siretLength} chiffres (sans espace)`,
    )
    .test("is-siret", "Le siret de l'entreprise est invalide", validateSiret)
    .required("Le siret de l'entreprise n'est pas renseigné")
    .typeError("Le siret de l'entreprise n'est pas renseigné"),
  salesChannel: Yup.string()
    .required("Données générales : Le canal de vente n'est pas renseigné")
    .typeError("Données générales : Le canal de vente n'est pas renseigné"),
  businessLine: Yup.string()
    .required("Données générales : Le secteur d'activité n'est pas renseignée")
    .typeError("Données générales : Le secteur d'activité n'est pas renseigné"),
  typology: Yup.string()
    .required(
      "Données générales : La typologie de partenaire n'est pas renseignée",
    )
    .typeError(
      "Données générales : La typologie de partenaire n'est pas renseignée",
    ),
  contact: ContactCompleteSchema,
  billing: BillingCompleteSchema,
});

export default PartnerCompleteSchema;
