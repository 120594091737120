import * as Yup from "yup";
import ScopesTechDataEnum from "models/enums/ScopesTechDataEnum";

const TechDataSchema = Yup.object().shape({
  useName: Yup.string().nullable().required("Choisir un nom"),
  environmentType: Yup.string()
    .nullable()
    .required("Sélectionner un environnement"),
  flow: Yup.string().nullable().required("Sélectionner un protocole"),
  urls: Yup.array()
    .of(Yup.string())
    .nullable()
    .min(1, "Ajouter au moins une URL")
    .required("Champ requis"),
  scopes: Yup.array()
    .of(
      Yup.string().oneOf([
        ...Object.values(ScopesTechDataEnum).map((value) => value.name),
      ]),
    )
    .nullable()
    .min(1, "Sélectionner au moins une scope")
    .required("Champ requis"),
  signingAlgorithm: Yup.string().nullable().required("Champ requis"),
  userInfoAlgorithm: Yup.string().nullable().required("Champ requis"),
  status: Yup.string().nullable().required("Sélectionner un statut"),
});

export default TechDataSchema;
