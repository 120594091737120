import PropTypes from "prop-types";
import UseStatShape from "models/shapes/UseStatShape";

const StatPartnerShape = PropTypes.shape({
  uses: PropTypes.arrayOf(UseStatShape).isRequired,
  total: PropTypes.shape({
    unique_users: PropTypes.number.isRequired,
    uses: PropTypes.number.isRequired,
  }).isRequired,
});

export default StatPartnerShape;
