import React from "react";
import PropTypes from "prop-types";

import { Box, Paper, Typography } from "@mui/material";

const Card = (props) => {
  const { category, value, icon, className, ...others } = props;

  return (
    <Paper
      className={className}
      sx={(theme) => ({
        height: "100%",
        padding: theme.spacing(2),
        borderRadius: "8px",
        boxShadow:
          "0 1px 10px 0 rgba(0, 0, 45, 0.06), 0 1px 4px 0 rgba(0, 0, 45, 0.1), 0 0 0 0 rgba(0, 0, 46, 0.06)",
        backgroundColor: theme.palette["secondary-white"],
        position: "relative",
      })}
      {...others}
    >
      {icon && (
        <Box
          sx={{
            cursor: "pointer",
            position: "absolute",
            top: "12px",
            right: "12px",
          }}
        >
          {icon}
        </Box>
      )}
      <Typography
        variant="h6"
        component="span"
        sx={{
          color: (theme) => theme.palette["cobalt-marque-blanche-second"],
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {category}
      </Typography>
      <Typography
        variant="h3"
        component="span"
        sx={{
          margin: (theme) => theme.spacing(4.5, 0, 3, 0),
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {value || <span aria-label="Non renseigné">-</span>}
      </Typography>
    </Paper>
  );
};

Card.propTypes = {
  category: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
};

Card.defaultProps = {
  category: "",
  value: "",
  icon: null,
  className: "",
};

export default Card;
