import Grid from "@mui/material/Grid";
import { useKeycloak } from "@react-keycloak/web";
import React, { useCallback, useEffect, useState } from "react";
import TcWrapper from "react-tag-commander";

import AclFront from "apps/front/AclFront";
import NeedHelp from "apps/front/infoTech/NeedHelp";
import { FrontUsesTechnicalData } from "components/partners/UsesTechnicalData";
import Snackbar from "components/Snackbar";
import PartnerService from "services/PartnerService";
import Acl, { withAccess } from "utils/Acl";
import IconDownload from "icons/download";
import IconHelp from "icons/help";
import IconSign from "icons/icon_doca_sign";
import InnerNavigation from "../../../components/InnerNavigation";
import PageTitle from "../../../components/PageTitle";

const InfoTech = () => {
  const wrapper = TcWrapper.getInstance();

  const [partner, setPartner] = useState({});
  const [partnerId, setPartnerId] = useState(null);
  const [uses, setUses] = useState([]);
  const [usesNb, setUsesNb] = useState(0);
  const { keycloak } = useKeycloak();
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  useEffect(() => {
    if (keycloak.authenticated) {
      const loadProfile = async () => {
        await keycloak.loadUserProfile();
        setPartnerId(keycloak?.profile?.attributes?.partnerId?.[0]);
      };
      loadProfile();
    }
  }, [keycloak]);

  const getUses = useCallback(() => {
    PartnerService.getUses(partnerId, { params: { join: "techDatas" } })
      .then((data) => {
        setUses(
          data.data.sort((a, b) => new Date(b.created) - new Date(a.created)),
        );
        setUsesNb(data.total);
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message:
            "Une erreur est survenue lors de la récupération des usages.",
          variant: "error",
        });
      });
  }, [partnerId]);

  const getPartner = useCallback(async () => {
    if (partnerId) {
      let currentPartner = {};
      await PartnerService.get(partnerId)
        .then((result) => {
          currentPartner = result || {};
          if (Acl.hasAccess(AclFront.INFORMATION_TECH)) {
            getUses();
          }
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message:
              "Une erreur est survenue lors de la récupération du compte partenaire.",
            variant: "error",
          });
        });
      setPartner(currentPartner);
    }
  }, [getUses, partnerId]);

  useEffect(() => {
    getPartner();
  }, [getPartner]);

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleClickFilter = (event, values) => {
    wrapper.triggerEvent("click", event.target, values);
  };

  return (
    <>
      <PageTitle>Informations techniques</PageTitle>
      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid
          item
          sx={(theme) => ({
            width: "340px",
            paddingTop: theme.spacing(2),
            [theme.breakpoints.down(theme.breakpoints.mainViewWidth + 20)]: {
              display: "none",
            },
          })}
        >
          <InnerNavigation container role="navigation">
            <InnerNavigation
              section
              Icon={IconDownload}
              title="Documentation"
              target="documentation"
              funcOnClick={(event) =>
                handleClickFilter(event, {
                  clic_type: "action",
                  chapter1: "informations_techniques",
                  name: "documentation",
                })
              }
            />
            <InnerNavigation
              section
              Icon={IconSign}
              title="Usages"
              target="usages"
              funcOnClick={(event) =>
                handleClickFilter(event, {
                  clic_type: "action",
                  chapter1: "informations_techniques",
                  name: "usages",
                })
              }
            />
            <InnerNavigation
              section
              Icon={IconHelp}
              title="Besoin d'aide ?"
              target="besoin_d_aide_?"
              funcOnClick={(event) =>
                handleClickFilter(event, {
                  clic_type: "action",
                  chapter1: "informations_techniques",
                  name: "besoin_aide",
                })
              }
            />
          </InnerNavigation>
        </Grid>
        <Grid item xs>
          <FrontUsesTechnicalData
            partner={partner}
            uses={uses}
            usesNb={usesNb}
            getUses={getUses}
          />
          <NeedHelp />
        </Grid>
      </Grid>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </>
  );
};

export default withAccess(AclFront.INFORMATION_TECH)(InfoTech);
