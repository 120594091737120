import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Tooltip,
  MenuItem,
  LinearProgress,
  Divider,
} from "@mui/material";
import { useFormik } from "formik";
import InfoIcon from "icons/info_fill";
import { getLabelRepresentation } from "utils/enum";
import ActionLabelEnum from "models/enums/ActionLabelEnum";
import linImage from "media/lin_image.png";
import linConfirmationWithTradingName from "media/lin_confirmation_with_trading_name.png";
import linConfirmationWithoutTradingName from "media/lin_confirmation_without_trading_name.png";
import TechDataShape from "models/shapes/TechDataShape";

const StyledFieldLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette["secondary-ultramarine"],
  marginBottom: theme.spacing(1),
}));

const LINConfirmationPageDataForm = (props) => {
  const { onValidate, onCancel, techData, validateLabel, isSubmitting } = props;
  const [confirmationPageImage, setConfirmationPageImage] = useState(
    linConfirmationWithoutTradingName,
  );

  const theme = useTheme();
  const [showTradingNameBox, setShowTradingNameBox] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handleValidate = useCallback(
    (values) => {
      const { created, updated, ...cleanData } = values;
      onValidate({
        ...cleanData,
      });
    },
    [onValidate],
  );

  const validationSchema = {
    tradingName: Yup.string().required("Champ requis"),
  };
  if (process.env.REACT_APP_with_additional_personalisation === "true") {
    validationSchema.actionLabel = Yup.string()
      .oneOf([
        ...Object.values(ActionLabelEnum).map((value) => value.name),
        null,
        "",
      ])
      .nullable();
  }

  const formik = useFormik({
    initialValues: techData,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: handleValidate,
  });

  const { touched = {}, errors = {}, values = {} } = formik;
  const { tradingName } = values;

  useEffect(() => {
    if (process.env.REACT_APP_with_additional_personalisation === "true") {
      setConfirmationPageImage(
        tradingName || showTradingNameBox
          ? linConfirmationWithTradingName
          : linConfirmationWithoutTradingName,
      );
    }
  }, [tradingName, showTradingNameBox]);

  useEffect(() => {
    if (Object.values(touched).length === 0) {
      formik
        .validateForm()
        .then((data) => setIsValid(Object.values(data).length === 0));
    } else {
      setIsValid(formik.isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid]);

  const handleBlurTradingName = (event) => {
    if (setShowTradingNameBox) setShowTradingNameBox(false);
    formik.handleBlur(event);
  };

  const handleFocusTradingName = () => {
    if (!tradingName) {
      setShowTradingNameBox(true);
    }
  };

  return (
    <>
      <LinearProgress variant="determinate" value={100} aria-label="Partie 3" />
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ p: theme.spacing(4, 4, 3, 6) }}>
            <Typography sx={{ mb: theme.spacing(5) }} variant="h4">
              Personnalisation (recommandé)
            </Typography>
            <StyledFieldLabel
              variant="subtitle2"
              component="h5"
              id="trading_name"
            >
              Nom commercial
            </StyledFieldLabel>
            <TextField
              onChange={formik.handleChange}
              sx={{ mb: theme.spacing(5) }}
              variant="outlined"
              name="tradingName"
              error={touched.tradingName && Boolean(errors.tradingName)}
              helperText={touched.tradingName && errors.tradingName}
              value={tradingName}
              onFocus={handleFocusTradingName}
              onBlur={handleBlurTradingName}
              inputProps={{
                "aria-labelledby": "trading_name",
                "aria-describedby": "ht_trading_name",
                "aria-invalid": Boolean(
                  touched.tradingName && errors.tradingName,
                ),
                "aria-required": true,
              }}
              FormHelperTextProps={{
                id: "ht_trading_name",
              }}
            />
            {process.env.REACT_APP_with_additional_personalisation ===
              "true" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: theme.spacing(1),
                  }}
                >
                  <StyledFieldLabel
                    variant="subtitle2"
                    component="h5"
                    sx={{ mb: 0, mr: theme.spacing(0.5) }}
                    id="action_label"
                  >
                    Libellé
                  </StyledFieldLabel>
                  <Tooltip
                    arrow
                    title="Action de l'utilisateur avec l'Identité Numérique (connexion, création de compte, ...)"
                    aria-hidden="true"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <InfoIcon sx={{ color: theme.palette["status-info"] }} />
                    </Box>
                  </Tooltip>
                </Box>
                <TextField
                  onChange={formik.handleChange}
                  sx={{ mb: theme.spacing(5) }}
                  variant="outlined"
                  name="actionLabel"
                  select
                  error={touched.actionLabel && Boolean(errors.actionLabel)}
                  helperText={touched.actionLabel && errors.actionLabel}
                  value={values.actionLabel}
                  onBlur={formik.handleBlur}
                  SelectProps={{
                    labelId: "action_label",
                    "aria-describedby": "ht_action_label",
                    "aria-invalid": Boolean(
                      touched.actionLabel && errors.actionLabel,
                    ),
                  }}
                  FormHelperTextProps={{
                    id: "ht_action_label",
                  }}
                >
                  {ActionLabelEnum &&
                    Object.values(ActionLabelEnum).map((value) => (
                      <MenuItem key={value.name} value={value.name}>
                        {value.label}
                      </MenuItem>
                    ))}
                </TextField>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: theme.palette["bleu-5"],
              p: theme.spacing(6),
            }}
          >
            <Box sx={{ position: "relative" }}>
              <img
                title="Page de confirmation de L'Identité Numérique"
                alt="Page de confirmation de L'Identité Numérique"
                src={confirmationPageImage}
                style={{
                  height: "450px",
                }}
              />
              <Box
                aria-hidden="true"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: theme.palette["secondary-white"],
                  position: "absolute",
                  height: "44px",
                  alignItems: "center",
                  top: "96px",
                  left: "48px",
                  width: "116px",
                }}
              >
                <img
                  id="logo"
                  title="Logo choisi"
                  src={
                    techData.logo
                      ? URL.createObjectURL(techData.logo)
                      : linImage
                  }
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                  }}
                  alt="Logo choisi"
                />
              </Box>
              {!tradingName && showTradingNameBox && (
                <Box
                  sx={{
                    backgroundColor: theme.palette["bleu-3"],
                    width: "130px",
                    height: "12px",
                    borderRadius: "4px",
                    position: "absolute",
                    top: "206px",
                    left: "59px",
                    border: `1px solid ${theme.palette["bleu-60"]}`,
                  }}
                />
              )}
              {tradingName && (
                <Box
                  aria-hidden="true"
                  sx={{
                    backgroundColor: theme.palette["bleu-3"],
                    width: "130px",
                    position: "absolute",
                    top: "206px",
                    left: "61px",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette["primaire-bleu"],
                      fontSize: "0.6rem",
                      height: "15px",
                      overflow: "hidden",
                    }}
                  >
                    {tradingName}
                  </Typography>
                </Box>
              )}
              {process.env.REACT_APP_with_additional_personalisation ===
                "true" &&
                values.actionLabel && (
                  <Box
                    aria-hidden="true"
                    sx={{
                      backgroundColor: theme.palette["bleu-3"],
                      width: "130px",
                      position: "absolute",
                      top:
                        tradingName || showTradingNameBox ? "221px" : "206px",
                      left: "60px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette["dark-theme-bg"],
                        fontSize: "0.525rem",
                        fontWeight: "bold",
                      }}
                    >
                      {values.actionLabel &&
                        getLabelRepresentation(
                          ActionLabelEnum,
                          values.actionLabel,
                        )}
                    </Typography>
                  </Box>
                )}
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            my: theme.spacing(3),
            mr: theme.spacing(4),
          }}
        >
          <Button
            sx={{ mr: theme.spacing(3), color: theme.palette["primaire-bleu"] }}
            onClick={() => onCancel(values, 2)}
          >
            Retour
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || isSubmitting}
          >
            {validateLabel}
          </Button>
        </Box>
      </form>
    </>
  );
};

LINConfirmationPageDataForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  techData: TechDataShape.isRequired,
  validateLabel: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

LINConfirmationPageDataForm.defaultProps = {
  validateLabel: "Enregistrer",
  isSubmitting: false,
};

export default LINConfirmationPageDataForm;
