import Acl from "utils/Acl";

const functionnalities = {
  DASHBOARD: "DASHBOARD",
  INFORMATION_TECH: "INFORMATION_TECH",
  USERS: "USERS",
  CONTRACT_INFORMATION: "CONTRACT_INFORMATION",
  BILLING_INFORMATION: "BILLING_INFORMATION",
  DOC_API: "DOC_API",
};

export default functionnalities;

export const profiles = {
  ADMINISTRATEUR: {
    [functionnalities.DASHBOARD]: [Acl.ALL],
    [functionnalities.INFORMATION_TECH]: [Acl.ALL],
    [functionnalities.USERS]: [Acl.ALL],
    [functionnalities.CONTRACT_INFORMATION]: [Acl.ALL],
    [functionnalities.BILLING_INFORMATION]: [Acl.ALL],
    [functionnalities.DOC_API]: [Acl.ALL],
  },
  METIER: {
    [functionnalities.DASHBOARD]: [Acl.ALL],
    [functionnalities.USERS]: [Acl.READ],
    [functionnalities.CONTRACT_INFORMATION]: [Acl.ALL],
    [functionnalities.BILLING_INFORMATION]: [Acl.ALL],
  },
  TECHNIQUE: {
    [functionnalities.INFORMATION_TECH]: [Acl.ALL],
    [functionnalities.USERS]: [Acl.READ],
    [functionnalities.CONTRACT_INFORMATION]: [Acl.ALL],
    [functionnalities.DOC_API]: [Acl.ALL],
  },
};

export const getFunctionnalityLabel = (functionnality) => {
  switch (functionnality) {
    case functionnalities.DASHBOARD:
      return "Tableau de bord";
    case functionnalities.INFORMATION_TECH:
      return "Informations techniques";
    case functionnalities.USERS:
      return "Gestion des utilisateurs";
    case functionnalities.CONTRACT_INFORMATION:
      return "Informations du contrat";
    case functionnalities.BILLING_INFORMATION:
      return "Informations de facturation";
    case functionnalities.DOC_API:
      return "Documentation API";
    default:
      return "";
  }
};
