import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12 13.5c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6z"
    />
    <path
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M6.734 10.68L2.25 18.75l4.072-.02c.13 0 .258.033.372.097.114.064.209.157.275.269L9 22.5l4.14-9.109M17.182 10.5l4.568 8.25-4.072-.02c-.13 0-.258.033-.372.097-.114.064-.209.157-.275.269L15 22.5l-3-6.6M12 10.5c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
    />
  </SvgIcon>
);
