import UseStatFactory from "./UseStatFactory";

export default class StatPartnerFactory {
  /**
   * Array of UseStatFactory
   */
  uses = [];

  total = {
    unique_users: 0,
    uses: 0,
  };

  constructor({ uses = [], total = {}, ...otherAttributes } = {}) {
    if (typeof uses === "object") {
      Object.keys(uses).forEach((useId) => {
        this.uses.push(new UseStatFactory({ ...uses[useId], id: useId }));
      });
    } else if (Array.isArray(uses)) {
      this.uses = uses.map((use) => new UseStatFactory(use));
    }

    this.total = {
      ...this.total,
      ...(typeof total === "object" ? total : {}),
    };

    Object.keys(otherAttributes).forEach((key) => {
      this[key] = otherAttributes[key];
    });
  }
}
