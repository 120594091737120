import React, { useCallback, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import Grid from "@mui/material/Grid";
import AclFront from "apps/front/AclFront";
import Acl, { withAccess } from "utils/Acl";
import PartnerService from "services/PartnerService";
import InnerNavigation from "components/InnerNavigation";
import IconSignIcon from "icons/icon_doca_sign";
import ContractInformation from "components/partners/ContractInformation";
import PartnerFactory from "models/factories/PartnerFactory";
import Snackbar from "components/Snackbar";
import TcWrapper from "react-tag-commander";

const Contract = () => {
  const wrapper = TcWrapper.getInstance();

  const [partner, setPartner] = useState(new PartnerFactory());
  const [partnerId, setPartnerId] = useState(null);

  const { keycloak } = useKeycloak();

  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  useEffect(() => {
    if (keycloak.authenticated) {
      const loadProfile = async () => {
        await keycloak.loadUserProfile();
        setPartnerId(keycloak?.profile?.attributes?.partnerId?.[0]);
      };
      loadProfile();
    }
  }, [keycloak]);

  const getPartner = useCallback(() => {
    if (partnerId) {
      PartnerService.get(partnerId)
        .then((result) => {
          setPartner(new PartnerFactory(result || {}));
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message:
              "Une erreur est survenue lors de la récupération du compte partenaire.",
            variant: "error",
          });
        });
    }
  }, [partnerId]);

  useEffect(() => {
    getPartner();
  }, [getPartner]);

  const handleClickFilter = (event, values) => {
    wrapper.triggerEvent("click", event.target, values);
  };

  return (
    <>
      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid
          item
          sx={(theme) => ({
            width: "340px",
            paddingTop: theme.spacing(13),
            [theme.breakpoints.down(theme.breakpoints.mainViewWidth + 20)]: {
              display: "none",
            },
          })}
        >
          <aside>
            <InnerNavigation container role="navigation">
              <InnerNavigation
                section
                Icon={IconSignIcon}
                title="Informations du contrat"
                target="informations-du-contrat"
              >
                <InnerNavigation
                  item
                  target="coordonnees-de-l-entreprise"
                  funcOnClick={(event) =>
                    handleClickFilter(event, {
                      clic_type: "action",
                      chapter1: "contrat",
                      name: "contrat_coordonnees_entreprise",
                    })
                  }
                >
                  Coordonnées de l’entreprise
                </InnerNavigation>
                <InnerNavigation
                  item
                  target="contact-de-l-entreprise"
                  funcOnClick={(event) =>
                    handleClickFilter(event, {
                      clic_type: "action",
                      chapter1: "contrat",
                      name: "contrat_contact_entreprise",
                    })
                  }
                >
                  Contact de l’entreprise
                </InnerNavigation>
                <InnerNavigation
                  item
                  target="donnees-generales"
                  funcOnClick={(event) =>
                    handleClickFilter(event, {
                      clic_type: "action",
                      chapter1: "contrat",
                      name: "contrat_donnees_generales",
                    })
                  }
                >
                  Données générales
                </InnerNavigation>
                <InnerNavigation
                  item
                  target="adresse-de-l-entreprise"
                  funcOnClick={(event) =>
                    handleClickFilter(event, {
                      clic_type: "action",
                      chapter1: "contrat",
                      name: "contrat_adresse_entreprise",
                    })
                  }
                >
                  Adresse de l’entreprise
                </InnerNavigation>
                <InnerNavigation
                  item
                  target="adresse-de-facturation"
                  funcOnClick={(event) =>
                    handleClickFilter(event, {
                      clic_type: "action",
                      chapter1: "contrat",
                      name: "contrat_adresse_facturation",
                    })
                  }
                >
                  Adresse de facturation
                </InnerNavigation>
                {Acl.hasAccess(AclFront.BILLING_INFORMATION, Acl.ALL) && (
                  <InnerNavigation
                    item
                    target="donnees-de-facturation"
                    funcOnClick={(event) =>
                      handleClickFilter(event, {
                        clic_type: "action",
                        chapter1: "contrat",
                        name: "contrat_donnees_facturation",
                      })
                    }
                  >
                    Données de facturation
                  </InnerNavigation>
                )}
              </InnerNavigation>
            </InnerNavigation>
          </aside>
        </Grid>
        <Grid item xs>
          <ContractInformation partner={partner} />
        </Grid>
      </Grid>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </>
  );
};

export default withAccess(AclFront.CONTRACT_INFORMATION)(Contract);
