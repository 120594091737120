import React, { useMemo, useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  LinearProgress,
  Divider,
} from "@mui/material";
import { useFormik } from "formik";
import linLogin from "media/lin_login.png";
import linLoginWithReturnUrl from "media/lin_login_with_return_url.png";
import linLoginWithCreationUrl from "media/lin_login_with_creation_url.png";
import linLoginWithCreationAndReturnUrls from "media/lin_login_with_creation_and_return_urls.png";
import DownloadIcon from "icons/export";
import TechDataShape from "models/shapes/TechDataShape";

const LINLoginPageDataForm = (props) => {
  const { onValidate, onCancel, techData } = props;
  const ENTER_KEY = "Enter";
  const theme = useTheme();
  const maxLogoSize = 100000;
  const [logo, setLogo] = useState(techData.logo || null);
  const logoError = useMemo(() => logo && logo.size > maxLogoSize, [logo]);
  const [linLoginImage, setLinLoginImage] = useState(linLogin);

  const handleValidate = useCallback(
    (values) => {
      const { created, updated, ...cleanData } = values;
      if (process.env.REACT_APP_with_additional_personalisation === "true") {
        onValidate({ ...cleanData, logo }, 3);
      } else if (!logoError) {
        onValidate(cleanData, 3);
      }
    },
    [logo, logoError, onValidate],
  );

  const formik = useFormik({
    initialValues: techData,
    onSubmit: handleValidate,
  });

  const { values = {} } = formik;
  const { hideReturnUrl, hideLinCreateLink } = values;

  useEffect(() => {
    if (hideReturnUrl) {
      setLinLoginImage(hideLinCreateLink ? linLogin : linLoginWithCreationUrl);
    } else {
      setLinLoginImage(
        hideLinCreateLink
          ? linLoginWithReturnUrl
          : linLoginWithCreationAndReturnUrls,
      );
    }
  }, [hideLinCreateLink, hideReturnUrl]);

  const handleUploadFile = async (e) => {
    const fileToUpload = e.target.files[0];
    setLogo(fileToUpload);
  };

  const handleDeleteFile = () => {
    const input = document.getElementById("input-file-attachment");
    input.value = "";
    setLogo(null);
  };

  const handleClickImportImage = (event) => {
    if (event.key === ENTER_KEY) {
      const button = document.getElementById("import-image");
      button.click();
    }
  };

  const handleClickChangeImage = (event) => {
    if (event.key === ENTER_KEY) {
      const button = document.getElementById("change-image");
      button.click();
    }
  };

  return (
    <>
      <LinearProgress
        variant="determinate"
        value={200 / 3}
        aria-label="Partie 2"
      />
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12} md={6} sx={{ p: theme.spacing(4, 4, 3, 6) }}>
            <Typography sx={{ mb: theme.spacing(5) }} variant="h4">
              Personnalisation (recommandé)
            </Typography>
            {process.env.REACT_APP_with_additional_personalisation ===
              "true" && (
              <>
                <Typography
                  variant="subtitle2"
                  component="h5"
                  id="logo"
                  sx={{
                    color: theme.palette["secondary-ultramarine"],
                    marginBottom: theme.spacing(1),
                  }}
                >
                  Logo (png, jpeg)
                </Typography>
                {logoError && (
                  <Typography
                    sx={{ fontSize: "0.75rem" }}
                    color="error"
                    id="ht_logo"
                    aria-live="assertive"
                  >
                    {`La taille du logo doit être inférieure ou égale à ${
                      maxLogoSize / 1000
                    } kB`}
                  </Typography>
                )}
                <input
                  id="input-file-attachment"
                  type="file"
                  style={{ display: "none" }}
                  accept="image/jpg, image/jpeg, image/png"
                  onChange={handleUploadFile}
                />
                {!logo && (
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <label htmlFor="input-file-attachment">
                    <Box
                      id="import-image"
                      component="span"
                      tabIndex={0}
                      role="button"
                      sx={{ width: "100%", cursor: "pointer" }}
                      onKeyUp={(e) => handleClickImportImage(e)}
                    >
                      <Box
                        sx={{
                          mb: theme.spacing(5),
                          mt: theme.spacing(2),
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "140px",
                          border: `2px dashed ${theme.palette["secondary-ultramarine"]}`,
                          borderRadius: "20px",
                          backgroundColor: theme.palette["bleu-5"],
                        }}
                      >
                        <Box sx={{ textAlign: "center" }}>
                          <DownloadIcon
                            sx={{
                              borderRadius: "50%",
                              fontSize: "2.25em",
                              padding: theme.spacing(0.75),
                              color: theme.palette["secondary-white"],
                              backgroundColor: theme.palette["status-info"],
                            }}
                          />
                          <Typography
                            variant="h4"
                            component="div"
                            sx={{
                              mt: theme.spacing(1),
                              color: theme.palette["primaire-bleu"],
                            }}
                          >
                            Importer mon logo
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </label>
                )}
                {logo && (
                  <Box
                    sx={{
                      textAlign: "center",
                      mb: theme.spacing(5),
                      mt: theme.spacing(2),
                      border: `1px solid ${theme.palette["grey-30"]}`,
                      borderRadius: 5,
                    }}
                  >
                    <img
                      id="logo"
                      title="Logo choisi"
                      src={URL.createObjectURL(logo)}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "140px",
                        padding: theme.spacing(3),
                      }}
                      alt="Logo choisi"
                    />
                    <Grid container>
                      <Grid
                        item
                        xs
                        sx={{
                          p: theme.spacing(1),
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderTop: `1px solid ${theme.palette["grey-30"]}`,
                          borderRight: `1px solid ${theme.palette["grey-30"]}`,
                        }}
                      >
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="input-file-attachment">
                          <Box
                            id="change-image"
                            component="span"
                            tabIndex={0}
                            role="button"
                            onKeyUp={(e) => handleClickChangeImage(e)}
                            sx={{
                              cursor: "pointer",
                              color: theme.palette["primaire-bleu"],
                              p: theme.spacing(1),
                              fontSize: "0.875rem",
                              fontWeight: 600,
                            }}
                          >
                            Modifier
                          </Box>
                        </label>
                      </Grid>
                      <Grid
                        item
                        xs
                        sx={{
                          p: theme.spacing(1),
                          borderTop: `1px solid ${theme.palette["grey-30"]}`,
                        }}
                      >
                        <Button
                          sx={{
                            color: theme.palette["status-error"],
                            p: theme.spacing(1),
                          }}
                          onClick={handleDeleteFile}
                        >
                          Supprimer
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </>
            )}
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ tabIndex: -1 }}
                    checked={!!hideReturnUrl}
                    tabindex="0"
                    role="checkbox"
                    aria-checked={!!hideReturnUrl}
                    onClick={() =>
                      formik.setFieldValue("hideReturnUrl", !hideReturnUrl)
                    }
                    onChange={formik.handleChange}
                    name="hideReturnUrl"
                  />
                }
                label={
                  <Typography
                    variant="subtitle2"
                    component="h5"
                    sx={{
                      marginBottom: 0,
                      color: theme.palette["cobalt-marque-blanche-second"],
                    }}
                  >
                    Masquer le lien de retour
                  </Typography>
                }
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ tabIndex: -1 }}
                    checked={!!hideLinCreateLink}
                    tabindex="0"
                    role="checkbox"
                    aria-checked={!!hideLinCreateLink}
                    onClick={() =>
                      formik.setFieldValue(
                        "hideLinCreateLink",
                        !hideLinCreateLink,
                      )
                    }
                    onChange={formik.handleChange}
                    name="hideLinCreateLink"
                  />
                }
                label={
                  <Typography
                    variant="subtitle2"
                    component="h5"
                    sx={{
                      marginBottom: 0,
                      color: theme.palette["cobalt-marque-blanche-second"],
                    }}
                  >
                    Masquer le lien &quot;Créer votre Identité Numérique&quot;
                  </Typography>
                }
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: theme.palette["bleu-5"],
              p: theme.spacing(6),
            }}
          >
            <Box sx={{ position: "relative" }}>
              <img
                title="Page d'authentification de L'Identité Numérique"
                alt="Page d'authentification de L'Identité Numérique"
                src={linLoginImage}
                style={{
                  height: "450px",
                }}
              />
              {process.env.REACT_APP_with_additional_personalisation ===
                "true" &&
                logo && (
                  <div
                    style={{
                      height: "30px",
                      position: "absolute",
                      top: "6px",
                      right: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      id="logo"
                      title="Logo choisi"
                      src={URL.createObjectURL(logo)}
                      aria-hidden="true"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100px",
                      }}
                      alt="Logo choisi"
                    />
                  </div>
                )}
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            my: theme.spacing(3),
            mr: theme.spacing(4),
          }}
        >
          <Button
            sx={{ mr: theme.spacing(3), color: theme.palette["primaire-bleu"] }}
            onClick={() =>
              process.env.REACT_APP_with_additional_personalisation === "true"
                ? onCancel({ ...values, logo }, 1)
                : onCancel(values, 1)
            }
          >
            Retour
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !formik.isValid ||
              (process.env.REACT_APP_with_additional_personalisation ===
                "true" &&
                logoError)
            }
          >
            Suivant
          </Button>
        </Box>
      </form>
    </>
  );
};

LINLoginPageDataForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  techData: TechDataShape.isRequired,
};

export default LINLoginPageDataForm;
