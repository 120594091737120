import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ShieldOff from "icons/shield-off";
import Paper from "components/Paper";
import AclBack from "apps/back/AclBack";
import { roleIsValid, getIconByRoleName } from "utils/role";
import Popover from "@mui/material/Popover";
import AclFront from "apps/front/AclFront";
import Acl from "utils/Acl";
import { handleEnterKeyPress } from "utils/enableEnterNavigation";
import EditIcon from "icons/write";
import MoreIcon from "icons/more";
import EmailIcon from "icons/mail";
import TrashIcon from "icons/trash";
import PlusIcon from "icons/plus";
import UserShape from "models/shapes/UserShape";
import UserRoleEnum from "models/enums/UserRoleEnum";
import UserService from "services/UserService";
import Snackbar from "components/Snackbar";
import Modal from "components/Modal";
import ModalHeader from "components/ModalHeader";
import theme from "theme";
import IsAssociatedBadge from "components/IsAssociatedBadge";

const StyledField = styled("div")({
  margin: "0 0 12px",
});

const disabledClass = {
  opacity: "0.5",
  cursor: "not-allowed",
  pointerEvents: "none",
};

const UserInfo = (props) => {
  const { label, value } = props;
  return (
    <>
      <Typography variant="body2">{label}</Typography>
      <Typography
        variant="subtitle1"
        component="div"
        sx={{ lineHeight: 1, wordBreak: "break-word" }}
      >
        {value || <span aria-label="Non renseigné">-</span>}
      </Typography>
    </>
  );
};
UserInfo.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

UserInfo.defaultProps = {
  value: "",
};

const UserCard = (props) => {
  const { user, refreshUsers, onClickUpdate, onClickToggleEnabled } = props;
  const {
    id,
    email,
    firstName,
    lastName,
    enabled,
    roles,
    createdTimestamp,
    attributes = {},
    isAssociated,
  } = user;

  const { last_login: lastLogin, poste, partnerId } = attributes;

  const [openPopper, setOpenPopper] = useState(false);

  const handleClosePopper = () => {
    setOpenPopper(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickPopperChange = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper((previousOpen) => !previousOpen);
  };

  const canUpdate = useMemo(
    () =>
      Acl.hasAccess(AclBack.ADMIN_USERS, Acl.UPDATE) ||
      Acl.hasAccess(AclFront.USERS, Acl.UPDATE),
    [],
  );

  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleOpenSuccessSnackbar = (
    message = "Le mail d'association du compte a été envoyé.",
  ) => {
    setSnackbar({
      open: true,
      variant: "success",
      message,
    });
  };

  const handleOpenErrorSnackbar = (
    message = "Une erreur est survenue lors de l'envoi du mail.",
  ) => {
    setSnackbar({
      open: true,
      variant: "error",
      message,
    });
  };

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const UpdateEnabledIcon = enabled ? TrashIcon : PlusIcon;

  const creation =
    (createdTimestamp && new Date(createdTimestamp).toLocaleDateString()) ||
    null;

  const lastConnection =
    (lastLogin && new Date(lastLogin).toLocaleDateString()) || null;

  const handleStatusEnabled = (event) => {
    onClickToggleEnabled(event);
    handleClosePopper();
  };

  const unlinkFromLIN = async () => {
    if (partnerId) {
      await UserService.unlinkPartnerUserFromLIN(id, {
        params: {
          clientId: process.env.REACT_APP_keycloak_client_id,
          provider: process.env.REACT_APP_identity_provider,
        },
      })
        .then(async () => {
          await UserService.updatePartnerUser(
            id,
            partnerId,
            process.env.REACT_APP_keycloak_client_id,
            {
              enabled: false,
            },
          );
          refreshUsers();
          handleOpenSuccessSnackbar(
            "Le compte de l'utilisateur a été dissocié.",
          );
        })
        .catch(() => {
          handleOpenErrorSnackbar(
            "Une erreur est survenue lors de la dissociation du compte de l'utilisateur.",
          );
        });
    } else {
      UserService.unlinkFromLIN(id)
        .then(async () => {
          await UserService.updateUser({
            id,
            enabled: false,
          });
          refreshUsers();
          handleOpenSuccessSnackbar(
            "Le compte de l'utilisateur a été dissocié.",
          );
        })
        .catch(() => {
          handleOpenErrorSnackbar(
            "Une erreur est survenue lors de la dissociation du compte de l'utilisateur.",
          );
        });
    }
    handleCloseConfirmationModal();
  };

  const handleUpdate = (event) => {
    onClickUpdate(event);
    handleClosePopper();
  };

  const linkToLIN = () => {
    UserService.sendLinkToIdpAccountEmail(id, {
      params: { clientId: process.env.REACT_APP_keycloak_client_id },
    })
      .then(() => {
        handleOpenSuccessSnackbar();
      })
      .catch(() => {
        handleOpenErrorSnackbar();
      });
    refreshUsers();
  };

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleUpdateFederatedIdentity = () => {
    if (isAssociated) {
      setOpenConfirmationModal(true);
    } else {
      linkToLIN();
    }
    handleClosePopper();
  };

  return (
    <Paper sx={{ height: "100%" }}>
      <Box px={3} py={2}>
        <Grid
          container
          component={Box}
          mb={1.5}
          sx={{ display: "flex", justifyContent: "space-between" }}
          spacing={2}
        >
          <Grid item xs={9} sx={!enabled ? disabledClass : null}>
            <Typography color="text.primary" variant="h4" component="div">
              <span style={{ textTransform: "uppercase" }}>{lastName}</span>{" "}
              {firstName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {canUpdate && (
              <IconButton
                aria-label={`Ouvrir le menu pour ${firstName} ${lastName}`}
                sx={{
                  fontSize: "1.5em",
                  p: 0,
                  cursor: "pointer",
                }}
                onClick={handleClickPopperChange}
              >
                <MoreIcon
                  sx={{ color: theme.palette["dark-theme-light-bg"] }}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
          }}
        >
          {roles &&
            roles[process.env.REACT_APP_keycloak_resource]?.map((role) => {
              if (roleIsValid(role)) {
                const Icon = getIconByRoleName(role);
                return (
                  <IconButton
                    title={UserRoleEnum[role]?.label || role}
                    key={role}
                    component={Box}
                    aria-label={`Rôle: ${UserRoleEnum[role]?.label || role}`}
                    tabIndex={-1}
                    color="secondary"
                    sx={[
                      {
                        padding: "0.5em",
                        fontSize: "16px",
                        cursor: "default",
                        mr: 1,
                        pointerEvents: "none",
                      },
                      ...(!enabled ? [disabledClass] : []),
                    ]}
                    size="large"
                  >
                    <Icon />
                  </IconButton>
                );
              }
              return <span key={role} />;
            })}
          <IsAssociatedBadge isAssociated={isAssociated} />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={!enabled ? disabledClass : null}
        style={{
          padding: "16px 24px",
        }}
      >
        <StyledField>
          <UserInfo label="Adresse mail" value={email} />
        </StyledField>
        <StyledField>
          <UserInfo label="Poste" value={poste} />
        </StyledField>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <StyledField>
            <UserInfo label="Création de compte" value={creation} />
          </StyledField>
          <StyledField>
            <UserInfo label="Dernière connexion" value={lastConnection} />
          </StyledField>
        </div>
      </Box>
      <Popover
        open={openPopper}
        onClose={handleClosePopper}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette["secondary-white"],
            borderRadius: "8px",
            boxShadow: "0px 12px 24px 0px rgba(102, 109, 146, 0.20)",
            minWidth: "258px",
          }}
        >
          <Box
            role="button"
            onClick={(e) => handleUpdate(e)}
            tabIndex={0}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              p: theme.spacing(1),
              borderBottom: `1px solid ${theme.palette["grey-10"]}`,
            }}
            onKeyUp={(e) => handleEnterKeyPress(e, handleUpdate, e)}
          >
            <EditIcon color="primary" sx={{ fontSize: "1.2em" }} />
            <Typography
              variant="h6"
              component="div"
              sx={{
                ml: theme.spacing(0.5),
                color: theme.palette["secondary-ultramarine"],
              }}
            >
              Modifier
            </Typography>
          </Box>
          <Box
            role="button"
            onClick={handleUpdateFederatedIdentity}
            tabIndex={0}
            sx={{
              display: "flex",
              alignItems: "center",
              p: theme.spacing(1),
              cursor: "pointer",
            }}
            onKeyUp={(e) =>
              handleEnterKeyPress(e, handleUpdateFederatedIdentity)
            }
          >
            {isAssociated ? (
              <ShieldOff color="primary" sx={{ fontSize: "1em", mx: "2px" }} />
            ) : (
              <EmailIcon color="primary" sx={{ fontSize: "1em", mx: "2px" }} />
            )}
            <Typography
              variant="h6"
              component="div"
              aria-label={
                isAssociated
                  ? "Dissocier l'Identité Numérique"
                  : "Envoyer un email d'association à l'Identité Numérique"
              }
              sx={{
                ml: theme.spacing(0.5),
                color: theme.palette["secondary-ultramarine"],
              }}
            >
              {isAssociated
                ? "Dissocier l'Identité Numérique"
                : "Envoyer un email d'association"}
            </Typography>
          </Box>
          <Box
            role="button"
            onClick={handleStatusEnabled}
            tabIndex={0}
            sx={{
              display: "flex",
              p: theme.spacing(1),
              cursor: "pointer",
              borderTop: `1px solid ${theme.palette["grey-10"]}`,
              alignItems: "center",
            }}
            onKeyUp={(e) => handleEnterKeyPress(e, handleStatusEnabled, e)}
          >
            <UpdateEnabledIcon color="primary" sx={{ fontSize: "1.2em" }} />
            <Typography
              variant="h6"
              component="div"
              sx={{
                ml: theme.spacing(0.5),
                color: theme.palette["secondary-ultramarine"],
              }}
            >
              {enabled ? "Désactiver" : "Activer"}
            </Typography>
          </Box>
        </Box>
      </Popover>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} duration={15000} />
      <Modal
        open={openConfirmationModal}
        disableBackdropClick
        onClose={handleCloseConfirmationModal}
        size="sm"
      >
        <Box>
          <ModalHeader
            handleCloseModal={handleCloseConfirmationModal}
            title={`Êtes-vous sûr de vouloir dissocier L'Identité Numérique de ${firstName} ${lastName} ?`}
          />
          <Divider />
          <Box
            sx={{
              p: theme.spacing(4, 3),
            }}
          >
            <Typography
              component="div"
              variant="h6"
              sx={{
                fontSize: "1rem",
                fontWeight: 400,
              }}
            >
              {`En cliquant sur confirmer, ${firstName} ${lastName} ne pourra plus utiliser son Identité Numérique pour se connecter à son espace partenaire business.`}
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              p: theme.spacing(3, 4),
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{ color: theme.palette["primaire-bleu"] }}
              variant="text.primary"
              onClick={handleCloseConfirmationModal}
            >
              Annuler
            </Button>
            <Button variant="contained" color="primary" onClick={unlinkFromLIN}>
              Confirmer
            </Button>
          </Box>
        </Box>
      </Modal>
    </Paper>
  );
};

UserCard.propTypes = {
  user: UserShape.isRequired,
  refreshUsers: PropTypes.func,
  onClickUpdate: PropTypes.func,
  onClickToggleEnabled: PropTypes.func,
};

UserCard.defaultProps = {
  refreshUsers: () => null,
  onClickUpdate: () => null,
  onClickToggleEnabled: () => null,
};

export default UserCard;
