import axios from "axios";

export default (name) =>
  class CRUD {
    static get = (id, options) =>
      axios
        .get(`${process.env.REACT_APP_api_url}/${name}/${id}`, options)
        .then((r) => r.data);

    static find = (options = {}) => {
      return axios
        .get(`${process.env.REACT_APP_api_url}/${name}`, options)
        .then((r) => r.data);
    };

    static create = (object) =>
      axios
        .post(`${process.env.REACT_APP_api_url}/${name}`, object)
        .then((r) => r.data);

    static update = (object) =>
      axios
        .patch(`${process.env.REACT_APP_api_url}/${name}/${object.id}`, {
          ...object,
          id: undefined,
        })
        .then((r) => r.data);

    static delete = (object) =>
      axios
        .delete(`${process.env.REACT_APP_api_url}/${name}/${object.id}`)
        .then((r) => r.data);
  };
