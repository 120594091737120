import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import AclBack from "apps/back/AclBack";
import PartnerAccountForm from "apps/back/pages/partners/PartnerAccountForm";
import Acl, { withAccess } from "utils/Acl";
import PartnerService from "services/PartnerService";
import history from "historyApp";
import Snackbar from "components/Snackbar";

const EditPartnerAccount = () => {
  const location = useLocation();
  const { partnerId } = useParams();

  const [partner, setPartner] = useState();
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  useEffect(() => {
    PartnerService.get(partnerId)
      .then(setPartner)
      .catch(() => {
        setSnackbar({
          open: true,
          message:
            "Une erreur est survenue lors de la récupération du compte partenaire.",
          variant: "error",
        });
      });
  }, [partnerId]);

  const handleValidatePartner = useCallback(
    (newPartner) => {
      const {
        created,
        updated,
        keycloakData,
        techDatas,
        activationDate,
        ...cleanPartner
      } = newPartner;
      PartnerService.update(cleanPartner)
        .then(() => {
          history.push(`/partenaire/${partnerId}`);
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message:
              "Une erreur est survenue lors de la mise à jour du compte partenaire.",
            variant: "error",
          });
        });
    },
    [partnerId],
  );

  const handleCancel = useCallback(() => {
    history.push(`/partenaire/${partnerId}`);
  }, [partnerId]);

  return (
    <>
      {partner && (
        <PartnerAccountForm
          isCompleteInformationForm={location.search?.includes(
            "action=complete",
          )}
          onValidate={handleValidatePartner}
          onCancel={handleCancel}
          validateLabel="Mettre à jour"
          partner={partner}
          headerLabel="Modification d'un compte partenaire"
        />
      )}
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </>
  );
};

export default withAccess(
  AclBack.PARTNERS_DETAILS,
  Acl.UPDATE,
)(EditPartnerAccount);
