import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M18.463 8.906c-.282.28-.663.438-1.06.438-.398 0-.78-.158-1.061-.438l-1.246-1.245c-.28-.282-.437-.663-.437-1.061 0-.398.157-.779.437-1.06l2.82-2.821c.005-.006.01-.012.011-.02.003-.007.003-.015.002-.022-.001-.008-.005-.015-.01-.021-.004-.006-.01-.011-.018-.014-1.82-.78-4.179-.383-5.677 1.105-1.433 1.422-1.515 3.562-.99 5.43.075.265.075.546 0 .811-.075.266-.222.505-.424.693L3 17.813c-.23.204-.416.453-.547.732-.13.279-.203.581-.212.89-.009.307.045.614.158.9.114.286.285.546.503.764.217.217.477.388.764.502.286.113.592.167.9.158.308-.01.61-.081.89-.212.278-.13.527-.317.732-.547l7.212-7.828c.185-.2.42-.344.681-.42.261-.075.538-.078.8-.008 1.854.496 3.963.403 5.378-.993 1.523-1.5 1.851-4.152 1.113-5.669-.003-.007-.008-.012-.014-.017-.006-.004-.013-.007-.02-.008-.008-.001-.015 0-.022.001-.007.003-.014.007-.02.012l-2.833 2.836z"
    />
    <path d="M4.5 20.25c.414 0 .75-.336.75-.75s-.336-.75-.75-.75-.75.336-.75.75.336.75.75.75z" />
  </SvgIcon>
);
