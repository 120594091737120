import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, Typography } from "@mui/material";
import Circle from "components/Circle";
import { PeriodShape } from "components/PeriodsPicker/PeriodUtil";
import DateForm from "components/PeriodsPicker/DateForm";

const EditPeriod = (props) => {
  const { period, label, secondary, onPeriodChange } = props;

  const { startDate: startD, endDate: endD, init } = period;

  const theme = useTheme();

  const handleEndDateChange = (newDate) => {
    onPeriodChange({
      startDate: startD,
      endDate:
        newDate < startD
          ? new Date(
              new Date(
                startD.getMonth() === "11"
                  ? startD.getFullYear() + 1
                  : startD.getFullYear(),
                startD.getMonth() + 1,
                0,
                23,
                59,
                59,
                999,
              ),
            )
          : new Date(
              new Date(
                newDate.getFullYear(),
                newDate.getMonth() + 1,
                0,
                23,
                59,
                59,
                999,
              ),
            ),
      init: false,
    });
  };

  const handleStartDateChange = (newDate) => {
    onPeriodChange({
      startDate: new Date(newDate),
      endDate:
        endD < newDate
          ? new Date(
              new Date(
                newDate.getMonth() === "11"
                  ? newDate.getFullYear() + 1
                  : newDate.getFullYear(),
                newDate.getMonth() + 1,
                0,
                23,
                59,
                59,
                999,
              ),
            )
          : endD,
      init: false,
    });
  };

  return (
    <Box
      sx={{
        width: "700px",
        maxWidth: "100%",
      }}
    >
      {label && (
        <Grid container spacing={2} alignItems="center" component={Box} mb={1}>
          <Grid item sx={{ fontSize: "0.6rem" }}>
            <Circle
              color={
                secondary
                  ? theme.palette.secondary.main
                  : theme.palette["bleu-80"]
              }
            />
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1" component="h4">
              {label}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        sx={{ display: "flex", justifyContent: "space-between" }}
        spacing={2}
      >
        <Grid item xs={12} md={6}>
          <DateForm
            label="De :"
            date={startD}
            init={init}
            onDateChange={handleStartDateChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateForm
            label="à :"
            date={endD}
            init={init}
            onDateChange={handleEndDateChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

EditPeriod.propTypes = {
  period: PeriodShape.isRequired,
  label: PropTypes.node,
  secondary: PropTypes.bool,
  onPeriodChange: PropTypes.func.isRequired,
};

EditPeriod.defaultProps = {
  label: "",
  secondary: false,
};

export default EditPeriod;
