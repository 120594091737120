export const getLabelRepresentation = (enumObject, name) => {
  const objectRepresentation = Object.values(enumObject).find(
    (value) => value.name === name,
  );
  return (objectRepresentation && objectRepresentation.label) || "";
};

export const isRequired = (completeSchema, name) => {
  let schema = completeSchema;
  const labels = name.split(".");
  labels.forEach(el => {
    schema = schema?.fields[el];
  });
  return schema?._exclusive?.required || false;
}

export const inputAdditionalMessage = (name, active = false) => {
  switch (name) {
    case "intracommunityVatNumber":
      return active ? "champ obligatoire si la TVA est appliquée" : "";
    case "rib":
      return active ? "champ obligatoire si le mode de règlement est par prélèvement" : "";
    case "bic":
      return active ? "champ obligatoire si le mode de règlement est par prélèvement" : "";
    case "bankDomiciliation":
      return active ? "champ obligatoire si le mode de règlement est par prélèvement" : "";
    case "copiesNumber":
      return "champ obligatoire si le mode d'envoi de la facture est par courrier";
    case "billingSendEmail":
      return "champ obligatoire si le mode d'envoi de la facture est par courriel";
    case "annualCommitmentVolume":
      return "champ obligatoire si le type de facturation est licence";
    default:
      return "";
  }
};

export default { inputAdditionalMessage, getLabelRepresentation, isRequired };
