import * as Yup from "yup";
import { errorPhoneMessage, regExPhone } from "utils/StringUtil";

const AttributesSchema = Yup.object().shape({
  poste: Yup.string().nullable(),
  phoneNumber: Yup.string()
    .matches(regExPhone, errorPhoneMessage)
    .transform((value) => (!value ? null : value))
    .nullable(),
  last_login: Yup.string(),
  locale: Yup.string().required("Champ requis"),
});

export default AttributesSchema;
