import React, { useCallback, useState } from "react";
import AclBack from "apps/back/AclBack";
import PartnerAccountForm from "apps/back/pages/partners/PartnerAccountForm";
import Acl, { withAccess } from "utils/Acl";
import { FrontUserRoleEnum } from "models/enums/UserRoleEnum";
import PartnerService from "services/PartnerService";
import UserService from "services/UserService";
import history from "historyApp";
import Snackbar from "components/Snackbar";

const CreatePartnerAccount = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleOpenErrorSnackbar = (
    msg = "Une erreur est survenue lors de la création d'un utilisateur.",
  ) => {
    setSnackbar({
      open: true,
      variant: "error",
      message: msg,
    });
  };

  const handleValidatePartner = useCallback((partner) => {
    const { id, status, keycloakData, techDatas, ...object } = partner;
    const { contact } = object;
    const keycloakClientId = process.env.REACT_APP_keycloak_front_client_id;
    const newUser = {
      username: contact.email,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      enabled: true,
      attributes: {
        poste: contact.roleInCompany,
        phoneNumber: contact.phoneNumber,
        last_login: "",
        locale: "fr",
      },
      clientRoles: {
        [keycloakClientId]: [FrontUserRoleEnum.ADMINISTRATEUR.name],
      },
    };

    PartnerService.create(object)
      .then(async (createdPartner) => {
        newUser.attributes.partnerId = createdPartner.id;
        UserService.createPartnerUser(
          createdPartner.id,
          keycloakClientId,
          newUser,
        )
          .then((userId) => {
            UserService.sendResetPasswordEmail(userId, {
              params: {
                clientId: keycloakClientId,
                redirectUri:
                  keycloakClientId ===
                  process.env.REACT_APP_keycloak_front_client_id
                    ? process.env.REACT_APP_front_url
                    : process.env.REACT_APP_back_url,
              },
            }).catch(() => {
              handleOpenErrorSnackbar(
                "Une erreur est survenue lors de l'envoi du mail d'activation du compte utilisateur.",
              );
            });
          })
          .catch((error) => {
            if (error.response.status === 409) {
              handleOpenErrorSnackbar(
                "Un utilisateur avec cette adresse mail existe déjà.",
              );
            } else {
              handleOpenErrorSnackbar();
            }
          });
        history.push("/listing-partenaires");
      })
      .catch(async () => {
        handleOpenErrorSnackbar(
          "Une erreur est survenue lors de la création du compte partenaire.",
        );
      });
  }, []);

  const handleCancel = useCallback(() => {
    history.push("/listing-partenaires");
  }, []);

  return (
    <>
      <PartnerAccountForm
        onValidate={handleValidatePartner}
        onCancel={handleCancel}
      />
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </>
  );
};

export default withAccess(
  AclBack.PARTNERS_DETAILS,
  Acl.CREATE,
)(CreatePartnerAccount);
