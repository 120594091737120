import PropTypes from "prop-types";
import ScopesTechDataEnum from "models/enums/ScopesTechDataEnum";

const TechDataShape = PropTypes.shape({
  useName: PropTypes.string,
  environmentType: PropTypes.string,
  urls: PropTypes.arrayOf(PropTypes.string),
  scopes: PropTypes.arrayOf(
    PropTypes.oneOf([
      ...Object.values(ScopesTechDataEnum).map((value) => value.name),
    ]),
  ),
  signingAlgorithm: PropTypes.string,
  userInfoAlgorithm: PropTypes.string,
  flow: PropTypes.string,
  hideLinCreateLink: PropTypes.bool,
  hideReturnUrl: PropTypes.bool,
  status: PropTypes.string,
});

export default TechDataShape;
