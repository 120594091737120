import PropTypes from "prop-types";

export const PeriodShape = PropTypes.shape({
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
});

export const getDefaultPeriod = (date) => {
  const currentDate = new Date();
  let startDate;

  if (date) {
    startDate = new Date(date);
  } else {
    startDate = new Date(currentDate);
    startDate.setMonth(0);
    startDate.setDate(1);
  }
  return {
    startDate,
    endDate: currentDate,
    init: false,
  };
};

export const getDefaultPeriodForLicenceBilling = (date) => {
  const currentDate = new Date();
  let startDate;
  const tmpDate = new Date(date);
  const aniversaryDate = new Date(
    tmpDate.getFullYear() + 1,
    tmpDate.getMonth() + 1,
    1,
  ); // + 1 an fin de mois + 1 jour

  if (date) {
    if (currentDate >= aniversaryDate) {
      // J'ai passé la date d'anniversaire
      startDate = new Date(aniversaryDate);
    } else {
      // La date d'anniversaire n'est pas passé, on récupère la date du jour
      startDate = new Date(date);
    }
  } else {
    startDate = new Date(currentDate);
    startDate.setMonth(0);
    startDate.setDate(1);
  }
  return {
    startDate,
    endDate: currentDate,
    init: false,
  };
};

export const areSamePeriods = (period1 = {}, period2 = {}) => {
  const { startDate: startDate1, endDate: endDate1 } = period1;
  const { startDate: startDate2, endDate: endDate2 } = period2;

  if (
    [startDate1, endDate1, startDate2, endDate2].some(
      (date) => !(date instanceof Date),
    )
  ) {
    return false;
  }
  return (
    startDate1.getTime() === startDate2.getTime() &&
    endDate1.getTime() === endDate2.getTime()
  );
};

const util = {
  PeriodShape,
  getDefaultPeriod,
  areSamePeriods,
};

export default util;
