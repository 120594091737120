import React, { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "Layout";
import NotFound from "NotFound";
import Acl from "utils/Acl";
import { profiles } from "apps/back/AclBack";
import { getMenu, getRoutes } from "apps/back/routesBack";
import { CurrentRouteContextProvider } from "components/CurrentRouteContext";

const AppBack = () => {
  Acl.setMapping(profiles);
  const routes = useMemo(getRoutes, []);
  const menu = useMemo(getMenu, []);

  return (
    <CurrentRouteContextProvider routes={routes}>
      <Layout menu={menu}>
        <Switch color="secondary">
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <Route path="*">
            {routes.length > 0 ? (
              <Redirect to={routes[0].path} />
            ) : (
              <NotFound />
            )}
          </Route>
        </Switch>
      </Layout>
    </CurrentRouteContextProvider>
  );
};

export default AppBack;
