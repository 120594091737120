import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props} width="33" height="32" viewBox="0 0 33 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3768 23.0441V10.4326H6.5V23.4189C6.5 25.9231 8.60612 27.9818 11.2165 27.9996H18.5988V24.6308H12.0307C11.1173 24.6308 10.3768 23.9203 10.3768 23.0441Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7836 10.4326H14.4014V22.459H18.1474V13.8016H20.9694C21.883 13.8016 22.6232 14.5119 22.6232 15.3881V27.9996H26.5001V15.0133C26.5001 12.5092 24.394 10.4506 21.7836 10.4326Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3668 8.45361C17.5987 8.45361 18.6043 7.4463 18.6043 6.22689C18.6043 5.00748 17.5987 4 16.3668 4C15.1349 4 14.1543 5.00748 14.1543 6.22689C14.1543 7.4463 15.1349 8.45361 16.3668 8.45361Z"
    />
  </SvgIcon>
);
