import * as Yup from "yup";
import { billingShape } from "models/schemas/BillingSchema";
import { isValidRib } from "utils/RibUtil"
import ModeOfPaymentEnum from "models/enums/ModeOfPaymentEnum";
import RibFormCompleteSchema from "models/schemas/RibFormCompleteSchema";

const BillingFormCompleteSchema = Yup.object().shape({
  ...billingShape,
  intracommunityVatNumber: Yup.string()
    .nullable()
    .when("vatApplication", {
      is: true,
      then: (fieldSchema) =>
        fieldSchema
          .required(
            "Champ requis si la TVA est appliquée",
          )
  }),
  rib: Yup.object()
    .nullable()
    .when("modeOfPayment", {
      is: ModeOfPaymentEnum.DEBIT.name,
      then: () => RibFormCompleteSchema.nullable(false).test("is-rib", "RIB non valide", isValidRib),
    }),
    bic: Yup.string()
      .nullable()
      .when("modeOfPayment", {
        is: ModeOfPaymentEnum.DEBIT.name,
        then: (fieldSchema) =>
          fieldSchema
            .required("Champ requis")
            .transform((value) => (!value ? null : value)).matches(
              /^[a-zA-Z]{6}[0-9a-zA-Z]{2}([0-9a-zA-Z]{3})?/i,
              "BIC non valide",
            )
      }),
    bankDomiciliation: Yup.string()
      .nullable()
      .when("modeOfPayment", {
        is: ModeOfPaymentEnum.DEBIT.name,
        then: (fieldSchema) =>
          fieldSchema
            .required(
              "Champ requis",
            )
      }),
  typeOfBilling: Yup.string().nullable()
    .required("Champ requis")
});

export default BillingFormCompleteSchema;
