import React from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";

const Loader = (props) => {
  const { title, className, ...others } = props;

  return (
    <Box
      className={className}
      {...others}
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        "& .MuiCircularProgress-circle": {
          stroke: theme.palette.primary.main,
          strokeLinecap: "round",
        },
        ...others.sx,
      })}
      aria-label="Chargement en cours"
    >
      <CircularProgress />
      <span style={{ color: "#666666", marginTop: "16px" }}>{title}</span>
    </Box>
  );
};

Loader.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

Loader.defaultProps = {
  title: "",
  className: "",
};

export default Loader;
