import PropTypes from "prop-types";
import ContractShape from "models/shapes/ContractShape";
import AddressShape from "models/shapes/AddressShape";
import ContactShape from "models/shapes/ContactShape";
import BillingShape from "models/shapes/BillingShape";
import TechDataShape from "models/shapes/TechDataShape";
import SalesChannelEnum from "models/enums/SalesChannelEnum";
import PartnerEnterpriseTypeEnum from "models/enums/PartnerEnterpriseTypeEnum";
import PartnerTypologyEnum from "models/enums/PartnerTypologyEnum";

const PartnerShape = PropTypes.shape({
  issuerCode: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  siretNumber: PropTypes.string.isRequired,
  code: PropTypes.string,
  status: PropTypes.string,
  salesChannel: PropTypes.oneOf([
    ...Object.values(SalesChannelEnum).map((value) => value.name),
    null,
    "",
  ]),
  businessLine: PropTypes.string,
  enterpriseType: PropTypes.oneOf([
    ...Object.values(PartnerEnterpriseTypeEnum),
    null,
    "",
  ]),
  typology: PropTypes.oneOf([
    ...Object.values(PartnerTypologyEnum).map((value) => value.name),
    null,
    "",
  ]),
  contract: ContractShape,
  address: AddressShape,
  contact: ContactShape,
  billing: BillingShape,
  techDatas: PropTypes.arrayOf(TechDataShape),
});

export default PartnerShape;
