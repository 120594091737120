import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import ContactShape from "models/shapes/ContactShape";
import Form from "components/Form";
import InputField from "components/InputField";
import { isRequired } from "utils/enum";

const ContactForm = (props) => {
  const {
    contact,
    namePrefix,
    touched,
    errors,
    schema,
    handleChange,
    handleBlur,
    ...others
  } = props;

  return (
    <Form
      content={[
        {
          key: "lastName/firstName",
          firstElement: (
            <InputField title="Nom">
              <TextField
                variant="outlined"
                name={`${namePrefix}lastName`}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                value={contact.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-invalid": Boolean(touched.lastName && errors.lastName),
                  "aria-required": isRequired(schema, `${namePrefix}lastName`),
                  "aria-labelledby": "nom",
                  "aria-describedby": "ht_lastname",
                }}
                FormHelperTextProps={{
                  id: "ht_lastname",
                }}
              />
            </InputField>
          ),
          secondElement: (
            <InputField title="Prénom">
              <TextField
                variant="outlined"
                name={`${namePrefix}firstName`}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                value={contact.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-invalid": Boolean(touched.firstName && errors.firstName),
                  "aria-required": isRequired(schema, `${namePrefix}firstName`),
                  "aria-labelledby": "prénom",
                  "aria-describedby": "ht_firstname",
                }}
                FormHelperTextProps={{
                  id: "ht_firstname",
                }}
              />
            </InputField>
          ),
        },
        {
          key: "roleInCompany/email",
          firstElement: (
            <InputField title="Fonction">
              <TextField
                name={`${namePrefix}roleInCompany`}
                variant="outlined"
                error={touched.roleInCompany && Boolean(errors.roleInCompany)}
                helperText={touched.roleInCompany && errors.roleInCompany}
                value={contact.roleInCompany}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-invalid": Boolean(touched.roleInCompany && errors.roleInCompany),
                  "aria-required": isRequired(schema, `${namePrefix}roleInCompany`),
                  "aria-labelledby": "fonction",
                  "aria-describedby": "ht_role",
                }}
                FormHelperTextProps={{
                  id: "ht_role",
                }}
              />
            </InputField>
          ),
          secondElement: (
            <InputField title="Adresse e-mail">
              <TextField
                variant="outlined"
                type="email"
                name={`${namePrefix}email`}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                value={contact.email}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-invalid": Boolean(touched.email && errors.email),
                  "aria-required": isRequired(schema, `${namePrefix}email`),
                  "aria-labelledby": "adresse_e-mail",
                  "aria-describedby": "ht_email",
                }}
                FormHelperTextProps={{
                  id: "ht_email",
                }}
              />
            </InputField>
          ),
        },
        {
          key: "phoneNumber",
          firstElement: (
            <InputField title="Téléphone">
              <TextField
                name={`${namePrefix}phoneNumber`}
                variant="outlined"
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
                value={contact.phoneNumber || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-invalid": Boolean(touched.phoneNumber && errors.phoneNumber),
                  "aria-required": isRequired(schema, `${namePrefix}phoneNumber`),
                  "aria-labelledby": "téléphone",
                  "aria-describedby": "ht_telephone",
                }}
                FormHelperTextProps={{
                  id: "ht_telephone",
                }}
              />
            </InputField>
          ),
          secondElement: <div />,
        },
      ]}
      {...others}
    />
  );
};

ContactForm.propTypes = {
  contact: ContactShape.isRequired,
  namePrefix: PropTypes.string,
  touched: PropTypes.shape(),
  errors: PropTypes.shape(),
  schema: PropTypes.shape(),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

ContactForm.defaultProps = {
  namePrefix: "",
  touched: {},
  errors: {},
  schema: {},
};

export default ContactForm;
