import * as Yup from "yup";
import BillingSendModeEnum from "models/enums/BillingSendModeEnum";
import ModeOfPaymentEnum from "models/enums/ModeOfPaymentEnum";
import BillingTypeEnum from "models/enums/BillingTypeEnum";
import RibCompleteSchema from "models/schemas/RibCompleteSchema";
import isEmailValidator from "validator/lib/isEmail";

const BillingCompleteSchema = Yup.object().shape({
  intracommunityVatNumber: Yup.string()
    .nullable()
    .when("vatApplication", {
      is: true,
      then: (fieldSchema) =>
        fieldSchema
          .required(
            "Le numéro de TVA intracommunautaire de l'entreprise n'est pas renseigné",
          )
          .nullable(false)
          .typeError(
            "Le numéro de TVA intracommunautaire de l'entreprise n'est pas renseigné",
          ),
    }),
  rib: Yup.object()
    .nullable()
    .when("modeOfPayment", {
      is: ModeOfPaymentEnum.DEBIT.name,
      then: () => RibCompleteSchema.nullable(false),
    }),
  bic: Yup.string()
    .nullable()
    .when("modeOfPayment", {
      is: ModeOfPaymentEnum.DEBIT.name,
      then: (fieldSchema) =>
        fieldSchema
          .required("Données de facturation : Le bic n'est pas renseigné")
          .nullable(false)
          .typeError("Données de facturation : Le bic n'est pas renseigné"),
    }),
  bankDomiciliation: Yup.string()
    .nullable()
    .when("modeOfPayment", {
      is: ModeOfPaymentEnum.DEBIT.name,
      then: (fieldSchema) =>
        fieldSchema
          .required(
            "Données de facturation : La domiciliation bancaire n'est pas renseignée",
          )
          .nullable(false)
          .typeError(
            "Données de facturation : La domiciliation bancaire n'est pas renseignée",
          ),
    }),
  copiesNumber: Yup.number()
    .typeError("Format incorrect")
    .min(1, "La valeur doit être supérieure ou égale à 1")
    .nullable()
    .when("sendMode", {
      is: BillingSendModeEnum.BY_POST.name,
      then: (fieldSchema) =>
        fieldSchema
          .required(
            "Données de facturation : Le nombre d'exemplaires n'est pas renseigné",
          )
          .nullable(false)
          .integer(
            "Données de facturation : Le nombre d'exemplaires doit être un nombre entier",
          )
          .typeError(
            "Données de facturation : Le nombre d'exemplaires doit être un nombre entier",
          ),
    }),
  billingSendEmail: Yup.string()
    .nullable()
    .when("sendMode", {
      is: BillingSendModeEnum.BY_EMAIL.name,
      then: (fieldSchema) =>
        fieldSchema
          .required(
            "Données de facturation : L'e-mail pour l'envoi de la facture n'est pas renseigné",
          )
          .nullable(false)
          .email("Données de facturation : Format d'e-mail incorrect")
          .test(
            "is-valid",
            "Données de facturation : Format d'e-mail incorrect",
            (val) => !val || isEmailValidator(val),
          )
          .typeError("Données de facturation : Format d'e-mail incorrect"),
    }),
  typeOfBilling: Yup.string()
    .required(
      "Données de facturation : Le type de facturation n'est pas renseigné",
    )
    .typeError(
      "Données de facturation : Le type de facturation n'est pas renseigné",
    ),
  annualCommitmentVolume: Yup.number()
    .typeError("Format incorrect")
    .nullable()
    .when("typeOfBilling", {
      is: BillingTypeEnum.LICENCE.name,
      then: (fieldSchema) =>
        fieldSchema
          .required(
            "Données de facturation : Le volume d'engagement annuel n'est pas renseigné",
          )
          .nullable(false)
          .typeError(
            "Données de facturation : Le volume d'engagement annuel n'est pas renseigné",
          ),
    })
    .nullable(),
});

export default BillingCompleteSchema;
