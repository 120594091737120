import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useKeycloak } from "@react-keycloak/web";
import UserService from "services/UserService";
import PageTitle from "components/PageTitle";
import UserCard from "components/UserCard";
import Button from "components/Button";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import Loader from "components/Loader";
import Modal from "components/Modal";
import AclBack from "apps/back/AclBack";
import AclFront from "apps/front/AclFront";
import CreateUserForm from "apps/back/pages/partners/CreateUserForm";
import EditUserForm from "apps/back/pages/partners/EditUserForm";
import { visuallyHidden } from "@mui/utils";
import AddIcon from "icons/plus";
import { getIconByRoleName, roleIsValid } from "utils/role";
import Acl from "utils/Acl";
import UserRoleEnum from "models/enums/UserRoleEnum";
import UserFactory from "models/factories/UserFactory";
import Snackbar from "components/Snackbar";
import TcWrapper from "react-tag-commander";

const StyledFilterButton = styled(Button)(({ theme }) => ({
  ...theme.components.MuiToggleButton.styleOverrides.root,
  margin: 0,
  "&:hover": {
    color: "white",
  },
}));

const StyledFilterButtonContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: "flex",
  justifyContent: "flex-start",
}));

const ManageUsers = () => {
  const canReadAll = useMemo(
    () => Acl.hasAccess(AclBack.ADMIN_USERS, Acl.READ),
    [],
  );
  const canCreate = useMemo(
    () =>
      Acl.hasAccess(AclBack.ADMIN_USERS, Acl.CREATE) ||
      Acl.hasAccess(AclFront.USERS, Acl.CREATE),
    [],
  );

  const { keycloak } = useKeycloak();
  const [partnerId, setPartnerId] = useState(null);

  useEffect(() => {
    if (keycloak.authenticated) {
      const loadProfile = async () => {
        await keycloak.loadUserProfile();
        setPartnerId(keycloak?.profile?.attributes?.partnerId?.[0]);
      };
      loadProfile();
    }
  }, [keycloak]);

  const [activesFilter, setActivesFilter] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(null);
  const [modal, setModal] = useState(null);
  const wrapper = TcWrapper.getInstance();

  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleClickResetFilter = useCallback(() => {
    setActivesFilter([]);
  }, []);

  const handleClickToggleFilter = useCallback(
    (filter, event) => {
      wrapper.triggerEvent("click", event.target, {
        clic_type: "action",
        name: `filtre_${filter.name.toLowerCase()}`,
        chapter1: "gestion_utilisateurs",
        chapter2: "liste_utilisateurs",
      });
      const { name } = filter;
      if (activesFilter.includes(name)) {
        setActivesFilter(activesFilter.filter((f) => f !== name));
      } else {
        setActivesFilter([...activesFilter, name]);
      }
    },
    [activesFilter, wrapper],
  );

  const isSelected = useCallback(
    (filter) => activesFilter.includes(filter.name),
    [activesFilter],
  );

  const sortUsersList = (list) => {
    list.sort((a, b) => {
      if (a.enabled && !b.enabled) {
        return -1;
      }
      if (!a.enabled && b.enabled) {
        return 1;
      }
      return a.lastName < b.lastName ? -1 : 1;
    });
  };

  const refreshUsers = useCallback(() => {
    if (canReadAll) {
      setIsLoading(true);
      UserService.getAdminUsers({
        params: { clientId: process.env.REACT_APP_keycloak_client_id },
      }).then(async (result) => {
        setIsLoading(false);
        const data =
          (Array.isArray(result) &&
            result.map((personalData) =>
              UserFactory.createFromPersonalData(personalData),
            )) ||
          [];
        sortUsersList(data);
        setUsers(data);
      });
    } else if (partnerId) {
      setIsLoading(true);
      UserService.getPartnerUsers(
        partnerId,
        process.env.REACT_APP_keycloak_client_id,
      )
        .then((result) => {
          setIsLoading(false);
          const data =
            (Array.isArray(result) &&
              result.map((personalData) =>
                UserFactory.createFromPersonalData({
                  ...personalData,
                  partnerId,
                }),
              )) ||
            [];
          sortUsersList(data);
          setUsers(data);
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message:
              "Une erreur est survenue lors de la récupération des utilisateurs.",
            variant: "error",
          });
        });
    }
  }, [canReadAll, partnerId]);

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);

  const roles = useMemo(() => {
    const list = [];
    users.forEach((user) => {
      const userRoles = user.roles[process.env.REACT_APP_keycloak_client_id];
      if (Array.isArray(userRoles)) {
        userRoles.forEach((role) => {
          if (!list.includes(role) && roleIsValid(role)) {
            list.push(role);
          }
        });
      }
    });
    return list;
  }, [users]);

  const filters = roles.map((role) => ({
    icon: getIconByRoleName(role),
    name: role,
    label: UserRoleEnum[role]?.label || role,
  }));

  const usersFiltered = useMemo(
    () =>
      users.filter(
        (user) =>
          activesFilter.length === 0 ||
          user?.roles?.[process.env.REACT_APP_keycloak_client_id].some((r) =>
            activesFilter.includes(r),
          ),
      ),
    [users, activesFilter],
  );

  const handleClickCreateUser = useCallback(
    (event) => {
      wrapper.triggerEvent("click", event.target, {
        clic_type: "action",
        name: "ajout_utilisateur",
        chapter1: "gestion_utilisateurs",
      });
      setOpenCreateUser(true);
    },
    [wrapper],
  );

  const handleCancelCreateUser = useCallback(() => {
    setOpenCreateUser(false);
  }, []);

  const handleCloseCreateUser = useCallback(() => {
    setOpenCreateUser(false);
    refreshUsers();
  }, [refreshUsers]);

  const handleClickUpdateUser = useCallback(
    (user, event) => {
      wrapper.triggerEvent("click", event.target, {
        clic_type: "action",
        name: "modifier_utilisateurs",
        chapter1: "gestion_utilisateurs",
        chapter2: "liste_utilisateurs",
        chapter3:
          activesFilter.length !== 0
            ? `filtre_${activesFilter.join("_").toLowerCase()}`
            : "",
      });

      setUpdatingUser(user);
    },
    [activesFilter, wrapper],
  );
  const handleCloseEditUser = useCallback(() => {
    setUpdatingUser(null);
    refreshUsers();
  }, [refreshUsers]);

  const handleCancelEditUser = useCallback(() => {
    setUpdatingUser(null);
  }, []);

  const toggleUserEnable = useCallback(
    (user) => () => {
      if (partnerId) {
        UserService.updatePartnerUser(
          user.id,
          partnerId,
          process.env.REACT_APP_keycloak_client_id,
          {
            enabled: !user.enabled,
          },
        ).then(() => {
          setModal(null);
          refreshUsers();
        });
      } else {
        UserService.updateUser({
          id: user.id,
          enabled: !user.enabled,
        }).then(() => {
          setModal(null);
          refreshUsers();
        });
      }
    },
    [refreshUsers, partnerId],
  );

  const handleClickToggleEnable = useCallback(
    (user, event) => {
      wrapper.triggerEvent("click", event.target, {
        clic_type: "action",
        name: "supprimer_utilisateurs",
        chapter1: "gestion_utilisateurs",
        chapter2: "liste_utilisateurs",
        chapter3:
          activesFilter.length !== 0
            ? `filtre_${activesFilter.join("_").toLowerCase()}`
            : "",
      });

      return setModal(
        <Box px={4} pt={4} pb={1.5}>
          <Typography variant="h5">
            {user.enabled
              ? "Êtes-vous sûr de vouloir désactiver ce compte ?"
              : "Êtes-vous sûr de vouloir activer ce compte ?"}
          </Typography>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "flex-end" }}
            component={Box}
            mt={2}
          >
            <Grid
              item
              component={Button}
              variant="text"
              onClick={() => setModal(null)}
            >
              Non
            </Grid>
            <Grid
              item
              component={Button}
              variant="text"
              onClick={toggleUserEnable(user)}
            >
              Oui
            </Grid>
          </Grid>
        </Box>,
      );
    },
    [toggleUserEnable, activesFilter, wrapper],
  );

  if (
    !Acl.hasAccess(AclBack.ADMIN_USERS, Acl.READ) &&
    !Acl.hasAccess(AclFront.USERS, Acl.READ)
  ) {
    return null;
  }

  return (
    <>
      <Modal open={!!modal} onClose={() => setModal(null)}>
        {modal}
      </Modal>
      <PageTitle>Gestion des utilisateurs</PageTitle>
      <StyledFilterButtonContainer container spacing={2}>
        <span style={visuallyHidden}>
          Filtrer les utilisateurs (plusieurs filtres peuvent être sélectionnés)
        </span>
        <Grid item>
          <StyledFilterButton
            style={{
              color:
                theme.palette[
                  activesFilter.length === 0
                    ? "secondary-white"
                    : "secondary-ultramarine"
                ],
              border: activesFilter.length === 0 ? "none" : "",
              backgroundColor:
                theme.palette[
                  activesFilter.length === 0
                    ? "states-active"
                    : "secondary-white"
                ],
            }}
            variant={activesFilter.length === 0 ? "contained" : "outlined"}
            onClick={handleClickResetFilter}
            tabIndex={isLoading ? -1 : 0}
            aria-label={`Sélectionner l'ensemble des utilisateurs, filtre ${
              activesFilter.length === 0
                ? " est sélectionné"
                : "n'est pas sélectionné"
            }`}
          >
            Tout
          </StyledFilterButton>
        </Grid>
        {filters.map((filter) => {
          const selected = isSelected(filter);
          return (
            <Grid item key={filter.name}>
              <StyledFilterButton
                style={{
                  color:
                    theme.palette[
                      selected ? "secondary-white" : "secondary-ultramarine"
                    ],
                  border: selected ? "none" : "",
                  backgroundColor:
                    theme.palette[
                      selected ? "states-active" : "secondary-white"
                    ],
                }}
                variant={selected ? "contained" : "outlined"}
                startIcon={<filter.icon />}
                onClick={(event) => handleClickToggleFilter(filter, event)}
                tabIndex={isLoading ? -1 : 0}
                aria-label={`Filtre sur le rôle ${filter.label}, filtre ${
                  selected ? "est sélectionné" : "n'est pas sélectionné"
                }`}
              >
                {filter.label}
              </StyledFilterButton>
            </Grid>
          );
        })}
      </StyledFilterButtonContainer>
      <StyledFilterButtonContainer container spacing={2}>
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 1,
            }}
          >
            <Loader sx={{ background: "rgba(255,255,255,0.5)" }} />
          </Box>
        )}
        <CreateUserForm
          openUsersModal={openCreateUser}
          handleCloseUserModal={handleCloseCreateUser}
          handleCancel={handleCancelCreateUser}
          partnerId={partnerId}
          keycloakClientId={process.env.REACT_APP_keycloak_client_id}
          setSnackbar={setSnackbar}
        />
        <EditUserForm
          currentUser={updatingUser}
          openUsersModal={!!updatingUser}
          handleCloseUserModal={handleCloseEditUser}
          handleCancel={handleCancelEditUser}
          editPartnerUser={!!partnerId}
          keycloakClientId={process.env.REACT_APP_keycloak_client_id}
          setSnackbar={setSnackbar}
        />
        {Array.isArray(usersFiltered) &&
          usersFiltered.map((user) => (
            <Grid item key={user.id} xs={12} sm={6} lg={4}>
              <UserCard
                user={user}
                refreshUsers={refreshUsers}
                onClickUpdate={(event) => handleClickUpdateUser(user, event)}
                onClickToggleEnabled={(event) =>
                  handleClickToggleEnable(user, event)
                }
              />
            </Grid>
          ))}
        {canCreate && (
          <Grid item xs={12} sm={6} lg={4}>
            <Grid
              container
              alignItems="center"
              sx={{
                display: "flex",
                justifyContent: "center",
                border: `2px solid ${theme.palette.primary.main}`,
                minHeight: "294px",
                height: "100%",
                color: theme.palette.primary.main,
                borderRadius: "8px",
                padding: theme.spacing(2),
                cursor: "pointer",
              }}
              wrap="nowrap"
              onClick={handleClickCreateUser}
            >
              <Grid item sx={{ p: theme.spacing(1.5), fontSize: "2rem" }}>
                <IconButton
                  sx={{
                    backgroundColor: theme.palette["primaire-bleu"],
                    color: theme.palette["secondary-white"],
                    "&:hover": {
                      backgroundColor: theme.palette["primaire-bleu"],
                      color: theme.palette["secondary-white"],
                    },
                  }}
                  size="large"
                  aria-label="Ajouter un utilisateur"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                sx={{
                  p: theme.spacing(1.5),
                  fontSize: "2rem",
                  fontWeight: 600,
                }}
                id="add-user-label"
              >
                Ajouter un utilisateur
              </Grid>
            </Grid>
          </Grid>
        )}
      </StyledFilterButtonContainer>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </>
  );
};

export default ManageUsers;
