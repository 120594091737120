import PropTypes from "prop-types";

const AttributesShape = PropTypes.shape({
  poste: PropTypes.string,
  phoneNumber: PropTypes.string,
  last_login: PropTypes.string,
  locale: PropTypes.string,
  partnerId: PropTypes.string,
});

export default AttributesShape;
