export default {
  PROOF: {
    title:
      "Puis-je conserver une preuve de l’authentification de l’utilisateur ?",
    content:
      "L’API /token permet de retourner un JWT horodaté et signé par La Poste, il est recommandé de conserver le JWT sans limitation de délais pour prouver l'authentification et la transmission des données de l'utilisateur.",
  },
  AUTHENTICITY: {
    title: "Comment vérifier l'authenticité du jeton JWT ?",
    content:
      "Par défaut le jeton JWT échangé est signé par La Poste en RS256, vous pouvez vérifier la signature du jeton en utilisant le endpoint /certs disponible sur les environnements de test et production.",
  },
  REDIRECTION_URLS: {
    title: "Puis-je déclarer plusieurs URLs de redirection ?",
    content:
      "Oui il est possible de déclarer plusieurs URLs de redirection, cela vous permet par exemple d'utiliser L'Identité Numérique sur vos différents environnements de test (dev, intégration, recette, préproduction....) en utilisant notre environnement de test.",
  },
  ENCRYPTION: {
    title: "Est-ce qu'il est possible de chiffrer le contenu du JWT ?",
    content:
      "Oui il est possible de chiffrer le JWT, si vous avez ce besoin, merci de nous faire la demande pour voir ensemble la meilleure solution à mettre en place.",
  },
  INTERNET_EXPLORER: {
    title:
      "Est-ce que L'Identité Numérique est utilisable sur Internet Explorer ?",
    content:
      "Non le service L’Identité Numérique n’est pas compatible avec le navigateur Internet Explorer, retrouvez la liste des navigateurs recommandés sur cette page",
    link: "https://lidentitenumerique.laposte.fr/old_browser",
  },
};
