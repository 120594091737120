import AttributesFactory from "./AttributesFactory";

export default class UserFactory {
  constructor({
    lastName = "",
    firstName = "",
    email = "",
    enabled = true,
    roles = {},
    attributes,
    createdTimestamp = null,
    isAssociated = false,
    ...additionalAttributes
  } = {}) {
    this.lastName = lastName;
    this.firstName = firstName;
    this.email = email;
    this.enabled = enabled;
    this.createdTimestamp = createdTimestamp;
    this.roles = roles;
    this.isAssociated = isAssociated;
    this.attributes = new AttributesFactory(attributes);
    Object.keys(additionalAttributes).forEach((key) => {
      this[key] = this[key] || additionalAttributes[key];
    });
  }

  static createFromPersonalData = (personalData) => {
    const {
      id,
      lastName,
      firstName,
      email,
      job,
      phoneNumber,
      created,
      lastLogin,
      enabled,
      roles,
      partnerId,
      isAssociated,
    } = personalData;
    const attributes = {};
    if (lastLogin) {
      attributes.last_login = lastLogin;
    }
    if (job) {
      attributes.poste = job;
    }
    if (phoneNumber) {
      attributes.phoneNumber = phoneNumber;
    }
    if (partnerId) {
      attributes.partnerId = partnerId;
    }
    return new UserFactory({
      id,
      lastName,
      firstName,
      email,
      enabled,
      isAssociated,
      createdTimestamp: created,
      roles,
      attributes,
    });
  };
}
