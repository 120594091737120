import React, { useState } from "react";
import PropTypes from "prop-types";
import AclBack from "apps/back/AclBack";
import { Box, Divider } from "@mui/material";
import TechDataForm from "apps/back/pages/partners/TechDataForm";
import LINConfirmationPageDataForm from "apps/back/pages/partners/LINConfirmationPageDataForm";
import { toBase64 } from "utils/FileUtil";
import LINLoginPageDataForm from "apps/back/pages/partners/LINLoginPageDataForm";
import Acl, { withAccess } from "utils/Acl";
import TechDataFactory from "models/factories/TechDataFactory";
import PartnerService from "services/PartnerService";
import ModalHeader from "components/ModalHeader";
import Snackbar from "components/Snackbar";
import { useTheme } from "@mui/material/styles";

const CreateNewUse = (props) => {
  const {
    partnerId,
    onSuccess,
    onCancel,
    onDisplayMailErrorSnackbar,
    onDisplayDataErrorSnackbar,
  } = props;
  const theme = useTheme();
  const [techData, setTechData] = useState(new TechDataFactory());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalStep, setModalStep] = useState(1);
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleChangeStep = (currentTechData, step) => {
    setTechData({ ...techData, ...currentTechData });
    setModalStep(step);
  };

  const manageTechDataCreationError = (err) => {
    let msg = "Une erreur est survenue lors de l'ajout des donnés techniques.";
    if (err.response.status === 404) {
      msg =
        "Impossible de créer des données techniques car aucun responsable contrat n'est rattaché à ce partenaire.";
    } else if (err.response.status === 409) {
      msg = "Un usage avec le même nom existe déjà pour ce partenaire.";
    }

    onDisplayDataErrorSnackbar(msg);
  };

  const handleValidateThirdStep = async (currentTechData) => {
    const modifiedTechData = { partnerId, ...techData, ...currentTechData };
    const { useName, ...data } = modifiedTechData;
    data.logo = data.logo && (await toBase64(data.logo));
    if (partnerId) {
      setIsSubmitting(true);
      PartnerService.createUse({ partnerId, useName })
        .then((use) => {
          PartnerService.createTechData({ useId: use.id, ...data })
            .then((res) => {
              if (res.partnersApiStatus === "error on mail sending") {
                onDisplayMailErrorSnackbar();
              }
              onSuccess();
            })
            .catch((err) => {
              onSuccess();
              manageTechDataCreationError(err);
            });
        })
        .catch(() => {
          setIsSubmitting(false);
          setSnackbar({
            open: true,
            message: "Une erreur est survenue lors de l'ajout d'un usage.",
            variant: "error",
          });
        });
    }
  };

  return (
    <>
      <ModalHeader
        title={
          <span>
            Ajout d&apos;un usage - Environnement de test
            <span
              aria-hidden="true"
              style={{
                marginLeft: theme.spacing(1.5),
                color: theme.palette["primaire-bleu"],
                fontSize: "1rem",
              }}
            >
              {modalStep}/3
            </span>
          </span>
        }
        handleCloseModal={onCancel}
      />
      <Divider />
      <Box>
        {modalStep === 1 && (
          <TechDataForm
            techData={techData}
            onValidate={handleChangeStep}
            onCancel={onCancel}
          />
        )}
        {modalStep === 2 && (
          <LINLoginPageDataForm
            techData={techData}
            onValidate={handleChangeStep}
            onCancel={handleChangeStep}
          />
        )}
        {modalStep === 3 && (
          <LINConfirmationPageDataForm
            techData={techData}
            onValidate={handleValidateThirdStep}
            onCancel={handleChangeStep}
            isSubmitting={isSubmitting}
          />
        )}
      </Box>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </>
  );
};

CreateNewUse.propTypes = {
  partnerId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDisplayMailErrorSnackbar: PropTypes.func.isRequired,
  onDisplayDataErrorSnackbar: PropTypes.func.isRequired,
};

export default withAccess(
  AclBack.PARTNERS_TECHNICAL_INFO,
  Acl.CREATE,
)(CreateNewUse);
