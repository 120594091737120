import React, { useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "Layout";
import NotFound from "NotFound";
import Acl from "utils/Acl";
import { profiles } from "apps/front/AclFront";
import { getMenu, getRoutes } from "apps/front/routesFront";
import { CurrentRouteContextProvider } from "components/CurrentRouteContext";
import TcWrapper from "react-tag-commander";
import Loader from "components/Loader";
import { useKeycloak } from "@react-keycloak/web";
import { Box } from "@mui/material";

const wrapper = TcWrapper.getInstance();

const AppFront = () => {
  Acl.setMapping(profiles);
  const routes = useMemo(getRoutes, []);
  const menu = useMemo(getMenu, []);
  const { keycloak } = useKeycloak();

  const [isTcReady, setIsTcReady] = useState(false);

  useEffect(() => {
    Promise.all([
      wrapper.addContainer(
        "container_head",
        process.env.REACT_APP_tag_commander,
        "head",
      ),
    ]).then(() => {
      setIsTcReady(true);
    });
  }, [isTcReady]);

  window.tc_vars = {
    env_work: process.env.REACT_APP_environment_name,
    level2: 1,
    user_id: keycloak?.authenticated && keycloak?.idTokenParsed.sub,
  };

  return isTcReady ? (
    <CurrentRouteContextProvider routes={routes}>
      <Layout menu={menu}>
        <Switch color="secondary">
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <Route path="*">
            {routes.length > 0 ? (
              <Redirect to={routes[0].path} />
            ) : (
              <NotFound />
            )}
          </Route>
        </Switch>
      </Layout>
    </CurrentRouteContextProvider>
  ) : (
    <Box sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}>
      <Loader
        sx={{
          background: "rgba(255,255,255,0.5)",
        }}
      />
    </Box>
  );
};

export default AppFront;
