import Dashboard from "apps/front/dashboard/Dashboard";
import InfoTech from "apps/front/infoTech/InfoTech";
import ManageUsers from "apps/front/users/ManageUsers";
import Contract from "apps/front/contract/Contract";
import AclFront from "apps/front/AclFront";
import DocAPI from "apps/front/api/DocAPI";
import CGU from "CGU";
import Acl from "utils/Acl";
import MentionsLegales from "MentionsLegales";
import Accessibilite from "Accessibilite";
import { withTracker } from "react-tag-commander";
import AccountAssociatedFront from "AccountAssociatedFront";

const routes = {
  DASHBOARD: {
    title: "Tableau de bord",
    path: "/",
    component: withTracker(Dashboard, {
      tcVars: { chapter1: "tableau_de_bord", page_name: "homepage" },
      event: { label: "pageview" },
    }),
    exact: true,
    hasAccess: () => Acl.hasAccess(AclFront.DASHBOARD),
  },
  INFORMATION_TECH: {
    title: "Informations techniques",
    path: "/informations-techniques",
    component: withTracker(InfoTech, {
      tcVars: { chapter1: "informations_techniques", page_name: "homepage" },
      event: { label: "pageview" },
    }),
    hasAccess: () => Acl.hasAccess(AclFront.INFORMATION_TECH),
  },
  USERS: {
    title: "Gestion des utilisateurs",
    path: "/gestion-utilisateurs",
    component: withTracker(ManageUsers, {
      tcVars: { chapter1: "gestion_utilisateurs", page_name: "homepage" },
      event: { label: "pageview" },
    }),
    hasAccess: () => Acl.hasAccess(AclFront.USERS, Acl.READ),
  },
  CONTRACT: {
    title: "Contrat",
    path: "/contrat",
    component: withTracker(Contract, {
      tcVars: { chapter1: "contrat", page_name: "homepage" },
      event: { label: "pageview" },
    }),
    hasAccess: () => Acl.hasAccess(AclFront.CONTRACT_INFORMATION),
  },
  CGU: {
    title: "CGU",
    path: "/cgu",
    component: withTracker(CGU, {
      tcVars: { chapter1: "footer", page_name: "cgu" },
      event: { label: "pageview" },
    }),
    hasAccess: () => true,
  },
  MentionsLegales: {
    title: "MentionsLegales",
    path: "/mentions-legales",
    component: withTracker(MentionsLegales, {
      tcVars: { chapter1: "footer", page_name: "mentions_legales" },
      event: { label: "pageview" },
    }),
    hasAccess: () => true,
  },
  Accessibilite: {
    title: "Accessibilite",
    path: "/accessibilite",
    component: withTracker(Accessibilite, {
      tcVars: { chapter1: "footer", page_name: "accessibilite" },
      event: { label: "pageview" },
    }),
    hasAccess: () => true,
  },
  AccountAssociatedFront: {
    title: "AccountAssociatedFront",
    path: "/account-associated-partner",
    component: AccountAssociatedFront,
    hasAccess: () => true,
  },
};

routes.DOC_API = {
  title: "Documentation API",
  path: "/api",
  component: withTracker(DocAPI, {
    tcVars: { chapter1: "api", page_name: "homepage" },
    event: { label: "pageview" },
  }),
  hasAccess: () => Acl.hasAccess(AclFront.DOC_API),
  parent: routes.INFORMATION_TECH,
};

export default routes;

export const getRoutes = () =>
  Object.values(routes)
    .filter((route) => route.hasAccess())
    .map((route) => ({ ...route, hasAccess: undefined }));

const menu = [
  routes.DASHBOARD,
  routes.INFORMATION_TECH,
  routes.USERS,
  routes.CONTRACT,
];

export const getMenu = () => menu.filter((route) => route.hasAccess());
