import React from "react";
import PropTypes from "prop-types";

import { Box } from "@mui/material";

const Circle = (props) => {
  const { color, className, ...others } = props;

  return (
    <Box
      {...others}
      className={className}
      sx={{
        width: "2em",
        height: "2em",
        borderRadius: "1em",
        background: color,
      }}
    />
  );
};

Circle.propTypes = {
  color: PropTypes.string.isRequired,
  className: PropTypes.shape(),
};

Circle.defaultProps = {
  className: {},
};

export default Circle;
