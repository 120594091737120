import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      stroke="#00008c"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12 18.75v3M12 9.75v3M12 2.25v1.5M19.5 9.75H4.81c-.098 0-.195-.02-.286-.057-.091-.038-.174-.093-.244-.163L1.5 6.75l2.78-2.78c.07-.07.153-.125.244-.163.09-.038.188-.057.287-.057H19.5c.199 0 .39.079.53.22.141.14.22.331.22.53V9c0 .199-.079.39-.22.53-.14.141-.331.22-.53.22zM4.5 18.75h14.69c.098 0 .195-.02.286-.057.091-.038.174-.093.244-.163l2.78-2.78-2.78-2.78c-.07-.07-.152-.125-.244-.163-.09-.038-.188-.057-.287-.057H4.5c-.199 0-.39.079-.53.22-.141.14-.22.331-.22.53V18c0 .199.079.39.22.53.14.141.331.22.53.22z"
    />
  </SvgIcon>
);
