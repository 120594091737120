import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";

const StyledColumn = styled(Grid)(({ theme }) => ({
  padding: "0 2.5rem",
  [theme.breakpoints.down("sm")]: {
    padding: "0 1rem",
  },
}));

const Form = (props) => {
  const {
    title,
    additionalField,
    content,
    notTwoColumns,
    className,
    ...others
  } = props;

  return (
    <Paper
      className={className}
      sx={{
        maxWidth: "51.375rem",
        margin: "1.5rem 0",
        borderRadius: "8px",
        boxShadow:
          "0 1px 10px 0 rgba(0, 0, 45, 0.06), 0 1px 4px 0 rgba(0, 0, 45, 0.1), 0 0 0 0 rgba(0, 0, 46, 0.06)",
        backgroundColor: (theme) => theme.palette["secondary-white"],
      }}
      {...others}
    >
      <Typography variant="h3" sx={{ padding: "1.5625rem 2.5rem" }}>
        {title}
      </Typography>
      <Divider
        sx={{
          width: "100%",
          height: "1px",
          margin: 0,
          padding: 0,
          borderColor: (theme) => theme.palette["grey-20"],
        }}
      />
      {additionalField && (
        <Box sx={{ padding: "2.5rem 2.5rem 0 2.5rem" }}>{additionalField}</Box>
      )}
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "2.25rem 0 0.5rem 0",
        }}
      >
        {content.map(
          (row) =>
            row && (
              <Fragment key={row.key}>
                {row.firstElement && (
                  <StyledColumn
                    item
                    xs={12}
                    md={!row.secondElement || notTwoColumns ? 12 : 6}
                  >
                    {row.firstElement}
                  </StyledColumn>
                )}
                {row.secondElement && (
                  <StyledColumn item xs={12} md={6}>
                    {row.secondElement}
                  </StyledColumn>
                )}
              </Fragment>
            ),
        )}
      </Grid>
    </Paper>
  );
};

Form.propTypes = {
  title: PropTypes.string.isRequired,
  additionalField: PropTypes.node,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      firstElement: PropTypes.node,
      secondElement: PropTypes.node,
    }),
  ),
  notTwoColumns: PropTypes.bool,
  className: PropTypes.string,
};

Form.defaultProps = {
  additionalField: null,
  content: [],
  notTwoColumns: false,
  className: "",
};

export default Form;
