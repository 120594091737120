import PropTypes from "prop-types";

const UseStatShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  by_date: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  unique_users: PropTypes.number.isRequired,
  uses: PropTypes.number.isRequired,
});

export default UseStatShape;
