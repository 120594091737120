import { createTheme } from "@mui/material/styles";

const colors = {
  "marque-blanche-cobal": "#0432ff",
  "primaire-bleu": "#0000ff",
  "primaire-bleu-print": "#003da5",
  "cobalt-marque-blanche-second": "#5e6872",
  "primaire-jaune": "#ffcb05",
  "dark-theme-light-bg": "#292933",
  "cobalt-marque-blanche-main": "#353846",
  "secondaire-rouge": "#cd3939",
  "dark-theme-black": "#16161c",
  "secondaire-vert": "#107f60",
  "secondaire-orange": "#e1793b",
  "jaune-90": "#ffcf19",
  "jaune-80": "#ffd433",
  "jaune-70": "#ffd94d",
  "jaune-60": "#ffde66",
  "jaune-50": "#ffe480",
  "jaune-40": "#ffe999",
  "jaune-30": "#fff0b3",
  "jaune-20": "#fff5cc",
  "jaune-10": "#fffae6",
  "bleu-90": "#1919ff",
  "bleu-80": "#3333ff",
  "bleu-70": "#4c61ff",
  "bleu-50": "#809bff",
  "bleu-60": "#6682ff",
  "bleu-40": "#99b1ff",
  "bleu-30": "#b3c7ff",
  "bleu-20": "#ccdaff",
  "bleu-10": "#e0eaff",
  "bleu-5": "#f0f4ff",
  "bleu-3": "#fafbff",
  "color-transparent": "rgba(0, 0, 0, 0)",
  "status-warning-05": "#fff8f3",
  "status-success": "#589422",
  "status-error-05": "#fef4f5",
  "secondary-white": "#ffffff",
  "status-warning": "#f18f01",
  "status-info": "#417cff",
  "status-error": "#d00000",
  "status-success-05": "#f4faf6",
  "secondary-ultramarine": "#00008c",
  "secondary-lynch": "#666d92",
  "states-active": "#0000cc",
  "states-hover": "#0101e4",
  "states-focused": "#6884ff",
  "grey-20": "#dadbe0",
  "grey-10": "#ececef",
  "grey-30": "#c6c8d0",
  "grey-subtitle": "#565b72",
  "color-dataviz-turquoise": "#2ddfd5",
  "color-dataviz-fuel-yellow": "#f0aa30",
  "bleu-digital-90": "#1d23f5",
  "bleu-digital-05": "#f2f2ff",
  "secondary-light-background": "#f8f8fb",
  "bleu-digital-10": "#e5e5fd",
  "bleu-background": "#fafaff",
};

const primary = {
  light: colors["bleu-10"],
  main: colors["primaire-bleu"],
  dark: colors["secondary-ultramarine"],
};

const secondary = {
  light: colors["primaire-jaune"],
  main: colors["primaire-jaune"],
  dark: colors["primaire-jaune"],
};

export default createTheme({
  breakpoints: {
    mainViewWidth: 1164,
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary,
    secondary,
    text: {
      primary: colors["secondary-ultramarine"],
      secondary: colors["secondary-ultramarine"],
    },
    ...colors,
  },
  typography: {
    fontSize: 14,
    h1: {
      color: colors["cobalt-marque-blanche-main"],
      fontFamily: "Montserrat",
      fontSize: "2.25rem",
      fontWeight: 600,
      letterSpacing: "0.075rem",
    },
    h2: {
      color: colors["cobalt-marque-blanche-main"],
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: 600,
      letterSpacing: "0.0375rem",
    },
    h3: {
      color: colors["cobalt-marque-blanche-main"],
      fontFamily: "Montserrat",
      fontSize: "1.375rem",
      letterSpacing: "0.03125rem",
      fontWeight: 600,
    },
    h4: {
      color: colors["cobalt-marque-blanche-main"],
      fontFamily: "Montserrat",
      fontSize: "1rem",
      letterSpacing: "0.025rem",
      fontWeight: 600,
    },
    h5: {
      color: colors["cobalt-marque-blanche-main"],
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      letterSpacing: "0.025rem",
      fontWeight: 500,
    },
    h6: {
      color: colors["cobalt-marque-blanche-main"],
      fontFamily: "Barlow",
      fontSize: "0.875rem",
      letterSpacing: "normal",
      fontWeight: 500,
    },
    subtitle1: {
      color: colors["cobalt-marque-blanche-main"],
      fontSize: "1.125rem",
      fontFamily: '"Barlow", sans-serif',
      fontWeight: 500,
      letterSpacing: "normal",
    },
    subtitle2: {
      color: colors["cobalt-marque-blanche-main"],
      fontSize: "0.875rem",
      fontFamily: '"Barlow", sans-serif',
      letterSpacing: "0",
      fontWeight: 500,
      lineHeight: 1,
    },
    body1: {
      fontFamily: "Barlow",
      fontSize: "1rem",
      fontWeight: 500,
      letterSpacing: "normal",
      color: colors["cobalt-marque-blanche-main"],
    },
    body2: {
      fontFamily: "Barlow",
      color: colors["cobalt-marque-blanche-second"],
      letterSpacing: 0,
      fontSize: "0.875rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "24px",
          textTransform: "none",
          fontFamily: "Montserrat",
          fontWeight: 600,
          fontSize: "0.875em",
          padding: "0.85em 2.1em",
          letterSpacing: "0.025em",
          color: colors["cobalt-marque-blanche-second"],
        },
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            padding: "inherit",
          },
        },
        {
          props: { variant: "contained", color: "primary" },
          style: {
            borderColor: colors["primaire-bleu"],
            fontWeight: 600,
            letterSpacing: "0.025em",
            backgroundColor: colors["primaire-bleu"],
            color: colors["secondary-white"],
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            backgroundColor: colors["primaire-jaune"],
            color: colors["primaire-bleu"],
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            padding: "calc(0.85em - 1px) calc(2.1em - 1px)",
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            borderColor: colors["primaire-bleu"],
            color: colors["primaire-bleu"],
          },
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: {
            borderColor: colors["primaire-bleu"],
            backgroundColor: colors["primaire-bleu"],
            color: colors["secondary-white"],
            "&:hover": {
              borderColor: colors["primaire-bleu"],
              backgroundColor: colors["secondary-white"],
              color: colors["primaire-bleu"],
            },
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: "0.25em",

          ...(ownerState.color === "secondary" && {
            backgroundColor: `${colors["primaire-jaune"]}`,
            color: `${colors["secondary-ultramarine"]}`,
            "&:hover": {
              backgroundColor: `${colors["primaire-jaune"]}`,
              color: `${colors["secondary-ultramarine"]}`,
            },
          }),
          fontSize: "1em",
          "& > svg": {
            fontSize: "1em",
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: { height: "64px" },
        root: { height: "48px" },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: colors["grey-20"],
          padding: "0",
        },
        head: {
          padding: "0",
          borderColor: colors["grey-30"],
        },
        body: {
          color: colors["cobalt-marque-blanche-second"],
          wordBreak: "inherit",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fontSize: "16px",
          marginLeft: 2,
          marginRight: 2,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontSize: "1.45em",

          ...(ownerState.color === "primary" && {
            color: colors["secondary-ultramarine"],
          }),
          ...(ownerState.color === "secondary" && {
            color: colors["secondary-lynch"],
          }),
          ...(ownerState.color === "action" && {
            color: colors["primaire-bleu"],
          }),
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === "primary" && {
            color: colors["primaire-bleu"],
          }),
        }),
      },
      colorError: {
        color: colors["status-error"],
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "2.5rem",
          borderRadius: "8px",
          borderColor: colors["grey-20"],
          backgroundColor: colors["secondary-white"],
        },
        input: {
          padding: "0.75em 1em",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginLeft: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: "2rem",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
        },
        root: {
          fontFamily: "Barlow",
          [`&.Mui-error`]: {
            color: colors["status-error"],
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors["secondary-lynch"],
          marginRight: "0.4375rem",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Barlow",
          fontSize: "0.875rem",
          letterSpacing: "normal",
          color: colors["secondary-lynch"],
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: colors["secondary-lynch"],
          ...(ownerState.color === "primary" && {
            "&.Mui-checked": {
              color: colors["status-info"],
            },
          }),
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: colors["cobalt-marque-blanche-main"],
          fontWeight: "bold",
          fontSize: "0.875rem",
        },
      },
    },
    LegendLabel: {
      styleOverrides: {
        root: {
          width: "max-content",
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: colors["secondary-white"],
          borderRadius: "10px",
          padding: "0px",
        },
        message: {
          padding: "0px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&[class*="MuiOutlinedInput-root"]': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        tag: {
          margin: "8px 4px 2px 4px",
          padding: "2px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: colors["status-info"],
          color: colors["secondary-white"],
          borderRadius: "4px",
          fontFamily: "Barlow",
          fontSize: "0.75rem",
          fontWeight: "normal",
          lineHeight: "normal",
          letterSpacing: "normal",
        },
        outlined: {
          backgroundColor: colors["color-transparent"],
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          border: `1px solid ${colors["grey-10"]}`,
          fontFamily: "Barlow",
          fontSize: "0.875rem",
          fontWeight: 500,
          color: colors["secondary-ultramarine"],
          padding: "12px 20px",
          textTransform: "none",
          margin: "0 16px",
          "&.Mui-selected": {
            color: colors["secondary-white"],
            backgroundColor: colors["states-active"],
            border: `1px solid ${colors["grey-10"]}`,
            "&:hover": {
              color: colors["secondary-white"],
              backgroundColor: colors["states-active"],
              border: `1px solid ${colors["grey-10"]}`,
            },
          },
          "&:hover": {
            color: colors["secondary-white"],
            backgroundColor: colors["states-active"],
            border: `1px solid ${colors["grey-10"]}`,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        groupedHorizontal: {
          "&:not(:last-of-type)": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
          },
          "&:not(:first-of-type)": {
            borderLeft: `1px solid ${colors["grey-10"]}`,
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
          },
        },
        groupedVertical: {
          "&:not(:last-of-type)": {
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          },
          "&:not(:first-of-type)": {
            borderLeft: `1px solid ${colors["grey-10"]}`,
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: colors["bleu-3"],
          border: "none",
          boxShadow: "none",
          "&.Mui-expanded": {
            margin: "auto",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: colors["bleu-3"],
          padding: "12px 20px 12px",
          "&.Mui-expanded": {
            padding: "12px 20px 0px",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: "32px",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: colors["bleu-20"],
          height: "3px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#3970e6",
          fontSize: "0.85em",
        },
        arrow: {
          color: "#3970e6",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: "8px",
        },
        elevation2: {
          boxShadow:
            "0 1px 10px 0 rgba(0, 0, 45, 0.06), 0 1px 4px 0 rgba(0, 0, 45, 0.1), 0 0 0 0 rgba(0, 0, 46, 0.06)",
        },
      },
    },
  },
});
