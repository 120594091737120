import React from "react";
import { formatDateToDDMMYYYY } from "utils/date";
import { styled, useTheme } from "@mui/material/styles";
import {
  Grid,
  TextField,
  Typography,
  RadioGroup,
  Box,
  FormControlLabel,
  Radio,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import BillingShape from "models/shapes/BillingShape";
import ContractShape from "models/shapes/ContractShape";
import BillingTypeEnum from "models/enums/BillingTypeEnum";
import BillingSendModeEnum from "models/enums/BillingSendModeEnum";
import BillingIssuingFrequencyEnum from "models/enums/BillingIssuingFrequencyEnum";
import ModeOfPaymentEnum from "models/enums/ModeOfPaymentEnum";
import TermsOfPaymentEnum from "models/enums/TermsOfPaymentEnum";
import IconCalendar from "icons/calendar";
import { isRequired, inputAdditionalMessage } from "utils/enum";
import Form from "components/Form";
import InputField from "components/InputField";
import { visuallyHidden } from "@mui/utils";
import InputWithIcon from "components/InputWithIcon";

const BillingDataForm = (props) => {
  const theme = useTheme();

  const {
    isActive,
    billing,
    namePrefixBilling,
    salesChannel,
    contract,
    namePrefixContract,
    touchedBilling,
    errorsBilling,
    namePrefixRib,
    touchedRib,
    handleClick,
    errorsRib,
    touchedContract,
    errorsContract,
    handleChange,
    handleBlur,
    schema,
    ...others
  } = props;

  const isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;

  const {
    purchaseReference,
    termsOfPayment,
    typeOfBilling,
    orderReference,
    otherReferences,
    modeOfPayment,
    issuingFrequency,
    copiesNumber,
    annualCommitmentVolume,
    sendMode,
    rib,
    bic,
    bankDomiciliation,
    billingSendEmail,
  } = billing;
  const { bankCode, counterCode, accountNumber, key } = rib;
  const { serviceStartDate, effectiveDate, reference } = contract;

  const getBillingSendOptions = () => {
    if (sendMode === BillingSendModeEnum.BY_POST.name) {
      return (
        <InputField
          title="Nombre d'exemplaires"
          inputAdditionalMessage={inputAdditionalMessage("copiesNumber")}
        >
          <TextField
            variant="outlined"
            type="number"
            name={`${namePrefixBilling}copiesNumber`}
            value={copiesNumber === 0 ? 0 : copiesNumber || ""}
            error={
              touchedBilling.copiesNumber && Boolean(errorsBilling.copiesNumber)
            }
            helperText={
              touchedBilling.copiesNumber && errorsBilling.copiesNumber
            }
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
              "aria-labelledby": "nombre_d'exemplaires",
              "aria-describedby": "ht_nombre_exemplaires",
              "aria-invalid": Boolean(
                touchedBilling.copiesNumber && errorsBilling.copiesNumber,
              ),
              "aria-required": isRequired(
                schema,
                `${namePrefixBilling}copiesNumber`,
              ),
            }}
            FormHelperTextProps={{
              id: "ht_nombre_exemplaires",
            }}
          />
        </InputField>
      );
    }
    if (sendMode === BillingSendModeEnum.BY_EMAIL.name) {
      return (
        <InputField
          title="E-mail pour l'envoi de la facture"
          inputAdditionalMessage={inputAdditionalMessage("billingSendEmail")}
        >
          <TextField
            name={`${namePrefixBilling}billingSendEmail`}
            error={
              touchedBilling.billingSendEmail &&
              Boolean(errorsBilling.billingSendEmail)
            }
            helperText={
              touchedBilling.billingSendEmail && errorsBilling.billingSendEmail
            }
            variant="outlined"
            value={billingSendEmail || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
              "aria-labelledby": "e-mail_pour_l'envoi_de_la_facture",
              "aria-describedby": "ht_email_facture",
              "aria-invalid": Boolean(
                touchedBilling.billingSendEmail &&
                  errorsBilling.billingSendEmail,
              ),
              "aria-required": isRequired(
                schema,
                `${namePrefixBilling}billingSendEmail`,
              ),
            }}
            FormHelperTextProps={{
              id: "ht_email_facture",
            }}
          />
        </InputField>
      );
    }
    return <div />;
  };

  const StyledIconCalendar = styled(IconCalendar)({
    width: "1.125rem",
    height: "1.125rem",
  });

  const StyledErrorMessage = styled(Typography)({
    fontSize: "0.75rem",
  });

  return (
    <Form
      content={[
        {
          key: "serviceStartDate/reference",
          firstElement: (
            <InputField title="Référence contrat">
              <TextField
                variant="outlined"
                name={`${namePrefixContract}reference`}
                value={reference}
                error={
                  touchedContract.reference && Boolean(errorsContract.reference)
                }
                helperText={
                  touchedContract.reference && errorsContract.reference
                }
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-labelledby": "référence_contrat",
                  "aria-describedby": "ht_référence_contrat",
                  "aria-invalid": Boolean(
                    touchedContract.reference && errorsContract.reference,
                  ),
                  "aria-required": isRequired(
                    schema,
                    `${namePrefixContract}reference`,
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_référence_contrat",
                }}
              />
            </InputField>
          ),
          secondElement: isActive ? (
            <InputField title="Date de début de service">
              <Typography
                variant="subtitle1"
                variantMapping={{ subtitle1: "div" }}
                sx={{ p: theme.spacing(1), fontSize: "1rem" }}
              >
                {formatDateToDDMMYYYY(new Date(serviceStartDate), "/")}
              </Typography>
            </InputField>
          ) : null,
        },
        {
          key: "effectiveDate/orderReference",
          firstElement: (
            <InputField title="Date de contrat">
              <InputWithIcon
                variant="outlined"
                type="date"
                name={`${namePrefixContract}effectiveDate`}
                value={effectiveDate ? effectiveDate.split("T")[0] : ""}
                error={
                  touchedContract.effectiveDate &&
                  Boolean(errorsContract.effectiveDate)
                }
                helperText={
                  touchedContract.effectiveDate && errorsContract.effectiveDate
                }
                onChange={handleChange}
                onBlur={handleBlur}
                startAdornment={false}
                adornment={isFirefox ? <StyledIconCalendar /> : null}
                inputProps={{
                  "aria-labelledby": "date_de_contrat",
                  "aria-describedby": "ht_date_contrat",
                  "aria-invalid": Boolean(
                    touchedContract.effectiveDate &&
                      errorsContract.effectiveDate,
                  ),
                  "aria-required": isRequired(
                    schema,
                    `${namePrefixContract}effectiveDate`,
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_date_contrat",
                }}
              />
            </InputField>
          ),
          secondElement: (
            <InputField title="Référence de commande (optionnel)">
              <TextField
                variant="outlined"
                name={`${namePrefixBilling}orderReference`}
                value={orderReference}
                error={
                  touchedBilling.orderReference &&
                  Boolean(errorsBilling.orderReference)
                }
                helperText={
                  touchedBilling.orderReference && errorsBilling.orderReference
                }
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-labelledby": "référence_de_commande_(optionnel)",
                  "aria-describedby": "ht_référence_commande",
                  "aria-invalid": Boolean(
                    touchedBilling.orderReference &&
                      errorsBilling.orderReference,
                  ),
                  "aria-required": isRequired(
                    schema,
                    `${namePrefixBilling}orderReference`,
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_référence_commande",
                }}
              />
            </InputField>
          ),
        },
        {
          key: "purchaseReference/otherReferences",
          firstElement: (
            <InputField title="Référence d'achat (optionnel)">
              <TextField
                variant="outlined"
                name={`${namePrefixBilling}purchaseReference`}
                value={purchaseReference}
                error={
                  touchedBilling.purchaseReference &&
                  Boolean(errorsBilling.purchaseReference)
                }
                helperText={
                  touchedBilling.purchaseReference &&
                  errorsBilling.purchaseReference
                }
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-labelledby": "référence_d'achat_(optionnel)",
                  "aria-describedby": "ht_référence_achat",
                  "aria-invalid": Boolean(
                    touchedBilling.purchaseReference &&
                      errorsBilling.purchaseReference,
                  ),
                  "aria-required": isRequired(
                    schema,
                    `${namePrefixBilling}purchaseReference`,
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_référence_achat",
                }}
              />
            </InputField>
          ),
          secondElement: (
            <InputField title="Autres références (optionnel)">
              <TextField
                variant="outlined"
                name={`${namePrefixBilling}otherReferences`}
                value={otherReferences}
                error={
                  touchedBilling.otherReferences &&
                  Boolean(errorsBilling.otherReferences)
                }
                helperText={
                  touchedBilling.otherReferences &&
                  errorsBilling.otherReferences
                }
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-labelledby": "autres_références_(optionnel)",
                  "aria-describedby": "ht_autres_références",
                  "aria-invalid": Boolean(
                    touchedBilling.otherReferences &&
                      errorsBilling.otherReferences,
                  ),
                  "aria-required": isRequired(
                    schema,
                    `${namePrefixBilling}otherReferences`,
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_autres_références",
                }}
              />
            </InputField>
          ),
        },
        {
          key: "issuingFrequency/termsOfPayment",
          firstElement: (
            <InputField title="Périodicité de l'émission de facture">
              {touchedBilling.issuingFrequency &&
                Boolean(errorsBilling.issuingFrequency) && (
                  <StyledErrorMessage color="error" id="ht_périodicité_facture">
                    {errorsBilling.issuingFrequency}
                  </StyledErrorMessage>
                )}
              <RadioGroup
                defaultValue=""
                row
                name={`${namePrefixBilling}issuingFrequency`}
                value={issuingFrequency}
                onChange={handleChange}
                aria-labelledby="périodicité_de_l'émission_de_facture"
                aria-describedby="ht_périodicité_facture"
                aria-invalid={Boolean(
                  touchedBilling.issuingFrequency &&
                    errorsBilling.issuingFrequency,
                )}
                aria-required={isRequired(
                  schema,
                  `${namePrefixBilling}issuingFrequency`,
                )}
              >
                {BillingIssuingFrequencyEnum &&
                  Object.values(BillingIssuingFrequencyEnum).map((value) => (
                    <FormControlLabel
                      key={value.name}
                      value={value.name}
                      control={
                        <Radio
                          tabindex="0"
                          role="radio"
                          aria-checked={issuingFrequency === value.name}
                          onClick={() =>
                            handleClick(
                              `${namePrefixBilling}issuingFrequency`,
                              value.name,
                            )
                          }
                          inputProps={{ tabIndex: -1 }}
                        />
                      }
                      label={value.label}
                    />
                  ))}
              </RadioGroup>
            </InputField>
          ),
          secondElement: (
            <InputField
              title="Condition de paiement"
              required={isRequired(
                schema,
                `${namePrefixBilling}termsOfPayment`,
              )}
            >
              <TextField
                variant="outlined"
                select
                name={`${namePrefixBilling}termsOfPayment`}
                value={termsOfPayment || ""}
                error={
                  touchedBilling.termsOfPayment &&
                  Boolean(errorsBilling.termsOfPayment)
                }
                helperText={
                  touchedBilling.termsOfPayment && errorsBilling.termsOfPayment
                }
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  labelId: "condition_de_paiement",
                  "aria-describedby": "ht_condition_paiement",
                  "aria-invalid": Boolean(
                    touchedBilling.termsOfPayment &&
                      errorsBilling.termsOfPayment,
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_condition_paiement",
                }}
              >
                {TermsOfPaymentEnum &&
                  Object.values(TermsOfPaymentEnum).map((value) => (
                    <MenuItem key={value.name} value={value.name}>
                      {value.label}
                    </MenuItem>
                  ))}
              </TextField>
            </InputField>
          ),
        },
        {
          key: "modeOfPayment",
          firstElement: (
            <InputField
              title="Mode de règlement"
              required={isRequired(schema, `${namePrefixBilling}modeOfPayment`)}
            >
              <TextField
                variant="outlined"
                select
                name={`${namePrefixBilling}modeOfPayment`}
                value={modeOfPayment || ""}
                error={
                  touchedBilling.modeOfPayment &&
                  Boolean(errorsBilling.modeOfPayment)
                }
                helperText={
                  touchedBilling.modeOfPayment && errorsBilling.modeOfPayment
                }
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  labelId: "mode_de_règlement",
                  "aria-describedby": "ht_mode_règlement",
                  "aria-invalid": Boolean(
                    touchedBilling.modeOfPayment && errorsBilling.modeOfPayment,
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_mode_règlement",
                }}
              >
                {ModeOfPaymentEnum &&
                  Object.values(ModeOfPaymentEnum).map((value) => (
                    <MenuItem key={value.name} value={value.name}>
                      {value.label}
                    </MenuItem>
                  ))}
              </TextField>
            </InputField>
          ),
          secondElement: <div />,
        },
        {
          key: "rib",
          firstElement: modeOfPayment === ModeOfPaymentEnum.DEBIT.name && (
            <>
              <Typography
                sx={{
                  color: theme.palette["secondary-ultramarine"],
                  fontSize: "0.875rem",
                }}
              >
                RIB
                <Box sx={visuallyHidden}>
                  {inputAdditionalMessage("rib", isActive)}
                </Box>
              </Typography>
              <Grid container>
                <Grid item xs={12} sm="auto" sx={{ mr: theme.spacing(3) }}>
                  <InputField title="Code Banque" secondary>
                    <TextField
                      sx={{ width: "7.5rem" }}
                      variant="outlined"
                      name={`${namePrefixRib}bankCode`}
                      value={bankCode || ""}
                      error={
                        (touchedRib.bankCode && Boolean(errorsRib.bankCode)) ||
                        (Object.keys(touchedRib).length !== 0 &&
                          typeof errorsRib === "string")
                      }
                      helperText={
                        (touchedRib.bankCode && errorsRib.bankCode) ||
                        (Object.keys(touchedRib).length !== 0 &&
                          typeof errorsRib === "string" &&
                          errorsRib)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 5,
                        "aria-labelledby": "code_banque",
                        "aria-describedby": "ht_code_banque",
                        "aria-invalid": Boolean(
                          touchedRib.bankCode && errorsRib.bankCode,
                        ),
                        "aria-required": isRequired(
                          schema,
                          `${namePrefixRib}bankCode`,
                        ),
                      }}
                      FormHelperTextProps={{
                        id: "ht_code_banque",
                      }}
                    />
                  </InputField>
                </Grid>
                <Grid item xs={12} sm="auto" sx={{ mr: theme.spacing(3) }}>
                  <InputField title="Code Guichet" secondary>
                    <TextField
                      sx={{ width: "7.5rem" }}
                      variant="outlined"
                      name={`${namePrefixRib}counterCode`}
                      value={counterCode || ""}
                      error={
                        (touchedRib.counterCode &&
                          Boolean(errorsRib.counterCode)) ||
                        (Object.keys(touchedRib).length !== 0 &&
                          typeof errorsRib === "string")
                      }
                      helperText={
                        touchedRib.counterCode && errorsRib.counterCode
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 5,
                        "aria-labelledby": "code_guichet",
                        "aria-describedby": "ht_code_guichet",
                        "aria-invalid": Boolean(
                          touchedRib.counterCode && errorsRib.counterCode,
                        ),
                        "aria-required": isRequired(
                          schema,
                          `${namePrefixRib}counterCode`,
                        ),
                      }}
                      FormHelperTextProps={{
                        id: "ht_code_guichet",
                      }}
                    />
                  </InputField>
                </Grid>
                <Grid item xs={12} sm="auto" sx={{ mr: theme.spacing(3) }}>
                  <InputField title="N° de compte" secondary>
                    <TextField
                      variant="outlined"
                      sx={{
                        width: "15rem",
                      }}
                      name={`${namePrefixRib}accountNumber`}
                      value={accountNumber || ""}
                      error={
                        (touchedRib.accountNumber &&
                          Boolean(errorsRib.accountNumber)) ||
                        (Object.keys(touchedRib).length !== 0 &&
                          typeof errorsRib === "string")
                      }
                      helperText={
                        touchedRib.accountNumber && errorsRib.accountNumber
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 11,
                        "aria-labelledby": "n°_de_compte",
                        "aria-describedby": "ht_numéro_compte",
                        "aria-invalid": Boolean(
                          touchedRib.accountNumber && errorsRib.accountNumber,
                        ),
                        "aria-required": isRequired(
                          schema,
                          `${namePrefixRib}accountNumber`,
                        ),
                      }}
                      FormHelperTextProps={{
                        id: "ht_numéro_compte",
                      }}
                    />
                  </InputField>
                </Grid>
                <Grid item xs={12} sm="auto" sx={{ mr: theme.spacing(3) }}>
                  <InputField title="Clé" secondary>
                    <TextField
                      variant="outlined"
                      sx={{ width: "4rem" }}
                      name={`${namePrefixRib}key`}
                      value={key || ""}
                      error={
                        (touchedRib.key && Boolean(errorsRib.key)) ||
                        (Object.keys(touchedRib).length !== 0 &&
                          typeof errorsRib === "string")
                      }
                      helperText={touchedRib.key && errorsRib.key}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 2,
                        "aria-labelledby": "clé",
                        "aria-describedby": "ht_clé",
                        "aria-invalid": Boolean(
                          touchedRib.key && errorsRib.key,
                        ),
                        "aria-required": isRequired(
                          schema,
                          `${namePrefixRib}key`,
                        ),
                      }}
                      FormHelperTextProps={{
                        id: "ht_clé",
                      }}
                    />
                  </InputField>
                </Grid>
              </Grid>
            </>
          ),
        },
        modeOfPayment === ModeOfPaymentEnum.DEBIT.name
          ? {
              key: "bic/bankDomiciliation",
              firstElement: (
                <InputField
                  title="BIC"
                  inputAdditionalMessage={inputAdditionalMessage(
                    "bic",
                    isActive,
                  )}
                >
                  <TextField
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        "aria-labelledby": "bic",
                        "aria-describedby": "ht_bic",
                        "aria-invalid": Boolean(
                          touchedBilling.bic && errorsBilling.bic,
                        ),
                        "aria-required": isRequired(
                          schema,
                          `${namePrefixBilling}bic`,
                        ),
                      },
                    }}
                    name={`${namePrefixBilling}bic`}
                    value={bic || ""}
                    error={touchedBilling.bic && Boolean(errorsBilling.bic)}
                    helperText={touchedBilling.bic && errorsBilling.bic}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    FormHelperTextProps={{
                      id: "ht_bic",
                    }}
                  />
                </InputField>
              ),
              secondElement: (
                <InputField
                  title="Domiciliation bancaire"
                  inputAdditionalMessage={inputAdditionalMessage(
                    "bankDimiciliation",
                    isActive,
                  )}
                >
                  <TextField
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        "aria-labelledby": "domiciliation_bancaire",
                        "aria-describedby": "ht_domiciliation_bancaire",
                        "aria-invalid": Boolean(
                          touchedBilling.bankDomiciliation &&
                            errorsBilling.bankDomiciliation,
                        ),
                        "aria-required": isRequired(
                          schema,
                          `${namePrefixBilling}bankDomiciliation`,
                        ),
                      },
                    }}
                    name={`${namePrefixBilling}bankDomiciliation`}
                    value={bankDomiciliation || ""}
                    error={
                      touchedBilling.bankDomiciliation &&
                      Boolean(errorsBilling.bankDomiciliation)
                    }
                    helperText={
                      touchedBilling.bankDomiciliation &&
                      errorsBilling.bankDomiciliation
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    FormHelperTextProps={{
                      id: "ht_domiciliation_bancaire",
                    }}
                  />
                </InputField>
              ),
            }
          : { key: "bic/bankDomiciliation" },
        {
          key: "sendMode/billingSendEmail",
          firstElement: (
            <InputField title="Mode d'envoi de la facture">
              {touchedBilling.sendMode && Boolean(errorsBilling.sendMode) && (
                <StyledErrorMessage color="error" id="ht_mode_envoi_facture">
                  {errorsBilling.sendMode}
                </StyledErrorMessage>
              )}
              <RadioGroup
                defaultValue=""
                row
                name={`${namePrefixBilling}sendMode`}
                value={sendMode}
                onChange={handleChange}
                aria-labelledby="mode_d'envoi_de_la_facture"
                aria-describedby="ht_mode_envoi_facture"
                aria-invalid={Boolean(
                  touchedBilling.sendMode && errorsBilling.sendMode,
                )}
                aria-required={isRequired(
                  schema,
                  `${namePrefixBilling}sendMode`,
                )}
              >
                {BillingSendModeEnum &&
                  Object.values(BillingSendModeEnum).map((value) => (
                    <FormControlLabel
                      key={value.name}
                      value={value.name}
                      control={
                        <Radio
                          tabindex="0"
                          role="radio"
                          aria-checked={sendMode === value.name}
                          onClick={() =>
                            handleClick(
                              `${namePrefixBilling}sendMode`,
                              value.name,
                            )
                          }
                          inputProps={{ tabIndex: -1 }}
                        />
                      }
                      label={value.label}
                    />
                  ))}
              </RadioGroup>
            </InputField>
          ),
          secondElement: getBillingSendOptions(),
        },
        {
          key: "typeOfBilling/annualCommitmentVolume",
          firstElement: (
            <InputField title="Type de facturation">
              {touchedBilling.typeOfBilling &&
                Boolean(errorsBilling.typeOfBilling) && (
                  <StyledErrorMessage color="error" id="ht_type_facturation">
                    {errorsBilling.typeOfBilling}
                  </StyledErrorMessage>
                )}
              <RadioGroup
                defaultValue=""
                row
                name={`${namePrefixBilling}typeOfBilling`}
                value={typeOfBilling}
                onChange={handleChange}
                aria-labelledby="type_de_facturation"
                aria-describedby="ht_type_facturation"
                aria-invalid={Boolean(
                  touchedBilling.typeOfBilling && errorsBilling.typeOfBilling,
                )}
                aria-required={isRequired(
                  schema,
                  `${namePrefixBilling}typeOfBilling`,
                )}
              >
                {BillingTypeEnum &&
                  Object.values(BillingTypeEnum).map((value) => (
                    <FormControlLabel
                      key={value.name}
                      value={value.name}
                      control={
                        <Radio
                          tabindex="0"
                          role="radio"
                          aria-checked={typeOfBilling === value.name}
                          onClick={() =>
                            handleClick(
                              `${namePrefixBilling}typeOfBilling`,
                              value.name,
                            )
                          }
                          inputProps={{ tabIndex: -1 }}
                        />
                      }
                      label={value.label}
                    />
                  ))}
              </RadioGroup>
            </InputField>
          ),
          secondElement:
            typeOfBilling === BillingTypeEnum.LICENCE.name ? (
              <InputField
                title="Volume d'engagement annuel"
                inputAdditionalMessage={inputAdditionalMessage(
                  "annualCommitmentVolume",
                )}
              >
                <TextField
                  variant="outlined"
                  placeholder="0.0"
                  type="number"
                  name={`${namePrefixBilling}annualCommitmentVolume`}
                  value={
                    annualCommitmentVolume === 0
                      ? 0
                      : annualCommitmentVolume || ""
                  }
                  error={
                    touchedBilling.annualCommitmentVolume &&
                    Boolean(errorsBilling.annualCommitmentVolume)
                  }
                  helperText={
                    touchedBilling.annualCommitmentVolume &&
                    errorsBilling.annualCommitmentVolume
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{
                    style: {
                      textAlign: "right",
                    },
                    "aria-labelledby": "volume_d'engagement_annuel",
                    "aria-describedby": "ht_volume_engagement_annuel",
                    "aria-invalid": Boolean(
                      touchedBilling.annualCommitmentVolume &&
                        errorsBilling.annualCommitmentVolume,
                    ),
                    "aria-required": isRequired(
                      schema,
                      `${namePrefixBilling}annualCommitmentVolume`,
                    ),
                  }}
                  FormHelperTextProps={{
                    id: "ht_volume_engagement_annuel",
                  }}
                />
              </InputField>
            ) : (
              <div />
            ),
        },
      ]}
      {...others}
    />
  );
};

BillingDataForm.propTypes = {
  isActive: PropTypes.bool,
  billing: BillingShape.isRequired,
  namePrefixBilling: PropTypes.string,
  salesChannel: PropTypes.string,
  contract: ContractShape.isRequired,
  namePrefixContract: PropTypes.string,
  namePrefixRib: PropTypes.string,
  touchedRib: PropTypes.shape(),
  errorsRib: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  touchedBilling: PropTypes.shape(),
  errorsBilling: PropTypes.shape(),
  handleClick: PropTypes.func.isRequired,
  touchedContract: PropTypes.shape(),
  errorsContract: PropTypes.shape(),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  schema: PropTypes.shape(),
};

BillingDataForm.defaultProps = {
  namePrefixBilling: "",
  namePrefixContract: "",
  namePrefixRib: "",
  salesChannel: null,
  errorsRib: {},
  touchedRib: {},
  touchedBilling: {},
  errorsBilling: {},
  touchedContract: {},
  errorsContract: {},
  schema: {},
  isActive: false,
};

export default BillingDataForm;
