import React, { useState } from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";

import AclFront from "apps/front/AclFront";
import AclBack from "apps/back/AclBack";
import Snackbar from "components/Snackbar";
import IconDownload from "icons/download";
import StatService from "services/StatService";
import Acl, { withAccess } from "utils/Acl";
import { getFileNameDateFormat, formatDateToYYYYMMDD } from "utils/date";
import { download } from "utils/FileUtil";
import StatusEnum from "models/enums/StatusEnum";
import TcWrapper from "react-tag-commander";

const StatTitleWithExport = (props) => {
  const {
    statTitle,
    statAdditionalParams,
    isDashboard,
    partner,
    periods,
    handleSetIsLoading,
  } = props;

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });
  const wrapper = TcWrapper.getInstance();

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleExportDashboard = (event) => {
    wrapper.triggerEvent("click", event.target, {
      clic_type: "action",
      chapter1: "tableau_de_bord",
      name: "exporter_donnees",
    });
    if (Array.isArray(periods) && periods.length > 0) {
      periods.forEach((period) => {
        if (Object.keys(period).length > 0) {
          handleSetIsLoading(true);
          StatService.getStats(
            {
              params: {
                type: "use",
                lowerDate: formatDateToYYYYMMDD(period.startDate),
                upperDate: formatDateToYYYYMMDD(period.endDate),
                timezone: "Europe/Paris",
                csv: true,
                ...statAdditionalParams,
              },
              responseType: "blob",
            },
            true,
          )
            .then((res) => {
              if (res) {
                const type = res.headers && res.headers["content-type"];
                download({
                  data: res.data,
                  fileName: decodeURI(
                    `${getFileNameDateFormat()}_Extract_LIN${
                      partner ? `_${partner.companyName || ""}` : ""
                    }.csv`,
                  ),
                  type,
                });
                handleSetIsLoading(false);
              }
            })
            .catch(() => {
              handleSetIsLoading(false);
              setSnackbar({
                open: true,
                message:
                  "Une erreur est survenue lors de la génération du fichier csv.",
                variant: "error",
              });
            });
        }
      });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          {statTitle}
        </Grid>
        {(!partner || partner.status === StatusEnum.ACTIVE.name) && (
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              aria-label="Exporter le rapport"
              onClick={(event) => handleExportDashboard(event)}
              sx={{
                marginBottom: isDashboard ? theme.spacing(6) : theme.spacing(2),
                padding: theme.spacing(1),
              }}
            >
              {!isMobile && (
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    marginRight: theme.spacing(1),
                    color: theme.palette["secondary-ultramarine"],
                    fontWeight: 600,
                  }}
                >
                  Exporter le rapport
                </Typography>
              )}
              <IconDownload
                sx={{
                  color: theme.palette["secondary-ultramarine"],
                  fontWeight: 600,
                }}
              />
            </Button>
          </Grid>
        )}
      </Grid>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </>
  );
};

StatTitleWithExport.propTypes = {
  statTitle: PropTypes.node.isRequired,
  statAdditionalParams: PropTypes.shape(),
  partner: PropTypes.shape(),
  isDashboard: PropTypes.bool,
  periods: PropTypes.arrayOf(PropTypes.shape()),
  handleSetIsLoading: PropTypes.func.isRequired,
};

StatTitleWithExport.defaultProps = {
  statAdditionalParams: {},
  partner: null,
  isDashboard: false,
  periods: [],
};

export const DashboardStatTitleWithExport = withAccess(AclFront.DASHBOARD)(
  StatTitleWithExport,
);
export const PartnerStatTitleWithExport = withAccess(
  AclBack.PARTNERS_CONSO,
  Acl.READ,
)(StatTitleWithExport);
export const BackDashboardStatTitleWithExport = withAccess(
  AclBack.DASHBOARD_INDIRECT_SALE,
  Acl.READ,
)(StatTitleWithExport);
