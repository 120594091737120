import PropTypes from "prop-types";

const PieChartDataShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    argument: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
);

export default PieChartDataShape;
