import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  LinearProgress,
  Divider,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import AddUrlIcon from "icons/add";
import CloseIcon from "icons/close";
import { isRequired } from "utils/enum";
import ProtocolePartnerEnum from "models/enums/ProtocolePartnerEnum";
import ScopesEnum from "models/enums/ScopesEnum";
import SigningAlgorithmEnum from "models/enums/SigningAlgorithmEnum";
import UserInfoAlgorithmEnum from "models/enums/UserInfoAlgorithmEnum";
import TechDatasStatusEnum from "models/enums/TechDatasStatusEnum";
import TechDataSchema from "models/schemas/TechDataSchema";
import TechDataShape from "models/shapes/TechDataShape";
import { isValidURL } from "utils/UrlUtil";
import {
  handleEnterKeyPress,
  handleSpaceKeyPress,
} from "utils/enableEnterNavigation";

const StyledFieldLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette["secondary-ultramarine"],
  marginBottom: theme.spacing(1),
}));

const TechDataForm = (props) => {
  const { onValidate, onCancel, techData, disableUseField } = props;
  const theme = useTheme();

  const [newUrl, setNewUrl] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [urlErrorMessage, setUrlErrorMessage] = useState("");
  const [scopesAnchorElement, setProfileAnchorElement] = useState(null);

  const handleValidate = useCallback(
    (values) => {
      const { created, updated, ...cleanData } = values;
      onValidate(
        {
          ...cleanData,
        },
        2,
      );
    },
    [onValidate],
  );

  const handleClickScopes = useCallback(
    (event) => {
      if (!scopesAnchorElement) {
        setProfileAnchorElement(event.target);
      } else {
        setProfileAnchorElement(null);
      }
    },
    [scopesAnchorElement],
  );

  const handleCloseScopes = useCallback(() => {
    setProfileAnchorElement(null);
  }, []);

  const formik = useFormik({
    initialValues: techData,
    validationSchema: TechDataSchema,
    onSubmit: handleValidate,
  });

  const handleScopes = (value) => {
    const { values } = formik;
    let { scopes } = values || {};
    const aliasArray = ["BIRTH", "PROFILE", "DIGITAL_IDENTITY_METADATA"];
    scopes = scopes || [];

    if (Array.isArray(scopes)) {
      let currentScopes = scopes;
      if (scopes.includes(value)) {
        currentScopes = currentScopes.filter((scope) => scope !== value);
        aliasArray.forEach((alias) => {
          if (
            ScopesEnum[alias].scopes
              ?.map((scope) => scope.name)
              .includes(value) &&
            currentScopes.includes(ScopesEnum[alias].name)
          ) {
            currentScopes = currentScopes.filter(
              (scope) => scope !== ScopesEnum[alias].name,
            );
          }
        });
      } else {
        currentScopes = [...currentScopes, value];
        aliasArray.forEach((alias) => {
          if (
            ScopesEnum[alias].scopes
              ?.map((scope) => scope.name)
              .includes(value) &&
            ScopesEnum[alias].scopes?.every((scope) =>
              currentScopes.includes(scope.name),
            )
          ) {
            currentScopes = [...currentScopes, ScopesEnum[alias].name];
          }
        });
      }
      const aliasNamesArray = aliasArray.map((alias) => ScopesEnum[alias].name);
      if (aliasNamesArray.includes(value)) {
        const selectedAlias = aliasArray.filter(
          (alias) => ScopesEnum[alias].name === value,
        );
        const scopesNames = ScopesEnum[selectedAlias].scopes?.map(
          (scope) => scope.name,
        );
        if (scopes.includes(ScopesEnum[selectedAlias].name)) {
          scopesNames?.forEach((name) => {
            currentScopes = currentScopes.filter((scope) => scope !== name);
          });
        } else {
          scopesNames?.forEach((name) => {
            if (!scopes.includes(name)) {
              currentScopes = [...currentScopes, name];
            }
          });
        }
      }
      formik.setFieldValue("scopes", currentScopes);
    } else {
      formik.setFieldValue("scopes", [...scopes, value]);
    }
  };

  const handleChangeUrl = (e) => {
    const { value } = e.target;
    setNewUrl(value);
    if (isSubmitted) {
      if (!isValidURL(value)) {
        setUrlErrorMessage("Veuillez renseigner une URL valide");
      } else {
        setIsSubmitted(false);
      }
    }
  };

  const addUrl = (value) => {
    const { values } = formik;
    let { urls } = values || {};

    urls = urls || [];
    if (value && urls.includes(value)) {
      setIsSubmitted(true);
      setUrlErrorMessage("Cette URL est déjà autorisée");
    } else if (!value || !isValidURL(value)) {
      setIsSubmitted(true);
      setUrlErrorMessage("Veuillez renseigner une URL valide");
    } else {
      formik.setFieldValue("urls", [...urls, value]);
      setNewUrl("");
    }
  };

  const handleDisplayUrl = (url) => {
    const maxLength = 40;
    let shortUrl = url;
    if (url.length > maxLength) {
      shortUrl = `${url.substring(0, maxLength)}...`;
    }
    return (
      <Typography
        title={url}
        sx={{
          fontSize: "0.75rem",
          color: theme.palette["secondary-ultramarine"],
        }}
      >
        {shortUrl}
      </Typography>
    );
  };

  const deleteUrl = (value) => {
    const { values } = formik;
    const { urls } = values || {};

    formik.setFieldValue(
      "urls",
      urls.filter((url) => url !== value),
    );
  };

  const { touched = {}, errors = {}, values = {} } = formik;

  const scopeMenuItem = (scope, firstLevel = true) => (
    <MenuItem
      tabindex={0}
      role="checkbox"
      aria-checked={
        Array.isArray(values.scopes) && values.scopes.includes(scope.name)
      }
      key={scope.name}
      value={scope.name}
      sx={firstLevel ? null : { paddingLeft: "3rem" }}
      onClick={() => handleScopes(scope.name)}
    >
      <Checkbox
        checked={
          Array.isArray(values.scopes) && values.scopes.includes(scope.name)
        }
        inputProps={{
          "aria-labelledby": scope.name,
        }}
        onChange={() => handleScopes(scope.name)}
      />
      <Typography id={scope.name} variant="subtitle2" component="div">
        {scope.label}
        <span style={visuallyHidden}>
          {firstLevel
            ? ", scope du premier niveau"
            : ", scope du deuxième niveau"}
        </span>
      </Typography>
    </MenuItem>
  );

  useEffect(() => {
    if (Object.values(touched).length === 0) {
      formik
        .validateForm()
        .then((data) => setIsValid(Object.values(data).length === 0));
    } else {
      setIsValid(formik.isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid]);

  return (
    <>
      <LinearProgress
        variant="determinate"
        value={100 / 3}
        aria-label="Partie 1"
      />
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ p: theme.spacing(5, 4, 1) }}>
          <Typography
            sx={{
              mb: theme.spacing(3),
            }}
            variant="h4"
          >
            Informations générales
          </Typography>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: theme.spacing(5),
            }}
            rowSpacing={3}
            columnSpacing={6}
          >
            <Grid item xs={12} md={6}>
              <StyledFieldLabel
                variant="subtitle2"
                component="h5"
                id="use_name"
              >
                Nom de l&apos;usage
              </StyledFieldLabel>
              <TextField
                onChange={formik.handleChange}
                variant="outlined"
                name="useName"
                error={touched.useName && Boolean(errors.useName)}
                helperText={touched.useName && errors.useName}
                value={values.useName}
                onBlur={formik.handleBlur}
                disabled={disableUseField}
                inputProps={{
                  "aria-required": isRequired(TechDataSchema, "useName"),
                  "aria-labelledby": "use_name",
                  "aria-describedby": "ht_use_name",
                  "aria-invalid": Boolean(touched.useName && errors.useName),
                }}
                FormHelperTextProps={{
                  id: "ht_use_name",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledFieldLabel variant="subtitle2" component="h5" id="urls">
                URLs autorisées
              </StyledFieldLabel>
              <TextField
                onChange={handleChangeUrl}
                variant="outlined"
                name="urls"
                error={(touched.urls && Boolean(errors.urls)) || isSubmitted}
                helperText={
                  isSubmitted ? urlErrorMessage : touched.urls && errors.urls
                }
                value={newUrl}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          p: 0,
                          "&:focus > svg": {
                            backgroundColor:
                              theme.palette["cobalt-marque-blanche-second"],
                          },
                        }}
                        aria-label="Ajouter l'URL"
                        onClick={() => addUrl(newUrl)}
                      >
                        <AddUrlIcon
                          sx={{
                            cursor: "pointer",
                            background: theme.palette.primary.main,
                            color: "#fff",
                            borderRadius: theme.spacing(0, 0.75, 0.75, 0),
                            height: 40,
                            width: 40,
                            padding: theme.spacing(0.75),
                            marginRight: theme.spacing(-1.75),
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: {
                    "aria-labelledby": "urls",
                    "aria-describedby": "ht_urls",
                    "aria-invalid":
                      (touched.urls && Boolean(errors.urls)) ||
                      isSubmitted ||
                      false,
                    "aria-required": isRequired(TechDataSchema, "urls"),
                  },
                }}
                FormHelperTextProps={{
                  id: "ht_urls",
                }}
              />
              <Grid
                container
                sx={{
                  "& > div": {
                    borderRadius: theme.spacing(2),
                    borderColor: theme.palette["secondary-ultramarine"],
                    margin: theme.spacing(0.5, 0.25),
                    padding: theme.spacing(0.25),
                    "& > * ": {
                      color: theme.palette["secondary-ultramarine"],
                    },
                  },
                }}
              >
                {Array.isArray(values.urls) &&
                  values.urls.map((url) => (
                    <Chip
                      key={url}
                      variant="outlined"
                      size="small"
                      label={handleDisplayUrl(url)}
                      aria-label={url}
                      color="primary"
                      deleteIcon={
                        <IconButton aria-label="Supprimer l'URL">
                          <CloseIcon
                            sx={{
                              color: `${theme.palette.primary.dark}!important`,
                              [`&:hover`]: {
                                color: `${theme.palette.primary.main}!important`,
                              },
                            }}
                          />
                        </IconButton>
                      }
                      onDelete={() => deleteUrl(url)}
                    />
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledFieldLabel
                variant="subtitle2"
                component="h5"
                id="scope_donnees"
              >
                Scopes de données
                <span style={visuallyHidden}>
                  Champ à choix multiple avec deux niveaux de scopes. Le scope
                  du premier niveau est coché uniquement si tous ses scopes du
                  deuxième niveau sont cochés.{" "}
                </span>
              </StyledFieldLabel>
              <TextField
                onClick={handleClickScopes}
                variant="outlined"
                name="scopes"
                error={touched.scopes && Boolean(errors.scopes)}
                helperText={touched.scopes && errors.scopes}
                value={values.scopes}
                onBlur={formik.handleBlur}
                onKeyUp={(e) => {
                  handleSpaceKeyPress(e, handleClickScopes, e);
                  handleEnterKeyPress(e, handleClickScopes, e);
                }}
                inputProps={{
                  "aria-labelledby": "scope_donnees",
                  "aria-describedby": "ht_scope_donnees",
                  "aria-invalid": Boolean(touched.scopes && errors.scopes),
                  "aria-required": isRequired(TechDataSchema, "scopes"),
                }}
                FormHelperTextProps={{
                  id: "ht_scope_donnees",
                }}
              />
              <Popover
                open={!!scopesAnchorElement}
                anchorEl={scopesAnchorElement}
                onClose={handleCloseScopes}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  sx: {
                    boxShadow: "none",
                    border: `1px solid ${theme.palette.divider}`,
                    width: 412,
                    maxHeight: 320,
                  },
                }}
                onKeyUp={(e) => handleEnterKeyPress(e, handleCloseScopes)}
              >
                {ScopesEnum &&
                  Object.values(ScopesEnum).map((value) =>
                    value.scopes ? (
                      <div key={value.name}>
                        {scopeMenuItem(value, true)}
                        {value.scopes.map((scope) => {
                          return scopeMenuItem(scope, false);
                        })}
                      </div>
                    ) : (
                      scopeMenuItem(value)
                    ),
                  )}
              </Popover>
            </Grid>
          </Grid>
          <Typography sx={{ mb: theme.spacing(3) }} variant="h4">
            Paramètres
          </Typography>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: theme.spacing(5),
            }}
            rowSpacing={3}
            columnSpacing={6}
          >
            <Grid item xs={12} md={6}>
              <StyledFieldLabel
                variant="subtitle2"
                component="h5"
                id="algorithme_userinfo"
              >
                Algorithme de signature UserInfo
              </StyledFieldLabel>
              <TextField
                onChange={formik.handleChange}
                variant="outlined"
                select
                name="userInfoAlgorithm"
                error={
                  touched.userInfoAlgorithm && Boolean(errors.userInfoAlgorithm)
                }
                helperText={
                  touched.userInfoAlgorithm && errors.userInfoAlgorithm
                }
                value={values.userInfoAlgorithm || UserInfoAlgorithmEnum.NONE}
                onBlur={formik.handleBlur}
                SelectProps={{
                  labelId: "algorithme_userinfo",
                  "aria-describedby": "ht_algorithme_userinfo",
                  "aria-invalid": Boolean(
                    touched.userInfoAlgorithm && errors.userInfoAlgorithm,
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_algorithme_userinfo",
                }}
              >
                {UserInfoAlgorithmEnum &&
                  Object.values(UserInfoAlgorithmEnum).map((value) => (
                    <MenuItem key={value.name} value={value.name}>
                      {value.label}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledFieldLabel
                variant="subtitle2"
                component="h5"
                id="algorithme_token"
              >
                Algorithme de signature ID Token
              </StyledFieldLabel>
              <TextField
                onChange={formik.handleChange}
                variant="outlined"
                select
                name="signingAlgorithm"
                error={
                  touched.signingAlgorithm && Boolean(errors.signingAlgorithm)
                }
                helperText={touched.signingAlgorithm && errors.signingAlgorithm}
                value={values.signingAlgorithm || SigningAlgorithmEnum.NONE}
                onBlur={formik.handleBlur}
                SelectProps={{
                  labelId: "algorithme_token",
                  "aria-describedby": "ht_algorithme_token",
                  "aria-invalid": Boolean(
                    touched.signingAlgorithm && errors.signingAlgorithm,
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_algorithme_token",
                }}
              >
                {SigningAlgorithmEnum &&
                  Object.values(SigningAlgorithmEnum).map((value) => (
                    <MenuItem key={value.name} value={value.name}>
                      {value.label}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledFieldLabel variant="subtitle2" component="h5" id="statut">
                Statut du compte
              </StyledFieldLabel>
              <RadioGroup
                defaultValue=""
                row
                name="status"
                value={values.status}
                onChange={formik.handleChange}
                aria-labelledby="statut"
              >
                {TechDatasStatusEnum &&
                  Object.values(TechDatasStatusEnum).map((value) => (
                    <FormControlLabel
                      key={value.name}
                      value={value.name}
                      control={
                        <Radio
                          tabindex="0"
                          role="radio"
                          aria-checked={values.status === value.name}
                          onClick={() =>
                            formik.setFieldValue("status", value.name)
                          }
                          inputProps={{ tabIndex: -1 }}
                        />
                      }
                      label={value.label}
                    />
                  ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledFieldLabel
                variant="subtitle2"
                component="h5"
                id="protocole"
              >
                Protocole
              </StyledFieldLabel>
              <RadioGroup
                defaultValue=""
                row
                name="flow"
                value={values.flow}
                onChange={formik.handleChange}
                aria-labelledby="protocole"
              >
                {ProtocolePartnerEnum &&
                  Object.values(ProtocolePartnerEnum).map((value) => (
                    <FormControlLabel
                      key={value.name}
                      value={value.name}
                      control={
                        <Radio
                          tabindex="0"
                          role="radio"
                          aria-checked={value.flow === value.name}
                          onClick={() =>
                            formik.setFieldValue("flow", value.name)
                          }
                          inputProps={{ tabIndex: -1 }}
                        />
                      }
                      label={value.label}
                    />
                  ))}
              </RadioGroup>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            my: theme.spacing(3),
            mr: theme.spacing(4),
          }}
        >
          <Button
            sx={{ mr: theme.spacing(3), color: theme.palette["primaire-bleu"] }}
            onClick={onCancel}
          >
            Annuler
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            Suivant
          </Button>
        </Box>
      </form>
    </>
  );
};

TechDataForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  techData: TechDataShape.isRequired,
  disableUseField: PropTypes.bool,
};

TechDataForm.defaultProps = {
  disableUseField: false,
};

export default TechDataForm;
