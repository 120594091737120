import React, { useEffect } from "react";
import AccountAssociated from "AccountAssociated";
import historyApp from "historyApp";

const AccountAssociatedBack = () => {
  useEffect(() => {
    historyApp.push("/account-associated-administrator-not-connected");
  }, []);

  return <AccountAssociated front={false} />;
};

export default AccountAssociatedBack;
