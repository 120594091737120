import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import AclBack from "apps/back/AclBack";
import Acl from "utils/Acl";
import AclFront from "apps/front/AclFront";
import IconSign from "icons/icon_doca_sign";
import FormReadOnly from "components/FormReadOnly";
import Link from "components/Link";
import PartnerFactory from "models/factories/PartnerFactory";
import PartnerShape from "models/shapes/PartnerShape";
import BillingIssuingFrequencyEnum from "models/enums/BillingIssuingFrequencyEnum";
import BillingSendModeEnum from "models/enums/BillingSendModeEnum";
import ModeOfPaymentEnum from "models/enums/ModeOfPaymentEnum";
import TermsOfPaymentEnum from "models/enums/TermsOfPaymentEnum";
import SalesChannelEnum from "models/enums/SalesChannelEnum";
import PartnerTypologyEnum from "models/enums/PartnerTypologyEnum";
import BillingTypeEnum from "models/enums/BillingTypeEnum";
import BusinessLineEnum from "models/enums/BusinessLineEnum";
import { formatDateToDDMMYYYY } from "utils/date";
import { getLabelRepresentation } from "utils/enum";
import EditIcon from "icons/write";

const ContractInformation = (props) => {
  const { partner, backUser } = props;

  const { billing } = partner;
  const { typeOfBilling, annualCommitmentVolume } = billing;

  const showForm = (data) => {
    return data.some((field) => field && !!field.value);
  };

  const generalData = [
    {
      category: "Secteur d'activité",
      value: getLabelRepresentation(BusinessLineEnum, partner.businessLine),
    },
    {
      category: "Typologie de client",
      value:
        partner.typology &&
        getLabelRepresentation(PartnerTypologyEnum, partner.typology),
    },
    {
      category: "Canal de vente",
      value:
        partner.salesChannel &&
        getLabelRepresentation(SalesChannelEnum, partner.salesChannel),
    },
    {
      category: "Type d'entreprise",
      value: partner.enterpriseType,
    },
    partner.salesChannel &&
    partner.salesChannel === SalesChannelEnum.INDIRECT.name
      ? {
          category: "Code émetteur",
          value: partner.issuerCode,
        }
      : null,
  ];

  const billingData = [
    {
      category: "Date de début de service",
      value: partner.contract.serviceStartDate
        ? formatDateToDDMMYYYY(new Date(partner.contract.serviceStartDate), "/")
        : null,
    },
    {
      category: "Date de contrat",
      value: partner.contract.effectiveDate
        ? formatDateToDDMMYYYY(new Date(partner.contract.effectiveDate), "/")
        : null,
    },
    {
      category: "Référence d'achat",
      value: partner.billing.purchaseReference,
    },
    {
      category: "Périodicité d'émission de facture",
      value:
        partner.billing.issuingFrequency &&
        getLabelRepresentation(
          BillingIssuingFrequencyEnum,
          partner.billing.issuingFrequency,
        ),
    },
    {
      category: "Mode d'envoi de la facture",
      value:
        partner.billing.sendMode &&
        getLabelRepresentation(BillingSendModeEnum, partner.billing.sendMode),
    },
    partner.billing.sendMode &&
    partner.billing.sendMode === BillingSendModeEnum.BY_EMAIL.name
      ? {
          category: "E-mail pour l'envoi de la facture",
          value: partner.billing.billingSendEmail,
        }
      : null,
    {
      category: "Type de facturation",
      value:
        partner.billing.typeOfBilling &&
        getLabelRepresentation(BillingTypeEnum, partner.billing.typeOfBilling),
    },
    {
      category: "Référence contrat",
      value: partner.contract.reference,
    },
    {
      category: "Référence de commande",
      value: partner.billing.orderReference,
    },
    {
      category: "Autres références",
      value: partner.billing.otherReferences,
    },
    partner.billing.sendMode &&
    partner.billing.sendMode === BillingSendModeEnum.BY_POST.name
      ? {
          category: "Nombre d’exemplaires",
          value:
            partner.billing.copiesNumber === 0
              ? "0"
              : partner.billing.copiesNumber,
        }
      : null,
    {
      category: "Condition de paiement",
      value:
        partner.billing.termsOfPayment &&
        getLabelRepresentation(
          TermsOfPaymentEnum,
          partner.billing.termsOfPayment,
        ),
    },
    {
      category: "Mode de règlement",
      value:
        partner.billing.modeOfPayment &&
        getLabelRepresentation(
          ModeOfPaymentEnum,
          partner.billing.modeOfPayment,
        ),
    },
    partner.billing.modeOfPayment &&
    partner.billing.modeOfPayment === ModeOfPaymentEnum.DEBIT.name
      ? {
          category: "RIB",
          value: Object.values(partner.billing.rib).some((field) => !!field)
            ? `${partner.billing.rib.bankCode} ${partner.billing.rib.counterCode} ${partner.billing.rib.accountNumber} ${partner.billing.rib.key}`
            : null,
        }
      : null,
    partner.billing.modeOfPayment &&
    partner.billing.modeOfPayment === ModeOfPaymentEnum.DEBIT.name
      ? {
          category: "BIC",
          value: partner.billing.bic,
        }
      : null,
    partner.billing.modeOfPayment &&
    partner.billing.modeOfPayment === ModeOfPaymentEnum.DEBIT.name
      ? {
          category: "Domiciliation bancaire",
          value: partner.billing.bankDomiciliation,
        }
      : null,
    typeOfBilling === BillingTypeEnum.LICENCE.name
      ? {
          category: "Volume d'engagement annuel",
          value: Number(annualCommitmentVolume),
        }
      : null,
  ];

  return (
    <Box sx={{ marginTop: (theme) => theme.spacing(7) }}>
      <Typography
        variant="h2"
        component={backUser ? "h2" : "h1"}
        sx={(theme) => ({
          color: theme.palette["secondary-ultramarine"],
          margin: theme.spacing(0, 3.5, 2, 3.5),
          [theme.breakpoints.down("md")]: {
            marginLeft: 0,
          },
        })}
        id="informations-du-contrat"
      >
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item>
            <IconButton
              color="secondary"
              tabIndex={-1}
              sx={{
                marginRight: (theme) => theme.spacing(2),
                pointerEvents: "none",
              }}
              size="large"
              aria-hidden="true"
            >
              <IconSign />
            </IconButton>
            Informations du contrat
          </Grid>
          {Acl.hasAccess(AclBack.PARTNERS_DETAILS, Acl.UPDATE) && (
            <Grid item>
              <Link
                to={`/edition-partenaire/${partner.id}`}
                color="inherit"
                aria-label="Modifier les informations du contrat"
                aria-hidden="false"
              >
                <EditIcon />
              </Link>
            </Grid>
          )}
        </Grid>
      </Typography>
      <Divider />
      <FormReadOnly
        id="coordonnees-de-l-entreprise"
        key="coordonnees-de-l-entreprise"
        title="Coordonnées de l'entreprise"
        firstLevel={backUser ? 3 : 2}
        data={[
          {
            data: [
              {
                category: "Raison sociale",
                value: partner.companyName,
              },
              {
                category: "Application de la TVA",
                value: partner.billing.vatApplication ? "Oui" : "Non",
              },
              {
                category: "N° SIRET",
                value: partner.siretNumber,
              },
              {
                category: "N° TVA intracommunautaire",
                value: partner.billing.intracommunityVatNumber,
              },
            ],
          },
        ]}
      />
      <FormReadOnly
        id="contact-de-l-entreprise"
        key="contact-de-l-entreprise"
        title="Contact de l'entreprise"
        firstLevel={backUser ? 3 : 2}
        data={[
          {
            data: [
              {
                category: "Nom",
                value: partner.contact && partner.contact.lastName,
              },
              {
                category: "Adresse e-mail",
                value: partner.contact && partner.contact.email,
              },
              {
                category: "Fonction",
                value: partner.contact && partner.contact.roleInCompany,
              },
              {
                category: "Prénom",
                value: partner.contact && partner.contact.firstName,
              },
              {
                category: "Téléphone",
                value: partner.contact && partner.contact.phoneNumber,
              },
            ],
          },
        ]}
      />
      {showForm(generalData) && (
        <FormReadOnly
          id="donnees-generales"
          key="donnees-generales"
          title="Données générales"
          firstLevel={backUser ? 3 : 2}
          data={[{ data: generalData }]}
        />
      )}
      {Object.values(partner.address).some((field) => !!field) && (
        <FormReadOnly
          id="adresse-de-l-entreprise"
          key="adresse-de-l-entreprise"
          title="Adresse de l'entreprise"
          firstLevel={backUser ? 3 : 2}
          data={[
            {
              data: [
                {
                  category: "Service",
                  value: partner.address.department,
                },
                {
                  category: "Adresse",
                  value: [
                    `${partner.address.streetNb} ${partner.address.street}`,
                    `${partner.address.zipCode || ""} ${partner.address.city}`,
                    partner.address.country,
                  ],
                },
              ],
            },
          ]}
        />
      )}
      {Object.values(partner.billing.address).some((field) => !!field) && (
        <FormReadOnly
          id="adresse-de-facturation"
          key="adresse-de-facturation"
          title="Adresse de facturation"
          firstLevel={backUser ? 3 : 2}
          data={[
            {
              data: [
                {
                  category: "Service",
                  value:
                    partner.billing.address &&
                    partner.billing.address.department,
                },
                {
                  category: "Adresse",
                  value: [
                    `${partner.billing.address.streetNb || ""} ${
                      partner.billing.address.street || ""
                    }`,
                    `${partner.billing.address.zipCode || ""} ${
                      partner.billing.address.city || ""
                    }`,
                    partner.billing.address.country,
                  ],
                },
              ],
            },
          ]}
        />
      )}
      {(backUser || Acl.hasAccess(AclFront.BILLING_INFORMATION, Acl.ALL)) &&
        showForm(billingData) && (
          <FormReadOnly
            id="donnees-de-facturation"
            key="donnees-de-facturation"
            title="Données de facturation"
            firstLevel={backUser ? 3 : 2}
            data={[{ data: billingData }]}
          />
        )}
    </Box>
  );
};

ContractInformation.propTypes = {
  partner: PartnerShape,
  backUser: PropTypes.bool,
};

ContractInformation.defaultProps = {
  partner: new PartnerFactory(),
  backUser: false,
};

export default ContractInformation;
