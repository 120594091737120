export default class ContractFactory {
  constructor({
    reference = "",
    effectiveDate = null,
    ...additionalAttributes
  } = {}) {
    this.reference = reference;
    this.effectiveDate = effectiveDate;
    Object.keys(additionalAttributes).forEach((key) => {
      this[key] = this[key] || additionalAttributes[key];
    });
  }
}
