import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M12 18.547c3.616 0 6.547-2.931 6.547-6.547 0-3.616-2.931-6.547-6.547-6.547-3.616 0-6.547 2.931-6.547 6.547 0 3.616 2.931 6.547 6.547 6.547zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8c0 2.122-.843 4.157-2.343 5.657S14.122 20 12 20z"
      clipRule="evenodd"
    />
    <path d="M12.667 12.634c0 .327-.266.593-.593.593h-.148c-.327 0-.593-.266-.593-.593V8.486c0-.327.266-.593.593-.593h.148c.327 0 .593.266.593.593v4.148z" />
    <path
      fillRule="evenodd"
      d="M12 16.107c.479 0 .867-.388.867-.867s-.388-.867-.867-.867-.867.388-.867.867.388.867.867.867z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
