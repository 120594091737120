import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  Button,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect } from "react";
import TcWrapper from "react-tag-commander";

import CreateNewUse from "apps/back/pages/partners/CreateNewUse";
import CreateTestTechData from "apps/back/pages/partners/CreateTestTechData";
import EditTechData from "apps/back/pages/partners/EditTechData";
import CreateProdTechData from "apps/back/pages/partners/CreateProdTechData";
import TechDataStatusForm from "apps/back/pages/partners/TechDataStatusForm";
import TechnicalDataKeyValues from "apps/back/pages/partners/TechnicalDataKeyValues";
import AclBack from "apps/back/AclBack";
import AclFront from "apps/front/AclFront";
import ActionLabelEnum from "models/enums/ActionLabelEnum";
import ProtocolePartnerEnum from "models/enums/ProtocolePartnerEnum";
import ScopesEnum from "models/enums/ScopesEnum";
import StatusEnum from "models/enums/StatusEnum";
import TechDataEnvironmentTypeEnum from "models/enums/TechDataEnvironmentTypeEnum";
import IconLabel from "components/IconLabel";
import Modal from "components/Modal";
import Snackbar from "components/Snackbar";
import history from "historyApp";
import IconArrowRight from "icons/arrow_right";
import ArrowDown from "icons/arrow_down";
import IconAdd from "icons/add";
import { dataURLtoFile } from "utils/FileUtil";
import IconDownload from "icons/download";
import IconTools from "icons/tools";
import UserInfoAlgorithmEnum from "models/enums/UserInfoAlgorithmEnum";
import SigningAlgorithmEnum from "models/enums/SigningAlgorithmEnum";
import UpdateStatusIcon from "icons/write";
import Acl, { withAccess } from "utils/Acl";
import TechDataFactory from "models/factories/TechDataFactory";
import { getLabelRepresentation } from "utils/enum";
import TechnicalDocumentation from "./TechnicalDocumentation";

export const Logo = (props) => {
  const { logoData } = props;
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    dataURLtoFile(logoData).then((logoFile) => {
      return setLogo(logoFile);
    });
  }, [logoData]);

  return (
    <div style={{ height: "120px" }}>
      <img
        id="logo"
        title="Logo choisi"
        src={logo && URL.createObjectURL(logo)}
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
        }}
        alt="Logo choisi"
      />
    </div>
  );
};

Logo.propTypes = {
  logoData: PropTypes.string.isRequired,
};

export const Section = (props) => {
  const { title, icon, isFirst, children } = props;
  const theme = useTheme();
  const titleToKey = title
    .toLowerCase()
    .replaceAll(" ", "_")
    .replaceAll("'", "_");

  return (
    <Box
      sx={{ mt: isFirst ? 0 : theme.spacing(7) }}
      id={titleToKey}
      key={titleToKey}
    >
      <Typography
        variant="h2"
        sx={{
          color: theme.palette["secondary-ultramarine"],
          m: theme.spacing(0, 3.5, 2, 3.5),
          [theme.breakpoints.down("md")]: {
            ml: 0,
          },
        }}
      >
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item>
            <IconButton
              color="secondary"
              tabIndex={-1}
              sx={{
                mr: theme.spacing(2),
                pointerEvents: "none",
              }}
              size="large"
              aria-hidden="true"
            >
              {icon}
            </IconButton>
            {title}
          </Grid>
        </Grid>
      </Typography>
      <Divider />
      {children}
    </Box>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  isFirst: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  isFirst: false,
};

const UsesTechnicalData = (props) => {
  const { partner, uses, usesNb, getUses, getPartner } = props;
  const wrapper = TcWrapper.getInstance();
  const notInPartner = Acl.hasAccess(AclFront.INFORMATION_TECH);
  const theme = useTheme();
  const [expanded, setExpanded] = useState([]);
  const [modal, setModal] = useState({ open: false, title: "" });
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const { id: partnerId } = partner || {};

  const closeModal = useCallback(() => {
    setModal({ open: false, title: "" });
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleAddUse = useCallback(() => {
    setModal({
      open: true,
      children: (
        <CreateNewUse
          partnerId={partnerId}
          onSuccess={() => {
            closeModal();
            getUses();
          }}
          onCancel={closeModal}
          onDisplayMailErrorSnackbar={() =>
            setSnackbar({
              open: true,
              message:
                "Une erreur est survenue lors de l'envoi d'un ou plusieurs mails aux responsables techniques.",
              variant: "error",
            })
          }
          onDisplayDataErrorSnackbar={(err) =>
            setSnackbar({
              open: true,
              message: err,
              variant: "error",
            })
          }
        />
      ),
      size: "md",
    });
  }, [partnerId, closeModal, getUses]);

  const handleAddTestTechDataOnExistingUse = useCallback(
    (use) => {
      setModal({
        open: true,
        children: (
          <CreateTestTechData
            partnerId={partnerId}
            use={use}
            onSuccess={() => {
              closeModal();
              getUses();
            }}
            onCancel={closeModal}
            onDisplayMailErrorSnackbar={() =>
              setSnackbar({
                open: true,
                message:
                  "Une erreur est survenue lors de l'envoi d'un ou plusieurs mails aux responsables techniques.",
                variant: "error",
              })
            }
            onDisplayDataErrorSnackbar={(err) =>
              setSnackbar({
                open: true,
                message: err,
                variant: "error",
              })
            }
          />
        ),
        size: "md",
      });
    },
    [closeModal, getUses, partnerId],
  );

  const handleAddProdTechData = useCallback(
    (techDatas) => {
      setModal({
        open: true,
        children: (
          <CreateProdTechData
            partner={partner}
            testTechData={techDatas[0]}
            onSuccess={() => {
              closeModal();
              getUses();
              if (partner.status === StatusEnum.TEST.name) {
                // Refresh the partner to get new ACTIVE status on screen, not needed if already ACTIVE
                getPartner();
              }
            }}
            onCancel={closeModal}
          />
        ),
        size: "xs",
      });
    },
    [closeModal, getUses, getPartner, partner],
  );

  const handleChange = (panel) => {
    if (expanded.includes(panel)) {
      setExpanded(expanded.filter((item) => item !== panel));
    } else {
      setExpanded(expanded.concat(panel));
    }
  };

  const handleDisplayScopes = (scopes) => {
    const scopesValues = Object.values(ScopesEnum);
    return (
      <ul>
        {scopesValues.map((scope) => {
          return scopes.includes(scope.name) ? (
            <li key={scope.name}>
              {scope.label}
              {scope.scopes && (
                <ul>
                  {scope.scopes.map((s) => (
                    <li key={s.name}>{s.label}</li>
                  ))}
                </ul>
              )}
            </li>
          ) : (
            scope.scopes?.map(
              (s) => scopes.includes(s.name) && <li key={s.name}>{s.label}</li>,
            )
          );
        })}
      </ul>
    );
  };

  const handleOpenKeyValuesModal = useCallback(
    (id) => {
      setModal({
        open: true,
        children: (
          <TechnicalDataKeyValues technicalDataId={id} onCancel={closeModal} />
        ),
        size: "md",
      });
    },
    [closeModal],
  );

  const handleEditTechData = async (useId, data) => {
    const logo = data.logo && (await dataURLtoFile(data.logo));
    setModal({
      open: true,
      children: (
        <EditTechData
          partnerId={partnerId}
          useId={useId}
          initialTechData={new TechDataFactory({ ...data, logo })}
          onSuccess={() => {
            closeModal();
            getUses();
          }}
          onCancel={closeModal}
        />
      ),
      size: "md",
    });
  };

  const handleOpenStatusForm = (use) => {
    setModal({
      open: true,
      children: (
        <TechDataStatusForm
          use={use}
          onSuccess={() => {
            closeModal();
            getUses();
          }}
          openTechDataModal={() => {
            handleAddProdTechData(use.techDatas);
          }}
          onCancel={closeModal}
        />
      ),
    });
  };

  return (
    <>
      {notInPartner && (
        <Section title="Documentation" icon={<IconDownload />} isFirst>
          <Paper
            sx={{
              margin: theme.spacing(3, 0),
              borderRadius: "8px",
              boxShadow:
                "0 1px 10px 0 rgba(0, 0, 45, 0.06), 0 1px 4px 0 rgba(0, 0, 45, 0.1), 0 0 0 0 rgba(0, 0, 46, 0.06)",
              backgroundColor: theme.palette["secondary-white"],
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: theme.spacing(3.125, 6),
            }}
            onClick={(e) => {
              history.push("/api");
              wrapper.triggerEvent("click", e.target, {
                clic_type: "action",
                name: "documentation_api",
                chapter1: "informations_techniques",
              });
            }}
          >
            <Typography variant="h3">
              Consulter la documentation technique
            </Typography>
            <IconButton aria-label="Accéder à la page de documentation technique">
              <IconArrowRight
                sx={{
                  height: theme.spacing(4),
                  width: theme.spacing(4),
                  verticalAlign: "middle",
                  color: theme.palette["secondary-ultramarine"],
                }}
              />
            </IconButton>
          </Paper>
          <TechnicalDocumentation />
        </Section>
      )}
      <Section title="Usages" icon={<IconTools />}>
        {!notInPartner && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              m: theme.spacing(3, 0),
            }}
          >
            <Button onClick={handleAddUse}>
              <IconLabel
                Icon={IconAdd}
                iconPosition="start"
                label="Ajouter un usage"
                variant="h5"
                component="div"
                color="primary"
                sx={{
                  fontWeight: 600,
                }}
              />
            </Button>
          </Box>
        )}
        {(!uses || usesNb === 0) && (
          <Paper
            sx={{
              m: theme.spacing(3, 0),
              borderRadius: "8px",
              boxShadow:
                "0 1px 10px 0 rgba(0, 0, 45, 0.06), 0 1px 4px 0 rgba(0, 0, 45, 0.1), 0 0 0 0 rgba(0, 0, 46, 0.06)",
              backgroundColor: theme.palette["secondary-white"],
            }}
          >
            <Box sx={{ p: theme.spacing(4) }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 400,
                  color: theme.palette["cobalt-marque-blanche-second"],
                  fontSize: "1.125rem",
                }}
              >
                Vous n&apos;avez pas encore mis en place d&apos;usage de
                L&apos;Identité Numérique
              </Typography>
            </Box>
          </Paper>
        )}
        {usesNb > 0 &&
          uses.map((use) => {
            return (
              use && (
                <Paper
                  sx={{
                    m: theme.spacing(3, 0),
                    borderRadius: "8px",
                    boxShadow:
                      "0 1px 10px 0 rgba(0, 0, 45, 0.06), 0 1px 4px 0 rgba(0, 0, 45, 0.1), 0 0 0 0 rgba(0, 0, 46, 0.06)",
                    backgroundColor: theme.palette["secondary-white"],
                  }}
                  key={use.id}
                >
                  <Grid
                    justifyContent="space-between"
                    alignItems="center"
                    rowSpacing={1}
                    columnSpacing={2}
                    container
                    sx={{ p: theme.spacing(2.125, 6, 3.125) }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm="auto"
                      sx={{
                        maxWidth: { xs: "100%" },
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          p: { xs: theme.spacing(0, 0, 1.5), sm: 0 },
                        }}
                      >
                        {use.useName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <Typography
                        sx={{
                          display: "flex",
                          fontWeight: 400,
                          p: 0,
                        }}
                      >
                        Statut:{" "}
                        <span
                          style={{
                            paddingLeft: theme.spacing(0.5),
                            fontWeight: 600,
                          }}
                        >
                          {getLabelRepresentation(StatusEnum, use.status)}
                        </span>
                        {!notInPartner && (
                          <IconButton
                            aria-label="Mettre à jour le statut"
                            sx={{
                              fontSize: "1.5em",
                              p: 0,
                              ml: theme.spacing(0.5),
                            }}
                            onClick={() => handleOpenStatusForm(use)}
                          >
                            <UpdateStatusIcon color="action" />
                          </IconButton>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider
                    sx={{
                      width: "100%",
                      height: "1px",
                      m: 0,
                      p: 0,
                      borderColor: theme.palette["grey-20"],
                    }}
                  />
                  <Box
                    sx={{
                      p: theme.spacing(2),
                    }}
                  >
                    {use.techDatas
                      ?.sort(
                        (a, b) =>
                          b.environmentType.localeCompare(a.environmentType), // "test" should be before "prod"
                      )
                      .map((data) => (
                        <Box
                          sx={{
                            m: theme.spacing(2),
                            borderRadius: "8px",
                            p: theme.spacing(0.5),
                            backgroundColor:
                              theme.palette["secondary-light-background"],
                          }}
                          key={data.id}
                        >
                          <Accordion
                            key={`${use.id} ${data.environmentType}`}
                            square
                            expanded={expanded.includes(
                              `${use.id} ${data.environmentType}`,
                            )}
                            disableGutters
                          >
                            <AccordionSummary
                              sx={{
                                backgroundColor:
                                  theme.palette["secondary-light-background"],
                                px: theme.spacing(2.5),
                                py: { xs: theme.spacing(1), md: 0 },
                                border: "none",
                              }}
                              expandIcon={
                                <IconButton
                                  onClick={() =>
                                    handleChange(
                                      `${use.id} ${data.environmentType}`,
                                    )
                                  }
                                  aria-label={
                                    expanded.includes(
                                      `${use.id} ${data.environmentType}`,
                                    )
                                      ? "Fermer les données techniques"
                                      : "Voir les données techniques"
                                  }
                                >
                                  <ArrowDown
                                    sx={{
                                      width: "32px",
                                      height: "32px",
                                      color:
                                        theme.palette["secondary-ultramarine"],
                                    }}
                                  />
                                </IconButton>
                              }
                            >
                              <Grid
                                container
                                columnSpacing={2}
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                  pr: theme.spacing(2),
                                }}
                                rowSpacing={2}
                              >
                                <Grid item xs="auto" sx={{ maxWidth: "100%" }}>
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      color: theme.palette["primaire-bleu"],
                                      fontWeight: 700,
                                    }}
                                  >
                                    {`Environnement de ${getLabelRepresentation(
                                      TechDataEnvironmentTypeEnum,
                                      data.environmentType,
                                    ).toLowerCase()}`}
                                  </Typography>
                                </Grid>
                                <Grid item xs="auto" sx={{ maxWidth: "100%" }}>
                                  <Grid container rowSpacing={1}>
                                    <Grid item xs={12} sm="auto">
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                          mr: {
                                            xs: 0,
                                            sm: theme.spacing(0.75),
                                          },
                                          width: { xs: "100%", sm: "auto" },
                                        }}
                                        onClick={() =>
                                          handleOpenKeyValuesModal(data?.id)
                                        }
                                      >
                                        Clés d’installation
                                      </Button>
                                    </Grid>
                                    {!notInPartner && (
                                      <Grid item xs={12} sm="auto">
                                        <Button
                                          variant="outlined"
                                          sx={{
                                            width: { xs: "100%", sm: "auto" },
                                          }}
                                          color="primary"
                                          onClick={() =>
                                            handleEditTechData(use.id, {
                                              useName: use.useName,
                                              ...data,
                                            })
                                          }
                                        >
                                          Modifier
                                        </Button>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                backgroundColor:
                                  theme.palette["secondary-light-background"],
                                px: theme.spacing(2.5),
                                py: 0,
                              }}
                            >
                              <Typography
                                variant="h4"
                                component="h5"
                                sx={{
                                  pt: theme.spacing(4),
                                  pb: theme.spacing(3),
                                  fontWeight: 700,
                                  fontSize: "1.125rem",
                                }}
                              >
                                Informations générales
                              </Typography>
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ mb: theme.spacing(1) }}
                                  >
                                    URLs autorisées
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    sx={{
                                      pb: theme.spacing(2),
                                      color:
                                        theme.palette[
                                          "cobalt-marque-blanche-second"
                                        ],
                                    }}
                                  >
                                    <ul>
                                      {data.urls?.map((url) => (
                                        <li key={url}>{url}</li>
                                      ))}
                                    </ul>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ mb: theme.spacing(1) }}
                                  >
                                    Scope de données
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    sx={{
                                      pb: theme.spacing(2),
                                      color:
                                        theme.palette[
                                          "cobalt-marque-blanche-second"
                                        ],
                                    }}
                                  >
                                    {data.scopes &&
                                      handleDisplayScopes(data.scopes || [])}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Typography
                                variant="h4"
                                component="h5"
                                sx={{
                                  pt: theme.spacing(4),
                                  pb: theme.spacing(3),
                                  fontWeight: 700,
                                  fontSize: "1.125rem",
                                }}
                              >
                                Paramètres
                              </Typography>
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ mb: theme.spacing(1) }}
                                  >
                                    Algorithme de signature UserInfo :
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    sx={{
                                      pb: theme.spacing(2),
                                      color:
                                        theme.palette[
                                          "cobalt-marque-blanche-second"
                                        ],
                                    }}
                                  >
                                    {data.userInfoAlgorithm &&
                                      getLabelRepresentation(
                                        UserInfoAlgorithmEnum,
                                        data.userInfoAlgorithm,
                                      )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ mb: theme.spacing(1) }}
                                  >
                                    Algorithme de signature ID Token :
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    sx={{
                                      pb: theme.spacing(2),
                                      color:
                                        theme.palette[
                                          "cobalt-marque-blanche-second"
                                        ],
                                    }}
                                  >
                                    {data.signingAlgorithm &&
                                      getLabelRepresentation(
                                        SigningAlgorithmEnum,
                                        data.signingAlgorithm,
                                      )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ mb: theme.spacing(1) }}
                                  >
                                    Protocole :
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    sx={{
                                      pb: theme.spacing(2),
                                      color:
                                        theme.palette[
                                          "cobalt-marque-blanche-second"
                                        ],
                                    }}
                                  >
                                    {getLabelRepresentation(
                                      ProtocolePartnerEnum,
                                      data.flow,
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Typography
                                variant="h4"
                                component="h5"
                                sx={{
                                  pt: theme.spacing(4),
                                  pb: theme.spacing(3),
                                  fontWeight: 700,
                                  fontSize: "1.125rem",
                                }}
                              >
                                Personnalisation
                              </Typography>
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ my: theme.spacing(1) }}
                                  >
                                    Masquer le lien de création :
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    sx={{
                                      pb: theme.spacing(2),
                                      color:
                                        theme.palette[
                                          "cobalt-marque-blanche-second"
                                        ],
                                    }}
                                  >
                                    {data.hideLinCreateLink ? "Oui" : "Non"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ my: theme.spacing(1) }}
                                  >
                                    Masquer le lien de retour :
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    sx={{
                                      pb: theme.spacing(2),
                                      color:
                                        theme.palette[
                                          "cobalt-marque-blanche-second"
                                        ],
                                    }}
                                  >
                                    {data.hideReturnUrl ? "Oui" : "Non"}
                                  </Typography>
                                </Grid>
                                {data.tradingName && (
                                  <Grid item xs={12} md={6}>
                                    <Typography
                                      variant="h4"
                                      component="div"
                                      sx={{ my: theme.spacing(1) }}
                                    >
                                      Nom commercial :
                                    </Typography>
                                    <Typography
                                      variant="subtitle1"
                                      component="div"
                                      sx={{
                                        pb: theme.spacing(2),
                                        color:
                                          theme.palette[
                                            "cobalt-marque-blanche-second"
                                          ],
                                      }}
                                    >
                                      {data.tradingName}
                                    </Typography>
                                  </Grid>
                                )}
                                {process.env
                                  .REACT_APP_with_additional_personalisation ===
                                  "true" && (
                                  <>
                                    {data.logo && (
                                      <Grid item xs={12} md={6}>
                                        <Typography
                                          variant="h4"
                                          component="div"
                                          sx={{ my: theme.spacing(1) }}
                                        >
                                          Logo :
                                        </Typography>
                                        <Logo logoData={data.logo} />
                                      </Grid>
                                    )}
                                    <Grid item xs={12} md={6}>
                                      <Typography
                                        variant="h4"
                                        component="div"
                                        sx={{ my: theme.spacing(1) }}
                                      >
                                        Libellé :
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                          pb: theme.spacing(2),
                                          color:
                                            theme.palette[
                                              "cobalt-marque-blanche-second"
                                            ],
                                        }}
                                      >
                                        {getLabelRepresentation(
                                          ActionLabelEnum,
                                          data.actionLabel,
                                        )}
                                      </Typography>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      ))}
                    {use.techDatas?.length === 0 && !notInPartner && (
                      <Box
                        sx={{
                          m: theme.spacing(2),
                          borderRadius: "8px",
                          p: theme.spacing(0.5),
                          backgroundColor:
                            theme.palette["secondary-light-background"],
                        }}
                      >
                        <Accordion square expanded={false} disableGutters>
                          <AccordionSummary
                            sx={{
                              backgroundColor:
                                theme.palette["secondary-light-background"],
                              py: { xs: theme.spacing(1), md: 0 },
                              border: "none",
                              px: theme.spacing(2.5),
                            }}
                            expandIcon={
                              <IconButton
                                disabled
                                aria-label="Bouton désactivé pour voir les données techniques"
                              >
                                <ArrowDown
                                  sx={{
                                    width: "32px",
                                    height: "32px",
                                  }}
                                />
                              </IconButton>
                            }
                          >
                            <Grid
                              container
                              columnSpacing={2}
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{
                                pr: theme.spacing(2),
                              }}
                              rowSpacing={2}
                            >
                              <Grid item xs="auto" sx={{ maxWidth: "100%" }}>
                                <Typography
                                  variant="h4"
                                  sx={{
                                    color: theme.palette["primaire-bleu"],
                                    fontWeight: 700,
                                  }}
                                >
                                  {`Environnement de ${TechDataEnvironmentTypeEnum.TEST.label.toLowerCase()}`}
                                </Typography>
                              </Grid>
                              <Grid item xs="auto" sx={{ width: "100%" }}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    width: { xs: "100%", sm: "auto" },
                                    mt: { xs: theme.spacing(2), sm: 0 },
                                  }}
                                  onClick={() =>
                                    handleAddTestTechDataOnExistingUse(use)
                                  }
                                >
                                  Paramétrer
                                </Button>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                        </Accordion>
                      </Box>
                    )}
                    {use.techDatas?.length === 1 && !notInPartner && (
                      <Box
                        sx={{
                          m: theme.spacing(2),
                          borderRadius: "8px",
                          p: theme.spacing(0.5),
                          backgroundColor:
                            theme.palette["secondary-light-background"],
                        }}
                      >
                        <Accordion square expanded={false} disableGutters>
                          <AccordionSummary
                            sx={{
                              backgroundColor:
                                theme.palette["secondary-light-background"],
                              py: { xs: theme.spacing(1), md: 0 },
                              border: "none",
                              px: theme.spacing(2.5),
                            }}
                            expandIcon={
                              <IconButton
                                disabled
                                aria-label="Bouton désactivé pour voir les données techniques"
                              >
                                <ArrowDown
                                  sx={{
                                    width: "32px",
                                    height: "32px",
                                  }}
                                />
                              </IconButton>
                            }
                          >
                            <Grid
                              container
                              columnSpacing={2}
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{
                                pr: theme.spacing(2),
                              }}
                              rowSpacing={2}
                            >
                              <Grid item xs="auto" sx={{ maxWidth: "100%" }}>
                                <Typography
                                  variant="h4"
                                  sx={{
                                    color: theme.palette["primaire-bleu"],
                                    fontWeight: 700,
                                  }}
                                >
                                  {`Environnement de ${TechDataEnvironmentTypeEnum.PRODUCTION.label.toLowerCase()}`}
                                </Typography>
                              </Grid>
                              <Grid item xs="auto" sx={{ width: "100%" }}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    width: { xs: "100%", sm: "auto" },
                                    mt: { xs: theme.spacing(2), sm: 0 },
                                  }}
                                  onClick={() =>
                                    handleAddProdTechData(use.techDatas)
                                  }
                                >
                                  Paramétrer
                                </Button>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                        </Accordion>
                      </Box>
                    )}
                  </Box>
                </Paper>
              )
            );
          })}
        <Modal open={modal.open} onClose={closeModal} size={modal.size}>
          {modal.children}
        </Modal>
        <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
      </Section>
    </>
  );
};

UsesTechnicalData.propTypes = {
  partner: PropTypes.shape().isRequired,
  uses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  usesNb: PropTypes.number.isRequired,
  getUses: PropTypes.func.isRequired,
  getPartner: PropTypes.func.isRequired,
};

export const BackUsesTechnicalData = withAccess(
  AclBack.PARTNERS_TECHNICAL_INFO,
  Acl.READ,
)(UsesTechnicalData);

export const FrontUsesTechnicalData = withAccess(AclFront.INFORMATION_TECH)(
  UsesTechnicalData,
);
