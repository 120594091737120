import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import PartnerShape from "models/shapes/PartnerShape";
import PartnerTypologyEnum from "models/enums/PartnerTypologyEnum";
import BusinessLineEnum from "models/enums/BusinessLineEnum";
import PartnerEnterpriseTypeEnum from "models/enums/PartnerEnterpriseTypeEnum";
import SalesChannelEnum from "models/enums/SalesChannelEnum";
import Form from "components/Form";
import InputField from "components/InputField";
import TrailSignIcon from "icons/trail-sign-outline";
import InputWithIcon from "components/InputWithIcon";
import { isRequired } from "utils/enum";

const StyledErrorMessage = styled(Typography)({
  fontSize: "0.75rem",
});

const GeneralDataForm = (props) => {
  const {
    generalData,
    namePrefix,
    touched,
    errors,
    schema,
    handleClick,
    handleChange,
    handleBlur,
    ...others
  } = props;

  const { businessLine, enterpriseType, typology, salesChannel, issuerCode } =
    generalData;

  return (
    <Form
      content={[
        {
          key: "businessLine/enterpriseType",
          firstElement: (
            <InputField
              title="Secteur d'activité"
              required={isRequired(schema, `${namePrefix}businessLine`)}
            >
              <InputWithIcon
                variant="outlined"
                name={`${namePrefix}businessLine`}
                value={businessLine || ""}
                error={touched.businessLine && Boolean(errors.businessLine)}
                onChange={handleChange}
                onBlur={handleBlur}
                select
                helperText={touched.businessLine && errors.businessLine}
                startAdornment
                adornment={
                  <TrailSignIcon
                    sx={{
                      color: (theme) => theme.palette["secondary-white"],
                      width: "16px",
                      height: "16px",
                    }}
                  />
                }
                SelectProps={{
                  labelId: "secteur_d'activité",
                  "aria-describedby": "ht_activité",
                  "aria-invalid": Boolean(
                    touched.businessLine && errors.businessLine,
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_activité",
                }}
              >
                {BusinessLineEnum &&
                  Object.values(BusinessLineEnum).map((value) => (
                    <MenuItem key={value.name} value={value.name}>
                      {value.label}
                    </MenuItem>
                  ))}
              </InputWithIcon>
            </InputField>
          ),
          secondElement: (
            <InputField title="Type d'entreprise">
              {touched.enterpriseType && Boolean(errors.enterpriseType) && (
                <StyledErrorMessage color="error" id="ht_type_entreprise">
                  {errors.enterpriseType}
                </StyledErrorMessage>
              )}
              <RadioGroup
                defaultValue=""
                row
                name={`${namePrefix}enterpriseType`}
                value={enterpriseType}
                onChange={handleChange}
                aria-invalid={Boolean(
                  touched.enterpriseType && errors.enterpriseType,
                )}
                aria-required={isRequired(
                  schema,
                  `${namePrefix}enterpriseType`,
                )}
                aria-labelledby="type_d'entreprise"
                aria-describedby="ht_type_entreprise"
              >
                <FormControlLabel
                  value={PartnerEnterpriseTypeEnum.TPE}
                  control={
                    <Radio
                      tabindex="0"
                      role="radio"
                      aria-checked={
                        enterpriseType === PartnerEnterpriseTypeEnum.TPE
                      }
                      onClick={() =>
                        handleClick(
                          `${namePrefix}enterpriseType`,
                          PartnerEnterpriseTypeEnum.TPE,
                        )
                      }
                      inputProps={{ tabIndex: -1 }}
                    />
                  }
                  label="TPE"
                />
                <FormControlLabel
                  value={PartnerEnterpriseTypeEnum.PME}
                  control={
                    <Radio
                      tabindex="0"
                      role="radio"
                      aria-checked={
                        enterpriseType === PartnerEnterpriseTypeEnum.PME
                      }
                      onClick={() =>
                        handleClick(
                          `${namePrefix}enterpriseType`,
                          PartnerEnterpriseTypeEnum.PME,
                        )
                      }
                      inputProps={{ tabIndex: -1 }}
                    />
                  }
                  label="PME"
                />
                <FormControlLabel
                  value={PartnerEnterpriseTypeEnum.ETI}
                  control={
                    <Radio
                      tabindex="0"
                      role="radio"
                      aria-checked={
                        enterpriseType === PartnerEnterpriseTypeEnum.ETI
                      }
                      onClick={() =>
                        handleClick(
                          `${namePrefix}enterpriseType`,
                          PartnerEnterpriseTypeEnum.ETI,
                        )
                      }
                      inputProps={{ tabIndex: -1 }}
                    />
                  }
                  label="ETI"
                />
                <FormControlLabel
                  value={PartnerEnterpriseTypeEnum.GE}
                  control={
                    <Radio
                      tabindex="0"
                      role="radio"
                      aria-checked={
                        enterpriseType === PartnerEnterpriseTypeEnum.GE
                      }
                      onClick={() =>
                        handleClick(
                          `${namePrefix}enterpriseType`,
                          PartnerEnterpriseTypeEnum.GE,
                        )
                      }
                      inputProps={{ tabIndex: -1 }}
                    />
                  }
                  label="GE"
                />
              </RadioGroup>
            </InputField>
          ),
        },
        {
          key: "typology/salesChannel",
          firstElement: (
            <InputField title="Typologie de partenaire">
              {touched.typology && Boolean(errors.typology) && (
                <StyledErrorMessage color="error" id="ht_typologie_partenaire">
                  {errors.typology}
                </StyledErrorMessage>
              )}
              <RadioGroup
                defaultValue=""
                row
                name={`${namePrefix}typology`}
                value={typology}
                onChange={handleChange}
                aria-labelledby="typologie_de_partenaire"
                aria-describedby="ht_typologie_partenaire"
                aria-invalid={Boolean(touched.typology && errors.typology)}
                aria-required={isRequired(schema, `${namePrefix}typology`)}
              >
                {PartnerTypologyEnum &&
                  Object.values(PartnerTypologyEnum).map((value) => (
                    <FormControlLabel
                      key={value.name}
                      value={value.name}
                      control={
                        <Radio
                          tabindex="0"
                          role="radio"
                          aria-checked={typology === value.name}
                          onClick={() =>
                            handleClick(`${namePrefix}typology`, value.name)
                          }
                          inputProps={{ tabIndex: -1 }}
                        />
                      }
                      label={value.label}
                    />
                  ))}
              </RadioGroup>
            </InputField>
          ),
          secondElement: (
            <InputField title="Canal de vente">
              {touched.salesChannel && Boolean(errors.salesChannel) && (
                <StyledErrorMessage color="error" id="ht_canal_vente">
                  {errors.salesChannel}
                </StyledErrorMessage>
              )}
              <RadioGroup
                defaultValue=""
                row
                name={`${namePrefix}salesChannel`}
                value={salesChannel}
                onChange={handleChange}
                aria-labelledby="canal_de_vente"
                aria-describedby="ht_canal_vente"
                aria-invalid={Boolean(
                  touched.salesChannel && errors.salesChannel,
                )}
                aria-required={isRequired(schema, `${namePrefix}salesChannel`)}
              >
                {SalesChannelEnum &&
                  Object.values(SalesChannelEnum).map((value) => (
                    <FormControlLabel
                      key={value.name}
                      value={value.name}
                      control={
                        <Radio
                          tabindex="0"
                          role="radio"
                          aria-checked={salesChannel === value.name}
                          onClick={() =>
                            handleClick(`${namePrefix}salesChannel`, value.name)
                          }
                          inputProps={{ tabIndex: -1 }}
                        />
                      }
                      label={value.label}
                    />
                  ))}
              </RadioGroup>
            </InputField>
          ),
        },
        salesChannel === SalesChannelEnum.INDIRECT.name
          ? {
              key: "issuerCode",
              firstElement: (
                <InputField title="Code émetteur">
                  <TextField
                    name={`${namePrefix}issuerCode`}
                    error={touched.issuerCode && Boolean(errors.issuerCode)}
                    helperText={touched.issuerCode && errors.issuerCode}
                    variant="outlined"
                    value={issuerCode || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      "aria-labelledby": "code_émetteur",
                      "aria-describedby": "ht_code_émetteur",
                      "aria-invalid": Boolean(
                        touched.issuerCode && errors.issuerCode,
                      ),
                      "aria-required": isRequired(
                        schema,
                        `${namePrefix}issuerCode`,
                      ),
                    }}
                    FormHelperTextProps={{
                      id: "ht_code_émetteur",
                    }}
                  />
                </InputField>
              ),
              secondElement: <div />,
            }
          : null,
      ]}
      {...others}
    />
  );
};

GeneralDataForm.propTypes = {
  generalData: PartnerShape.isRequired,
  namePrefix: PropTypes.string,
  touched: PropTypes.shape(),
  handleClick: PropTypes.func.isRequired,
  errors: PropTypes.shape(),
  schema: PropTypes.shape(),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

GeneralDataForm.defaultProps = {
  namePrefix: "",
  touched: {},
  errors: {},
  schema: {},
};

export default GeneralDataForm;
