import axios from "axios";
import { download } from "utils/FileUtil";

export const references = {
  TECHNICAL: "technic",
  GRAPHIC: "graphic",
  API: "doc_api",
};

const name = "integration-kits";
export default class TechnicalDocsService {
  static download = (reference, options = {}) => {
    return axios
      .get(`${process.env.REACT_APP_api_url}/${name}`, {
        ...options,
        params: { type: reference },
        responseType: "blob",
      })
      .then((response) => {
        const { data, headers } = response;
        const {
          "content-type": type,
          "content-disposition": contentDisposition,
        } = headers;
        const filenameMatch = contentDisposition.match(/filename="(.*)"/i);
        download({
          data,
          fileName: Array.isArray(filenameMatch)
            ? decodeURIComponent(filenameMatch[1].replaceAll("%25", "%"))
            : "documentation",
          type,
        });
      });
  };
}
