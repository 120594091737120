import React from "react";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const StatNumber = (props) => {
  const { value, secondary } = props;

  return (
    <Typography
      variant="h2"
      component="div"
      sx={{
        padding: "4px 16px",
        borderRadius: "0.3em",
        minWidth: "120px",
        textAlign: "center",
        color: (theme) =>
          secondary ? theme.palette["secondary-ultramarine"] : "white",
        background: (theme) =>
          secondary
            ? theme.palette["primaire-jaune"]
            : theme.palette["bleu-80"],
      }}
    >
      <span style={visuallyHidden}>{secondary ? "Période 2, " : "Période 1, "}</span>
      {value || <span aria-label="Aucune donnée">-</span>}
    </Typography>
  );
};

StatNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  secondary: PropTypes.bool,
};

StatNumber.defaultProps = {
  secondary: false,
};

const Stat = (props) => {
  const { Icon, label, stats, isDashboard, extendedVersion, ...others } = props;

  let statsToDisplay = [];
  if (Array.isArray(stats)) {
    statsToDisplay = stats;
  } else if (String(stats)) {
    statsToDisplay = [stats];
  }

  const [firstS = "", secondS] = statsToDisplay.map((stat) =>
    !stat && ![0, "0"].includes(stat) ? "" : stat,
  );
  const firstStat = String(firstS);
  const secondStat = secondS !== undefined && String(secondS);

  return (
    <Grid
      component={Box}
      py={6}
      container
      sx={{ maxWidth: "95%" }}
      alignItems="center"
      direction="column"
      {...others}
    >
      <Grid component={Box} mb={3} item>
        <Typography
          variant={!isDashboard ? "h4" : "h3"}
          color="text.primary"
          component="div"
        >
          <Grid container alignItems="center" spacing={1}>
            {Icon && (
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.23em",
                }}
              >
                <Icon />
              </Grid>
            )}
            <Grid item>{label}</Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={extendedVersion ? 3 : 2}
          justifyContent="center"
        >
          <Grid item>
            <StatNumber value={firstStat} />
          </Grid>
          {secondStat && (
            <Grid item>
              <StatNumber value={secondStat} secondary />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Stat;

Stat.propTypes = {
  Icon: PropTypes.elementType,
  label: PropTypes.string,
  stats: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  isDashboard: PropTypes.bool,
  extendedVersion: PropTypes.bool,
};

Stat.defaultProps = {
  Icon: null,
  label: "",
  stats: "-",
  isDashboard: false,
  extendedVersion: false,
};
