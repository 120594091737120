import * as Yup from "yup";
import AddressSchema from "models/schemas/AddressSchema";
import RibSchema from "models/schemas/RibSchema";
import BillingIssuingFrequencyEnum from "models/enums/BillingIssuingFrequencyEnum";
import BillingTypeEnum from "models/enums/BillingTypeEnum";
import BillingSendModeEnum from "models/enums/BillingSendModeEnum";
import TermsOfPaymentEnum from "models/enums/TermsOfPaymentEnum";
import ModeOfPaymentEnum from "models/enums/ModeOfPaymentEnum";
import { isValidRib } from "utils/RibUtil";
import isEmailValidator from "validator/lib/isEmail";

export const billingShape = {
  vatApplication: Yup.boolean().nullable(),
  intracommunityVatNumber: Yup.string().nullable(),
  termsOfPayment: Yup.string()
    .oneOf([
      ...Object.values(TermsOfPaymentEnum).map((value) => value.name),
      null,
      "",
    ])
    .nullable(),
  modeOfPayment: Yup.string()
    .oneOf([
      ...Object.values(ModeOfPaymentEnum).map((value) => value.name),
      null,
      "",
    ])
    .nullable(),
  rib: Yup.object()
    .nullable()
    .when("modeOfPayment", {
      is: ModeOfPaymentEnum.DEBIT.name,
      then: () =>
        RibSchema.nullable().test("is-rib", "RIB non valide", isValidRib),
    }),
  bic: Yup.string()
    .nullable()
    .when("modeOfPayment", {
      is: ModeOfPaymentEnum.DEBIT.name,
      then: (fieldSchema) =>
        fieldSchema.transform((value) => (!value ? null : value)).matches(
          /^[a-zA-Z]{6}[0-9a-zA-Z]{2}([0-9a-zA-Z]{3})?/i,
          "BIC non valide",
        ),
    }),
  bankDomiciliation: Yup.string().nullable(),
  issuingFrequency: Yup.string()
    .oneOf([
      ...Object.values(BillingIssuingFrequencyEnum).map((value) => value.name),
      null,
      "",
    ])
    .nullable(),
  copiesNumber: Yup.number()
    .typeError("Format incorrect")
    .min(1, "La valeur doit être supérieure ou égale à 1")
    .nullable()
    .when("sendMode", {
      is: BillingSendModeEnum.BY_POST.name,
      then: (fieldSchema) =>
        fieldSchema
          .required("Champ requis")
          .integer("Le nombre d'exemplaires doit être un nombre entier")
          .min(1, "La valeur doit être supérieure ou égale à 1")
    }),
  sendMode: Yup.string()
    .oneOf([
      ...Object.values(BillingSendModeEnum).map((value) => value.name),
      null,
      "",
    ])
    .nullable(),
  billingSendEmail: Yup.string()
    .nullable()
    .when("sendMode", {
      is: BillingSendModeEnum.BY_EMAIL.name,
      then: (fieldSchema) =>
        fieldSchema
          .required("Champ requis")
          .email("Format d'email incorrect")
          .test(
            "is-valid",
            "Format d'email incorrect",
            (val) => !val || isEmailValidator(val),
          )
    }),
  typeOfBilling: Yup.string()
    .oneOf([
      ...Object.values(BillingTypeEnum).map((value) => value.name),
      null,
      "",
    ])
    .nullable(),
  annualCommitmentVolume: Yup.number()
    .typeError("Format incorrect")
    .nullable()
    .when("typeOfBilling", {
      is: BillingTypeEnum.LICENCE.name,
      then: (fieldSchema) =>
        fieldSchema
          .required("Champ requis")
    }),
  orderReference: Yup.string().nullable(),
  purchaseReference: Yup.string().nullable(),
  otherReferences: Yup.string().nullable(),
  address: AddressSchema.nullable(),
};

const BillingSchema = Yup.object().shape(billingShape);

export default BillingSchema;
