import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";

import AclBack from "apps/back/AclBack";
import Acl, { withAccess } from "utils/Acl";
import PartnerService from "services/PartnerService";
import Link from "components/Link";
import IconLabel from "components/IconLabel";
import IconArrowRight from "icons/arrow_right";
import IconAdd from "icons/add";
import IconDownload from "icons/download";
import TableListing from "components/TabListing";
import PageTitle from "components/PageTitle";
import { download } from "utils/FileUtil";
import Snackbar from "components/Snackbar";
import history from "historyApp";

const headCells = [
  { id: "companyName", sortable: true, isDate: false, label: "Partenaire" },
  { id: "code", sortable: true, isDate: false, label: "Code partenaire" },
  {
    id: "issuerCode",
    sortable: true,
    isDate: false,
    label: "Code émetteur",
  },
  { id: "created", sortable: true, isDate: true, label: "Date de création" },
  {
    id: "businessLine",
    sortable: true,
    isDate: false,
    label: "Secteur d'activité",
  },
  {
    id: "typology",
    sortable: true,
    isDate: false,
    label: "Typologie de partenaire",
  },
  {
    id: "salesChannel",
    sortable: true,
    isDate: false,
    label: "Canal de vente",
  },
  {
    id: "billing.typeOfBilling",
    sortable: true,
    isDate: false,
    label: "Facturation",
  },
  { id: "status", sortable: true, isDate: false, label: "Statut" },
];

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPartners, setTotalPartners] = useState(0);
  const [sorting, setSorting] = useState({
    sortBy: localStorage.getItem("sortBy") || "created",
    sortByLabel:
      headCells.find((el) => el.id === localStorage.getItem("sortBy"))?.label ||
      "Date de création",
    order: localStorage.getItem("order") || "desc",
  });
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });

  const { sortBy, order } = sorting;
  const { page, rowsPerPage } = pagination;

  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const getPartners = useCallback(() => {
    setIsLoading(true);
    PartnerService.find({
      params: {
        sort: `${sortBy},${order.toLocaleUpperCase()}`,
        offset: page * rowsPerPage,
        per_page: rowsPerPage,
      },
    })
      .then((result) => {
        setIsLoading(false);
        const { data = [], total = 0 } = result || {};
        setPartners(data);
        setTotalPartners(total);
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message:
            "Une erreur est survenue lors de la récupération des comptes partenaires.",
          variant: "error",
        });
      });
  }, [sortBy, order, page, rowsPerPage]);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  const handleExportPartners = () => {
    PartnerService.find({
      params: { sort: `${sortBy},${order.toLocaleUpperCase()}`, csv: true },
    })
      .then((res) => {
        if (res) {
          const type = res.headers && res.headers["content-type"];
          download({ data: res, fileName: decodeURI("partenaires.csv"), type });
        }
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message:
            "Une erreur est survenue lors de la génération du fichier csv.",
          variant: "error",
        });
      });
  };

  const handleClickPartner = (partner) => {
    history.push(`/partenaire/${partner.id}`);
  };

  const theme = useTheme();

  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <PageTitle>Mes partenaires</PageTitle>
      <div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: theme.spacing(3),
          }}
        >
          <Typography
            color="primary"
            variant="h4"
            component="div"
          >{`${totalPartners} partenaire${
            totalPartners > 1 ? "s" : ""
          }`}</Typography>
          <Box sx={{ display: "flex" }}>
            {Acl.hasAccess(AclBack.PARTNERS_DETAILS, Acl.CREATE) && (
              <Link
                to="/creation-partenaire"
                underline="none"
                aria-label="Créer une nouvelle fiche partenaire"
                style={{ display: "flex" }}
              >
                {isMobile ? (
                  <IconAdd />
                ) : (
                  <IconLabel
                    id="create_partner_label"
                    Icon={IconAdd}
                    iconPosition="start"
                    iconStyle={{ fontSize: "1em" }}
                    label="Créer une nouvelle fiche partenaire"
                    variant="h5"
                    component="div"
                    color="primary"
                    style={{ fontWeight: 600 }}
                  />
                )}
              </Link>
            )}
            <Button
              aria-label="Exporter la liste"
              onClick={handleExportPartners}
              sx={{
                marginLeft: theme.spacing(8),
                padding: theme.spacing(0, 1),
              }}
            >
              {!isMobile && (
                <Typography
                  id="export_list_label"
                  variant="h5"
                  component="div"
                  color="primary"
                  sx={{ marginRight: theme.spacing(1), fontWeight: 600 }}
                >
                  Exporter la liste
                </Typography>
              )}
              <IconDownload style={{ color: theme.palette["primaire-bleu"] }} />
            </Button>
          </Box>
        </Box>
        <TableListing
          headCells={headCells}
          liste={partners}
          isLoading={isLoading}
          onClick={handleClickPartner}
          icon={
            <IconArrowRight
              sx={{
                height: theme.spacing(3),
                width: theme.spacing(3),
                verticalAlign: "middle",
                color: theme.palette["secondary-ultramarine"],
              }}
            />
          }
          pagination={pagination}
          setPagination={setPagination}
          sorting={sorting}
          setSorting={setSorting}
          totalElements={totalPartners}
          rowsPerPageLabel="Partenaires"
          partnersTable
          caption="Liste des partenaires"
        />
      </div>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </>
  );
};

export default withAccess(
  AclBack.PARTNERS_LISTING_INDIRECT_SALE,
  Acl.READ,
)(Partners);
