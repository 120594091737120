import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "icons/close";
import ArrowDownIcon from "icons/arrow_down";
import DeleteIcon from "icons/trash";
import IconTools from "icons/tools";
import TcWrapper from "react-tag-commander";

const Close = styled(CloseIcon)(({ theme }) => ({
  color: `${theme.palette["secondary-white"]}!important`,
  width: "16px",
  height: "16px",
  [`&:hover`]: {
    color: "rgba(0, 0, 140, 0.4)!important",
  },
}));

const ArrowDown = styled(ArrowDownIcon)(({ theme }) => ({
  width: "32px",
  height: "32px",
  color: theme.palette["secondary-ultramarine"],
}));

const FiltersCard = (props) => {
  const { listOfUses, usesFilter, handleChangeUsesFilter, isDashboard } = props;

  function compare(elementA, elementB) {
    return elementA.localeCompare(elementB);
  }

  const sortedListOfUses = listOfUses.sort((elementA, elementB) => {
    return compare(elementA, elementB);
  });

  const wrapper = TcWrapper.getInstance();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  const FilterLabel = styled(Typography)(() => ({
    color: theme.palette["secondary-ultramarine"],
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(-1),
    [theme.breakpoints.down("lg")]: {
      fontSize: isDashboard ? "1.125rem" : "0.875rem",
    },
    // These two lines are a work-around to remove blanksplace next to Autocomplete active label
    backgroundColor: "white",
    paddingRight: "8px",
  }));

  const usesFilterLabel = (
    <FilterLabel variant={isDashboard ? "h3" : "h5"} component="span">
      <IconTools
        sx={{
          width: "32px",
          height: "32px",
          margin: theme.spacing(0.25, 1),
        }}
      />{" "}
      {!isMobile && "Filtrer par usage"}
    </FilterLabel>
  );

  const clearText = "Supprimer les filtres sélectionnés";
  const closeText = "Fermer la liste";
  const openText = "Ouvrir la liste";

  const handleResetFilters = (event) => {
    handleChangeUsesFilter(event, []);
    wrapper.triggerEvent("click", event.target, {
      clic_type: "action",
      name: "supprimer_gestion_periode",
      chapter1: "tableau_de_bord",
    });
  };

  return (
    <Box sx={{ padding: theme.spacing(4, 5) }}>
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", justifyContent: "center" }}
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <Autocomplete
            multiple
            sx={{
              "& label": {
                overflow: "unset",
              },
            }}
            options={sortedListOfUses}
            noOptionsText="Pas d'options"
            clearText={clearText}
            closeText={closeText}
            openText={openText}
            disableCloseOnSelect
            value={usesFilter}
            onChange={handleChangeUsesFilter}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            limitTags={3}
            popupIcon={<ArrowDown />}
            ChipProps={{
              deleteIcon: (
                <IconButton
                  sx={{ color: "rgba(0, 0, 140, 0.5)" }}
                  aria-label="Supprimer le filtre"
                >
                  <Close />
                </IconButton>
              ),
            }}
            getOptionLabel={(option) => option || ""}
            /* eslint-disable-next-line no-shadow */
            renderOption={(props, option, { selected }) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between !important",
                }}
                key={option}
                {...props}
              >
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          p: theme.spacing(0.5, 1.5),
                          mr: theme.spacing(1),
                        }}
                        checked={selected}
                      />
                    }
                    label={
                      <Typography variant="subtitle1" component="span">
                        {option}
                      </Typography>
                    }
                  />
                </Box>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={usesFilterLabel}
                placeholder="Votre sélection"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    ...params.InputProps.styles,
                    height: "auto",
                    width: "100%",
                    padding: "0.625em",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs="auto">
          <IconButton
            data-testid="editiconbutton"
            aria-label="Supprimer tous les filtres"
            sx={{ fontSize: isDashboard ? "2em" : "1.5em", p: 0 }}
            onClick={handleResetFilters}
          >
            <DeleteIcon color="action" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

FiltersCard.propTypes = {
  listOfUses: PropTypes.arrayOf(PropTypes.string),
  usesFilter: PropTypes.arrayOf(PropTypes.string),
  handleChangeUsesFilter: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool,
};

FiltersCard.defaultProps = {
  listOfUses: [],
  usesFilter: [],
  isDashboard: false,
};

export default FiltersCard;
