import AddressFactory from "models/factories/AddressFactory";
import RibFactory from "models/factories/RibFactory";

export default class BillingFactory {
  constructor({
    vatApplication = null,
    intracommunityVatNumber = "",
    modeOfPayment = null,
    termsOfPayment = null,
    rib,
    bic = "",
    bankDomiciliation = "",
    issuingFrequency = null,
    copiesNumber = 1,
    sendMode = null,
    billingSendEmail = "",
    typeOfBilling = null,
    annualCommitmentVolume = null,
    orderReference = "",
    purchaseReference = "",
    otherReferences = "",
    address,
    ...additionalAttributes
  } = {}) {
    this.vatApplication = vatApplication;
    this.intracommunityVatNumber = intracommunityVatNumber;
    this.modeOfPayment = modeOfPayment;
    this.termsOfPayment = termsOfPayment;
    this.rib = new RibFactory(rib);
    this.bic = bic;
    this.bankDomiciliation = bankDomiciliation;
    this.issuingFrequency = issuingFrequency;
    this.copiesNumber = copiesNumber;
    this.sendMode = sendMode;
    this.billingSendEmail = billingSendEmail;
    this.typeOfBilling = typeOfBilling;
    this.annualCommitmentVolume = annualCommitmentVolume;
    this.orderReference = orderReference;
    this.purchaseReference = purchaseReference;
    this.otherReferences = otherReferences;
    this.address = new AddressFactory(address);
    Object.keys(additionalAttributes).forEach((key) => {
      this[key] = this[key] || additionalAttributes[key];
    });
  }
}
