import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M12 8c-.186-.002-.364.077-.49.218L5.202 14.77c-.175.182-.243.447-.18.696.065.249.252.443.491.51.24.066.495-.005.67-.187L12 9.762l5.816 6.027c.175.182.43.253.67.186.24-.066.426-.26.49-.51.065-.248-.004-.513-.18-.695l-6.305-6.552c-.127-.141-.305-.22-.491-.218z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
