import React from "react";
import AclFront from "apps/front/AclFront";
import { withAccess } from "utils/Acl";
import MyApiDoc from "utils/MyApiDoc";
import openapi from "../infoTech/authentication-api_LIN.yaml";

const DocAPI = () => {
  return <MyApiDoc specUrl={openapi} />;
};

export default withAccess(AclFront.INFORMATION_TECH)(DocAPI);
