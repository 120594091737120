import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Snackbar from "components/Snackbar";
import PartnerService from "services/PartnerService";
import Button from "components/Button";
import PartnerShape from "models/shapes/PartnerShape";
import StatusEnum from "models/enums/StatusEnum";
import ModalHeader from "components/ModalHeader";
import WarningIcon from "icons/warning";

const PartnerStatusForm = (props) => {
  const { partner, onValidate, onCancel } = props;
  const { id, status } = partner;

  const [currentStatus, setCurrentStatus] = useState(partner.status);
  const theme = useTheme();

  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleClickValidate = useCallback(() => {
    if (status !== currentStatus) {
      PartnerService.update({ status: currentStatus, id })
        .then(() => {
          onValidate();
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message:
              "Une erreur est survenue lors du changement du statut du partenaire.",
            variant: "error",
          });
        });
    } else {
      onCancel();
    }
  }, [status, currentStatus, id, onCancel, onValidate]);

  const handleClickCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleChangeStatus = useCallback((e) => {
    setCurrentStatus(e.target.value);
  }, []);

  return (
    <div>
      <Box>
        <ModalHeader title="Statut" handleCloseModal={onCancel} />
        <Divider />
        <Box p={4}>
          <Box pb={3}>
            <Typography variant="h4" component={Box} mb={3}>
              Vous pouvez définir le statut du partenaire :
            </Typography>
            <RadioGroup
              defaultValue=""
              row
              name="status"
              value={currentStatus}
              onChange={handleChangeStatus}
            >
              {Object.values(StatusEnum).map((data) => (
                <FormControlLabel
                  key={data.name}
                  value={data.name}
                  disabled={
                    (status === StatusEnum.INACTIVE.name &&
                      data.name !== StatusEnum.INACTIVE.name) ||
                    (status === StatusEnum.ACTIVE.name &&
                      data.name === StatusEnum.TEST.name)
                  }
                  control={
                    <Radio
                      tabindex="0"
                      role="radio"
                      aria-checked={currentStatus === data.name}
                      onClick={() => setCurrentStatus(data.name)}
                      inputProps={{ tabIndex: -1 }}
                    />
                  }
                  label={data.label}
                />
              ))}
            </RadioGroup>
          </Box>
          {currentStatus === StatusEnum.INACTIVE.name &&
            status !== StatusEnum.INACTIVE.name && (
              <Typography
                sx={{
                  fontSize: "1.125rem",
                  mt: theme.spacing(0.5),
                  mb: theme.spacing(4.5),
                }}
                role="alert"
                color="error"
              >
                <Grid container spacing={3} wrap="nowrap" alignItems="center">
                  <Grid
                    item
                    sx={{
                      fontSize: "1.5em",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <WarningIcon />
                  </Grid>
                  <Grid item>
                    Attention, le passage au statut Inactif désactivera tous les
                    usages liés à cette fiche partenaire.
                  </Grid>
                </Grid>
              </Typography>
            )}
          {status !== StatusEnum.ACTIVE.name &&
            currentStatus === StatusEnum.ACTIVE.name && (
              <Grid
                container
                direction="column"
                alignItems="center"
                sx={{
                  p: theme.spacing(3),
                  borderRadius: 2.5,
                  mb: theme.spacing(4),
                  backgroundColor: theme.palette["secondary-light-background"],
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.125rem",
                    fontWeight: "400",
                  }}
                >
                  Vous devez configurer l&apos;environnement de production
                  d&apos;au moins un usage pour passer en actif
                </Typography>
              </Grid>
            )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button variant="text" onClick={handleClickCancel} sx={{ mr: 3 }}>
              <Typography variant="h5" component="span" color="inherit">
                Annuler
              </Typography>
            </Button>
            <Button
              color="primary"
              onClick={handleClickValidate}
              disabled={
                status !== StatusEnum.ACTIVE.name &&
                currentStatus === StatusEnum.ACTIVE.name
              }
            >
              <Typography variant="h5" component="span" color="inherit">
                Enregistrer
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </div>
  );
};

PartnerStatusForm.propTypes = {
  partner: PartnerShape.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PartnerStatusForm;
