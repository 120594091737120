/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import TcWrapper from "react-tag-commander";
import ResetIcon from "icons/update";
import ModalHeader from "components/ModalHeader";
import DeleteIcon from "icons/trash";
import Button from "components/Button";
import Link from "components/Link";
import { PeriodShape } from "components/PeriodsPicker/PeriodUtil";
import EditPeriod from "components/PeriodsPicker/EditPeriod";

const EditPeriodsForm = (props) => {
  const { periods, defaultPeriod, onValidate, onCancel } = props;

  const theme = useTheme();

  const [firstPeriod, setFirstPeriod] = useState(
    Array.isArray(periods) && periods[0],
  );
  const [secondPeriod, setSecondPeriod] = useState(
    Array.isArray(periods) && periods[1],
  );

  const wrapper = TcWrapper.getInstance();

  useEffect(() => {
    if (Array.isArray(periods)) {
      setFirstPeriod(periods[0]);
      setSecondPeriod(periods[1]);
    }
  }, [periods]);

  if (!Array.isArray(periods) || !periods[0]) {
    return null;
  }

  const handleClickReset = () => {
    setFirstPeriod(defaultPeriod);
    setSecondPeriod(
      secondPeriod !== undefined ? { ...defaultPeriod, init: true } : undefined,
    );
  };

  const handleClickValidate = (event) => {
    wrapper.triggerEvent("click", event.target, {
      clic_type: "action",
      name: "valider_gestion_periode",
      chapter1: "tableau_de_bord",
    });
    if (secondPeriod === undefined) {
      onValidate([firstPeriod]);
    } else {
      onValidate([firstPeriod, secondPeriod]);
    }
  };

  const handleClickDeleteSecondPeriod = () => {
    setSecondPeriod(undefined);
  };

  const handleClickCancel = () => {
    onCancel();
  };

  return (
    <>
      <ModalHeader
        title={secondPeriod ? "Gestion de période" : "Modifier la période"}
        handleCloseModal={onCancel}
      />
      <Divider />
      <Box p={3}>
        <Box pb={3} sx={{ display: "inline-block" }}>
          <Link
            component="button"
            onClick={handleClickReset}
            sx={{
              fontSize: "0.9rem",
              textDecoration: "none",
              fontWeight: "bold",
              fontFamily: "Montserrat",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item sx={{ fontWeight: 600 }}>
                {secondPeriod ? "Réinitialiser les périodes" : "Réinitialiser"}
              </Grid>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <ResetIcon />
              </Grid>
            </Grid>
          </Link>
        </Box>
        <EditPeriod
          period={firstPeriod}
          label={secondPeriod && "Période 1"}
          onPeriodChange={setFirstPeriod}
        />
        {secondPeriod && (
          <Box mt={3}>
            <EditPeriod
              period={secondPeriod}
              label={
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  alignItems="center"
                >
                  <Grid item>Période 2</Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      aria-label="Supprimer la deuxième période"
                      endIcon={<DeleteIcon color="secondary" />}
                      onClick={handleClickDeleteSecondPeriod}
                    >
                      <Typography
                        component="span"
                        variant="h5"
                        sx={{
                          color: theme.palette["secondary-lynch"],
                        }}
                      >
                        Supprimer
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              }
              secondary
              onPeriodChange={setSecondPeriod}
            />
          </Box>
        )}
      </Box>
      <Box px={3} py={4}>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "flex-end" }}
          spacing={3}
        >
          <Grid item onClick={handleClickCancel}>
            <Button variant="text" sx={{ padding: theme.spacing(1.75, 4.25) }}>
              <Typography
                sx={{ color: theme.palette["primaire-bleu"] }}
                component="span"
                variant="h5"
              >
                Annuler
              </Typography>
            </Button>
          </Grid>
          <Tooltip
            title={
              secondPeriod && secondPeriod.init
                ? "Merci de renseigner les valeurs de la période 2"
                : ""
            }
            placement="top-end"
          >
            <Grid item>
              <Button
                color="primary"
                onClick={(event) => handleClickValidate(event)}
                disabled={secondPeriod && secondPeriod.init}
              >
                <Typography
                  sx={{ color: theme.palette["secondary-white"] }}
                  component="span"
                  variant="h5"
                >
                  Enregistrer
                </Typography>
              </Button>
            </Grid>
          </Tooltip>
        </Grid>
      </Box>
    </>
  );
};

EditPeriodsForm.propTypes = {
  periods: PropTypes.arrayOf(PeriodShape).isRequired,
  defaultPeriod: PropTypes.shape(),
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

EditPeriodsForm.defaultProps = {
  defaultPeriod: null,
};

export default EditPeriodsForm;
