import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { monthFullNames } from "utils/date";

import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import ArrowDown from "icons/arrow_down";
import ArrowUp from "icons/arrow_up";
import ArrowLeft from "icons/arrow_left";
import ArrowRight from "icons/arrow_right";

const monthSelected = (theme) => ({
  backgroundColor: theme.palette["bleu-10"],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 1.5),
  margin: theme.spacing(0.5),
  "&:hover": {
    backgroundColor: theme.palette["bleu-10"],
  },
});

const monthUnselected = (theme) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 1.5),
  margin: theme.spacing(0.5),
  "&:hover": {
    backgroundColor: theme.palette["bleu-10"],
  },
});

const MONTHS = [
  { id: 0, abr: "Jan.", name: "Janvier" },
  { id: 1, abr: "Fév.", name: "Février" },
  { id: 2, abr: "Mars", name: "Mars" },
  { id: 3, abr: "Avr.", name: "Avril" },
  { id: 4, abr: "Mai", name: "Mai" },
  { id: 5, abr: "Juin", name: "Juin" },
  { id: 6, abr: "Jui.", name: "Juillet" },
  { id: 7, abr: "Août", name: "Août" },
  { id: 8, abr: "Sep.", name: "Septembre" },
  { id: 9, abr: "Oct.", name: "Octobre" },
  { id: 10, abr: "Nov.", name: "Novembre" },
  { id: 11, abr: "Déc.", name: "Décembre" },
];

const MonthPicker = (props) => {
  const { date, init, onDateChange } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());

  const open = Boolean(anchorEl);
  const id = open ? "calendar-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleIncrement = (increment) => {
    setYear(year + increment);
  };

  const handleMonthSelect = (m) => {
    onDateChange(new Date(year, m, 1));
    setAnchorEl(null);
  };

  useEffect(() => {
    setYear(date.getFullYear());
    setMonth(date.getMonth());
  }, [date, month]);

  const selectedDate = !init ? `${monthFullNames[month]} ${year}` : "-";

  return (
    <>
      <Button
        data-testid="monthdropbutton"
        sx={{
          boxShadow:
            "0 1px 10px 0 rgba(0, 0, 45, 0.06), 0 1px 4px 0 rgba(0, 0, 45, 0.1), 0 0 0 0 rgba(0, 0, 46, 0.06)",
          width: 260,
          borderRadius: (theme) => theme.spacing(1),
          justifyContent: "space-between",
          padding: "1rem 2rem",
        }}
        aria-describedby={id}
        aria-label={`Modifier la date ${selectedDate === "-" ? ", la date n'est pas encore sélectionée" : selectedDate}`}
        onClick={handleClick}
        endIcon={
          open ? <ArrowUp color="primary" /> : <ArrowDown color="primary" />
        }
      >
        <Typography
          component="span"
          variant="h5"
          color="text.primary"
          sx={{ fontWeight: 600 }}
        >
          {selectedDate}
        </Typography>
      </Button>
      <Popover
        data-testid="popover"
        sx={{ textAlign: "center" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ margin: "15px 0" }}>
          <IconButton
            sx={{ marginRight: (theme) => theme.spacing(4) }}
            aria-label="Décrémenter"
            onClick={() => handleIncrement(-1)}
            size="large"
          >
            <ArrowLeft color="primary" />
          </IconButton>
          <Typography component="span" variant="h4" color="text.primary">
            {year}
          </Typography>
          <IconButton
            sx={{ marginLeft: (theme) => theme.spacing(4) }}
            aria-label="Incrémenter"
            onClick={() => handleIncrement(1)}
            size="large"
          >
            <ArrowRight color="primary" />
          </IconButton>
        </div>
        <Divider />
        <Box p={2}>
          <Grid container sx={{ width: 270 }}>
            {MONTHS.map((m) => (
              <Grid item xs={3} key={m.id}>
                <Button
                  onClick={() => handleMonthSelect(m.id)}
                  sx={
                    m.id === month && year === date.getFullYear()
                      ? monthSelected
                      : monthUnselected
                  }
                >
                  <Typography
                    component="span"
                    variant="h5"
                    color="text.primary"
                    sx={{ fontWeight: 600 }}
                    aria-label={m.name}
                  >
                    {m.abr}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </>
  );
};

MonthPicker.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  init: PropTypes.bool.isRequired,
  onDateChange: PropTypes.func.isRequired,
};

export default MonthPicker;
