import React, { useMemo } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Route, Switch, useLocation } from "react-router-dom";
import keycloak from "keycloak";
import LoadingPage from "LoadingPage";
import AppBack from "apps/back/AppBack";
import AppFront from "apps/front/AppFront";
import LayoutNotConnected from "LayoutNotConnected";
import {
  getRoutes,
  getRoutesPaths,
  getAccountRoutes,
  getAccountRoutesPaths,
} from "routesNotConnected";
import { CurrentRouteContextProvider } from "components/CurrentRouteContext";
import Acl from "utils/Acl";

const resourceName = process.env.REACT_APP_keycloak_resource;

function AppRouter() {
  const { pathname } = useLocation();

  const routesNotConnected = useMemo(getRoutes, []);
  const routesPathsNotConnected = useMemo(getRoutesPaths, []);

  const accountRoutes = useMemo(getAccountRoutes, []);
  const accountRoutesPaths = useMemo(getAccountRoutesPaths, []);

  const handleKeycloakEvent = (eventName) => {
    if (eventName === "onAuthSuccess") {
      const { resourceAccess } = keycloak;
      const { [resourceName]: resource } = resourceAccess || {};
      const { roles = [] } = resource || {};
      Acl.setRoles(roles);
    }
  };

  return (
    <Switch color="secondary">
      {routesPathsNotConnected.includes(pathname) &&
        !keycloak.authenticated && (
          // Ces routes sont accesibles si un utilisateur n'est pas connecté (dans ce cas keycloak.authenticated a false comme valeur)
          // ou si la page est ouvert directement depuis son URL (dans ce cas keycloak.authenticated a undefined comme valeur)
          <CurrentRouteContextProvider routes={routesNotConnected}>
            <LayoutNotConnected>
              {routesNotConnected.map((route) => (
                <Route key={route.path} {...route} />
              ))}
            </LayoutNotConnected>
          </CurrentRouteContextProvider>
        )}
      {accountRoutesPaths.includes(pathname) && (
        <CurrentRouteContextProvider routes={accountRoutes}>
          <LayoutNotConnected>
            {accountRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </LayoutNotConnected>
        </CurrentRouteContextProvider>
      )}
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
          onLoad: "login-required",
          checkLoginIframe: false,
          pkceMethod: "S256",
        }}
        onEvent={handleKeycloakEvent}
        LoadingComponent={<LoadingPage title="Authentification" />}
      >
        {process.env.REACT_APP_APPLICATION === "front" && <AppFront />}
        {process.env.REACT_APP_APPLICATION === "back" && <AppBack />}
      </ReactKeycloakProvider>
    </Switch>
  );
}

export default AppRouter;
