import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path d="M11.834 20.667c4.878 0 8.833-3.955 8.833-8.833C20.667 6.955 16.712 3 11.834 3 6.955 3 3 6.955 3 11.834c0 4.878 3.955 8.833 8.834 8.833z" />
    <rect
      width="12.571"
      height="1.571"
      x="7"
      y="15.889"
      fill="#fff"
      rx=".786"
      transform="rotate(-45 7 15.889)"
    />
    <rect
      width="12.571"
      height="1.571"
      x="15.889"
      y="17"
      fill="#fff"
      rx=".786"
      transform="rotate(-135 15.89 17)"
    />
  </SvgIcon>
);
