import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EditOutlinedIcon from "icons/write";
import Circle from "components/Circle";
import IconButton from "@mui/material/IconButton";

const formatDate = (date) =>
  date && date.toLocaleDateString(undefined, {
    month: "short",
    year: "numeric",
  });

  const formatLongDate = (date) =>
    date && date.toLocaleDateString(undefined, {
      month: "long",
      year: "numeric",
    });

const Period = (props) => {
  const { period, isDashboard, onClickEdit, label, color } = props;
  const { startDate, endDate } = period;

  return (
    <Grid container spacing={1} alignItems="center" data-testid="period">
      {color && (
        <Grid item sx={{ fontSize: "0.60rem" }}>
          <Circle color={color} />
        </Grid>
      )}
      {label && (
        <Grid item>
          <Typography variant="subtitle1" component="div">
            {label}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Typography
          variant={isDashboard ? "h3" : "h4"}
          component="div"
          aria-label={`${formatLongDate(startDate)} - ${formatLongDate(endDate)}`}
        >
          {`${formatDate(startDate)} - ${formatDate(endDate)}`}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton
          data-testid="editiconbutton"
          aria-label="Modifier la période"
          sx={{ fontSize: "2em", p: 0 }}
          onClick={onClickEdit}
        >
          <EditOutlinedIcon color="action" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

Period.propTypes = {
  period: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  onClickEdit: PropTypes.func.isRequired,
  label: PropTypes.node,
  color: PropTypes.string,
  isDashboard: PropTypes.bool,
};

Period.defaultProps = {
  period: {},
  label: "",
  color: "",
  isDashboard: false,
};

export default Period;
