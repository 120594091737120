import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "icons/close";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const ModalHeader = (props) => {
const { title, handleCloseModal } = props;

const theme = useTheme();

return (
    <Grid
        container
        sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: theme.spacing(3, 4)
        }}
    >
        <Grid item xs={10}>
            <Typography variant="h3">{title}</Typography>
        </Grid>
        <Grid item xs="auto">
            <IconButton
                onClick={handleCloseModal}
                aria-label="Fermer"
                sx={{
                    fontSize: "1.45em",
                    p: 0,
                }}
            >
                <CloseIcon color="primary" />
            </IconButton>
        </Grid>
    </Grid>
);
};

ModalHeader.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    handleCloseModal: PropTypes.func.isRequired
};

export default ModalHeader;
