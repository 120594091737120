import React, { useContext } from "react";
import Link from "components/Link";
import { useTheme } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CurrentRouteContext from "components/CurrentRouteContext";

const Breadcrumb = () => {
  const theme = useTheme();

  const { title, currentRoute } = useContext(CurrentRouteContext);

  const { parent } = currentRoute || {};

  return (
    <Breadcrumbs separator=">" aria-label="Fil d'ariane">
      <Link
        to={parent.path}
        style={{
          color: theme.palette["cobalt-marque-blanche-main"],
        }}
        underline="hover"
      >
        <Typography variant="subtitle2" component="span">
          {parent.title}
        </Typography>
      </Link>
      {title && (
        <Typography variant="subtitle2" component="span">
          {title}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
