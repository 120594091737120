import React from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import PartnerShape from "models/shapes/PartnerShape";
import Form from "components/Form";
import InputField from "components/InputField";
import { isRequired, inputAdditionalMessage } from "utils/enum";

const CompanyDetailsForm = (props) => {
  const {
    isActive,
    company,
    namePrefix,
    touched,
    errors,
    schema,
    handleClick,
    handleChange,
    handleBlur,
    ...others
  } = props;

  const { billing } = company;

  return (
    <Form
      content={[
        {
          key: "companyName/siretNumber",
          firstElement: (
            <InputField title="Raison sociale">
              <TextField
                name={`${namePrefix}companyName`}
                error={touched.companyName && Boolean(errors.companyName)}
                helperText={touched.companyName && errors.companyName}
                variant="outlined"
                value={company.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-invalid": Boolean(
                    touched.companyName && errors.companyName,
                  ),
                  "aria-required": isRequired(
                    schema,
                    `${namePrefix}companyName`,
                  ),
                  "aria-labelledby": "raison_sociale",
                  "aria-describedby": "ht_rs",
                }}
                FormHelperTextProps={{
                  id: "ht_rs",
                }}
              />
            </InputField>
          ),
          secondElement: (
            <InputField title="N° SIRET">
              <TextField
                name={`${namePrefix}siretNumber`}
                error={touched.siretNumber && Boolean(errors.siretNumber)}
                helperText={touched.siretNumber && errors.siretNumber}
                variant="outlined"
                value={company.siretNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-invalid": Boolean(
                    touched.siretNumber && errors.siretNumber,
                  ),
                  "aria-required": isRequired(
                    schema,
                    `${namePrefix}siretNumber`,
                  ),
                  "aria-labelledby": "n°_siret",
                  "aria-describedby": "ht_siret",
                }}
                FormHelperTextProps={{
                  id: "ht_siret",
                }}
              />
            </InputField>
          ),
        },
        {
          key: "vatApplication/intracommunityVatNumber",
          firstElement: (
            <InputField title="Application de la TVA">
              {touched.billing?.vatApplication &&
                Boolean(errors.billing?.vatApplication) && (
                  <Typography
                    color="error"
                    sx={{ fontSize: "0.75rem" }}
                    id="ht_application_tva"
                  >
                    {errors.billing?.vatApplication}
                  </Typography>
                )}
              <RadioGroup
                defaultValue=""
                row
                name={`${namePrefix}billing.vatApplication`}
                value={billing.vatApplication}
                onChange={handleChange}
                aria-invalid={Boolean(
                  touched.vatApplication && errors.vatApplication,
                )}
                aria-required={isRequired(
                  schema,
                  `${namePrefix}billing.vatApplication`,
                )}
                aria-labelledby="application_de_la_tva"
                aria-describedby="ht_application_tva"
              >
                <FormControlLabel
                  value="true"
                  checked={billing.vatApplication === true}
                  control={
                    <Radio
                      tabindex="0"
                      role="radio"
                      aria-checked={billing.vatApplication === true}
                      onClick={() =>
                        handleClick(`${namePrefix}billing.vatApplication`, true)
                      }
                      inputProps={{ tabIndex: -1 }}
                    />
                  }
                  label="Oui"
                />
                <FormControlLabel
                  value="false"
                  checked={billing.vatApplication === false}
                  control={
                    <Radio
                      tabindex="0"
                      role="radio"
                      aria-checked={billing.vatApplication === false}
                      onClick={() =>
                        handleClick(
                          `${namePrefix}billing.vatApplication`,
                          false,
                        )
                      }
                      inputProps={{ tabIndex: -1 }}
                    />
                  }
                  label="Non"
                />
              </RadioGroup>
            </InputField>
          ),
          secondElement: (
            <InputField
              title="N° TVA intracommunautaire"
              inputAdditionalMessage={inputAdditionalMessage(
                "intracommunityVatNumber",
                isActive,
              )}
            >
              <TextField
                variant="outlined"
                name={`${namePrefix}billing.intracommunityVatNumber`}
                value={billing.intracommunityVatNumber}
                error={
                  touched.billing &&
                  touched.billing.intracommunityVatNumber &&
                  Boolean(
                    errors.billing && errors.billing.intracommunityVatNumber,
                  )
                }
                helperText={
                  touched.billing?.intracommunityVatNumber &&
                  errors.billing?.intracommunityVatNumber
                }
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  "aria-invalid": Boolean(
                    touched.intracommunityVatNumber &&
                      errors.intracommunityVatNumber,
                  ),
                  "aria-labelledby": "n°_tva_intracommunautaire",
                  "aria-describedby": "ht_tva",
                }}
                FormHelperTextProps={{
                  id: "ht_tva",
                }}
              />
            </InputField>
          ),
        },
      ]}
      {...others}
    />
  );
};

CompanyDetailsForm.propTypes = {
  company: PartnerShape.isRequired,
  namePrefix: PropTypes.string,
  touched: PropTypes.shape(),
  errors: PropTypes.shape(),
  isActive: PropTypes.bool,
  schema: PropTypes.shape(),
  handleClick: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

CompanyDetailsForm.defaultProps = {
  namePrefix: "",
  touched: {},
  errors: {},
  isActive: false,
  schema: {},
};

export default CompanyDetailsForm;
