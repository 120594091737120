export const handleEnterKeyPress = (event, targetFunction, data) => {
  if (event.key === "Enter") {
    targetFunction(data);
  }
};

export const handleSpaceKeyPress = (event, targetFunction, data) => {
  if (event.key === " ") {
    targetFunction(data);
  }
};

export default { handleEnterKeyPress, handleSpaceKeyPress };
