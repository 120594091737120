import axios from "axios";

export default class TechDataService {
  static getKeyValues = (id, params) => {
    return axios
      .get(`${process.env.REACT_APP_api_url}/techDatas/${id}/keyValues`, params)
      .then((response) => {
        return response && response.data;
      });
  };

  static getKeyValue = (techDataId, id, params) => {
    return axios
      .get(
        `${process.env.REACT_APP_api_url}/techDatas/${techDataId}/keyValues/${id}`,
        params,
      )
      .then((response) => {
        return response && response.data;
      });
  };
}
