import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import logo from "media/illu-compte-active.svg";
import PropTypes from "prop-types";
import keycloak from "keycloak";
import historyApp from "historyApp";

const AccountAssociated = (props) => {
  const { front } = props;

  useEffect(() => {
    if (keycloak.authenticated) {
      keycloak.logout();
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          mt: 9,
          mb: 4,
        }}
      >
        <Typography
          variant="h1"
          color="text.primary"
          sx={{
            textAlign: "center",
          }}
        >
          Accédez à l’Espace {front ? "Partneaire" : "Administrateur"} de
          L’Identité Numérique
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "365px",
            mt: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <img
              height="148px"
              width="148px"
              src={logo}
              aria-hidden="true"
              alt="Icône compte activé"
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette["secondary-ultramarine"],
              mb: 3,
              textAlign: "center",
            }}
          >
            Votre compte {front ? "partenaire" : "administrateur"} est
            maintenant activé.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => historyApp.push("/")}
              sx={{
                width: "100%",
              }}
            >
              Se connecter
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

AccountAssociated.propTypes = {
  front: PropTypes.bool.isRequired,
};

export default AccountAssociated;
