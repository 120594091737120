export default {
  AFGHANISTAN: "Afghanistan",
  AFRIQUE_DU_SUD: "Afrique du Sud",
  ALBANIE: "Albanie",
  ALGERIE: "Algerie",
  ALLEMAGNE: "Allemagne",
  ANDORRE: "Andorre",
  ANGOLA: "Angola",
  ANGUILLA: "Anguilla",
  ANTIGUA_ET_BARBUDA: "Antigua-et-Barbuda",
  ANTILLES_NEERLANDAISES: "Antilles neerlandaises",
  ARABIE_SAOUDITE: "Arabie Saoudite",
  ARGENTINE: "Argentine",
  ARMENIE: "Armenie",
  ARUBA: "Aruba",
  AUSTRALIE: "Australie",
  AUTRICHE: "Autriche",
  AZERBAIDJAN: "Azerbaidjan",
  BAHAMAS: "Bahamas",
  BAHREIN: "Bahrein",
  BANGLADESH: "Bangladesh",
  BARBADE: "Barbade",
  BELGIQUE: "Belgique",
  BELIZE: "Belize",
  BENIN: "Benin",
  BERMUDES: "Bermudes",
  BHOUTAN: "Bhoutan",
  BIELORUSSIE: "Bielorussie",
  BIRMANIE: "Birmanie (Myanmar)",
  BOLIVIE: "Bolivie",
  BOSNIE_HERZEGOVINE: "Bosnie-Herzegovine",
  BOTSWANA: "Botswana",
  BRESIL: "Bresil",
  BRUNEI_DARUSSALAM: "Brunei Darussalam",
  BULGARIE: "Bulgarie",
  BURKINA_FASO: "Burkina Faso",
  BURUNDI: "Burundi",
  CAMBODGE: "Cambodge",
  CANADA: "Canada",
  CAP_VERT: "Cap-Vert",
  CHILI: "Chili",
  CHINE: "Chine",
  CHYPRE: "Chypre",
  COLOMBIE: "Colombie",
  COMORES: "Comores",
  CONGO: "Congo",
  COREE_DU_NORD: "Coree du Nord",
  COREE_DU_SUD: "Coree du Sud",
  COSTA_RICA: "Costa Rica",
  COTE_D_IVOIRE: "Cote d Ivoire",
  CROATIE: "Croatie",
  CUBA: "Cuba",
  CURACAO: "Curacao",
  DANEMARK: "Danemark",
  DJIBOUTI: "Djibouti",
  DOMINIQUE: "Dominique",
  EGYPTE: "Egypte",
  EL_SALVADOR: "El Salvador",
  EMIRATS_ARABES_UNIS: "Emirats Arabes Unis",
  EQUATEUR: "Equateur",
  ERYTHREE: "Erythree",
  ESPAGNE: "Espagne",
  ESTONIE: "Estonie",
  ETATS_UNIS: "Etats-Unis",
  ETAT_LIBRE_ASSOCIE_D_ALAND: "Etat libre associe d Aland",
  ETHIOPIE: "Ethiopie",
  FIDJI: "Fidji",
  FINLANDE: "Finlande",
  FRANCE: "France",
  GABON: "Gabon",
  GAMBIE: "Gambie",
  GEORGIE: "Georgie",
  GHANA: "Ghana",
  GIBRALTAR: "Gibraltar",
  GRECE: "Grece",
  GRENADE: "Grenade",
  GROENLAND: "Groenland",
  GUADELOUPE: "Guadeloupe",
  GUAM: "Guam",
  GUATEMALA: "Guatemala",
  GUERNESEY: "Guernesey",
  GUINEE: "Guinee",
  GUINEE_BISSAU: "Guinee-Bissau",
  GUINEE_EQUATORIALE: "Guinee equatoriale",
  GUYANE: "Guyane",
  HAITI: "Haiti",
  HONDURAS: "Honduras",
  HONGRIE: "Hongrie",
  HONG_KONG: "Hong Kong",
  ILES_COOK: "Iles Cook",
  ILES_FEROE: "Iles Feroe",
  ILES_MARSHALL: "Iles Marshall",
  ILES_SALOMON: "Iles Salomon",
  ILE_DE_MAN: "Ile de Man",
  INDE: "Inde",
  INDONESIE: "Indonesie",
  IRAK: "Irak",
  IRAN: "Iran",
  IRLANDE: "Irlande",
  ISLANDE: "Islande",
  ISRAEL: "Israel",
  ITALIE: "Italie",
  JAMAIQUE: "Jamaique",
  JAPON: "Japon",
  JERSEY: "Jersey",
  JORDANIE: "Jordanie",
  KAZAKHSTAN: "Kazakhstan",
  KENYA: "Kenya",
  KIRGHIZISTAN: "Kirghizistan",
  KIRIBATI: "Kiribati",
  KOWEIT: "Koweit",
  LAOS: "Laos",
  LA_REUNION: "La Reunion",
  LESOTHO: "Lesotho",
  LETTONIE: "Lettonie",
  LIBAN: "Liban",
  LIBERIA: "Liberia",
  LIBYE: "Libye",
  LIECHTENSTEIN: "Liechtenstein",
  LITUANIE: "Lituanie",
  LUXEMBOURG: "Luxembourg",
  MACAO: "Macao",
  MACEDOINE: "Macedoine",
  MADAGASCAR: "Madagascar",
  MALAISIE: "Malaisie",
  MALAWI: "Malawi",
  MALDIVES: "Maldives",
  MALI: "Mali",
  MALTE: "Malte",
  MAROC: "Maroc",
  MARTINIQUE: "Martinique",
  MAURICE: "Maurice",
  MAURITANIE: "Mauritanie",
  MAYOTTE: "Mayotte",
  MEXIQUE: "Mexique",
  MOLDAVIE: "Moldavie",
  MONACO: "Monaco",
  MONGOLIE: "Mongolie",
  MONTENEGRO: "Montenegro",
  MONTSERRAT: "Montserrat",
  MOZAMBIQUE: "Mozambique",
  NAMIBIE: "Namibie",
  NAURU: "Nauru",
  NEPAL: "Nepal",
  NICARAGUA: "Nicaragua",
  NIGER: "Niger",
  NIGERIA: "Nigeria",
  NIUE: "Niue",
  NORVEGE: "Norvege",
  NOUVELLE_CALEDONIE: "Nouvelle-Caledonie",
  NOUVELLE_ZELANDE: "Nouvelle-Zelande",
  OMAN: "Oman",
  OUGANDA: "Ouganda",
  OUZBEKISTAN: "Ouzbekistan",
  PAKISTAN: "Pakistan",
  PALAOS: "Palaos",
  PALESTINE: "Palestine",
  PANAMA: "Panama",
  PAPOUASIE_NOUVELLE_GUINEE: "Papouasie-Nouvelle-Guinee",
  PARAGUAY: "Paraguay",
  PAYS_BAS: "Pays-Bas",
  PAYS_BAS_CARIBEENS: "Pays-Bas caribeens",
  PEROU: "Perou",
  PHILIPPINES: "Philippines",
  POLOGNE: "Pologne",
  POLYNESIE_FRANCAISE: "Polynesie francaise",
  PORTO_RICO: "Porto Rico",
  PORTUGAL: "Portugal",
  QATAR: "Qatar",
  REPUBLIQUE_CENTRAFRICAINE: "Republique Centrafricaine",
  REPUBLIQUE_DEMOCRATIQUE_DU_CON: "Republique democratique du Congo",
  REPUBLIQUE_DOMINICAINE: "Republique dominicaine",
  REPUBLIQUE_DU_CAMEROUN: "Republique du Cameroun",
  REPUBLIQUE_DU_GUYANA: "Republique du Guyana",
  REPUBLIQUE_TCHEQUE: "Republique Tcheque",
  ROUMANIE: "Roumanie",
  ROYAUME_UNI: "Royaume-Uni",
  RUSSIE: "Russie",
  RWANDA: "Rwanda",
  SAINTE_HELENE: "Sainte-Helene",
  SAINTE_LUCIE: "Sainte-Lucie",
  SAINT_BARTHELEMY: "Saint-Barthelemy",
  SAINT_CHRISTOPHE_ET_NIEVES: "Saint Christophe et Nieves",
  SAINT_MARIN: "Saint-Marin",
  SAINT_PIERRE_ET_MIQUELON: "Saint Pierre et Miquelon",
  SAINT_VINCENT_ET_LES_GRENADINE: "Saint-Vincent et les Grenadine",
  SAMOA: "Samoa",
  SAO_TOME_ET_PRINCIPE: "Sao Tome-et-Principe",
  SENEGAL: "Senegal",
  SERBIE: "Serbie",
  SEYCHELLES: "Seychelles",
  SIERRA_LEONE: "Sierra Leone",
  SINGAPOUR: "Singapour",
  SLOVAQUIE: "Slovaquie",
  SLOVENIE: "Slovenie",
  SOMALIE: "Somalie",
  SOUDAN: "Soudan",
  SOUDAN_DU_SUD: "Soudan du Sud",
  SRI_LANKA: "Sri Lanka",
  SUEDE: "Suede",
  SUISSE: "Suisse",
  SURINAME: "Suriname",
  SWAZILAND: "Swaziland",
  SYRIE: "Syrie",
  TADJIKISTAN: "Tadjikistan",
  TAIWAN: "Taiwan",
  TANZANIE: "Tanzanie",
  TCHAD: "Tchad",
  THAILANDE: "Thailande",
  TIMOR_ORIENTAL: "Timor oriental",
  TOGO: "Togo",
  TOKELAU: "Tokelau",
  TONGA: "Tonga",
  TRINITE_ET_TOBAGO: "Trinite-et-Tobago",
  TUNISIE: "Tunisie",
  TURKMENISTAN: "Turkmenistan",
  TURQUIE: "Turquie",
  TUVALU: "Tuvalu",
  UKRAINE: "Ukraine",
  URUGUAY: "Uruguay",
  VANUATU: "Vanuatu",
  VATICAN: "Vatican",
  VENEZUELA: "Venezuela",
  VIET_NAM: "Viet Nam",
  WALLIS_ET_FUTUNA: "Wallis-et-Futuna",
  YEMEN: "Yemen",
  ZAMBIE: "Zambie",
  ZIMBABWE: "Zimbabwe",
};
