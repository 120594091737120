import React from "react";
import PropTypes from "prop-types";
import Loader from "components/Loader";
import { Box } from "@mui/material";

const LoadingPage = (props) => {
  const { title } = props;

  return (
    <Box
      sx={{
        height: "100vh",
        fontSize: "1.3rem",
      }}
    >
      <Loader title={title} />
    </Box>
  );
};

LoadingPage.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LoadingPage;
