import PropTypes from "prop-types";

const RibShape = PropTypes.shape({
  bankCode: PropTypes.string,
  counterCode: PropTypes.string,
  accountNumber: PropTypes.string,
  key: PropTypes.string,
});

export default RibShape;
