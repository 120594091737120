export const download = ({ data, fileName, type }) => {
  const file = new Blob([data], {
    type: type || "application/octet-stream",
  });
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(file, fileName);
  } else {
    const fileURL = URL.createObjectURL(file);
    const element = document.createElement("a");
    element.setAttribute("href", fileURL);
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
};

export const dataURLtoFile = async (content, fileName = "name") => {
  const arr = content.split(",");
  const contentType = arr[0].match(/:(.*?);/)[1];
  const res = await fetch(content);
  const blob = await res.blob();
  return new File([blob], fileName, { type: contentType });
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });

export default {
  download,
  dataURLtoFile,
  toBase64,
};
