import * as Yup from "yup";
import CountryEnum from "models/enums/CountryEnum";

const AddressSchema = Yup.object().shape({
  department: Yup.string().nullable(),
  streetNb: Yup.string().nullable(),
  street: Yup.string().nullable(),
  city: Yup.string().nullable(),
  zipCode: Yup.string()
    .transform((value) => (!value ? null : value))
    .matches(/^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/, "Format incorrect")
    .nullable(),
  country: Yup.string()
    .oneOf(
      [...Object.values(CountryEnum), null, ""],
      "Veuillez sélectionner un pays de la liste",
    )
    .nullable(),
});

export default AddressSchema;
