import Dashboard from "apps/back/pages/dashboard/Dashboard";
import Partners from "apps/back/pages/partners/Partners";
import CreatePartnerAccount from "apps/back/pages/partners/CreatePartnerAccount";
import EditPartnerAccount from "apps/back/pages/partners/EditPartnerAccount";
import ManageUsers from "apps/front/users/ManageUsers";
import Partner from "apps/back/pages/partners/Partner";
import AclBack from "apps/back/AclBack";
import CGU from "CGU";
import Acl from "utils/Acl";
import MentionsLegales from "MentionsLegales";
import Accessibilite from "Accessibilite";
import AccountAssociatedBack from "AccountAssociatedBack";

const routes = {
  DASHBOARD: {
    title: "Tableau de bord",
    path: "/",
    component: Dashboard,
    exact: true,
    hasAccess: () => Acl.hasAccess(AclBack.DASHBOARD_INDIRECT_SALE, Acl.READ),
  },
  PARTNERS: {
    title: "Mes partenaires",
    path: "/listing-partenaires",
    component: Partners,
    hasAccess: () =>
      Acl.hasAccess(AclBack.PARTNERS_LISTING_INDIRECT_SALE, Acl.READ),
  },
  USERS: {
    title: "Gestion des utilisateurs",
    path: "/gestion-utilisateurs",
    component: ManageUsers,
    hasAccess: () => Acl.hasAccess(AclBack.ADMIN_USERS, Acl.READ),
  },
  CGU: {
    title: "CGU",
    path: "/cgu",
    component: CGU,
    hasAccess: () => true,
  },
  MentionsLegales: {
    title: "MentionsLegales",
    path: "/mentions-legales",
    component: MentionsLegales,
    hasAccess: () => true,
  },
  Accessibilite: {
    title: "Accessibilite",
    path: "/accessibilite",
    component: Accessibilite,
    hasAccess: () => true,
  },
  AccountAssociatedBack: {
    title: "AccountAssociatedBack",
    path: "/account-associated-administrator",
    component: AccountAssociatedBack,
    hasAccess: () => true,
  },
};

routes.CREATE_PARTNER_ACCOUNT = {
  path: "/creation-partenaire",
  component: CreatePartnerAccount,
  hasAccess: () => Acl.hasAccess(AclBack.PARTNERS_DETAILS, Acl.CREATE),
  parent: routes.PARTNERS,
};

routes.UPDATE_PARTNER_ACCOUNT = {
  path: "/edition-partenaire/:partnerId",
  component: EditPartnerAccount,
  hasAccess: () => Acl.hasAccess(AclBack.PARTNERS_DETAILS, Acl.UPDATE),
  parent: routes.PARTNERS,
};

routes.PARTNER = {
  path: "/partenaire/:partnerId",
  component: Partner,
  hasAccess: () => Acl.hasAccess(AclBack.PARTNERS_DETAILS, Acl.READ),
  parent: routes.PARTNERS,
};

export default routes;

export const getRoutes = () =>
  Object.values(routes)
    .filter((route) => route.hasAccess())
    .map((route) => ({ ...route, hasAccess: undefined }));

const menu = [routes.DASHBOARD, routes.PARTNERS, routes.USERS];

export const getMenu = () => menu.filter((route) => route.hasAccess());
