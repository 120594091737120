import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M12 5.453c3.616 0 6.547 2.931 6.547 6.547 0 3.616-2.931 6.547-6.547 6.547-3.616 0-6.547-2.931-6.547-6.547 0-3.616 2.931-6.547 6.547-6.547zM12 4c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8z"
      clipRule="evenodd"
    />
    <path d="M12.667 15.427c0 .368-.299.666-.667.666-.368 0-.667-.298-.667-.666v-4c0-.368.299-.667.667-.667.368 0 .667.299.667.667v4z" />
    <path
      fillRule="evenodd"
      d="M12 9.64c.479 0 .867-.388.867-.867 0-.478-.388-.866-.867-.866s-.867.388-.867.866c0 .479.388.867.867.867z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
