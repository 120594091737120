import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MonthPicker from "components/PeriodsPicker/MonthPicker";

const DateForm = (props) => {
  const { label, date, init, onDateChange } = props;

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid data-testid="label" item>
        <Typography variant="h5" component="div" color="text.primary">
          {label}
        </Typography>
      </Grid>
      <Grid item md>
        <MonthPicker date={date} init={init} onDateChange={onDateChange} />
      </Grid>
    </Grid>
  );
};

DateForm.propTypes = {
  label: PropTypes.node,
  date: PropTypes.instanceOf(Date).isRequired,
  init: PropTypes.bool.isRequired,
  onDateChange: PropTypes.func.isRequired,
};

DateForm.defaultProps = {
  label: "",
};

export default DateForm;
