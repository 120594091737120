import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

const InputField = (props) => {
  const { title, required, inputAdditionalMessage, children, secondary } = props;

  return (
    <Box sx={{ marginBottom: "2.25rem" }}>
      <Typography
        sx={(theme) => ({
          fontFamily: "Barlow",
          color: secondary
            ? theme.palette["cobalt-marque-blanche-main"]
            : theme.palette["secondary-ultramarine"],
          fontWeight: 500,
          fontSize: "0.875rem",
          letterSpacing: "normal",
          margin: "0.5rem 0",
        })}
        id={title.toLowerCase().replace(/ /g, "_")}
      >
        {title}
        {inputAdditionalMessage && <Box sx={visuallyHidden}>, {inputAdditionalMessage}</Box>}
        {required && <Box sx={visuallyHidden}>, champ obligatoire</Box>}
      </Typography>
      {children}
    </Box>
  );
};

InputField.propTypes = {
  title: PropTypes.string.isRequired,
  inputAdditionalMessage: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
};

InputField.defaultProps = {
  inputAdditionalMessage: null,
  required: false,
  secondary: false,
};

export default InputField;
