/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Link from "components/Link";
import Modal from "components/Modal";
import Snackbar from "components/Snackbar";
import {
  areSamePeriods,
  PeriodShape,
} from "components/PeriodsPicker/PeriodUtil";
import { convertToMonth } from "utils/date";
import EditPeriodsForm from "components/PeriodsPicker/EditPeriodsForm";
import Period from "components/PeriodsPicker/Period";
import CalendarIcon from "icons/calendar";
import TcWrapper from "react-tag-commander";

const PeriodsPicker = (props) => {
  const { periods, defaultPeriod, onPeriodsChange, isDashboard } = props;

  const theme = useTheme();

  const [modalPeriodsOpened, setModalPeriodsOpened] = useState(false);
  const [snackbarOpened, setSnackbarOpened] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentPeriods, setCurrentPeriods] = useState(periods);
  const wrapper = TcWrapper.getInstance();

  useEffect(() => {
    setCurrentPeriods(periods);
  }, [periods]);

  useEffect(() => {
    if (defaultPeriod && (!Array.isArray(periods) || !periods[0])) {
      onPeriodsChange([defaultPeriod]);
    }
  }, [defaultPeriod, onPeriodsChange, periods]);

  if (!Array.isArray(currentPeriods) || !currentPeriods[0]) {
    return null;
  }

  const [firstPeriod, secondPeriod] = currentPeriods;

  const handleClickAddPeriod = (event) => {
    wrapper.triggerEvent("click", event.target, {
      clic_type: "action",
      name: "ouverture_gestion_periode",
      chapter1: "tableau_de_bord",
    });
    setCurrentPeriods([firstPeriod, { ...firstPeriod, init: true }]);
    setModalPeriodsOpened(true);
  };

  const handleClickEditPeriod = () => {
    setModalPeriodsOpened(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpened(false);
  };

  const closePeriodsModal = () => {
    setModalPeriodsOpened(false);
  };

  const handleValidatePeriods = (changedPeriods) => {
    if (
      changedPeriods.length !== currentPeriods.length ||
      (currentPeriods[1] && currentPeriods[1].init) ||
      changedPeriods.some(
        (period, index) => !areSamePeriods(period, currentPeriods[index]),
      )
    ) {
      if (changedPeriods.length === 2) {
        const durationP1 = convertToMonth(
          changedPeriods[0].startDate,
          changedPeriods[0].endDate,
        );
        const durationP2 = convertToMonth(
          changedPeriods[1].startDate,
          changedPeriods[1].endDate,
        );
        if (durationP1 !== durationP2) {
          setSnackbarMessage("Les périodes doivent être de la même durée");
          setSnackbarOpened(true);
        } else if (durationP1 >= 12) {
          setSnackbarMessage(
            "Pour des données comparatives, les 2 périodes de comparaison doivent être inférieures à 12 mois",
          );
          setSnackbarOpened(true);
        } else {
          onPeriodsChange(changedPeriods);
          closePeriodsModal();
        }
      } else {
        onPeriodsChange(changedPeriods);
        closePeriodsModal();
      }
    } else {
      closePeriodsModal();
    }
  };

  const handleCloseWihoutSaving = () => {
    setCurrentPeriods(periods);
    closePeriodsModal();
  };

  return (
    <>
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: secondPeriod ? "flex-start" : "space-between",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
              marginBottom: theme.spacing(2),
            },
          }}
        >
          <Period
            label={secondPeriod && "Période 1 :"}
            period={firstPeriod}
            color={theme.palette["bleu-80"]}
            onClickEdit={handleClickEditPeriod}
            isDashboard={isDashboard}
          />
        </Grid>
        {secondPeriod && !secondPeriod.init && (
          <Grid item xs={12} md={6}>
            <Period
              label="Période 2 :"
              period={secondPeriod}
              color={theme.palette.secondary.main}
              onClickEdit={handleClickEditPeriod}
              isDashboard={isDashboard}
            />
          </Grid>
        )}
        {!secondPeriod && (
          <Grid item>
            <Link
              data-testid="addperiod"
              component="button"
              onClick={(event) => handleClickAddPeriod(event)}
              sx={{
                fontSize: "0.9rem",
                textDecoration: "none",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                "&:hover": {
                  textDecoration: "underline"
                }
              }}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item>Comparer deux périodes</Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <CalendarIcon />
                </Grid>
              </Grid>
            </Link>
          </Grid>
        )}
      </Grid>
      <Modal
        data-testid="modal"
        open={modalPeriodsOpened}
        onClose={handleCloseWihoutSaving}
      >
        <EditPeriodsForm
          periods={currentPeriods}
          defaultPeriod={defaultPeriod}
          onValidate={handleValidatePeriods}
          onCancel={handleCloseWihoutSaving}
        />
      </Modal>
      <Snackbar
        open={snackbarOpened}
        onClose={closeSnackbar}
        message={snackbarMessage}
        variant="error"
        duration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
};

PeriodsPicker.propTypes = {
  periods: PropTypes.arrayOf(PeriodShape),
  onPeriodsChange: PropTypes.func.isRequired,
  defaultPeriod: PropTypes.shape(),
  isDashboard: PropTypes.bool,
};

PeriodsPicker.defaultProps = {
  periods: [],
  defaultPeriod: null,
  isDashboard: false,
};

export default PeriodsPicker;
