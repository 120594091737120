import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M16 12c.002.186-.077.364-.218.49L9.23 18.797c-.182.175-.447.244-.696.18-.249-.065-.443-.252-.51-.491-.066-.24.005-.495.187-.67L14.238 12 8.211 6.184c-.182-.175-.253-.43-.186-.67.066-.24.26-.426.51-.49.248-.064.513.004.695.18l6.552 6.305c.141.127.22.305.218.491z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
