import React, { useEffect } from "react";
import AccountAssociated from "AccountAssociated";
import historyApp from "historyApp";

const AccountAssociatedFront = () => {
  useEffect(() => {
    historyApp.push("/account-associated-partner-not-connected");
  }, []);

  return <AccountAssociated front />;
};

export default AccountAssociatedFront;
