import PropTypes from "prop-types";
import ApplicationStatShape from "models/shapes/ApplicationStatShape";
import BusinessLineStatShape from "models/shapes/BusinessLineStatShape";

const StatShape = PropTypes.shape({
  applications: PropTypes.arrayOf(ApplicationStatShape).isRequired,
  business_lines: PropTypes.arrayOf(BusinessLineStatShape).isRequired,
  total: PropTypes.shape({
    applications_number: PropTypes.number.isRequired,
    unique_users: PropTypes.number.isRequired,
    uses: PropTypes.number.isRequired,
  }).isRequired,
});

export default StatShape;
