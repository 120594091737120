import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Divider } from "@mui/material";
import PageTitle from "components/PageTitle";
import Paper from "components/Paper";
import PeriodsPicker from "components/PeriodsPicker";
import UsesFilterCard from "components/UsesFilterCard";
import Loader from "components/Loader";
import StatService from "services/StatService";
import {
  getDefaultPeriodForLicenceBilling,
  getDefaultPeriod,
} from "components/PeriodsPicker/PeriodUtil";
import { getUsesFromStats } from "utils/StatsUtil";
import { formatDateToYYYYMMDD } from "utils/date";
import AclFront from "apps/front/AclFront";
import { useKeycloak } from "@react-keycloak/web";
import { DashboardStatConso } from "components/stats/StatConso";
import { DashboardStatUsers } from "components/stats/StatUsers";
import { withAccess } from "utils/Acl";
import PartnerService from "services/PartnerService";
import { DashboardApplicationsClassification } from "components/stats/ApplicationsClassification";
import { DashboardStatTitleWithExport } from "components/stats/StatTitleWithExport";
import Snackbar from "components/Snackbar";
import BillingTypeEnum from "models/enums/BillingTypeEnum";
import StatusEnum from "models/enums/StatusEnum";

const Dashboard = () => {
  const theme = useTheme();

  const [periods, setPeriods] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [defaultPeriod, setDefaultPeriod] = useState(null);
  const { keycloak } = useKeycloak();
  const [isConsolidated, setIsConsolidated] = useState(false);
  const [partner, setPartner] = useState({});
  const [partnerId, setPartnerId] = useState(null);
  const [usesFilter, setUsesFilter] = useState([]);

  useEffect(() => {
    if (keycloak.authenticated) {
      const loadProfile = async () => {
        await keycloak.loadUserProfile();
        setPartnerId(keycloak?.profile?.attributes?.partnerId?.[0]);
      };
      loadProfile();
    }
  }, [keycloak]);

  const getStats = useCallback(() => {
    setIsLoading(true);
    if (Array.isArray(periods) && periods.length > 0) {
      const resultsPromise = [];
      periods.forEach((period) => {
        if (Object.keys(period).length > 0) {
          resultsPromise.push(
            StatService.getStats({
              params: {
                type: "use",
                lowerDate: formatDateToYYYYMMDD(period.startDate),
                upperDate: formatDateToYYYYMMDD(period.endDate),
                timezone: "Europe/Paris",
                onlyUses: true,
              },
            }),
          );
        }
      });
      Promise.all(resultsPromise)
        .then((results) => {
          setIsLoading(false);
          setStats(results);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [periods]);

  useEffect(() => {
    getStats();
  }, [getStats]);

  const statsFiltered = useMemo(
    () =>
      stats.map((stat) => {
        const { uses: statUses } = stat;
        if (!usesFilter || usesFilter.length === 0) {
          return stat;
        }
        return {
          ...stat,
          uses: statUses.filter((use) => usesFilter.includes(use.name)),
        };
      }),
    [stats, usesFilter],
  );

  const handleChangePeriods = (changedPeriods) => {
    setPeriods(changedPeriods);
  };

  const handleChangeUsesFilter = (event, values) => {
    setUsesFilter(values);
  };

  const getPartner = useCallback(() => {
    if (partnerId) {
      PartnerService.get(partnerId)
        .then((result) => {
          let newDefaultPeriod;
          const startDate =
            result && result.contract && result.contract.serviceStartDate;
          if (result.billing.typeOfBilling === BillingTypeEnum.LICENCE.name) {
            newDefaultPeriod = getDefaultPeriodForLicenceBilling(startDate);
          } else {
            newDefaultPeriod = getDefaultPeriod(startDate);
          }
          setPartner(result);
          setDefaultPeriod(newDefaultPeriod);
          PartnerService.getUses(partnerId)
            .then((uses) =>
              setIsConsolidated(
                uses.data?.filter(
                  (use) => use.status === StatusEnum.ACTIVE.name,
                ).length > 1,
              ),
            )
            .catch(() => {
              setSnackbar({
                open: true,
                message:
                  "Une erreur est survenue lors de la récupération des usages.",
                variant: "error",
              });
            });
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message:
              "Une erreur est survenue lors de la récupération du compte partenaire.",
            variant: "error",
          });
        });
    }
  }, [partnerId]);

  useEffect(() => {
    getPartner();
  }, [getPartner]);

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <>
      <DashboardStatTitleWithExport
        statTitle={
          <PageTitle subtitle={partner.companyName || ""}>
            Tableau de bord
          </PageTitle>
        }
        partner={partner}
        statAdditionalParams={{ onlyUses: true }}
        isDashboard
        periods={periods}
        handleSetIsLoading={setIsLoading}
      />
      {partner.status === StatusEnum.ACTIVE.name ||
      partner.activationDate !== null ? (
        <>
          <Box mb={2} component={Paper}>
            <Box
              sx={{
                p: theme.spacing(4),
                [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
                  p: theme.spacing(2),
                },
              }}
            >
              <PeriodsPicker
                periods={periods}
                onPeriodsChange={handleChangePeriods}
                defaultPeriod={defaultPeriod}
                isDashboard
              />
            </Box>
            <Divider />
            {isConsolidated && (
              <UsesFilterCard
                listOfUses={getUsesFromStats(stats)}
                usesFilter={usesFilter}
                handleChangeUsesFilter={handleChangeUsesFilter}
                isDashboard
              />
            )}
          </Box>
          {isLoading && (
            <Box
              sx={{
                position: "fixed",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                zIndex: 1,
              }}
            >
              <Loader sx={{ background: "rgba(255,255,255,0.5)" }} />
            </Box>
          )}
          <DashboardStatConso
            stats={statsFiltered}
            isConsolidated={isConsolidated}
            isPartner
            isDashboard
          />
          <DashboardStatUsers
            stats={statsFiltered}
            sx={{ mt: theme.spacing(4) }}
            isPartner
            periods={periods}
          />
          {isConsolidated && (
            <DashboardApplicationsClassification
              stats={statsFiltered}
              isPartner
              sx={{ mt: theme.spacing(4) }}
            />
          )}
        </>
      ) : (
        <Typography
          variant="h4"
          component="div"
          align="center"
          sx={{
            color: theme.palette["secondary-ultramarine"],
            m: theme.spacing(5, 0),
          }}
        >
          Les données de consommation seront disponibles
          <br />
          lorsque le compte partenaire sera en production
        </Typography>
      )}
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </>
  );
};

export default withAccess(AclFront.DASHBOARD)(Dashboard);
