import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      stroke="currentColor"
      fill="none"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M21 3H3c-.828 0-1.5.672-1.5 1.5v12c0 .828.672 1.5 1.5 1.5h18c.828 0 1.5-.672 1.5-1.5v-12c0-.828-.672-1.5-1.5-1.5z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M14.25 21l-.375-3h-3.75l-.375 3h4.5z"
    />
    <path
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M17.25 21H6.75"
    />
    <path d="M1.5 14.25v2.25c.001.398.16.778.44 1.06.282.28.663.439 1.06.44h18c.398-.001.778-.16 1.06-.44.28-.282.439-.662.44-1.06v-2.25h-21zm10.5 3c-.148 0-.293-.044-.417-.126-.123-.083-.22-.2-.276-.337-.057-.137-.072-.288-.043-.433.03-.146.1-.28.206-.384.105-.105.238-.177.384-.206.145-.028.296-.014.433.043.137.057.254.153.337.276.082.124.126.269.126.417 0 .199-.079.39-.22.53-.14.141-.331.22-.53.22z" />
  </SvgIcon>
);
