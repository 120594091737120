import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path d="M11.632 5.334l3.649 6.32 3.649 6.32H4.333l3.65-6.32 3.649-6.32zm0-1.334c-.234 0-.464.061-.667.178-.203.117-.372.286-.489.489l-3.654 6.32-3.643 6.32c-.117.203-.179.433-.179.667 0 .234.062.464.179.667.117.202.285.37.487.488.203.117.433.178.667.179H18.93c.233 0 .463-.062.666-.18.203-.116.371-.285.488-.487.117-.203.179-.433.179-.667 0-.234-.062-.464-.18-.667l-3.648-6.32-3.644-6.32c-.117-.203-.285-.371-.488-.488-.202-.117-.432-.179-.666-.179h-.005z" />
    <path
      fillRule="evenodd"
      d="M10.81 8.544c-.004-.11.014-.22.053-.324.04-.104.1-.198.176-.278.077-.08.169-.143.27-.186.102-.044.212-.066.322-.066.11 0 .22.022.322.066.102.043.194.107.27.186.077.08.137.174.176.278.04.103.058.214.053.324v4.925c-.008.212-.098.413-.251.56-.153.147-.357.23-.57.23-.212 0-.416-.083-.57-.23-.152-.147-.242-.348-.25-.56V8.544z"
      clipRule="evenodd"
    />
    <path d="M11.632 17.463c.59 0 1.067-.477 1.067-1.066 0-.59-.478-1.067-1.067-1.067-.59 0-1.067.477-1.067 1.067 0 .589.478 1.067 1.067 1.067z" />
  </SvgIcon>
);
