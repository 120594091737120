import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fill="#D00000"
      d="M12.197 19.404H3.71l4.243-7.349 4.244-7.35 4.242 7.35 4.243 7.349h-8.485z"
    />
    <path
      fill="#D00000"
      d="M20.682 20.115H3.71c-.125 0-.247-.034-.355-.096-.108-.063-.198-.152-.26-.26-.062-.108-.095-.23-.095-.355 0-.125.033-.247.095-.355L11.58 4.355c.062-.108.152-.198.26-.26.108-.062.23-.095.355-.095.125 0 .247.033.355.095.108.062.198.152.26.26l8.487 14.7c.063.108.095.23.095.355 0 .125-.033.247-.095.355-.063.108-.152.198-.26.26-.108.062-.231.095-.356.095v-.005zm-15.74-1.422h14.51L12.197 6.127 4.942 18.693z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.266 8.465c.01-.241.112-.469.285-.636.174-.167.405-.26.646-.26s.473.093.646.26c.174.167.276.395.285.636v5.584c.005.125-.016.25-.06.367-.045.117-.113.224-.2.314-.087.09-.19.162-.306.211-.115.05-.24.075-.365.075s-.25-.026-.364-.075c-.116-.049-.22-.12-.307-.21-.087-.091-.155-.198-.2-.315-.044-.117-.064-.242-.06-.367V8.465z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M12.197 18.58c.668 0 1.21-.543 1.21-1.211 0-.669-.542-1.21-1.21-1.21-.669 0-1.21.541-1.21 1.21 0 .668.541 1.21 1.21 1.21z"
    />
  </SvgIcon>
);
