import CGU from "CGU";
import MentionsLegales from "MentionsLegales";
import Accessibilite from "Accessibilite";
import AccountAssociatedFrontNotConnected from "AccountAssociatedFrontNotConnected";
import AccountAssociatedBackNotConnected from "AccountAssociatedBackNotConnected";

const routes = {
  CGU: {
    title: "CGU",
    path: "/cgu",
    component: CGU,
  },
  MentionsLegales: {
    title: "MentionsLegales",
    path: "/mentions-legales",
    component: MentionsLegales,
  },
  Accessibilite: {
    title: "Accessibilite",
    path: "/accessibilite",
    component: Accessibilite,
  },
};

const accountRoutes = {
  AccountAssociatedFront: {
    title: "AccountAssociatedFrontNotConnected",
    path: "/account-associated-partner-not-connected",
    component: AccountAssociatedFrontNotConnected,
  },
  AccountAssociatedBack: {
    title: "AccountAssociatedBackNotConnected",
    path: "/account-associated-administrator-not-connected",
    component: AccountAssociatedBackNotConnected,
  },
};

export default routes;

export const getRoutes = () => Object.values(routes);

export const getAccountRoutes = () => Object.values(accountRoutes);

export const getRoutesPaths = () =>
  Object.values(routes).map((route) => route.path);

export const getAccountRoutesPaths = () =>
  Object.values(accountRoutes).map((route) => route.path);
