import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Paper from "components/Paper";
import {
  Chart,
  Legend,
  PieSeries,
  Title,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { EventTracker, Palette } from "@devexpress/dx-react-chart";
import PieChartDataShape from "models/shapes/PieChartDataShape";
import ChartLabel from "components/ChartLabel";

const Marker = (props) => {
  const { className, color } = props;
  return (
    <svg className={className} fill={color} width="10" height="10">
      <rect x={0} y={0} width={10} height={10} />
    </svg>
  );
};

Marker.propTypes = {
  className: PropTypes.shape(),
  color: PropTypes.string.isRequired,
};

Marker.defaultProps = {
  className: {},
};

const PieChartCard = (props) => {
  const { chartsData, chartTitle, ...otherProps } = props;

  const theme = useTheme();

  const scheme = useMemo(
    () => [
      [theme.palette["bleu-10"], theme.palette["bleu-80"]],
      [theme.palette["jaune-10"], theme.palette["primaire-jaune"]],
    ],
    [theme],
  );

  const coloring = useMemo(
    () => [
      theme.palette["secondary-white"],
      theme.palette["secondary-ultramarine"],
    ],
    [theme],
  );

  const legendRootBase = ({ ...restProps }) => (
    <Legend.Root
      {...restProps}
      sx={{
        display: "flex",
        margin: "auto",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        maxWidth: theme.spacing(32),
        justifyContent: "space-between",
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        minHeight: "112px",
      }}
    />
  );

  const Item = ({ ...restProps }) => (
    <Legend.Item
      {...restProps}
      sx={{
        width: "inherit",
        padding: 0,
      }}
    />
  );

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    return {
      x: centerX + radius * Math.cos(angleInDegrees),
      y: centerY + radius * Math.sin(angleInDegrees),
    };
  };

  const describeArc = (x, y, radius, startAngle, endAngle, val) => {
    const end = polarToCartesian(x, y, radius, startAngle - Math.PI / 2);
    const start = polarToCartesian(x, y, radius, endAngle - Math.PI / 2);

    if (val > 50) {
      return [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        0,
        0,
        end.x,
        end.y,
        "L",
        x,
        y,
      ].join(" ");
    }
    return [
      "M",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      1,
      0,
      end.x,
      end.y,
      "L",
      x,
      y,
      "Z",
    ].join(" ");
  };

  const pieWithBorderColor = ({
    maxRadius,
    arg,
    val,
    startAngle,
    endAngle,
    value,
    color,
  }) => {
    let stroke = "";
    let fillColor = "";

    if (color === theme.palette["bleu-10"]) {
      stroke = theme.palette["bleu-80"];
      fillColor = theme.palette["bleu-80"];
    } else if (color === theme.palette["bleu-80"]) {
      stroke = theme.palette["bleu-80"];
      fillColor = theme.palette["bleu-10"];
    } else if (color === theme.palette["jaune-10"]) {
      stroke = theme.palette["primaire-jaune"];
      fillColor = theme.palette["primaire-jaune"];
    } else if (color === theme.palette["primaire-jaune"]) {
      stroke = theme.palette["primaire-jaune"];
      fillColor = theme.palette["jaune-10"];
    }

    if (value === 100) {
      return (
        <svg>
          <circle
            cx={arg}
            cy={val}
            r={maxRadius * 0.99}
            strokeWidth="2px"
            stroke={stroke}
            fill={color}
          />
        </svg>
      );
    }
    if (value > 0) {
      return (
        <path
          d={describeArc(arg, val, maxRadius, endAngle, startAngle, value)}
          strokeWidth="2px"
          fill={fillColor}
          stroke={stroke}
        />
      );
    }
    return null;
  };

  console.info("chartsData", chartsData);

  return (
    <Paper {...otherProps}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: theme.spacing(3),
          height: theme.spacing(9),
        }}
      >
        <Typography component="h2" variant="h3">
          {chartTitle}
        </Typography>
      </Box>
      <Divider />
      <Grid
        container
        sx={{
          padding: theme.spacing(3, 1.5, 0, 1.5),
        }}
      >
        {Array.isArray(chartsData) && chartsData.length !== 0 ? (
          chartsData.map((currentChartData, index) => (
            <Grid
              key={`Période ${index + 1}`}
              item
              xs={chartsData.length === 2 ? 6 : 12}
            >
              {currentChartData && (
                <Chart data={currentChartData} height={382}>
                  <Palette scheme={scheme[index]} />
                  <PieSeries
                    valueField="value"
                    argumentField="argument"
                    pointComponent={pieWithBorderColor}
                  />
                  <Legend
                    position="bottom"
                    rootComponent={legendRootBase}
                    markerComponent={Marker}
                    itemComponent={Item}
                  />
                  <EventTracker />
                  <Tooltip
                    overlayComponent={(overlayProps) => (
                      <Tooltip.Overlay {...overlayProps}>
                        {overlayProps.children &&
                          overlayProps.children.props &&
                          overlayProps.children.props.children}
                      </Tooltip.Overlay>
                    )}
                    contentComponent={(info) => {
                      const { point = 0 } = info.targetItem;
                      return (
                        <ChartLabel
                          title={`Période ${index + 1}`}
                          color={coloring[index]}
                          backgroundColor={scheme[index][1]}
                          stats={[
                            {
                              label: currentChartData[point]?.label,
                              value: `${currentChartData[point]?.value}%`,
                            },
                          ]}
                        />
                      );
                    }}
                  />
                  {chartsData.length === 2 && (
                    <Title text={`Période ${index + 1}`} />
                  )}
                </Chart>
              )}
            </Grid>
          ))
        ) : (
          <div aria-label="Aucune donnée" />
        )}
      </Grid>
    </Paper>
  );
};

PieChartCard.propTypes = {
  chartsData: PropTypes.arrayOf(PieChartDataShape),
  chartTitle: PropTypes.string,
};

PieChartCard.defaultProps = {
  chartsData: [],
  chartTitle: "",
};

export default PieChartCard;
