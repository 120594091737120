import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AclBack from "apps/back/AclBack";
import Acl, { withAccess } from "utils/Acl";
import SalesChannelEnum from "models/enums/SalesChannelEnum";
import StatShape from "models/shapes/StatShape";
import PieChartCard from "components/stats/PieChartCard";

const StatSales = (props) => {
  const { stats, ...otherProps } = props;

  const [salesChannelsDistributions, setSalesChannelsDistributions] =
    useState(null);

  useEffect(() => {
    if (Array.isArray(stats)) {
      const newSalesChannelsDistribution = stats.map((stat) => {
        const { applications } = stat;
        if (Array.isArray(applications) && applications.length > 0) {
          const applicationsNb = applications.length;
          const directSalesApplications = applications.filter(
            (application) =>
              application.sales_channel === SalesChannelEnum.DIRECT.name,
          );
          const percentDirectSales =
            Math.round(
              (directSalesApplications.length * 10000) / applicationsNb,
            ) / 100;
          const indirectSalesApplications = applications.filter(
            (application) =>
              application.sales_channel === SalesChannelEnum.INDIRECT.name,
          );
          const percentIndirectSales =
            Math.round(
              (indirectSalesApplications.length * 10000) / applicationsNb,
            ) / 100;
          return [
            {
              argument: `${SalesChannelEnum.DIRECT.label} ${percentDirectSales}%`,
              label: SalesChannelEnum.DIRECT.label,
              value: percentDirectSales,
            },
            {
              argument: `${SalesChannelEnum.INDIRECT.label} ${percentIndirectSales}%`,
              label: SalesChannelEnum.INDIRECT.label,
              value: percentIndirectSales,
            },
          ];
        }
        return null;
      });
      setSalesChannelsDistributions(newSalesChannelsDistribution);
    }
  }, [stats]);

  return (
    <PieChartCard
      chartTitle="Répartition par vente"
      chartsData={salesChannelsDistributions}
      {...otherProps}
    />
  );
};

StatSales.propTypes = {
  stats: PropTypes.arrayOf(StatShape).isRequired,
};

export default withAccess(AclBack.DASHBOARD_GLOBAL, Acl.READ)(StatSales);
