import UserRoleEnum from "models/enums/UserRoleEnum";
import BuildIcon from "icons/build-outline";
import RibbonIcon from "icons/ribbon-outline";
import DesktopIcon from "icons/desktop-outline";
import FlaskIcon from "icons/flask-outline";
import WarningIcon from "icons/warning";

export const getRoleKey = (roleLabel) =>
  Object.keys(UserRoleEnum).find((key) => UserRoleEnum[key] === roleLabel);

export const roleIsValid = (role) => Object.keys(UserRoleEnum).includes(role);

export const getIconByRoleName = (name) => {
  switch (name) {
    case UserRoleEnum.METIER.name: {
      return DesktopIcon;
    }
    case UserRoleEnum.TECHNIQUE.name: {
      return BuildIcon;
    }
    case UserRoleEnum.ADMINISTRATEUR.name: {
      return RibbonIcon;
    }
    case UserRoleEnum.ADMIN_LIN.name: {
      return RibbonIcon;
    }
    case UserRoleEnum.EXPERT.name: {
      return FlaskIcon;
    }
    case UserRoleEnum.SUPERADMIN.name: {
      return DesktopIcon;
    }
    default:
      return WarningIcon;
  }
};
