export default class AddressFactory {
  constructor({
    department = "",
    street = "",
    streetNb = "",
    city = "",
    zipCode = null,
    country = null,
    ...additionalAttributes
  } = {}) {
    this.department = department;
    this.street = street;
    this.streetNb = streetNb;
    this.city = city;
    this.zipCode = zipCode;
    this.country = country;
    Object.keys(additionalAttributes).forEach((key) => {
      this[key] = this[key] || additionalAttributes[key];
    });
  }
}
