export default {
  DEMANDE_DE_CONNEXION: {
    name: "Demande de connexion",
    label: "Demande de connexion",
  },
  RETRAIT_DE_COLIS: { name: "Retrait de colis", label: "Retrait de colis" },
  OUVERTURE_DE_COMPTE: {
    name: "Ouverture de compte",
    label: "Ouverture de compte",
  },
  SIGNATURE_DE_CONTRAT: {
    name: "Signature de contrat",
    label: "Signature de contrat",
  },
};
