import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M18.745 10.922c1.357 0 2.455-1.104 2.455-2.467 0-1.364-1.098-2.468-2.454-2.468-1.357 0-2.455 1.104-2.455 2.468 0 1.363 1.098 2.467 2.454 2.467zm0-3.454c.54 0 .982.444.982.987 0 .542-.441.987-.982.987-.54 0-.982-.445-.982-.987 0-.543.442-.987.982-.987zM4.998 10.922c1.356 0 2.455-1.104 2.455-2.467 0-1.364-1.099-2.468-2.455-2.468S2.543 7.091 2.543 8.455c0 1.363 1.099 2.467 2.455 2.467zm0-3.454c.54 0 .982.444.982.987 0 .542-.442.987-.982.987s-.982-.445-.982-.987c0-.543.442-.987.982-.987zm12.164 6.505c-.844-1.259-2.476-1.728-3.922-1.287-.435.133-.893.207-1.371.207-.48 0-.936-.074-1.372-.207-1.445-.44-3.078.025-3.922 1.287-.38.567-.601 1.249-.601 1.983v1.382c0 .817.66 1.48 1.473 1.48h8.837c.814 0 1.473-.663 1.473-1.48v-1.382c.006-.734-.214-1.416-.595-1.983zm-.871 3.365H7.453v-1.382c0-1.123.896-2.042 2.007-2.073.782.327 1.592.494 2.412.494.819 0 1.63-.167 2.412-.494 1.11.031 2.007.947 2.007 2.073v1.382zm2.823-5.429h-.737c-.53 0-1.025.164-1.436.441.411.312.773.685 1.056 1.117.12-.043.245-.077.377-.077h.737c.607 0 1.104.5 1.104 1.11 0 .407.332.74.737.74.405 0 .736-.333.736-.74.003-1.428-1.153-2.59-2.574-2.59zm-7.242 0c1.9 0 3.437-1.545 3.437-3.454C15.309 6.545 13.77 5 11.872 5c-1.9 0-3.437 1.545-3.437 3.455 0 1.909 1.537 3.454 3.437 3.454zm0-5.428c1.083 0 1.964.885 1.964 1.974 0 1.088-.881 1.974-1.964 1.974s-1.964-.886-1.964-1.974c0-1.09.88-1.974 1.964-1.974zm-5.07 5.87c-.41-.278-.905-.442-1.436-.442H4.63c-1.421 0-2.578 1.163-2.578 2.591 0 .407.331.74.736.74.406 0 .737-.333.737-.74 0-.61.497-1.11 1.105-1.11h.736c.135 0 .261.034.378.077.285-.432.647-.805 1.058-1.117z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
