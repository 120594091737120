import React, { useCallback, useRef, useState } from "react";

import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Typography } from "@mui/material";

import AclFront from "apps/front/AclFront";
import FormReadOnly from "components/FormReadOnly";
import Snackbar from "components/Snackbar";
import DownloadIcon from "icons/download";
import TechnicalDocsService, {
  references as docsReferences,
} from "services/TechnicalDocsService";
import { withAccess } from "utils/Acl";
import TcWrapper from "react-tag-commander";

const StyledDownloadIcon = styled(DownloadIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  borderBottomWidth: theme.palette["status-success"],
}));

const StyledProgressBar = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  height: "4px",
  background: theme.palette["status-success"],
}));

const TechnicalDocumentation = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });
  const [downloadingGraphic, setDownloadingGraphic] = useState(false);
  const [downloadingTechnical, setDownloadingTechnical] = useState(false);

  const graphicButtonRef = useRef(null);
  const technicalButtonRef = useRef(null);
  const wrapper = TcWrapper.getInstance();

  const downloadDoc = useCallback((reference, progressBarRef) => {
    return TechnicalDocsService.download(reference, {
      onDownloadProgress: (event) => {
        if (progressBarRef && progressBarRef.current) {
          const { total, loaded } = event;
          // eslint-disable-next-line no-param-reassign
          progressBarRef.current.style.width = `${Math.ceil(
            (loaded / total) * 100,
          )}%`;
        }
      },
    }).catch(() => {
      setSnackbar({
        open: true,
        message: "Une erreur est survenue lors de l'accès à ce document.",
        variant: "error",
      });
    });
  }, []);

  const handleClickDownloadGraphicsDoc = useCallback(
    (event) => {
      if (!downloadingGraphic) {
        setDownloadingGraphic(true);
        downloadDoc(docsReferences.GRAPHIC, graphicButtonRef).then(() => {
          setDownloadingGraphic(false);
        });
      }
      wrapper.triggerEvent("click", event.target, {
        clic_type: "download",
        name: "telechargements_kit_integration_graphique",
        chapter1: "informations_techniques",
      });
    },
    [downloadDoc, downloadingGraphic, wrapper],
  );

  const handleClickDownloadTechnicalDoc = useCallback(
    (event) => {
      if (!downloadingTechnical) {
        setDownloadingTechnical(true);
        downloadDoc(docsReferences.TECHNICAL, technicalButtonRef).then(() => {
          setDownloadingTechnical(false);
        });
      }
      wrapper.triggerEvent("click", event.target, {
        clic_type: "download",
        name: "telechargements_kit_integration_technique",
        chapter1: "informations_techniques",
      });
    },
    [downloadDoc, downloadingTechnical, wrapper],
  );

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar({
      open: false,
    });
  }, []);

  return (
    <>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
      <FormReadOnly
        id="telechargements"
        title="Téléchargements"
        firstLevel={3}
        data={[
          {
            twoColumns: false,
            data: [
              {
                category: "Téléchargez vos documents au format PDF",
                value: (
                  <Grid
                    container
                    spacing={6}
                    sx={{ marginTop: (theme) => theme.spacing(1) }}
                  >
                    <Grid item>
                      <StyledButton
                        variant="outlined"
                        color="secondary"
                        onClick={handleClickDownloadGraphicsDoc}
                        endIcon={<StyledDownloadIcon />}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          color="inherit"
                          aria-label="Télécharger le kit d'intégration graphique"
                        >
                          {downloadingGraphic
                            ? "Téléchargement en cours ..."
                            : "Kit d’intégration graphique"}
                        </Typography>
                        <StyledProgressBar ref={graphicButtonRef} />
                      </StyledButton>
                    </Grid>
                    <Grid item>
                      <StyledButton
                        variant="outlined"
                        color="secondary"
                        onClick={handleClickDownloadTechnicalDoc}
                        endIcon={<StyledDownloadIcon />}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          color="inherit"
                          aria-label="Télécharger le kit d'intégration technique"
                        >
                          {downloadingTechnical
                            ? "Téléchargement en cours ..."
                            : "Kit d’intégration technique"}
                        </Typography>
                        <StyledProgressBar ref={technicalButtonRef} />
                      </StyledButton>
                    </Grid>
                  </Grid>
                ),
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default withAccess(AclFront.INFORMATION_TECH)(TechnicalDocumentation);
