import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Grid,
} from "@mui/material";
import Button from "components/Button";
import Snackbar from "components/Snackbar";
import StatusEnum from "models/enums/StatusEnum";
import ModalHeader from "components/ModalHeader";
import PartnerService from "services/PartnerService";
import { useTheme } from "@mui/material/styles";

const TechDataStatusForm = (props) => {
  const { use, onSuccess, onCancel, openTechDataModal } = props;
  const theme = useTheme();
  const { techDatas = [], status, id, partnerId } = use;
  const [currentStatus, setCurrentStatus] = useState(status);
  const [showError, setShowError] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  useEffect(() => {
    setShowError(
      currentStatus === StatusEnum.ACTIVE.name && techDatas.length !== 2,
    );
  }, [currentStatus, techDatas.length]);

  const handleClickConfigurationButton = () => {
    onCancel();
    openTechDataModal();
  };

  const handleClickValidate = useCallback(() => {
    if (status !== currentStatus) {
      PartnerService.editUse({ id, partnerId, status: currentStatus })
        .then(() => {
          onSuccess();
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message: "Une erreur est survenue lors du changement du statut",
            variant: "error",
          });
        });
    } else {
      onCancel();
    }
  }, [status, currentStatus, id, partnerId, onSuccess, onCancel]);

  const handleChangeStatus = useCallback((e) => {
    setCurrentStatus(e.target.value);
  }, []);

  return (
    <Box sx={{ width: { md: 800 } }}>
      <ModalHeader title="Statut" handleCloseModal={onCancel} />
      <Divider />
      <Box p={4}>
        <Box pb={3}>
          <Typography variant="h4" component={Box} mb={3}>
            Vous pouvez définir le statut de cet usage :
          </Typography>
          <RadioGroup
            defaultValue=""
            row
            name="status"
            value={currentStatus}
            onChange={handleChangeStatus}
          >
            {Object.values(StatusEnum).map((val) => (
              <FormControlLabel
                key={val.name}
                value={val.name}
                disabled={
                  (status === StatusEnum.INACTIVE.name &&
                    val.name !== StatusEnum.INACTIVE.name) ||
                  (status === StatusEnum.ACTIVE.name &&
                    val.name === StatusEnum.TEST.name)
                }
                control={
                  <Radio
                    tabindex="0"
                    role="radio"
                    aria-checked={currentStatus === val.name}
                    onClick={() => setCurrentStatus(val.name)}
                    inputProps={{ tabIndex: -1 }}
                  />
                }
                label={val.label}
              />
            ))}
          </RadioGroup>
        </Box>
        {showError && (
          <Grid
            container
            direction="column"
            alignItems="center"
            sx={{
              p: theme.spacing(3),
              borderRadius: 2.5,
              backgroundColor: theme.palette["secondary-light-background"],
            }}
          >
            <Typography
              sx={{
                fontSize: "1.125rem",
                fontWeight: "400",
                mb: theme.spacing(2.5),
              }}
            >
              Vous devez configurer l&apos;environnement{" "}
              {techDatas.length === 0 && "de test et"} de production pour passer
              en actif
            </Typography>
            {techDatas.length === 1 && (
              <Button
                variant="contained"
                onClick={handleClickConfigurationButton}
              >
                Paramétrer l&apos;environnement de production
              </Button>
            )}
          </Grid>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: theme.spacing(4),
          }}
        >
          <Button
            variant="text"
            sx={{
              marginRight: "1.5rem",
              color: theme.palette["primaire-bleu"],
            }}
            onClick={onCancel}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={showError}
            onClick={handleClickValidate}
          >
            Enregistrer
          </Button>
        </Box>
      </Box>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
    </Box>
  );
};

TechDataStatusForm.propTypes = {
  use: PropTypes.shape().isRequired,
  openTechDataModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TechDataStatusForm;
