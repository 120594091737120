import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M11 19c0 .552.448 1 1 1s1-.448 1-1v-6h6c.552 0 1-.448 1-1s-.448-1-1-1h-6V5c0-.552-.448-1-1-1s-1 .448-1 1v6H5c-.552 0-1 .448-1 1s.448 1 1 1h6v6z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
