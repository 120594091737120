/* eslint-disable camelcase */
export default class UseStatFactory {
  constructor({
    id,
    by_date = {},
    name = "",
    unique_users = 0,
    uses = 0,
    ...otherAttributes
  }) {
    this.id = id;
    this.by_date = by_date;
    this.name = name;
    this.unique_users = unique_users;
    this.uses = uses;

    Object.keys(otherAttributes).forEach((key) => {
      this[key] = otherAttributes[key];
    });
  }
}
