export default class PersonalDataFactory {
  constructor({
    id = "",
    username = "",
    lastName = "",
    firstName = "",
    email = "",
    created = null,
    job = "",
    phoneNumber = "",
    lastLogin = "",
    enabled = true,
    roles = {
      "Plateforme2b-front": [],
      "Plateforme2b-back": [],
    },
    isAssociated = false,
    ...additionalAttributes
  } = {}) {
    this.id = id;
    this.username = username;
    this.lastName = lastName;
    this.firstName = firstName;
    this.email = email;
    this.created = created;
    this.job = job;
    this.phoneNumber = phoneNumber;
    this.lastLogin = lastLogin;
    this.enabled = enabled;
    this.roles = roles;
    this.isAssociated = isAssociated;
    Object.keys(additionalAttributes).forEach((key) => {
      this[key] = this[key] || additionalAttributes[key];
    });
  }
}
