import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M11.853 11.984c.213-.593.513-1.14.888-1.63-.232.093-.482.146-.744.146-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2c0 .262-.053.512-.147.744.49-.375 1.037-.678 1.631-.888C15.406 6.491 13.884 5 12 5c-1.934 0-3.5 1.566-3.5 3.5 0 1.884 1.49 3.406 3.353 3.484zM5 11c1.381 0 2.5-1.119 2.5-2.5S6.381 6 5 6 2.5 7.119 2.5 8.5 3.619 11 5 11zm0-3.5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7.634 10H7.5v-1.4c0-1.138.912-2.069 2.044-2.1.643.269 1.31.425 1.981.475-.022-.29-.063-.75.072-1.519-.525-.047-1.034-.153-1.5-.35-.16-.065-.325-.106-.497-.106-1.987 0-3.6 1.613-3.6 3.6v1.4c0 .828.672 1.5 1.5 1.5h6.688c-.607-.403-1.132-.913-1.554-1.5zm-5.796-5.053c-.42-.281-.922-.447-1.463-.447h-.75C3.178 12 2 13.178 2 14.625c0 .412.337.75.75.75s.75-.338.75-.75c0-.619.506-1.125 1.125-1.125h.75c.138 0 .266.034.384.078.291-.437.66-.816 1.079-1.131zm14.24 2.219c.081-.441.081-.891 0-1.332l.806-.465c.094-.053.135-.163.104-.266-.21-.675-.57-1.287-1.038-1.794-.072-.078-.188-.096-.281-.043l-.806.465c-.341-.29-.732-.515-1.154-.665v-.932c0-.106-.075-.2-.178-.222-.697-.156-1.406-.15-2.069 0-.103.022-.178.116-.178.222v.932c-.421.15-.812.375-1.153.665l-.806-.465c-.09-.053-.21-.035-.281.043-.469.507-.828 1.12-1.038 1.794-.031.103.013.213.103.266l.807.465c-.082.441-.082.891 0 1.332l-.807.465c-.093.053-.134.163-.103.266.21.675.569 1.284 1.038 1.794.072.078.187.097.281.043l.806-.465c.34.29.732.515 1.153.665v.932c0 .106.075.2.178.221.697.157 1.407.15 2.07 0 .102-.021.177-.115.177-.221v-.932c.422-.15.813-.375 1.154-.665l.806.465c.09.053.21.035.281-.043.469-.507.828-1.12 1.038-1.794.03-.103-.013-.213-.104-.266l-.806-.465zm-3.578.85c-.837 0-1.516-.682-1.516-1.516s.682-1.516 1.516-1.516 1.516.682 1.516 1.516-.678 1.516-1.516 1.516z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
