import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AclFront from "apps/front/AclFront";
import AclBack from "apps/back/AclBack";
import Paper from "components/Paper";
import RankingButtonGroup from "components/RankingButtonGroup";
import ReverseIcon from "icons/reverse";
import PartnerTypologyEnum from "models/enums/PartnerTypologyEnum";
import StatShape from "models/shapes/StatShape";
import StatPartnerShape from "models/shapes/StatPartnerShape";
import Acl, { withAccess } from "utils/Acl";
import { visuallyHidden } from "@mui/utils";

const StyledHeadRow = styled(TableRow)(({ theme }) => ({
  height: theme.spacing(6.5),
}));

const StyledHeadCell = styled(TableCell)(({ theme }) => ({
  fontFamily: "Barlow",
  fontSize: "0.75rem",
  color: theme.palette["cobalt-marque-blanche-main"],
  borderColor: theme.palette["grey-20"],
}));

const StyledTableCellIndex = styled(TableCell)(({ theme }) => ({
  fontFamily: "Montserrat",
  fontSize: "1rem",
  fontWeight: 600,
  color: theme.palette["cobalt-marque-blanche-second"],
  width: theme.spacing(6),
  height: theme.spacing(6.5),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: "Barlow",
  fontSize: "0.875rem",
  color: theme.palette["cobalt-marque-blanche-main"],
  height: theme.spacing(6.5),
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(1),
  maxHeight: theme.spacing(40),
}));

const StyledReverseIcon = styled(ReverseIcon)(({ theme }) => ({
  width: "24px",
  height: "24px",
  color: theme.palette["primaire-bleu"],
}));

const StyledPeriodCard = styled(Paper)(({ theme }) => ({
  borderRadius: "8px",
  boxShadow: "none",
  fontFamily: "Barlow",
  fontSize: "0.875rem",
  fontWeight: 500,
  textAlign: "center",
  padding: theme.spacing(1, 0),
}));

const StyledFirstColumn = styled(Grid)(({ theme }) => ({
  marginRight: theme.spacing(3),
}));

const filters = [
  {
    name: PartnerTypologyEnum.PARTNER.name,
    ressource: "uses",
    sentence:
      "Des partenaires standalone en vente directe sur le nombre total d’utilisation",
    title: "Partenaires standalone",
    subtitle: "Nombre d’utilisation",
  },
  {
    name: PartnerTypologyEnum.PARTNER.name,
    ressource: "unique_users",
    sentence:
      "Des partenaires standalone en vente directe sur le nombre total d’utilisateurs uniques",
    title: "Partenaires standalone",
    subtitle: "Nombre d’utilisateurs uniques",
  },
  {
    name: PartnerTypologyEnum.PUBLISHER.name,
    ressource: "uses",
    sentence:
      "Des partenaires éditeur en vente directe sur le nombre total d’utilisation",
    title: "Partenaires éditeur",
    subtitle: "Nombre d’utilisation",
  },
  {
    name: PartnerTypologyEnum.PUBLISHER.name,
    ressource: "unique_users",
    sentence:
      "Des partenaires éditeur en vente directe sur le nombre total d’utilisateurs uniques",
    title: "Partenaires éditeur",
    subtitle: "Nombre d’utilisateurs uniques",
  },
  {
    name: PartnerTypologyEnum.DOCAPOSTE_BU.name,
    ressource: "uses",
    sentence:
      "Des Partenaires BU Docaposte sur la base du nombre d'utilisation",
    title: "BU Docaposte",
    subtitle: "Nombre d’utilisation",
  },
];

const ApplicationsClassification = (props) => {
  const { stats, isPartner, ...otherProps } = props;
  const theme = useTheme();
  const [orderDesc, setOrderDesc] = useState(true);
  const [filter, setFilter] = useState(filters[0]);
  const sortFunction = useCallback(
    (a, b) => {
      const ressource = isPartner ? "uses" : filter.ressource;
      return orderDesc
        ? b[ressource] - a[ressource]
        : a[ressource] - b[ressource];
    },
    [filter, isPartner, orderDesc],
  );
  const sortedDataLists = useMemo(
    () =>
      stats.map((stat) => {
        if (isPartner) {
          return stat.uses.sort(sortFunction);
        }
        const { applications } = stat;
        const list = applications.filter(
          (application) => application.partner_typology === filter.name,
        );
        list.sort(sortFunction);
        return list;
      }),
    [stats, sortFunction, isPartner, filter.name],
  );

  const [sortedDataListPeriod1, sortedDataListPeriod2] = sortedDataLists;

  const biggestList = sortedDataLists.reduce(
    (biggest, current) =>
      !biggest || biggest.length < current.length ? current : biggest,
    [],
  );

  const handleSetFilter = (filterKey) => {
    setFilter(
      filters.find(
        (completeFilter) =>
          completeFilter && completeFilter.sentence === filterKey,
      ),
    );
  };

  const handleChangeSortOrder = () => {
    setOrderDesc(!orderDesc);
  };

  const generateTable = (list, period, single = true) => {
    if (!Array.isArray(list) || list.length === 0) {
      return (
        <Typography variant="body2">
          {`Aucune donnée ${
            isPartner ? "usage" : "partenaire"
          } pour cette période.`}
        </Typography>
      );
    }

    return (
      <>
        {!single && <span style={visuallyHidden}>{period}</span>}
        <Table stickyHeader>
          <TableHead>
            <StyledHeadRow>
              {single && <StyledHeadCell align="center" />}
              <StyledHeadCell role="columnheader">Nom</StyledHeadCell>
              <StyledHeadCell role="columnheader" align="right">
                Utilisations
              </StyledHeadCell>
              {!sortedDataListPeriod2 && (
                <StyledHeadCell align="right" style={{ width: "10%" }}>
                  <IconButton
                    aria-label="Inverser l'ordre de tri"
                    onClick={handleChangeSortOrder}
                    size="large"
                  >
                    <StyledReverseIcon />
                  </IconButton>
                </StyledHeadCell>
              )}
            </StyledHeadRow>
          </TableHead>
          <TableBody>
            {list.map(
              (data, index) =>
                data && (
                  <TableRow key={data.id}>
                    {single && (
                      <StyledTableCellIndex align="center">
                        {index + 1}
                      </StyledTableCellIndex>
                    )}
                    <StyledTableCell sx={{ fontWeight: 500 }}>
                      {!single && (
                        <span style={visuallyHidden}>{index + 1}</span>
                      )}
                      {data.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {parseInt(
                        data[isPartner ? "uses" : filter.ressource],
                        10,
                      )}
                    </StyledTableCell>
                    {!sortedDataListPeriod2 && (
                      <StyledTableCell align="right" />
                    )}
                  </TableRow>
                ),
            )}
          </TableBody>
        </Table>
      </>
    );
  };

  return (
    <Paper {...otherProps}>
      <Box p={3}>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs="auto">
            <Typography variant="h3" component="h2">
              Classement
            </Typography>
          </Grid>
          {sortedDataListPeriod2 && (
            <Grid item xs="auto">
              <IconButton
                aria-label="Inverser l'ordre de tri"
                onClick={handleChangeSortOrder}
                size="large"
              >
                <StyledReverseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <Divider />
      <Grid container>
        {!isPartner && (
          <Grid
            item
            sm={12}
            md={4}
            sx={{
              m: {
                xs: theme.spacing(4, 0, 0, 0),
                sm: theme.spacing(4, 0),
                lg: theme.spacing(4, 8),
              },
            }}
          >
            <RankingButtonGroup
              changeFilter={handleSetFilter}
              filters={filters}
              filter={filter}
            />
          </Grid>
        )}
        <Grid item sm={12} md={!isPartner ? 6 : 12}>
          <Box
            sx={{
              p: {
                xs: isPartner
                  ? theme.spacing(2, 3, 2, 2.5)
                  : theme.spacing(0, 3, 3, 2.5),
                md: theme.spacing(2, 3, 2, 2.5),
              },
            }}
          >
            {!isPartner && (
              <Typography
                variant="subtitle1"
                component="h3"
                sx={{
                  width: "65%",
                  lineHeight: 1,
                  my: theme.spacing(3),
                }}
              >
                {filter && filter.sentence}
              </Typography>
            )}
            {!sortedDataListPeriod2 && (
              <StyledTableContainer>
                {generateTable(sortedDataListPeriod1)}
              </StyledTableContainer>
            )}
            {sortedDataListPeriod2 && (
              <>
                <Grid container aria-hidden="true">
                  <Grid item xs={1} />
                  <StyledFirstColumn item xs={5}>
                    <StyledPeriodCard
                      sx={{
                        backgroundColor: theme.palette["bleu-80"],
                        color: theme.palette["secondary-white"],
                      }}
                    >
                      Période 1
                    </StyledPeriodCard>
                  </StyledFirstColumn>
                  <Grid item xs={5}>
                    <StyledPeriodCard
                      sx={{
                        backgroundColor: theme.palette["primaire-jaune"],
                        color: theme.palette["secondary-ultramarine"],
                      }}
                    >
                      Période 2
                    </StyledPeriodCard>
                  </Grid>
                </Grid>
                <StyledTableContainer>
                  <Grid container>
                    <Grid item xs={1} aria-hidden="true">
                      <Table>
                        <TableHead>
                          <StyledHeadRow>
                            {sortedDataListPeriod2 && (
                              <TableCell
                                sx={{ border: "none" }}
                                align="center"
                              />
                            )}
                          </StyledHeadRow>
                        </TableHead>
                        <TableBody>
                          {biggestList.map((application, index) => (
                            <TableRow key={application.id}>
                              <StyledTableCellIndex
                                sx={{ border: "none" }}
                                align="center"
                              >
                                {index + 1}
                              </StyledTableCellIndex>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                    <StyledFirstColumn item xs={5}>
                      {generateTable(sortedDataListPeriod1, "Période 1", false)}
                    </StyledFirstColumn>
                    <Grid item xs={5}>
                      {generateTable(sortedDataListPeriod2, "Période 2", false)}
                    </Grid>
                  </Grid>
                </StyledTableContainer>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

ApplicationsClassification.propTypes = {
  stats: PropTypes.oneOfType([
    PropTypes.arrayOf(StatShape),
    PropTypes.arrayOf(StatPartnerShape),
  ]).isRequired,
  isPartner: PropTypes.bool.isRequired,
};

export const DashboardApplicationsClassification = withAccess(
  AclFront.DASHBOARD,
)(ApplicationsClassification);
export const PartnerApplicationsClassification = withAccess(
  AclBack.PARTNERS_CONSO,
  Acl.READ,
)(ApplicationsClassification);
export const BackDashboardApplicationsClassification = withAccess(
  AclBack.DASHBOARD_INDIRECT_SALE,
  Acl.READ,
)(ApplicationsClassification);
