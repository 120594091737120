import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M19.273 6h-3.957V4.605c0-.426-.164-.834-.456-1.135-.291-.3-.687-.47-1.1-.47h-3.52c-.86 0-1.556.719-1.556 1.605V6H4.727C4.326 6 4 6.336 4 6.75s.326.75.727.75h.728v11.82c0 .928.729 1.68 1.629 1.68h9.832c.9 0 1.63-.752 1.63-1.68V7.5h.727c.401 0 .727-.336.727-.75S19.674 6 19.273 6zm-9.135-1.395c0-.058.046-.105.102-.105h3.52c.027 0 .053.011.072.03.02.02.03.047.03.075V6h-3.724V4.605zm6.953 14.715c0 .1-.078.18-.175.18H7.084c-.047 0-.091-.019-.124-.053s-.05-.08-.05-.127V7.5h10.18v11.82z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M9.09 9c-.4 0-.726.336-.726.75v7.5c0 .414.325.75.727.75.402 0 .727-.336.727-.75v-7.5c0-.414-.325-.75-.727-.75zM12 9c-.402 0-.727.336-.727.75v7.5c0 .414.325.75.727.75.402 0 .727-.336.727-.75v-7.5c0-.414-.325-.75-.727-.75zM14.182 9.75v7.5c0 .414.325.75.727.75.402 0 .727-.336.727-.75v-7.5c0-.414-.325-.75-.727-.75-.402 0-.727.336-.727.75z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
