import Keycloak from "keycloak-js";

const {
  REACT_APP_keycloak_url: keycloakUrl,
  REACT_APP_keycloak_realm: realm,
  REACT_APP_keycloak_ssl_required: sslRequired,
  REACT_APP_keycloak_resource: resource,
  REACT_APP_keycloak_client_id: clientId,
  REACT_APP_keycloak_confidential_port: confidentialPort,
} = process.env;

const keycloak = new Keycloak({
  url: `${keycloakUrl}/auth`,
  realm,
  "ssl-required": sslRequired,
  resource,
  clientId,
  "confidential-port": confidentialPort,
});

export default keycloak;
