import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Modal from "components/Modal";
import ModalHeader from "components/ModalHeader";
import Visibility from "icons/show";
import VisibilityOff from "icons/icon_doca_hide";
import TechDataService from "services/TechDataService";
import Circle from "components/Circle";
import Loader from "components/Loader";
import Snackbar from "components/Snackbar";

const StyledDecryptModalButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3),
}));

const TechnicalDataKeyValues = (props) => {
  const { technicalDataId, onCancel } = props;
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [keyValues, setKeyValues] = useState(false);
  const [clientSecret, setClientSecret] = useState({});
  const [isNotDecrypted, setIsNotDecrypted] = useState(true);
  const [decryptKey, setDecryptKey] = useState("");
  const [openDecryptModal, setOpenDecryptModal] = useState(false);
  const [showDecryptKey, setShowDecryptKey] = useState(false);
  const CLIENT_SECRET_KEY = "Client Secret";

  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  useEffect(() => {
    if (technicalDataId) {
      setIsLoading(true);
      TechDataService.getKeyValues(technicalDataId)
        .then((result) => {
          setIsLoading(false);
          const dataKeyValues = Array.isArray(result.data) ? result.data : [];
          setKeyValues(
            Array.isArray(result.extra)
              ? [...dataKeyValues, ...result.extra]
              : dataKeyValues,
          );
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message:
              "Une erreur est survenue lors de la récupération des clés d'installation.",
            variant: "error",
          });
        });
    }
  }, [technicalDataId]);

  const handleCloseDecryptModal = () => {
    setDecryptKey("");
    setOpenDecryptModal(false);
  };

  const handleChangeDecryptKey = (event) => {
    setDecryptKey(event.target.value);
  };

  const handleDecryptClientSecret = () => {
    const clientSecretKeyValue =
      Array.isArray(keyValues) &&
      keyValues.find((keyValue) => keyValue.key === CLIENT_SECRET_KEY);
    TechDataService.getKeyValue(technicalDataId, clientSecretKeyValue?.id, {
      params: { decryptKey },
    })
      .then((result) => {
        setClientSecret(result);
        setIsNotDecrypted(false);
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: "Une erreur est survenue lors du décodage.",
          variant: "error",
        });
      });
    setDecryptKey("");
    setOpenDecryptModal(false);
  };

  return (
    <>
      <ModalHeader
        title="Mes clés d’installation"
        handleCloseModal={onCancel}
      />
      <Divider />
      <Box>
        <Box sx={{ p: theme.spacing(4, 6, 3, 6) }}>
          {isLoading && (
            <Box mt={5} mb={3}>
              <Loader />
            </Box>
          )}
          {!isLoading &&
            Array.isArray(keyValues) &&
            keyValues.map((keyValue) => (
              <div key={keyValue.key}>
                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                  <Grid
                    item
                    xs="auto"
                    sx={{
                      ml: theme.spacing(1),
                      mr: theme.spacing(2),
                    }}
                  >
                    <Circle
                      style={{ width: "12px", height: "12px" }}
                      color={theme.palette["primaire-jaune"]}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        color: theme.palette["secondary-ultramarine"],
                      }}
                    >
                      {keyValue.key}
                    </Typography>
                  </Grid>
                </Grid>
                {keyValue.key === CLIENT_SECRET_KEY && isNotDecrypted ? (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setOpenDecryptModal(true)}
                    sx={{ m: theme.spacing(2, 0, 3, 3.5) }}
                  >
                    Afficher la clé
                  </Button>
                ) : (
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      mt: theme.spacing(2),
                      mb: theme.spacing(3),
                      ml: theme.spacing(4.5),
                      wordWrap: "break-word",
                      color: theme.palette["cobalt-marque-blanche-second"],
                    }}
                  >
                    {keyValue.key !== CLIENT_SECRET_KEY || isNotDecrypted
                      ? keyValue.value
                      : clientSecret.value}
                  </Typography>
                )}
              </div>
            ))}
          <Modal open={openDecryptModal} onClose={handleCloseDecryptModal}>
            <ModalHeader
              title="Clé de décodage"
              handleCloseModal={handleCloseDecryptModal}
            />
            <Divider />
            <Box sx={{ p: theme.spacing(4, 6, 3, 6) }}>
              <Typography id="clé">
                {" "}
                Veuillez saisir la clé de décodage:
              </Typography>
              <OutlinedInput
                type={showDecryptKey ? "text" : "password"}
                name="decryptKey"
                value={decryptKey || ""}
                onChange={handleChangeDecryptKey}
                sx={{
                  width: "25rem",
                  maxWidth: "100%",
                  mt: theme.spacing(2),
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowDecryptKey(!showDecryptKey)}
                      edge="end"
                      size="large"
                      aria-label={
                        showDecryptKey ? "Cacher la clé" : "Afficher la clé"
                      }
                    >
                      {showDecryptKey ? (
                        <VisibilityOff
                          style={{
                            fontSize: "1.875em",
                            marginRight: "0.25rem",
                          }}
                        />
                      ) : (
                        <Visibility
                          style={{
                            fontSize: "1.875em",
                            marginRight: "0.25rem",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  "aria-labelledby": "clé",
                  "aria-describedby": "ht_clé",
                }}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <StyledDecryptModalButton
                  color="primary"
                  onClick={handleDecryptClientSecret}
                >
                  Valider
                </StyledDecryptModalButton>
                <StyledDecryptModalButton onClick={handleCloseDecryptModal}>
                  Annuler
                </StyledDecryptModalButton>
              </Box>
            </Box>
          </Modal>
          <Snackbar {...snackbar} onClose={handleCloseSnackbar} />
        </Box>
      </Box>
    </>
  );
};

TechnicalDataKeyValues.propTypes = {
  technicalDataId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
};

TechnicalDataKeyValues.defaultProps = {
  technicalDataId: "",
};

export default TechnicalDataKeyValues;
