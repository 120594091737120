import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "icons/close";
import { visuallyHidden } from "@mui/utils";

import UserGroupIcon from "icons/user group";
import TrailSignIcon from "icons/trail-sign-outline";
import ArrowDownIcon from "icons/arrow_down";
import DeleteIcon from "icons/trash";
import BusinessLineEnum from "models/enums/BusinessLineEnum";
import { getLabelRepresentation } from "utils/enum";
import TcWrapper from "react-tag-commander";

const Close = styled(CloseIcon)(({ theme }) => ({
  color: `${theme.palette["secondary-white"]}!important`,
  width: "16px",
  height: "16px",
  [`&:hover`]: {
    color: "rgba(0, 0, 140, 0.4)!important",
  },
}));

const ArrowDown = styled(ArrowDownIcon)(({ theme }) => ({
  width: "32px",
  height: "32px",
  color: theme.palette["secondary-ultramarine"],
}));

const FiltersCard = (props) => {
  const {
    listOfApplications,
    businessLinesList,
    businessLinesFilter,
    applicationsFilter,
    handleChangeApplicationsFilter,
    handleChangeBusinessLinesFilter,
    isAdminDashboard,
    isDashboard,
  } = props;

  function compare(elementA, elementB, parameter) {
    const nameElementA = elementA[parameter] || "";
    const nameElementB = elementB[parameter] || "";

    return nameElementA.localeCompare(nameElementB);
  }

  const sortedListOfApplications = listOfApplications.sort(
    (elementA, elementB) => {
      return compare(elementA, elementB, "name");
    },
  );

  const sortedListOfBussiness = businessLinesList.sort((elementA, elementB) => {
    return compare(elementA, elementB, "title");
  });

  const wrapper = TcWrapper.getInstance();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  const FilterLabel = styled(Typography)(() => ({
    color: theme.palette["secondary-ultramarine"],
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(-1),
    [theme.breakpoints.down("lg")]: {
      fontSize: isDashboard ? "1.125rem" : "0.875rem",
    },
    // These two lines are a work-around to remove blanksplace next to Autocomplete active label
    backgroundColor: "white",
    paddingRight: "8px",
  }));

  const applicationFilterLabel = (
    <FilterLabel variant={isDashboard ? "h3" : "h5"} component="span">
      <UserGroupIcon
        sx={{
          width: "32px",
          height: "32px",
          margin: theme.spacing(0.25, 1),
        }}
      />{" "}
      {!isMobile &&
        (isAdminDashboard ? "Filtrer par partenaire" : "Filtrer par client")}
    </FilterLabel>
  );

  const businessLinesFilterLabel = (
    <FilterLabel variant={isDashboard ? "h3" : "h5"} component="span">
      <TrailSignIcon
        sx={{
          width: "32px",
          height: "32px",
          margin: theme.spacing(0.25, 1),
          color: theme.palette["secondary-white"],
        }}
      />{" "}
      {!isMobile && "Filtrer par secteur d'activité"}
    </FilterLabel>
  );

  const getApplicationsBusinessLines = (businessLines) => {
    if (
      businessLines &&
      Array.isArray(businessLines) &&
      businessLines.length > 0
    ) {
      let label = null;
      businessLines.forEach((businessLine) => {
        const newBusinessLine = getLabelRepresentation(
          BusinessLineEnum,
          businessLine,
        );
        label = label ? `${label}/${newBusinessLine}` : newBusinessLine;
      });
      return label;
    }
    return "";
  };

  const handleResetFilters = (event) => {
    handleChangeApplicationsFilter(event, []);
    handleChangeBusinessLinesFilter(event, []);
    wrapper.triggerEvent("click", event.target, {
      clic_type: "action",
      name: "supprimer_gestion_periode",
      chapter1: "tableau_de_bord",
    });
  };

  const clearText = "Supprimer les filtres sélectionnés";
  const closeText = "Fermer la liste";
  const openText = "Ouvrir la liste";

  return (
    <Box sx={{ padding: theme.spacing(4, 5) }}>
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", justifyContent: "center" }}
        alignItems="center"
      >
        <Grid item xs={12} sm>
          <Autocomplete
            multiple
            sx={{
              "& label": {
                overflow: "unset",
              },
            }}
            options={sortedListOfBussiness}
            isOptionEqualToValue={(option, value) =>
              option.title === value.title
            }
            noOptionsText="Pas d'options"
            clearText={clearText}
            closeText={closeText}
            openText={openText}
            disableCloseOnSelect
            limitTags={3}
            value={businessLinesFilter}
            onChange={handleChangeBusinessLinesFilter}
            popupIcon={<ArrowDown />}
            ChipProps={{
              deleteIcon: (
                <IconButton
                  sx={{ color: "rgba(0, 0, 140, 0.5)" }}
                  aria-label="Supprimer le filtre"
                >
                  <Close />
                </IconButton>
              ),
            }}
            getOptionLabel={(option) => option.title}
            /* eslint-disable-next-line no-shadow */
            renderOption={(props, option, { selected }) => (
              <Box
                sx={{
                  [theme.breakpoints.up("md")]: {
                    padding: theme.spacing(0, 2),
                  },
                }}
                key={option.title}
                {...props}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      sx={{
                        p: 0,
                        mr: "24px",
                      }}
                      checked={selected}
                    />
                  }
                  label={
                    <Typography variant="subtitle1" component="span">
                      {getLabelRepresentation(BusinessLineEnum, option.title)} (
                      {option.applicationIds &&
                      Array.isArray(option.applicationIds) ? (
                        <>
                          <span style={visuallyHidden}>
                            {isAdminDashboard
                              ? "; Nombre de partenaires: "
                              : "; Nombre de clients: "}
                          </span>{" "}
                          {option.applicationIds.length}
                        </>
                      ) : (
                        "0"
                      )}
                      )
                    </Typography>
                  }
                />
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={businessLinesFilterLabel}
                placeholder="Votre sélection"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    ...params.InputProps.styles,
                    height: "auto",
                    width: "100%",
                    padding: "0.625em",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm>
          <Autocomplete
            multiple
            sx={{
              "& label": {
                overflow: "unset",
              },
            }}
            options={sortedListOfApplications}
            noOptionsText="Pas d'options"
            disableCloseOnSelect
            closeText={closeText}
            value={applicationsFilter}
            onChange={handleChangeApplicationsFilter}
            openText={openText}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            limitTags={3}
            clearText={clearText}
            popupIcon={<ArrowDown />}
            ChipProps={{
              deleteIcon: (
                <IconButton
                  sx={{ color: "rgba(0, 0, 140, 0.5)" }}
                  aria-label="Supprimer le filtre"
                >
                  <Close />
                </IconButton>
              ),
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            /* eslint-disable-next-line no-shadow */
            renderOption={(props, option, { selected }) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between !important",
                }}
                key={option.name}
                {...props}
              >
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          mr: "24px",
                        }}
                        checked={selected}
                      />
                    }
                    label={
                      <Typography variant="subtitle1" component="span">
                        {option.name}
                      </Typography>
                    }
                  />
                </Box>
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ fontWeight: "normal" }}
                >
                  {
                    <span style={visuallyHidden}>
                      ; Secteur d&apos;activité:{" "}
                    </span>
                  }
                  {getApplicationsBusinessLines(option.business_lines)}
                </Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={applicationFilterLabel}
                placeholder="Votre sélection"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    ...params.InputProps.styles,
                    height: "auto",
                    width: "100%",
                    padding: "0.625em",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs="auto">
          <IconButton
            data-testid="editiconbutton"
            aria-label="Supprimer tous les filtres"
            sx={{ fontSize: isDashboard ? "2em" : "1.5em", p: 0 }}
            onClick={handleResetFilters}
          >
            <DeleteIcon color="action" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

FiltersCard.propTypes = {
  listOfApplications: PropTypes.arrayOf(PropTypes.shape()),
  businessLinesList: PropTypes.arrayOf(PropTypes.shape()),
  businessLinesFilter: PropTypes.arrayOf(PropTypes.shape()),
  applicationsFilter: PropTypes.arrayOf(PropTypes.shape()),
  handleChangeApplicationsFilter: PropTypes.func.isRequired,
  handleChangeBusinessLinesFilter: PropTypes.func.isRequired,
  isAdminDashboard: PropTypes.bool,
  isDashboard: PropTypes.bool,
};

FiltersCard.defaultProps = {
  listOfApplications: [],
  businessLinesList: [],
  businessLinesFilter: null,
  applicationsFilter: null,
  isAdminDashboard: false,
  isDashboard: false,
};

export default FiltersCard;
