/* eslint-disable camelcase */
export default class AttributesFactory {
  constructor({
    poste = "",
    phoneNumber = "",
    last_login = "",
    locale = "fr",
    partnerId,
    ...additionalAttributes
  } = {}) {
    this.poste = poste;
    this.phoneNumber = phoneNumber;
    this.last_login = last_login;
    this.locale = locale;
    this.partnerId = partnerId;
    Object.keys(additionalAttributes).forEach((key) => {
      this[key] = this[key] || additionalAttributes[key];
    });
  }
}
