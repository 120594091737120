import * as Yup from "yup";

const RibCompleteSchema = Yup.object().shape({
  bankCode: Yup.string()
    .nullable()
    .required("Champ requis")
    .test(
      "len",
      "5 caractères requis",
      (val) => !val || (val && (val.length === 0 || val.length === 5)),
    ),
  counterCode: Yup.string()
    .nullable()
    .required("Champ requis")
    .test(
      "len",
      "5 caractères requis",
      (val) => !val || (val && (val.length === 0 || val.length === 5)),
    ),
  accountNumber: Yup.string()
    .nullable()
    .required("Champ requis")
    .test(
      "len",
      "11 caractères requis",
      (val) => !val || (val && (val.length === 0 || val.length === 11)),
    ),
  key: Yup.string()
    .nullable()
    .required("Champ requis")
    .test(
      "len",
      "2 caractères requis",
      (val) => !val || (val && (val.length === 0 || val.length === 2)),
    ),
});

export default RibCompleteSchema;
