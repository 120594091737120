import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@mui/material";

const PageTitle = (props) => {
  const { subtitle, children, className, ...others } = props;

  return (
    <Box mb={6}>
      <Typography variant="h1" className={className} {...others}>
        {children}
        {subtitle && (
          <>
            <Box
              component="span"
              sx={(theme) => ({
                [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
                  display: "none",
                },
              })}
            >
              {" - "}
            </Box>
            <Typography
              sx={(theme) => ({
                [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
                  display: "block",
                  marginTop: theme.spacing(1),
                },
                color: theme.palette["secondary-ultramarine"],
              })}
              variant="h2"
              component="span"
            >
              {subtitle}
            </Typography>
          </>
        )}
      </Typography>
    </Box>
  );
};

export default PageTitle;

PageTitle.propTypes = {
  subtitle: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

PageTitle.defaultProps = {
  subtitle: "",
  children: null,
  className: "",
};
