import React from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";

import "rapidoc";
import "../rapidoc.css";
import Breadcrumb from "components/Breadcrumb";

const MyApiDoc = (props) => {
  const { specUrl } = props;

  const theme = useTheme();

  return (
    <rapi-doc
      style={{
        height: "calc(100vh - 104px - 4.625rem)",
        width: "100%",
        minWidth: "auto",
      }}
      spec-url={specUrl}
      theme="light"
      render-style="read"
      show-header="false"
      font-size="largest"
      layout="column"
      nav-bg-color={theme.palette["bleu-5"]}
      primary-color={theme.palette["primaire-bleu"]}
      bg-color={theme.palette["bleu-3"]}
      nav-text-color={theme.palette["cobalt-marque-blanche-main"]}
      nav-hover-bg-color={theme.palette["bleu-3"]}
      regular-font="Montserrat"
      allow-try="true"
      nav-item-spacing="compact"
      show-method-in-nav-bar="as-colored-text"
      info-description-headings-in-navbar="true"
    >
      <div id="rapidoc-content">
        <Breadcrumb />
      </div>
    </rapi-doc>
  );
};

MyApiDoc.propTypes = {
  specUrl: PropTypes.string.isRequired,
};

export default MyApiDoc;
