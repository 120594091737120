import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Link from "components/Link";
import docaposteSvg from "media/docaposte.svg";
import { Box, Button, Grid, Typography } from "@mui/material";
import TcWrapper from "react-tag-commander";
import INTipsIcon from "icons/IN_Tips";
import Modal from "components/Modal";
import ModalHeader from "components/ModalHeader";
import Divider from "@mui/material/Divider";

const StyledLinkText = styled(Link)(({ theme }) => ({
  color: theme.palette["secondary-lynch"],
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

const StyledLinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette["secondary-lynch"],
  fontWeight: 500,
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    textAlign: "start",
  },
  whiteSpace: "no-wrap",
}));

const Footer = (props) => {
  const { apiLayout } = props;
  const theme = useTheme();
  const wrapper = TcWrapper.getInstance();
  const [openContactUsModal, setOpenContactUsModal] = useState(false);

  const handleCloseContactUsModal = () => {
    setOpenContactUsModal(false);
  };

  const handleOpenContactUsModal = () => {
    setOpenContactUsModal(true);
  };

  const handleContactUsClick = (event) => {
    wrapper.triggerEvent("click", event.target, {
      clic_type: "action",
      chapter1: "footer",
      name: "contactez_nous",
    });
    handleOpenContactUsModal(true);
  };

  const handleDocaposteClick = (event) => {
    wrapper.triggerEvent("click", event.target, {
      clic_type: "action",
      chapter1: "footer",
      name: "docaposte",
    });
  };

  return (
    <>
      <Grid
        container
        sx={{
          overflow: "hidden",
          paddingTop: apiLayout ? 0 : "56px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette["secondary-light-background"],
            boxShadow: "0 -2px 8px 0 rgba(0, 0, 45, 0.06)",
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",
              position: "relative",
              height: "4.625rem",
              paddingLeft: "20px",
              paddingRight: "20px",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "space-evenly",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "start",
                textAlign: "start",
                height: "100%",
              },
            }}
          >
            <Typography
              sx={{
                margin: "10px",
                [theme.breakpoints.down("xs")]: {
                  margin: "2px",
                },
              }}
            >
              <StyledLinkText to="/mentions-legales" underline="none">
                Mentions Légales
              </StyledLinkText>
            </Typography>
            <Typography
              sx={{
                margin: "10px",
                [theme.breakpoints.down("xs")]: {
                  margin: "2px",
                },
              }}
            >
              <StyledLinkText to="/cgu" underline="none">
                C.G.U.
              </StyledLinkText>
            </Typography>
            <Typography
              sx={{
                margin: "10px",
                [theme.breakpoints.down("xs")]: {
                  margin: "2px",
                },
              }}
            >
              <StyledLinkText to="/accessibilite" underline="none">
                Accessibilité : audit à venir
              </StyledLinkText>
            </Typography>
            <Typography
              sx={{
                margin: "10px",
                [theme.breakpoints.down("xs")]: {
                  margin: "2px",
                },
              }}
            >
              <StyledLinkButton
                onClick={(event) => handleContactUsClick(event)}
                aria-haspopup="dialog"
              >
                Contactez-nous
              </StyledLinkButton>
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "0 !important",
              height: "24px",
              width: "114px",
              position: "absolute",
              right: "6rem",
              [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
                position: "static",
                marginRight: "20px",
              },
            }}
            onClick={(event) => handleDocaposteClick(event)}
          >
            <img
              src={docaposteSvg}
              title="Tech & Trust by Docaposte"
              alt="This website is Tech & Trust by Docaposte"
            />
          </Box>
        </Box>
      </Grid>
      <Modal
        data-testid="modal"
        open={openContactUsModal}
        disableBackdropClick
        onClose={handleCloseContactUsModal}
        size="sm"
      >
        <ModalHeader
          title="Contactez-nous"
          handleCloseModal={handleCloseContactUsModal}
        />
        <Divider />
        <div style={{ padding: theme.spacing(3.5, 4, 4, 4) }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "0.875rem",
              lineHeight: "1.0625rem",
            }}
          >
            Vous rencontrez un problème ou un blocage ?
          </Typography>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "0.875rem",
              lineHeight: "1.0625rem",
            }}
          >
            Rendez-vous sur votre{" "}
            <a
              href="https://jira.docaposte.fr/plugins/servlet/desk"
              className="underlined-link"
            >
              Espace Client Support
            </a>{" "}
            pour nous le signaler.
          </Typography>
          <div
            style={{
              display: "flex",
              background: "#FAFAFF",
              borderRadius: "10px",
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3.5),
              padding: theme.spacing(2, 4, 2, 3),
            }}
          >
            <div style={{ fontSize: "1.2rem" }}>
              <INTipsIcon />
            </div>
            <Typography
              sx={{
                ml: "8px",
                fontWeight: "400",
                fontSize: "0.875rem",
                lineHeight: "1.0625rem",
              }}
            >
              Vous devez avoir un compte à votre nom pour y accéder. Si ce n’est
              pas le cas, nous vous invitons à contacter la personne avec le
              rôle de &ldquo;Responsable de contrat&ldquo; afin qu&apos;il
              puisse vous créer un accès.
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseContactUsModal}
            >
              Annuler
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

Footer.propTypes = {
  apiLayout: PropTypes.bool,
};

Footer.defaultProps = {
  apiLayout: false,
};

export default Footer;
