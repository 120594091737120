import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { withRouter } from "react-router-dom";
import Link from "components/Link";

const Container = (props) => {
  const { children, className, ...others } = props;

  const [position, setPosition] = useState({});

  const anchorRef = useRef();

  useEffect(() => {
    if (anchorRef.current) {
      const { x, y } = anchorRef.current.getBoundingClientRect();
      setPosition({ top: y, left: x });
    }
  }, [anchorRef]);

  return (
    <>
      <div ref={anchorRef} />
      <Box
        className={className}
        {...others}
        sx={(theme) => ({
          borderLeft: `2px solid ${theme.palette["secondary-ultramarine"]}`,
          padding: "8px 0 0 18px",
          position: "fixed",
          display:
            position.top !== undefined && position.left !== undefined
              ? "block"
              : "none",
        })}
      >
        {children}
      </Box>
    </>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Container.defaultProps = {
  children: null,
  className: "",
};

const Section = (props) => {
  const { children, Icon, title, target, location, funcOnClick, ...others } =
    props;

  const { pathname } = location;

  return (
    <Grid container spacing={2} {...others}>
      <Grid item>
        {Icon && (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette["primaire-jaune"],
              borderRadius: "20px",
              height: "31px",
              padding: "5px",
            }}
          >
            <Icon
              sx={{
                fontSize: "21px",
                pointerEvents: "none",
                color: (theme) => theme.palette["secondary-ultramarine"],
              }}
            />
          </Box>
        )}
      </Grid>
      <Grid item>
        <Box mb={1.5}>
          <Typography
            variant="subtitle1"
            color="text.primary"
            component={Link}
            to={`${pathname}#${target}`}
            onClick={funcOnClick}
            sx={{ textDecoration: "none" }}
            smooth
          >
            {title}
          </Typography>
        </Box>
        {children}
      </Grid>
    </Grid>
  );
};

Section.propTypes = {
  children: PropTypes.node,
  Icon: PropTypes.elementType,
  title: PropTypes.string,
  target: PropTypes.string.isRequired,
  location: PropTypes.shape().isRequired,
  funcOnClick: PropTypes.func,
};

Section.defaultProps = {
  children: null,
  Icon: null,
  title: "",
  funcOnClick: null,
};

const Item = (props) => {
  const { children, target, location, funcOnClick, ...others } = props;

  const { pathname } = location;

  return (
    <Typography
      variant="subtitle2"
      color="primary"
      component={Link}
      to={`${pathname}#${target}`}
      onClick={funcOnClick}
      smooth
      sx={{ textDecoration: "none" }}
      {...others}
    >
      <Box mb={2}>{children}</Box>
    </Typography>
  );
};

Item.propTypes = {
  children: PropTypes.node,
  target: PropTypes.string.isRequired,
  location: PropTypes.shape().isRequired,
  funcOnClick: PropTypes.func,
};

Item.defaultProps = {
  children: null,
  funcOnClick: null,
};

const InnerNavigation = (props) => {
  const {
    container,
    section,
    Icon,
    item,
    target,
    match,
    location,
    history,
    staticContext,
    ...others
  } = props;

  if (container) {
    return <Container {...others} />;
  }
  if (section) {
    return (
      <Section location={location} Icon={Icon} target={target} {...others} />
    );
  }
  if (item) {
    return <Item location={location} target={target} {...others} />;
  }

  return <div {...others} />;
};

InnerNavigation.propTypes = {
  container: PropTypes.bool,
  section: PropTypes.bool,
  Icon: PropTypes.elementType,
  title: PropTypes.string,
  item: PropTypes.bool,
  target: PropTypes.string,
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  staticContext: PropTypes.shape(),
};

InnerNavigation.defaultProps = {
  container: false,
  section: false,
  Icon: null,
  title: "",
  item: false,
  target: "",
  staticContext: undefined,
};

export default withRouter(InnerNavigation);
