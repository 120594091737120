export const sirenLength = 9;
export const siretLength = 14;

function validateLuhn(value) {
  // Accept only digits or spaces
  if (/[^0-9\s]+/.test(value)) return false;

  let nCheck = 0;
  let bEven = false;
  const cleanValue = value.replace(/\D/g, "");

  for (let n = cleanValue.length - 1; n >= 0; n -= 1) {
    const cDigit = cleanValue.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven) {
      nDigit *= 2;
      if (nDigit > 9) nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
}

export const validateSiren = (siren) => {
  if (/[^0-9\s]+/.test(siren)) return false;

  const trimedSiren = siren.split(" ").join("");
  if (!trimedSiren.length === sirenLength) return false;

  return validateLuhn(siren);
};

export const validateSiret = (siret) => {
  if (/[^0-9\s]+/.test(siret)) return false;

  const trimedSiret = siret.split(" ").join("");

  return (
    trimedSiret.length === siretLength &&
    validateSiren(trimedSiret.substring(0, sirenLength))
  );
};

export default {
  sirenLength,
  siretLength,
  validateSiren,
  validateSiret,
};
