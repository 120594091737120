export default class ContactFactory {
  constructor({
    lastName = "",
    firstName = "",
    email = "",
    phoneNumber = null,
    roleInCompany = "",
    ...additionalAttributes
  } = {}) {
    this.lastName = lastName;
    this.firstName = firstName;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.roleInCompany = roleInCompany;
    Object.keys(additionalAttributes).forEach((key) => {
      this[key] = this[key] || additionalAttributes[key];
    });
  }
}
