import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette["secondary-ultramarine"],
  margin: theme.spacing(3, 0, 1, 0),
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 400,
}));

const StyledListItem = styled(Typography)({
  fontWeight: 400,
});

const StyledLighterText = styled("span")(({ theme }) => ({
  color: theme.palette["cobalt-marque-blanche-second"],
}));

const CGU = () => {
  return (
    <>
      <Typography
        variant="h1"
        sx={(theme) => ({
          color: theme.palette["secondary-ultramarine"],
          textAlign: "center",
          marginBottom: theme.spacing(3),
          fontSize: "2rem",
        })}
      >
        Conditions Générales d’Utilisation Plateforme Partenaire Business
        L&apos;Identité Numérique
      </Typography>
      <StyledParagraph>
        Dans le cadre de la mise en marché de la solution Identité Numérique La
        Poste auprès des partenaires privés, La Poste a conçu et développé une
        plateforme de gestion et de pilotage de la commercialisation qui permet
        de répondre aux objectifs suivants :
      </StyledParagraph>
      <ul>
        <li>
          <StyledListItem>
            Centraliser la création et le paramétrage des comptes partenaires
            ainsi que des accès utilisateurs
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            Mettre à disposition les services API et toute la documentation
            (technique & graphique) permettant au partenaire l’implémentation de
            la solution Identité Numérique
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            Offrir au partenaire un outil de pilotage et de suivi de sa
            consommation de L’Identité Numérique
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            Héberger les données de consommation et alimenter l’outil de
            refacturation
          </StyledListItem>
        </li>
      </ul>
      <StyledParagraph>
        Les présentes Conditions Générales d’Utilisation, ont pour objet de
        définir les relations contractuelles entre La Poste et l’Utilisateur
        dans le cadre de la création et de l’utilisation d’un compte sur la
        Plateforme.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        1. DÉFINITIONS
      </StyledSubtitle>
      <StyledParagraph>
        Chaque terme débutant ci-après par une majuscule a le sens qui lui est
        donné dans sa définition qui figure au présent article.
      </StyledParagraph>
      <StyledParagraph>
        <strong>Adresse de courrier électronique (email)</strong>: désigne
        l’adresse de courrier électronique renseignée par l’Utilisateur lors de
        la phase de création de son Compte et sur laquelle lui sera transmis
        toute notification (alerte, avis de réception, information sur le
        Compte, etc.). Cette Adresse servira également d&apos;Identifiant du
        Compte.
      </StyledParagraph>
      <StyledParagraph>
        <strong>Compte</strong>: désigne le compte individuel d’un Utilisateur,
        lui permettant d’accéder aux Services.
      </StyledParagraph>
      <StyledParagraph>
        <strong>Conditions Générales d’Utilisation</strong> (ou «{" "}
        <strong>CGU</strong> »): désignent les présentes CGU (y compris tout
        document qui est expressément inclus par référence dans les présentes),
        et tous éventuels avenants s&apos;y rapportant.
      </StyledParagraph>
      <StyledParagraph>
        <strong>Identifiant</strong>: désigne l’Adresse de courrier électronique
        renseignée par l’Utilisateur.
      </StyledParagraph>
      <StyledParagraph>
        <strong>Inscription</strong>: désigne la procédure qui permet à
        L’Identité Numérique de créer un Compte à un Utilisateur à partir des
        données communiquées par son employeur ou un représentant de ce dernier.
      </StyledParagraph>
      <StyledParagraph>
        <strong>Mot de passe</strong>: désigne une suite de caractères
        renseignée par l’Utilisateur, constituant alors son code personnel,
        exclusif et confidentiel qui, associé à l’Identifiant, lui permet
        d’accéder à son Compte.
      </StyledParagraph>
      <StyledParagraph>
        <strong>Services</strong>: désigne les services présents sur la
        plateforme 2B L&apos;Identité Numérique.
      </StyledParagraph>
      <StyledParagraph>
        <strong>Utilisateur</strong>: désigne une personne qui dispose d’un
        Compte
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        2. ACCEPTATION ET MODIFICATION DES CGU
      </StyledSubtitle>
      <StyledSubtitle variant="h4" component="h3">
        2.1. Acceptation des CGU
      </StyledSubtitle>
      <StyledParagraph>
        La création de Compte est soumise au respect des Conditions Générales
        d&apos;Utilisation. L’acceptation des CGU est un préalable indispensable
        et obligatoire.
      </StyledParagraph>
      <StyledParagraph>
        L’Utilisateur accepte sans réserve les présentes CGU en cochant la case
        « J’ai lu et j’accepte les Conditions Générales d’Utilisation » lors de
        son premier accès au Compte.
      </StyledParagraph>
      <StyledSubtitle variant="h4" component="h3">
        2.2. Modification des CGU
      </StyledSubtitle>
      <StyledParagraph>
        La Poste se réserve le droit d’apporter des modifications aux Conditions
        Générales d’Utilisation. Toute nouvelle version des CGU sera soumise à
        l’Utilisateur qui devra les accepter afin de continuer à accéder et
        utiliser son Compte.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        3. CREATION D’UN COMPTE
      </StyledSubtitle>
      <StyledSubtitle variant="h4" component="h3">
        3.1. Création d’un Compte
      </StyledSubtitle>
      <StyledParagraph>
        La création d’un Compte est une étape obligatoire, gratuite et préalable
        à l’utilisation des Services.
        <br />
        La création d’un Compte suppose que l’Utilisateur crée un mot de passe
        strictement confidentiel.
        <br />
        L’Utilisateur garde l’entière responsabilité des conséquences de la
        divulgation de son mot de passe.
        <br />
        L’Inscription est validée lorsque l’Utilisateur a accepté les CGU.
      </StyledParagraph>
      <StyledSubtitle variant="h4" component="h3">
        3.2 Informations à fournir
      </StyledSubtitle>
      <StyledSubtitle
        variant="h5"
        component="h4"
        sx={{ marginTop: (theme) => theme.spacing(2) }}
      >
        3.2.1. Informations sur le formulaire d&apos;Inscription
      </StyledSubtitle>
      <StyledParagraph>
        L’identité Numérique s’occupe de l’Inscription.
        <br />
        <StyledLighterText>
          Du fait que la création de Compte soit faite sur la base de données
          communiquées par l’employeur de l’Utilisateur, l’Utilisateur s’engage
          à prendre connaissance des informations personnelles le concernant
          (dans la rubrique Profil de son espace) et à les valider ou le cas
          échéant à apporter les modifications et corrections utiles selon la
          procédure édictée dans le paragraphe ci-dessous.
        </StyledLighterText>
      </StyledParagraph>
      <StyledParagraph>
        L’Utilisateur garantit que les informations personnelles sont exactes,
        sincères et à jour, tant pendant la phase d’Inscription qu’à tout moment
        ultérieurement en sa qualité d’Utilisateur. L’Utilisateur est le seul
        responsable des conséquences d’informations personnelles erronées.
        L’Utilisateur s’engage à notifier immédiatement toute modification
        affectant ses informations, en créant un ticket depuis le portail
        Relation Client&nbsp;:&nbsp;
        <a href="https://jira.docaposte.fr/plugins/servlet/desk">
          https://jira.docaposte.fr/plugins/servlet/desk
        </a>
      </StyledParagraph>
      <StyledParagraph>
        Si ces informations devaient s’avérer fausses, incomplètes ou obsolètes,
        La Poste se réserve le droit de refuser l’Inscription et/ou l’accès au
        Compte conformément aux stipulations de l’article « Résiliation -
        Manquement - Suspension » des CGU.
      </StyledParagraph>
      <StyledSubtitle variant="h5" component="h4">
        3.2.2. Modification des informations et changement de Mot de passe
      </StyledSubtitle>
      <StyledParagraph>
        En cas de changement de l’Adresse de courrier électronique,
        l’Identifiant et l’adresse de contact seront modifiés.
      </StyledParagraph>
      <StyledParagraph>
        En cas de perte/oubli du Mot de passe, l’Utilisateur devra saisir son
        Adresse de courrier électronique dans le champ prévu à cet effet. Un
        courrier électronique lui sera alors envoyé à l’Adresse de courrier
        électronique indiquée pour créer son nouveau Mot de passe.
      </StyledParagraph>
      <StyledSubtitle variant="h4" component="h3">
        3.3. Confirmation de la création de Compte
      </StyledSubtitle>
      <StyledParagraph>
        Au terme de la procédure d’Inscription l’Utilisateur reçoit un lien pour
        activer son compte et choisir son mot de passe.
      </StyledParagraph>
      <StyledParagraph>
        Une fois cette démarche effectuée, le compte est activé et l’Utilisateur
        doit retourner sur la page de connexion pour se connecter avec son
        Identifiant et le mot de passe qu’il vient de paramétrer.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        4. DISPONIBILITE DU SERVICE
      </StyledSubtitle>
      <StyledParagraph>
        Hors les cas de force majeure visés à l’article 7 des CGU, La Poste
        s’engage, dans le cadre d’une obligation de moyens, à assurer la
        disponibilité et l’accessibilité de la Plateforme, et ce, 24/24 heures
        et 7/7 jours.
      </StyledParagraph>
      <StyledParagraph>
        Tout Utilisateur reconnaît être informé des limites de l&apos;internet,
        de ses caractéristiques fonctionnelles et performances techniques, et de
        ses risques tels que notamment des risques d’interruption, des temps de
        réponse pour consulter, interroger ou transférer des informations, des
        risques, quels qu’ils soient, inhérents à tout transfert de données
        notamment sur réseau ouvert.
      </StyledParagraph>
      <StyledParagraph>
        La Poste se réserve la faculté de suspendre l’accès au Compte
        lorsqu’elle estime qu’un événement est susceptible d’en affecter le
        fonctionnement, l’intégrité ou la maintenance, et ce, pour la durée
        nécessaire à l’intervention envisagée. Le cas échéant, dès qu’elle aura
        connaissance des dates d’intervention programmées, La Poste s’engage à
        prévenir les Utilisateurs dans les meilleurs délais.
      </StyledParagraph>
      <StyledParagraph>
        Tous les coûts et frais d’équipements, de communications et autres,
        nécessaires à la connexion, l’accès et l’utilisation du Compte sont et
        restent à la charge de l’Utilisateur.
      </StyledParagraph>
      <StyledParagraph>
        Tout Utilisateur s’engage à ne pas entraver le bon fonctionnement du
        Compte et des Services de quelque manière que ce soit, notamment en
        transmettant tout élément susceptible de contenir un virus ou de nature
        à endommager ou affecter les Services et, plus largement, le système
        d’information de La Poste et/ou de ses cocontractants.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        5. UTILISATION DU COMPTE ET DESCRIPTION DES SERVICES
      </StyledSubtitle>
      <StyledSubtitle variant="h4" component="h3">
        5.1 Utilisation du Compte
      </StyledSubtitle>
      <StyledParagraph>
        Pour accéder aux Services, l’Utilisateur peut s’identifier en utilisant
        son Identifiant et son Mot de passe. Ces éléments d’Identification sont
        strictement personnels et confidentiels. L’Utilisateur s&apos;engage:
      </StyledParagraph>
      <ul>
        <li>
          <StyledListItem>à les conserver secrets ;</StyledListItem>
        </li>
        <li>
          <StyledListItem>
            à ne pas les communiquer à quelque tiers que ce soit et par quelque
            moyen que ce soit ;
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            à assumer intégralement les conséquences de toute divulgation
            effectuée en violation des Conditions Générales d’Utilisation sauf
            faute démontrée d&apos;un tiers ;
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            à informer sans délai La Poste de toute compromission, perte ou
            anomalie constatée de ceux-ci.
          </StyledListItem>
        </li>
      </ul>
      <StyledParagraph>
        L’Utilisateur peut également accéder aux Services en se connectant via
        son Identité Numérique La Poste, s’il en possède une.
        <br />
        Pour se faire, l’Utilisateur doit synchroniser son Identité Numérique La
        Poste à son compte Espace Partenaire soit depuis son profil soit au
        moment de sa connexion avec son Identifiant et Mot de passe.
      </StyledParagraph>
      <StyledParagraph>
        L’Utilisateur reconnaît que toute utilisation du Compte est effectuée
        sous sa responsabilité et que les actions effectuées par le biais de son
        Compte lui seront imputées, en l’absence d’information de toute
        compromission, perte ou anomalie à La Poste.
      </StyledParagraph>
      <StyledParagraph>
        En cas de compromission avérée, de suspicion de compromission de l’un
        des éléments d’Identification de l’Utilisateur ou en cas de fraude
        avérée ou de suspicion de fraude, de tentative d’atteinte ou d’accès
        frauduleux au Compte ou de manquement de L’Utilisateur à l’une des
        obligations détaillées dans le Contrat, La Poste se réserve la faculté
        de suspendre discrétionnairement et sans délai l’accès de l’Utilisateur
        à son Compte et d’effectuer toute action qu’elle jugerait utile à la
        défense de ses intérêts et/ou de ceux de l’Utilisateur en question.
      </StyledParagraph>
      <StyledParagraph>
        La Poste recommande à chaque Utilisateur de prendre toutes les
        précautions nécessaires en matière de sécurité informatique (utilisation
        d’un anti-virus, d’un firewall, etc.) et de faire preuve de prudence et
        de bon sens lors de l’utilisation de ses Identifiants et Mots de passe.
      </StyledParagraph>
      <StyledSubtitle variant="h4" component="h3">
        5.2 Description des Services
      </StyledSubtitle>
      <StyledParagraph>
        En se connectant à son Compte, L’Utilisateur a accès, en fonction des
        droits qui lui sont octroyés, à :
      </StyledParagraph>
      <ul>
        <li>
          <StyledListItem>
            un tableau de bord permettant de suivre sa consommation de
            L’Identité Numérique, sur la base d’un certain nombre de KPI (nombre
            d’utilisateurs uniques, nombre d’utilisations, moyenne
            d’utilisation)
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            la documentation technique et graphique permettant d’implémenter la
            solution Identité Numérique conformément aux exigences demandées
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            la liste des collaborateurs habilités à se connecter à la plateforme
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            aux informations relatives au Contrat souscrit
          </StyledListItem>
        </li>
      </ul>
      <StyledSubtitle variant="h3" component="h2">
        6. RESPONSABILITÉ
      </StyledSubtitle>
      <StyledSubtitle variant="h4" component="h3">
        6.1. Responsabilité de La Poste
      </StyledSubtitle>
      <StyledParagraph>
        La Poste ne pourra être tenue responsable des informations renseignées
        sur le Compte et de l’usage qui en est fait de son Compte par
        l’Utilisateur.
      </StyledParagraph>
      <StyledParagraph>
        En aucun cas, La Poste n’est responsable des dommages indirects pouvant
        être subis par l’Utilisateur tels que notamment : préjudice financier,
        commercial, perte de clientèle, trouble commercial quelconque, perte de
        bénéfice, perte d’image de marque, qui pourraient résulter de
        l’utilisation du Compte.
      </StyledParagraph>
      <StyledParagraph>
        La Poste ne peut être responsable des retards, altération ou autres
        erreurs pouvant se produire dans la transmission des informations
        lorsque ces événements résultent des réseaux de tiers ou d’une
        défaillance ou négligence de l’Utilisateur.
      </StyledParagraph>
      <StyledParagraph>
        En outre, La Poste ne pourra être responsable des dommages pouvant être
        causés du fait :
      </StyledParagraph>
      <ul>
        <li>
          <StyledListItem>
            de l’utilisation du Compte par l’Utilisateur de manière non conforme
            aux présentes CGU et à la réglementation en vigueur ;
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            d’informations inexactes ou mensongères renseignées sur le Compte de
            l’Utilisateur ou de déclarations mensongères
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            du paramétrage de l’ordinateur de l’Utilisateur lui permettant de
            s&apos;identifier automatiquement et de laisser ainsi tout tiers
            utiliser les Services avec son Compte ou en usurpant son identité.
          </StyledListItem>
        </li>
      </ul>
      <StyledSubtitle variant="h4" component="h3">
        6.2. Responsabilité de l’Utilisateur
      </StyledSubtitle>
      <StyledParagraph>
        L’Utilisateur est responsable de l’utilisation de son Compte ainsi que
        de l&apos;utilisation de ses éléments d’Identification.
      </StyledParagraph>
      <StyledParagraph>
        Afin de répondre à une alerte de sécurité (contrôle anti-virus, etc.), à
        la demande d’une autorité (administrative, judiciaire ou toute autorité
        habilitée), à une alerte émanant d’un autre Utilisateur, la Poste se
        réserve le droit le cas échéant :
      </StyledParagraph>
      <ul>
        <li>
          <StyledListItem>
            de conserver, à des fins de preuve, toute information ne respectant
            pas les règles fixées par les Conditions Générales d’Utilisation
            mais également tous logs ou traces de connexion en relation avec la
            gestion ou le contrôle du Compte de l’Utilisateur;
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            de conserver, à des fins de preuve, tous logs, traces de connexion
            ou données à caractère personnel ou non prouvant l’imputabilité à
            l’Utilisateur des comportements ou actions en cause ;
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            de modifier, rejeter et/ou supprimer l’accès à toute information ne
            respectant pas les règles fixées aux présentes CGU ;
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            d’appliquer les stipulations de l’article 9 « Résiliation -
            Manquement – Suspension » des CGU
          </StyledListItem>
        </li>
      </ul>
      <StyledSubtitle variant="h3" component="h2">
        7. FORCE MAJEURE
      </StyledSubtitle>
      <StyledParagraph>
        La Poste ne saurait être tenue responsable de tout retard dans
        l’exécution de ses obligations ou de toute inexécution de ses
        obligations résultant des présentes Conditions Générales d’Utilisation
        lorsque les circonstances y donnant lieu relèvent de la force majeure.
      </StyledParagraph>
      <StyledParagraph>
        De façon expresse, sont considérés comme cas de force majeure ou cas
        fortuit, outre ceux habituellement retenus par la jurisprudence des
        cours et tribunaux français, les cas suivants : blocage des
        communications électroniques, y compris des réseaux de communications
        électroniques, non prévisible par La Poste, remettant en cause les
        normes et standards de sa profession et tout autre cas indépendant de la
        volonté des parties empêchant l’exécution normale des obligations
        découlant du Contrat.
      </StyledParagraph>
      <StyledParagraph>
        Tout cas de force majeure affectant l’exécution des obligations
        résultant des présentes Conditions Générales d’Utilisation par
        l’Utilisateur suspendra, dès sa date de survenance, l’exécution des
        Services.
      </StyledParagraph>
      <StyledParagraph>
        A partir de cette date, et malgré le cas de force majeure, La Poste
        s’efforcera dans la mesure du possible d’informer l’Utilisateur de
        l’existence de ce cas de force majeure et/ou de rétablir un accès, même
        dégradé, aux Services.
      </StyledParagraph>
      <StyledParagraph>
        Il est expressément convenu entre les Parties que la mise en œuvre tout
        à fait exceptionnelle de ces moyens palliatifs par La Poste pendant la
        survenance d’un cas de force majeure ne pourra donner lieu à aucune
        responsabilité ou indemnisation de la part de La Poste.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        8. RÉSILIATION - MANQUEMENT – SUSPENSION
      </StyledSubtitle>
      <StyledSubtitle variant="h4" component="h3">
        8.1. À l’initiative de l’Utilisateur : Désinscription – Clôture du
        Compte – fin de contrat
      </StyledSubtitle>
      <StyledParagraph sx={{ fontWeight: "bold" }}>
        L’Utilisateur peut, à tout moment, demander la suppression de son compte
        en contactant le service support à l’adresse suivante :{" "}
        <a href="https://jira.docaposte.fr/plugins/servlet/desk">
          https://jira.docaposte.fr/plugins/servlet/desk
        </a>
      </StyledParagraph>
      <StyledSubtitle variant="h4" component="h3">
        8.2. Résiliation à l’initiative de La Poste
      </StyledSubtitle>
      <StyledParagraph>
        La Poste se réserve le droit de mettre unilatéralement fin à la présente
        relation contractuelle au cas où l’Utilisateur ferait preuve de
        manquements graves et / ou répétés à l’une quelconque de ses obligations
        notamment visées par les présentes.
      </StyledParagraph>
      <StyledParagraph>
        Cette résiliation se fera de plein droit, sans préjudice des dommages et
        intérêts que La Poste pourrait solliciter. Les éléments de preuve
        mentionnés à l’article Convention de preuve des présentes Conditions
        Générales d’Utilisation pourront être conservés en tant que de besoin
        par La Poste.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        9. DROITS DE PROPRIÉTÉ INTELLECTUELLE
      </StyledSubtitle>
      <StyledParagraph>
        La Poste est et restera propriétaire de ses signes distinctifs, à savoir
        marques, dénominations sociales et autres, noms commerciaux, enseignes
        et noms de domaine associés au Compte et aux Services.
      </StyledParagraph>
      <StyledParagraph>
        La reproduction, l&apos;imitation ou l&apos;apposition, partielle ou
        totale des marques et dessins et modèles appartenant à La Poste est
        strictement interdite sans son accord écrit préalable.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        10. SOUS-TRAITANCE
      </StyledSubtitle>
      <StyledParagraph>
        La Poste se réserve le droit de faire exécuter tout ou partie des
        prestations objet des présentes par toute autre société de son choix en
        sous-traitance, La Poste restant seule responsable à l’égard de
        l’Utilisateur, à charge pour elle de se retourner contre ses
        sous-traitants.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        11. NON RENONCIATION
      </StyledSubtitle>
      <StyledParagraph>
        Le fait pour l’Utilisateur et/ou La Poste de ne pas se prévaloir d’un
        manquement par la partie défaillante à l’une quelconque des obligations
        résultant des Conditions Générales d’Utilisation ne saurait être
        interprété comme une renonciation à l’obligation en cause.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        12. PERMANENCE
      </StyledSubtitle>
      <StyledParagraph>
        La nullité d’une clause quelconque des Conditions Générales
        d’Utilisation n’affecte pas la validité des autres clauses ; elle se
        poursuit en l’absence du dispositif annulé sauf si la clause annulée
        rend la poursuite des relations contractuelles impossible ou
        déséquilibrée par rapport aux conventions initiales.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        13. RÉCLAMATION
      </StyledSubtitle>
      <StyledParagraph>
        Toute réclamation doit être adressée au Service support à l’adresse{" "}
        <a href="https://jira.docaposte.fr/plugins/servlet/desk">
          https://jira.docaposte.fr/plugins/servlet/desk
        </a>
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        14. CONVENTION DE PREUVE
      </StyledSubtitle>
      <StyledParagraph>
        La Poste et/ou l’Utilisateur entendent fixer, dans du Service, les
        règles relatives aux preuves recevables entre eux en cas de litige et à
        leur force probante. Les stipulations qui suivent constituent ainsi la
        convention de preuve passée entre les parties, lesquelles s’engagent à
        respecter le présent article.
      </StyledParagraph>
      <StyledParagraph>
        La Poste et l’Utilisateur acceptent qu’en cas de litige:
      </StyledParagraph>
      <ul>
        <li>
          <StyledListItem>
            les moyens d’Identification (Identifiant, Mot de passe) utilisés
            dans le cadre du Service soient admissibles devant les tribunaux et
            fassent preuve des données et des faits qu’ils contiennent ainsi que
            des signatures et procédés d’Identification qu’ils expriment.
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            les données de connexion relatives à des actions effectuées à partir
            du Compte de l’Utilisateur soient admissibles devant les tribunaux
            et fassent preuve des données et des faits qu’ils contiennent.
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            tout ou partie des informations issues du Compte lui soient
            présumées imputables.
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            les dates générées ou inscrites électroniquement de toute
            information ou décision soient admissibles devant les tribunaux et
            fassent preuve des données et des faits qu’elles contiennent.
          </StyledListItem>
        </li>
        <li>
          <StyledListItem>
            les informations archivées dans le cadre de l’un des Services, les
            courriers électroniques, les accusés de réception échangés soient
            admissibles devant les tribunaux et fassent preuve des données et
            des faits qu’ils contiennent.
          </StyledListItem>
        </li>
      </ul>
      <StyledParagraph>
        La preuve contraire peut être rapportée.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        15. RÈGLEMENT DES LITIGES – TRIBUNAL COMPÉTENT
      </StyledSubtitle>
      <StyledParagraph>
        A défaut de règlement amiable et à l&apos;exception des cas où cette
        clause est réputée non écrite en application de l&apos;article 48 du
        Code de procédure civile, en cas de litige relatif à l’interprétation,
        la formation ou l’exécution des Conditions Générales d’Utilisation , la
        partie la plus diligente saisira les tribunaux compétents.
      </StyledParagraph>
      <StyledSubtitle variant="h3" component="h2">
        16. LOI APPLICABLE
      </StyledSubtitle>
      <StyledParagraph>
        En cas de litige relatif à l’interprétation, la formation, la validité
        ou l’exécution des Conditions Générales d’Utilisation, La Poste et
        l’Utilisateur reconnaissent de manière expresse que seule la loi
        française est applicable.
      </StyledParagraph>
    </>
  );
};

export default CGU;
