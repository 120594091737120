import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M12 4c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8c0-2.122-.843-4.157-2.343-5.657S14.122 4 12 4zm.667 11.427c0 .368-.299.666-.667.666-.368 0-.667-.298-.667-.666v-4c0-.368.299-.667.667-.667.368 0 .667.299.667.667v4zM12 9.627c-.479 0-.867-.388-.867-.867s.388-.867.867-.867.867.388.867.867-.388.867-.867.867z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
