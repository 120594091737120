import * as Yup from "yup";

const prefix = "Contact de l'entreprise : ";

const ContactCompleteSchema = Yup.object().shape({
  lastName: Yup.string()
    .required(`${prefix}Le nom n'est pas renseigné`)
    .typeError(`${prefix}Le nom n'est pas renseigné`),
  firstName: Yup.string()
    .required(`${prefix}Le prénom n'est pas renseigné`)
    .typeError(`${prefix}Le prénom n'est pas renseigné`),
  email: Yup.string()
    .email(`${prefix}Le format de l'adresse email est incorrect`)
    .required(`${prefix}L'adresse email n'est pas renseignée`)
    .typeError(`${prefix}L'adresse email n'est pas renseignée`),
});

export default ContactCompleteSchema;
