import React, { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import AclBack from "apps/back/AclBack";
import Acl, { withAccess } from "utils/Acl";
import IconSignIcon from "icons/icon_doca_sign";
import IconWarning from "icons/warning_01";
import Breadcrumb from "components/Breadcrumb";
import InnerNavigation from "components/InnerNavigation";
import StatusEnum from "models/enums/StatusEnum";
import PartnerFormCompleteSchema from "models/schemas/PartnerFormCompleteSchema";
import PartnerShape from "models/shapes/PartnerShape";
import PartnerFactory from "models/factories/PartnerFactory";
import PartnerSchema from "models/schemas/PartnerSchema";
import PartnerTypologyEnum from "models/enums/PartnerTypologyEnum";
import SalesChannelEnum from "models/enums/SalesChannelEnum";
import CompanyDetailsForm from "apps/back/pages/partners/CompanyDetailsForm";
import BillingAddressForm from "apps/back/pages/partners/BillingAddressForm";
import BillingDataForm from "apps/back/pages/partners/BillingDataForm";
import ContactForm from "apps/back/pages/partners/ContactForm";
import AddressForm from "apps/back/pages/partners/AddressForm";
import GeneralDataForm from "apps/back/pages/partners/GeneralDataForm";
import { isEmpty } from "utils/data";
import ModeOfPaymentEnum from "models/enums/ModeOfPaymentEnum";
import BillingSendModeEnum from "models/enums/BillingSendModeEnum";
import BillingTypeEnum from "models/enums/BillingTypeEnum";

const PartnerAccountForm = (props) => {
  const {
    isCompleteInformationForm,
    onValidate,
    onCancel,
    partner,
    validateLabel,
    headerLabel,
  } = props;

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleValidatePartner = useCallback(
    (values) => {
      const { rib, annualCommitmentVolume, bic } = values.billing;
      const { phoneNumber } = values.contact;

      onValidate({
        ...values,
        contact: {
          ...values.contact,
          phoneNumber: phoneNumber === "" ? null : phoneNumber,
        },
        billing: {
          ...values.billing,
          annualCommitmentVolume: annualCommitmentVolume
            ? parseFloat(Number(annualCommitmentVolume).toFixed(2))
            : null,
          rib: isEmpty([
            rib.bankCode,
            rib.counterCode,
            rib.accountNumber,
            rib.key,
          ])
            ? null
            : values.billing.rib,
          bic: bic === "" ? null : bic,
        },
      });
    },
    [onValidate],
  );

  const setTouched = (field) => {
    if (field && typeof field === "object") {
      const res = {};
      Object.keys(field).forEach((key) => {
        res[key] = setTouched(field[key]);
      });
      return res;
    }
    return true;
  };

  const { status } = partner;
  const isActive = status === StatusEnum.ACTIVE.name;

  const partnerSchema =
    isActive || isCompleteInformationForm
      ? PartnerFormCompleteSchema
      : PartnerSchema;

  const formik = useFormik({
    initialValues: partner,
    validationSchema: partnerSchema,
    initialTouched: isCompleteInformationForm ? setTouched(partner) : {},
    onSubmit: handleValidatePartner,
  });

  useEffect(() => {
    formik.resetForm({
      values: partner,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  useEffect(() => {
    setShowErrorMessage(
      formik &&
        Object.keys(formik.errors).length > 0 &&
        (formik.submitCount > 0 || isCompleteInformationForm),
    );
  }, [formik, isCompleteInformationForm]);

  const handleChangeAddress = useCallback(
    (address) => {
      formik.setFieldValue("billing.address", address);
    },
    [formik],
  );

  const displayError = useMemo(
    () =>
      showErrorMessage && (
        <Typography
          sx={{
            marginTop: "1.5rem",
            display: "flex",
            alignItems: "center",
            color: (theme) => theme.palette["status-error"],
          }}
          role="alert"
        >
          <IconWarning
            sx={(theme) => ({
              color: theme.palette["status-error"],
              backgroundColor: theme.palette["secondary-white"],
              marginRight: "0.5rem",
            })}
          />
          <span key={formik.submitCount}>
            Attention : pour enregistrer la fiche client vous devez compléter a
            minima les champs en rouge.
          </span>
        </Typography>
      ),
    [showErrorMessage, formik.submitCount],
  );

  const handleChange = async (event) => {
    const { target } = event;
    const { name, type, value } = target;
    if (
      name === "typology" &&
      value === PartnerTypologyEnum.DOCAPOSTE_BU.name
    ) {
      await formik.setFieldValue("salesChannel", SalesChannelEnum.DIRECT.name);
      formik.setFieldValue("issuerCode", "");
    }
    if (name === "salesChannel" && value === SalesChannelEnum.DIRECT.name) {
      formik.setFieldValue("issuerCode", "");
    }
    if (
      name === "billing.modeOfPayment" &&
      value === ModeOfPaymentEnum.TRANSFER.name
    ) {
      formik.setFieldValue("billing.rib.bankCode", "");
      formik.setFieldValue("billing.rib.counterCode", "");
      formik.setFieldValue("billing.rib.accountNumber", "");
      formik.setFieldValue("billing.rib.key", "");
      formik.setFieldValue("billing.bic", "");
      formik.setFieldValue("billing.bankDomiciliation", "");
    }
    if (
      name === "billing.sendMode" &&
      value === BillingSendModeEnum.BY_POST.name
    ) {
      formik.setFieldValue("billing.billingSendEmail", "");
    }
    if (
      name === "billing.sendMode" &&
      value === BillingSendModeEnum.BY_EMAIL.name
    ) {
      formik.setFieldValue("billing.copiesNumber", 1);
    }
    if (
      name === "billing.typeOfBilling" &&
      value === BillingTypeEnum.USE.name
    ) {
      formik.setFieldValue("billing.annualCommitmentVolume", null);
    }
    if (type === "radio" && ["true", "false"].includes(value)) {
      formik.setFieldValue(name, value === "true");
    } else if (type === "number" && value === "") {
      formik.setFieldValue(name, null);
    } else {
      formik.handleChange(event);
    }
  };

  const handleClick = (name, value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container sx={{ justifyContent: "space-between" }}>
        <Grid
          item
          sx={(theme) => ({
            width: "21.25rem",
            [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
              display: "none",
            },
          })}
        >
          <aside>
            <InnerNavigation container>
              <InnerNavigation
                section
                Icon={IconSignIcon}
                title="Informations du contrat"
                target="informations-du-contrat"
              >
                <InnerNavigation item target="coordonnees-de-l-entreprise">
                  Coordonnées de l’entreprise
                </InnerNavigation>
                <InnerNavigation item target="contact-de-l-entreprise">
                  Contact de l’entreprise
                </InnerNavigation>
                <InnerNavigation item target="donnees-generales">
                  Données générales
                </InnerNavigation>
                <InnerNavigation item target="adresse-de-l-entreprise">
                  Adresse de l’entreprise
                </InnerNavigation>
                <InnerNavigation item target="adresse-de-facturation">
                  Adresse de facturation
                </InnerNavigation>
                <InnerNavigation item target="donnees-de-facturation">
                  Données de facturation
                </InnerNavigation>
              </InnerNavigation>
            </InnerNavigation>
          </aside>
        </Grid>
        <Grid item xs>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={12} sm="auto">
              <Typography
                variant="h2"
                sx={{
                  color: (theme) => theme.palette["secondary-ultramarine"],
                }}
                component="h1"
              >
                {headerLabel}
              </Typography>
              <Breadcrumb
                parent={{
                  label: "Mes partenaires",
                  path: "/listing-partenaires",
                }}
                current={{
                  label: headerLabel,
                }}
              />
            </Grid>
            <Grid item xs={12} md="auto" sx={{ textAlign: "end" }}>
              <Button type="submit" variant="contained" color="primary">
                {validateLabel}
              </Button>
            </Grid>
          </Grid>
          <Typography
            variant="h2"
            sx={{
              color: (theme) => theme.palette["secondary-ultramarine"],
              margin: "2rem 0 1rem 0",
            }}
            id="informations-du-contrat"
          >
            <Grid
              component={Box}
              ml={3}
              container
              spacing={2}
              sx={(theme) => ({
                [theme.breakpoints.down("md")]: {
                  marginLeft: 0,
                },
              })}
            >
              <Grid item>
                <IconButton
                  color="secondary"
                  tabIndex={-1}
                  sx={{ pointerEvents: "none" }}
                  size="large"
                  aria-hidden="true"
                >
                  <IconSignIcon />
                </IconButton>
              </Grid>
              <Grid item>Informations du contrat</Grid>
            </Grid>
          </Typography>
          <Divider />
          {displayError}
          <CompanyDetailsForm
            title="Coordonnées de l'entreprise"
            company={formik.values}
            isActive={isActive}
            touched={formik.touched}
            errors={formik.errors}
            schema={partnerSchema}
            handleChange={handleChange}
            handleClick={handleClick}
            handleBlur={formik.handleBlur}
            id="coordonnees-de-l-entreprise"
            style={{ maxWidth: "100%" }}
          />
          <ContactForm
            title="Contact de l'entreprise"
            namePrefix="contact."
            contact={formik.values.contact}
            touched={formik.touched.contact}
            errors={formik.errors.contact}
            schema={partnerSchema}
            handleChange={handleChange}
            handleBlur={formik.handleBlur}
            id="contact-de-l-entreprise"
            style={{ maxWidth: "100%" }}
          />
          <GeneralDataForm
            title="Données générales"
            generalData={formik.values}
            touched={formik.touched}
            errors={formik.errors}
            handleClick={handleClick}
            schema={partnerSchema}
            handleChange={handleChange}
            handleBlur={formik.handleBlur}
            id="donnees-generales"
            style={{ maxWidth: "100%" }}
          />
          <AddressForm
            title="Adresse de l'entreprise"
            namePrefix="address."
            address={formik.values.address}
            touched={formik.touched.address}
            errors={formik.errors.address}
            schema={partnerSchema}
            handleChange={handleChange}
            handleBlur={formik.handleBlur}
            id="adresse-de-l-entreprise"
            style={{ maxWidth: "100%" }}
          />
          <BillingAddressForm
            title="Adresse de facturation"
            namePrefix="billing.address."
            address={formik.values.billing.address}
            companyAddress={formik.values.address}
            touched={
              (formik.touched.billing && formik.touched.billing.address) || {}
            }
            errors={
              (formik.errors.billing && formik.errors.billing.address) || {}
            }
            schema={partnerSchema}
            handleChange={handleChange}
            handleChangeAddress={handleChangeAddress}
            handleBlur={formik.handleBlur}
            id="adresse-de-facturation"
            style={{ maxWidth: "100%" }}
          />
          <BillingDataForm
            title="Données de facturation"
            isActive={isActive}
            billing={formik.values.billing}
            namePrefixBilling="billing."
            touchedBilling={formik.touched.billing}
            errorsBilling={formik.errors.billing}
            contract={formik.values.contract}
            handleClick={handleClick}
            salesChannel={formik.values.salesChannel}
            namePrefixContract="contract."
            touchedContract={formik.touched.contract}
            errorsContract={formik.errors.contract}
            schema={partnerSchema}
            namePrefixRib="billing.rib."
            touchedRib={
              (formik.touched.billing && formik.touched.billing.rib) || {}
            }
            errorsRib={
              (formik.errors.billing && formik.errors.billing.rib) || {}
            }
            handleChange={handleChange}
            handleBlur={formik.handleBlur}
            id="donnees-de-facturation"
            style={{ maxWidth: "100%" }}
          />
          {displayError}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "1.5rem 0",
            }}
          >
            <Button sx={{ marginRight: "1.5rem" }} onClick={onCancel}>
              Annuler
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {validateLabel}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

PartnerAccountForm.propTypes = {
  isCompleteInformationForm: PropTypes.bool,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  partner: PartnerShape,
  validateLabel: PropTypes.string,
  headerLabel: PropTypes.string,
};

PartnerAccountForm.defaultProps = {
  isCompleteInformationForm: false,
  partner: new PartnerFactory(),
  validateLabel: "Enregistrer",
  headerLabel: "Création d'un compte partenaire",
};

export default withAccess(
  AclBack.PARTNERS_DETAILS,
  Acl.CREATE,
)(PartnerAccountForm);
