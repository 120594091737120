import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import AclBack, {
  getFunctionnalityLabel as getFunctionnalityBackLabel,
  getHigherLevelFunctionnality as getHigherLevelBackFunctionnality,
  profiles as profilesBack,
} from "apps/back/AclBack";
import AclFront, {
  getFunctionnalityLabel as getFunctionnalityFrontLabel,
  profiles as profilesFront,
} from "apps/front/AclFront";
import Acl from "utils/Acl";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IconLabel from "components/IconLabel";
import ModalHeader from "components/ModalHeader";
import Modal from "components/Modal";
import * as Yup from "yup";
import AttributesSchema from "models/schemas/AttributesSchema";

import { isRequired } from "utils/enum";
import { BackUserRoleEnum, FrontUserRoleEnum } from "models/enums/UserRoleEnum";
import IconApproved from "icons/approved_01";
import IconRefused from "icons/refused";
import UserShape from "models/shapes/UserShape";
import TcWrapper from "react-tag-commander";
import { visuallyHidden } from "@mui/utils";
import IsAssociatedBadge from "components/IsAssociatedBadge";

const StyledButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: theme.spacing(2),
}));

const StyledFormGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(1),
}));

const StyledFieldLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette["secondary-ultramarine"],
  marginBottom: theme.spacing(1),
}));

const StyledEligibleList = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  marginTop: theme.spacing(1),
}));

const profilesFunctionnalities = {};

const generateFunctionnalitiesLabelByProfile = ({
  profiles,
  functionnalities,
  getLabel,
}) => {
  Object.keys(profiles).forEach((profileName) => {
    const profile = profiles[profileName];
    if (profile) {
      profilesFunctionnalities[profileName] = {};
      profilesFunctionnalities[profileName].eligible = [];
      profilesFunctionnalities[profileName].notEligible = [];
      Object.values(functionnalities).forEach((functionnality) => {
        const accesses = profile[functionnality] || [];
        const highestAccess = [Acl.UPDATE, Acl.CREATE, Acl.READ].find(
          (accesLevel) =>
            (accesses.includes(accesLevel) || accesses.includes(Acl.ALL)) &&
            getLabel(functionnality, accesLevel),
        );
        if (highestAccess) {
          const label = getLabel(functionnality, highestAccess);
          if (!profilesFunctionnalities[profileName].eligible.includes(label)) {
            profilesFunctionnalities[profileName].eligible.push(label);
          }
        }
        const lowestNoAccess = [Acl.READ, Acl.CREATE, Acl.UPDATE, Acl.ALL].find(
          (accesLevel) =>
            !accesses.includes(accesLevel) &&
            !accesses.includes(Acl.ALL) &&
            getLabel(functionnality, accesLevel),
        );
        if (lowestNoAccess) {
          const label = getLabel(functionnality, lowestNoAccess);
          if (
            !profilesFunctionnalities[profileName].eligible.includes(label) &&
            !profilesFunctionnalities[profileName].notEligible.includes(label)
          ) {
            profilesFunctionnalities[profileName].notEligible.push(label);
          }
        }
      });
    }
  });
};

generateFunctionnalitiesLabelByProfile({
  profiles: profilesFront,
  functionnalities: AclFront,
  getLabel: getFunctionnalityFrontLabel,
});
generateFunctionnalitiesLabelByProfile({
  profiles: profilesBack,
  functionnalities: AclBack,
  getLabel: getFunctionnalityBackLabel,
});

const UserForm = (props) => {
  const theme = useTheme();

  const {
    title,
    edit,
    currentUser,
    handleOpenLINConfirmationModal,
    handleLinkToLIN,
    handleValidateUser,
    openUsersModal,
    handleCloseUserModal,
    keycloakClientId,
  } = props;

  const wrapper = TcWrapper.getInstance();
  const isFrontApp = useMemo(
    () => keycloakClientId === process.env.REACT_APP_keycloak_front_client_id,
    [keycloakClientId],
  );

  const availableRoles = isFrontApp
    ? Object.values(FrontUserRoleEnum)
    : Object.values(BackUserRoleEnum);

  const UserSchema = Yup.object().shape({
    lastName: Yup.string().required("Champ requis"),
    firstName: Yup.string().required("Champ requis"),
    email: Yup.string()
      .email("Format d'email incorrect")
      .required("Champ requis"),
    enabled: Yup.bool(),
    roles: Yup.object().test("roles", "Profil requis", (value) => {
      const availableRolesNames = availableRoles.map((r) => r.name);
      return (
        Array.isArray(value?.[keycloakClientId]) &&
        value?.[keycloakClientId].filter((r) => availableRolesNames.includes(r))
          .length > 0
      );
    }),
    attributes: AttributesSchema,
  });

  const formik = useFormik({
    initialValues: currentUser,
    validationSchema: UserSchema,
    onSubmit: (values) => {
      handleValidateUser(values);
      wrapper.triggerEvent("click", "button", {
        clic_type: "action",
        name: "enregistrer_utilisateur",
        chapter1: "gestion_utilisateurs",
        chapter2: edit ? "modification_utilisateur" : "ajout_utilisateur",
      });
    },
  });

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  useEffect(() => {
    if (openUsersModal) {
      formik.resetForm({
        values: currentUser,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openUsersModal]);

  const handleChangeUserEnabled = useCallback(() => {
    setOpenConfirmationModal(true);
  }, []);

  const handleChangeRoles = (event, targetRole) => {
    const { target } = event || { target: targetRole };
    const { name, checked } = target;
    if (!checked) {
      formik.setFieldValue(
        `roles.${keycloakClientId}`,
        (formik.values?.roles &&
          Array.isArray(formik.values.roles[keycloakClientId]) &&
          formik.values.roles[keycloakClientId].filter(
            (role) => role !== name,
          )) ||
          [],
      );
    } else {
      formik.setFieldValue(`roles.${keycloakClientId}`, [
        ...formik.values.roles[keycloakClientId],
        name,
      ]);
    }
  };

  const eligibleFunctionnalities = useMemo(() => {
    let functionnalities = [];
    if (Array.isArray(formik.values?.roles[keycloakClientId])) {
      formik.values.roles[keycloakClientId].forEach((role) => {
        const roleFunctionnalities = profilesFunctionnalities[role]?.eligible;
        if (Array.isArray(roleFunctionnalities)) {
          roleFunctionnalities.forEach((f) => {
            if (
              !functionnalities.includes(f) &&
              !functionnalities.includes(getHigherLevelBackFunctionnality(f))
            ) {
              functionnalities.push(f);
              functionnalities = functionnalities.filter(
                (functionality) =>
                  getHigherLevelBackFunctionnality(functionality) !== f,
              );
            }
          });
        }
      });
    }
    return functionnalities;
  }, [formik.values.roles, keycloakClientId]);

  const notEligibleFunctionnalities = useMemo(() => {
    const functionnalities = [];
    if (Array.isArray(formik.values?.roles[keycloakClientId])) {
      formik.values.roles[keycloakClientId].forEach((role) => {
        const roleFunctionnalities =
          profilesFunctionnalities[role]?.notEligible;
        if (Array.isArray(roleFunctionnalities)) {
          roleFunctionnalities.forEach(
            (f) =>
              !eligibleFunctionnalities.includes(f) &&
              !eligibleFunctionnalities.includes(
                getHigherLevelBackFunctionnality(f),
              ) &&
              !functionnalities.includes(f) &&
              functionnalities.push(f),
          );
        }
      });
    }
    return functionnalities;
  }, [formik.values.roles, keycloakClientId, eligibleFunctionnalities]);

  const handleCancel = (event) => {
    wrapper.triggerEvent("click", event.target, {
      clic_type: "action",
      name: "annuler",
      chapter1: "gestion_utilisateurs",
      chapter2: edit ? "modification_utilisateur" : "ajout_utilisateur",
    });
    handleCloseUserModal();
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const handleChangeUserStatus = () => {
    formik.setFieldValue("enabled", formik.values && !formik.values.enabled);
    setOpenConfirmationModal(false);
  };

  const isRoleSelected = (role) => {
    return Array.isArray(formik.values?.roles?.[keycloakClientId])
      ? formik.values.roles[keycloakClientId].includes(role)
      : false;
  };

  const { touched = {}, errors = {} } = formik;

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Modal open={openUsersModal} onClose={handleCloseUserModal} size="md">
      <ModalHeader title={title} handleCloseModal={handleCloseUserModal} />
      <Divider />
      <Box sx={{ p: theme.spacing(5, 4, 4, 4) }}>
        <form onSubmit={formik.handleSubmit}>
          {Object.keys(formik.errors).length > 0 && formik.submitCount > 0 && (
            <span style={visuallyHidden} role="alert" key={formik.submitCount}>
              Attention: pour enregistrer vous devez compléter les champs
              obligatoires
            </span>
          )}
          <StyledFormGrid container>
            <Grid item xs sx={{ pb: "28px", pr: "28px" }}>
              <StyledFieldLabel variant="subtitle2" component="h4" id="nom">
                Nom :
              </StyledFieldLabel>
              <TextField
                onChange={formik.handleChange}
                variant="outlined"
                name="lastName"
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                value={formik.values && formik.values.lastName}
                inputProps={{
                  "aria-labelledby": "nom",
                  "aria-describedby": "ht_nom",
                  "aria-invalid": Boolean(touched.lastName && errors.lastName),
                  "aria-required": isRequired(UserSchema, "lastName"),
                }}
                FormHelperTextProps={{
                  id: "ht_nom",
                }}
              />
            </Grid>
            <Grid item xs sx={{ pb: "28px", pl: "28px" }}>
              <StyledFieldLabel variant="subtitle2" component="h4" id="prénom">
                Prénom :
              </StyledFieldLabel>
              <TextField
                onChange={formik.handleChange}
                variant="outlined"
                name="firstName"
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                value={formik.values && formik.values.firstName}
                inputProps={{
                  "aria-labelledby": "prénom",
                  "aria-describedby": "ht_prénom",
                  "aria-invalid": Boolean(
                    touched.firstName && errors.firstName,
                  ),
                  "aria-required": isRequired(UserSchema, "firstName"),
                }}
                FormHelperTextProps={{
                  id: "ht_prénom",
                }}
              />
            </Grid>
          </StyledFormGrid>
          <StyledFormGrid container>
            <Grid item xs sx={{ pb: "28px", pr: "28px" }}>
              <StyledFieldLabel variant="subtitle2" component="h4" id="email">
                Email :
              </StyledFieldLabel>
              <TextField
                onChange={formik.handleChange}
                variant="outlined"
                disabled={edit}
                name="email"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                value={formik.values && formik.values.email}
                inputProps={{
                  "aria-labelledby": "email",
                  "aria-describedby": "ht_email",
                  "aria-invalid": Boolean(touched.email && errors.email),
                  "aria-required": isRequired(UserSchema, "email"),
                }}
                FormHelperTextProps={{
                  id: "ht_email",
                }}
              />
            </Grid>
            <Grid item xs sx={{ pb: "28px", pl: "28px" }}>
              <StyledFieldLabel variant="subtitle2" component="h4" id="poste">
                Poste :
              </StyledFieldLabel>
              <TextField
                onChange={formik.handleChange}
                variant="outlined"
                name="attributes.poste"
                error={
                  touched.attributes?.poste && Boolean(errors.attributes?.poste)
                }
                helperText={
                  touched.attributes?.poste && errors.attributes?.poste
                }
                value={formik.values && formik.values.attributes?.poste}
                inputProps={{
                  "aria-labelledby": "poste",
                  "aria-describedby": "ht_poste",
                  "aria-invalid": Boolean(
                    touched.attributes?.poste && errors.attributes?.poste,
                  ),
                  "aria-required": isRequired(
                    UserSchema?.fields?.attributes,
                    "poste",
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_poste",
                }}
              />
            </Grid>
          </StyledFormGrid>
          <StyledFormGrid container>
            <Grid
              item
              xs
              sx={{
                pb: "28px",
                pr:
                  edit &&
                  (Acl.hasAccess(AclBack.PARTNERS_STATUS, Acl.UPDATE) ||
                    Acl.hasAccess(AclFront.USERS, Acl.UPDATE))
                    ? "28px"
                    : 0,
              }}
            >
              <StyledFieldLabel
                variant="subtitle2"
                component="h4"
                id="telephone"
              >
                N° de téléphone :
              </StyledFieldLabel>
              <TextField
                onChange={formik.handleChange}
                variant="outlined"
                name="attributes.phoneNumber"
                error={
                  touched.attributes?.phoneNumber &&
                  Boolean(errors.attributes?.phoneNumber)
                }
                helperText={
                  touched.attributes?.phoneNumber &&
                  errors.attributes?.phoneNumber
                }
                value={formik.values && formik.values.attributes?.phoneNumber}
                inputProps={{
                  "aria-labelledby": "telephone",
                  "aria-describedby": "ht_telephone",
                  "aria-invalid": Boolean(
                    touched.attributes?.phoneNumber &&
                      errors.attributes?.phoneNumber,
                  ),
                  "aria-required": isRequired(
                    UserSchema?.fields?.attributes,
                    "phoneNumber",
                  ),
                }}
                FormHelperTextProps={{
                  id: "ht_telephone",
                }}
              />
            </Grid>
            {edit &&
              (Acl.hasAccess(AclBack.PARTNERS_STATUS, Acl.UPDATE) ||
                Acl.hasAccess(AclFront.USERS, Acl.UPDATE)) && (
                <Grid item xs sx={{ pb: "28px", pl: "28px" }}>
                  <StyledFieldLabel
                    variant="subtitle2"
                    component="h4"
                    id="active_user"
                  >
                    Utilisateur actif
                  </StyledFieldLabel>
                  <Switch
                    checked={formik.values && formik.values.enabled}
                    onChange={handleChangeUserEnabled}
                    name="enabled"
                    inputProps={{
                      "aria-labelledby": "active_user",
                    }}
                  />
                </Grid>
              )}
          </StyledFormGrid>
          {edit && (
            <>
              <StyledFieldLabel variant="subtitle2" component="h4">
                Identité Numérique :
              </StyledFieldLabel>
              <Box
                sx={{
                  display: "flex",
                  pb: 4,
                  pt: 2,
                }}
              >
                <IsAssociatedBadge isAssociated={currentUser.isAssociated} />
                <Button
                  variant="text"
                  onClick={
                    currentUser.isAssociated
                      ? handleOpenLINConfirmationModal
                      : handleLinkToLIN
                  }
                  sx={{
                    px: 0,
                    py: 1,
                    ml: 2,
                  }}
                >
                  <Typography
                    component="div"
                    color="primary"
                    variant="h5"
                    sx={{
                      textDecoration: "underline",
                    }}
                  >
                    {currentUser.isAssociated
                      ? "Dissocier l'Identité Numérique"
                      : "Envoyer une demande d'association"}
                  </Typography>
                </Button>
              </Box>
            </>
          )}
          <FormControl
            variant="standard"
            error={touched.roles?.[keycloakClientId] && Boolean(errors.roles)}
            component="fieldset"
          >
            <FormLabel component="legend">
              <Typography
                component="span"
                variant="h4"
                sx={{ mt: theme.spacing(4) }}
              >
                Profil octroyé :{" "}
                <span style={visuallyHidden}>
                  Choissisez au moins un des profils ci-dessous
                </span>
              </Typography>
              {touched.roles?.[keycloakClientId] && Boolean(errors.roles) && (
                <Typography
                  color="error"
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    mt: theme.spacing(0.5),
                  }}
                >
                  {errors.roles}
                </Typography>
              )}
            </FormLabel>
            <Grid container spacing={1} component={Box} py={2}>
              {availableRoles.map((value) => (
                <Grid item md={4} xs={12} key={value.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabindex="0"
                        role="checkbox"
                        inputProps={{ tabIndex: -1 }}
                        onClick={() =>
                          handleChangeRoles(null, {
                            name: value.name,
                            checked: !isRoleSelected(value.name),
                          })
                        }
                        aria-checked={isRoleSelected(value.name)}
                        checked={isRoleSelected(value.name)}
                        onChange={handleChangeRoles}
                        name={value.name}
                      />
                    }
                    label={
                      <Typography variant="subtitle1" component="h4">
                        {value.label}
                      </Typography>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </FormControl>
          <Paper
            sx={{
              borderRadius: "8px",
              border: `1px solid ${theme.palette["bleu-30"]}`,
              backgroundColor: theme.palette["bleu-3"],
              boxShadow: "none",
              p: theme.spacing(2),
              mb: theme.spacing(3),
            }}
          >
            <Grid container>
              <Grid
                item
                md={6}
                xs={12}
                sx={{ mb: isMobile ? theme.spacing(3) : 0 }}
              >
                <IconLabel
                  Icon={IconApproved}
                  iconPosition="start"
                  iconStyle={{
                    color: theme.palette["status-success"],
                    fontSize: "0.8em",
                  }}
                  ariaLabel="L'utilisateur avec le ou les profils choisis sera éligible aux parties suivantes: "
                  label="Éligible"
                  variant="h3"
                  component="h4"
                />
                <StyledEligibleList role="list">
                  {Array.isArray(eligibleFunctionnalities) &&
                    eligibleFunctionnalities.map((right) => (
                      <Typography
                        key={right}
                        variant="h6"
                        component="div"
                        role="listitem"
                      >
                        - {right}
                      </Typography>
                    ))}
                </StyledEligibleList>
              </Grid>
              <Grid item xs={12} md={6}>
                <IconLabel
                  Icon={IconRefused}
                  iconPosition="start"
                  iconStyle={{
                    color: theme.palette["secondaire-rouge"],
                    fontSize: "0.8em",
                  }}
                  ariaLabel="L'utilisateur avec le ou les profils choisis sera non éligible aux parties suivantes: "
                  label="Non éligible"
                  variant="h3"
                  component="h4"
                />
                <StyledEligibleList>
                  {Array.isArray(notEligibleFunctionnalities) &&
                    notEligibleFunctionnalities.map((right) => (
                      <Typography
                        key={right}
                        variant="h6"
                        component="div"
                        role="listitem"
                      >
                        - {right}
                      </Typography>
                    ))}
                </StyledEligibleList>
              </Grid>
            </Grid>
          </Paper>
          <StyledButtonsContainer>
            <Button sx={{ mr: theme.spacing(3) }} onClick={handleCancel}>
              Annuler
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Enregistrer
            </Button>
          </StyledButtonsContainer>
        </form>
      </Box>
      <Modal
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
      >
        <Box sx={{ p: theme.spacing(4, 4, 2.5, 4) }}>
          <Typography variant="h5">
            {formik.values && formik.values.enabled
              ? "Êtes-vous sûr de vouloir désactiver ce compte ?"
              : "Êtes-vous sûr de vouloir activer ce compte ?"}
          </Typography>
          <StyledButtonsContainer>
            <Button onClick={handleCloseConfirmationModal}> Non</Button>
            <Button onClick={handleChangeUserStatus}>Oui</Button>
          </StyledButtonsContainer>
        </Box>
      </Modal>
    </Modal>
  );
};

UserForm.propTypes = {
  title: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  currentUser: UserShape.isRequired,
  handleOpenLINConfirmationModal: PropTypes.func,
  handleLinkToLIN: PropTypes.func,
  handleValidateUser: PropTypes.func.isRequired,
  openUsersModal: PropTypes.bool,
  handleCloseUserModal: PropTypes.func.isRequired,
  keycloakClientId: PropTypes.string.isRequired,
};

UserForm.defaultProps = {
  edit: false,
  handleOpenLINConfirmationModal: null,
  handleLinkToLIN: null,
  openUsersModal: false,
};

export default UserForm;
