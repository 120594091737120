import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import AclBack from "apps/back/AclBack";
import Acl, { withAccess } from "utils/Acl";
import Paper from "components/Paper";
import BarSeriesChartLabel from "components/BarSeriesChartLabel";
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Tooltip,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { EventTracker, Stack, ValueScale } from "@devexpress/dx-react-chart";
import PartnerTypologyEnum from "models/enums/PartnerTypologyEnum";
import StatShape from "models/shapes/StatShape";

const modifyDomain = (domain) => [
  domain[0],
  Math.max(8, domain[domain.length - 1]),
];

const StatPartner = (props) => {
  const theme = useTheme();
  const { stats, ...otherProps } = props;

  const colors = useMemo(
    () => [
      {
        color: theme.palette["bleu-80"],
        labelBackground: theme.palette["bleu-80"],
        labelColor: theme.palette["secondary-white"],
      },
      {
        color: theme.palette["primaire-jaune"],
        labelBackground: theme.palette["primaire-jaune"],
        labelColor: theme.palette["secondary-ultramarine"],
      },
    ],
    [theme],
  );

  const partnerTypologyDistributions = useMemo(() => {
    if (!Array.isArray(stats) || stats.length === 0) {
      return null;
    }
    const getStatsByPartnerTypology = (partnerTypology) => {
      const partnerTypologyStats = {
        partner: partnerTypology.label,
      };
      stats.forEach((stat, index) => {
        const { applications } = stat;
        let value = 0;
        if (Array.isArray(applications)) {
          value = applications.filter(
            (application) =>
              application.partner_typology === partnerTypology.name,
          ).length;
        }
        partnerTypologyStats[`period_${index}`] = value;
      });
      return partnerTypologyStats;
    };

    const newPartnerTypologyDistributions = [
      getStatsByPartnerTypology(PartnerTypologyEnum.PARTNER),
      getStatsByPartnerTypology(PartnerTypologyEnum.PUBLISHER),
    ];
    if (Acl.hasAccess(AclBack.DASHBOARD_GLOBAL, Acl.READ)) {
      newPartnerTypologyDistributions.push(
        getStatsByPartnerTypology(PartnerTypologyEnum.DOCAPOSTE_BU),
      );
    }
    return newPartnerTypologyDistributions;
  }, [stats]);

  const barSeries = useMemo(
    () =>
      stats.map((stat, index) => ({
        valueField: `period_${index}`,
        argumentField: "partner",
        argumentLabelField: "partner",
        name: `period_${index}`,
        label: `Période ${index + 1}`,
        ...colors[index % colors.length],
      })),
    [stats, colors],
  );

  const BarWithLabel = ({ index, label, valueField, ...restProps }) => {
    const currentData = partnerTypologyDistributions[index];
    return (
      <BarSeries.Point
        aria-label={
          currentData[valueField]
            ? `${label}, nombre de partenaires de type ${currentData.partner}, ${currentData[valueField]}`
            : ""
        }
        {...restProps}
      />
    );
  };

  BarWithLabel.propTypes = {
    index: PropTypes.number,
    label: PropTypes.string,
    valueField: PropTypes.string,
  };

  BarWithLabel.defaultProps = {
    index: 0,
    label: "",
    valueField: "",
  };

  return (
    <Paper {...otherProps}>
      <Box p={3}>
        <Typography component="h2" variant="h3">
          Répartition par type de partenaire
        </Typography>
      </Box>
      <Divider />
      {partnerTypologyDistributions ? (
        <div style={{ padding: theme.spacing(3, 3, 2, 3) }}>
          <Chart
            aria-label="Graphique en barres"
            data={partnerTypologyDistributions}
            height={360}
            key={stats.length}
          >
            <ArgumentAxis
              rootComponent={(rootProps) => (
                <ArgumentAxis.Root {...rootProps} aria-hidden="true" />
              )}
            />
            <ValueAxis
              rootComponent={(rootProps) => (
                <ValueAxis.Root {...rootProps} aria-hidden="true" />
              )}
            />
            <ValueScale modifyDomain={modifyDomain} />

            <EventTracker />
            <Tooltip
              overlayComponent={(overlayProps) => (
                <Tooltip.Overlay {...overlayProps}>
                  {overlayProps.children &&
                    overlayProps.children.props &&
                    overlayProps.children.props.children}
                </Tooltip.Overlay>
              )}
              contentComponent={(info) => (
                <BarSeriesChartLabel
                  info={info}
                  series={barSeries}
                  data={partnerTypologyDistributions}
                />
              )}
            />
            {barSeries.map((serie) => (
              <BarSeries
                key={serie.name}
                argumentField={serie.argumentField}
                valueField={serie.valueField}
                name={serie.name}
                color={serie.color}
                pointComponent={(values, index) => {
                  return (
                    <BarWithLabel
                      index={index}
                      valueField={serie.valueField}
                      label={serie.label}
                      {...values}
                    />
                  );
                }}
              />
            ))}
            <Stack />
          </Chart>
        </div>
      ) : (
        <div aria-label="Aucune donnée" />
      )}
    </Paper>
  );
};

StatPartner.propTypes = {
  stats: PropTypes.arrayOf(StatShape).isRequired,
};

export default withAccess(
  AclBack.DASHBOARD_INDIRECT_SALE,
  Acl.READ,
)(StatPartner);
