import * as Yup from "yup";
import { errorPhoneMessage, regExPhone } from "utils/StringUtil";

const ContactSchema = Yup.object().shape({
  lastName: Yup.string().nullable().required("Champ requis"),
  firstName: Yup.string().nullable().required("Champ requis"),
  phoneNumber: Yup.string()
    .transform((value) => (!value ? null : value))
    .matches(regExPhone, errorPhoneMessage)
    .nullable(),
  roleInCompany: Yup.string().nullable(),
  email: Yup.string()
    .nullable()
    .email("Format d'email incorrect")
    .required("Champ requis"),
});

export default ContactSchema;
