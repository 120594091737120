export default {
  CCU_ID: { name: "ccu_id", label: "ccu_id" },
  PROFILE: { name: "profile", label: "profile" },
  GENDER: { name: "gender", label: "gender" },
  GIVEN_NAME: { name: "given_name", label: "given_name" },
  FAMILY_NAME: { name: "family_name", label: "family_name" },
  PREFERRED_USERNAME: {
    name: "preferred_username",
    label: "preferred_username",
  },
  BIRTHDATE: { name: "birthdate", label: "birthdate" },
  BIRTH: { name: "birth", label: "birth" },
  BIRTHPLACE: { name: "birthplace", label: "birthplace" },
  BIRTHCOUNTRY: { name: "birthcountry", label: "birthcountry" },
  NATIONALITY: { name: "nationality", label: "nationality" },
  EMAIL: { name: "email", label: "email" },
  PHONE: { name: "phone", label: "phone" },
  SPLITTED_PHONE: { name: "splitted_phone", label: "splitted_phone" },
  BIRTHPLACE_LABEL: { name: "birthplace_label", label: "birthplace_label" },
  BIRTHCOUNTRY_ISO: { name: "birthcountry_iso", label: "birthcountry_iso" },
  BIRTHCOUNTRY_LABEL: {
    name: "birthcountry_label",
    label: "birthcountry_label",
  },
  CONTACT_INFORMATION: {
    name: "contact_information",
    label: "contact_information",
  },
  SPLITTED_GIVEN_NAME: {
    name: "splitted_given_name",
    label: "splitted_given_name",
  },
  DIGITAL_IDENTITY_METADATA: {
    name: "digital_identity_metadata",
    label: "digital_identity_metadata",
  },
  DIGITAL_IDENTITY_CREATION_DATE: {
    name: "digital_identity_creation_date",
    label: "digital_identity_creation_date",
  },
  DIGITAL_IDENTITY_EXPIRATION_DATE: {
    name: "digital_identity_expiration_date",
    label: "digital_identity_expiration_date",
  },
  MAJORITY: { name: "majority", label: "majority" },
};
