import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M16.556 17.97c.39.391 1.024.391 1.415 0 .39-.39.39-1.023 0-1.414l-4.243-4.242L17.97 8.07c.39-.39.39-1.024 0-1.414-.391-.39-1.024-.39-1.415 0l-4.242 4.242L8.07 6.657c-.39-.39-1.024-.39-1.414 0-.39.39-.39 1.024 0 1.414l4.242 4.243-4.242 4.242c-.39.39-.39 1.024 0 1.415.39.39 1.023.39 1.414 0l4.243-4.243 4.242 4.243z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
