import React, { useCallback } from "react";
import PropTypes from "prop-types";

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import AclBack from "apps/back/AclBack";
import Loader from "components/Loader";
import Paper from "components/Paper";
import SortIcon from "icons/sorts";
import SortIconUp from "icons/sortUp";
import Acl, { withAccess } from "utils/Acl";
import { formatDateToDDMMYYYY } from "utils/date";
import { getLabelRepresentation } from "utils/enum";
import PartnerTypologyEnum from "models/enums/PartnerTypologyEnum";
import SalesChannelEnum from "models/enums/SalesChannelEnum";
import BillingTypeEnum from "models/enums/BillingTypeEnum";
import BusinessLineEnum from "models/enums/BusinessLineEnum";
import UserRoleEnum from "models/enums/UserRoleEnum";
import StatusEnum from "models/enums/StatusEnum";
import { handleEnterKeyPress } from "utils/enableEnterNavigation";

const StyledCell = styled(TableCell)({
  padding: "0 16px 0 0",
  "&:first-of-type": {
    paddingLeft: "16px",
  },
  "&:last-of-type": {
    paddingRight: "16px",
  },
});

const TabListing = (props) => {
  const {
    headCells,
    liste,
    isLoading,
    onClick,
    icon,
    clickOnIcon,
    pagination,
    setPagination,
    sorting,
    setSorting,
    totalElements,
    rowsPerPageOptions,
    rowsPerPageLabel,
    partnersTable,
    caption,
  } = props;

  const { sortBy, order } = sorting;
  const { page, rowsPerPage } = pagination !== null && pagination;

  const handleClickHeadCell = useCallback(
    (headCell) => () => {
      const { id, label } = headCell;
      if (id === sortBy) {
        setSorting({
          sortBy: id,
          sortByLabel: label,
          order: order === "asc" ? "desc" : "asc",
        });
        if (partnersTable) {
          localStorage.setItem("sortBy", id);
          localStorage.setItem("order", order === "asc" ? "desc" : "asc");
        }
      } else {
        setSorting({
          sortBy: id,
          sortByLabel: label,
          order: "asc",
        });
        if (partnersTable) {
          localStorage.setItem("sortBy", id);
          localStorage.setItem("order", "asc");
        }
      }
    },
    [sortBy, order, setSorting, partnersTable],
  );

  const getSortProps = useCallback(
    (headCell) => {
      const { id } = headCell;
      const isCurrent = sortBy === id;
      if (localStorage.getItem("sortBy") === id && partnersTable) {
        return {
          active: true,
          direction: localStorage.getItem("order"),
          onClick: handleClickHeadCell(headCell),
          // eslint-disable-next-line no-nested-ternary
          IconComponent: SortIconUp,
        };
      }
      return {
        active: true,
        direction: isCurrent ? order : "asc",
        onClick: handleClickHeadCell(headCell),
        // eslint-disable-next-line no-nested-ternary
        IconComponent: isCurrent ? SortIconUp : SortIcon,
      };
    },
    [handleClickHeadCell, sortBy, order, partnersTable],
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPagination({
        rowsPerPage,
        page: newPage,
      });
    },
    [rowsPerPage, setPagination],
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setPagination({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
    },
    [setPagination],
  );

  const getDeepElement = (data, multipleIds, i) => {
    if (multipleIds.length === 1 && i >= 1) {
      return data[multipleIds[0]] === null || data[multipleIds[0]] === undefined
        ? ""
        : data[multipleIds[0]];
    }
    const copyIds = [...multipleIds];
    copyIds.shift();
    return getDeepElement(data[multipleIds[0]], copyIds, i + 1);
  };

  const handleDataTypeToDisplay = (data, id, isDate) => {
    if (isDate) {
      return formatDateToDDMMYYYY(new Date(data[id]));
    }
    if (id === "isAssociated") {
      return data[id] ? "Associée" : "Non associée";
    }
    if (id === "enabled") {
      return data[id] ? "actif" : "inactif";
    }
    if (id === `roles.${process.env.REACT_APP_keycloak_front_client_id}`) {
      let roles = null;
      if (
        Array.isArray(
          data.roles[process.env.REACT_APP_keycloak_front_client_id],
        )
      ) {
        roles = data.roles[process.env.REACT_APP_keycloak_front_client_id]
          .map((role) => UserRoleEnum[role]?.label)
          .filter((r) => !!r)
          .join("/");
      }
      return roles || "";
    }
    if (id === "status") {
      return getLabelRepresentation(StatusEnum, data.status);
    }
    if (id.includes(".")) {
      const multipleIds = id.split(".");
      if (id === "billing.typeOfBilling") {
        return getLabelRepresentation(
          BillingTypeEnum,
          data[multipleIds[0]][multipleIds[1]],
        );
      }
      let element = getDeepElement(data, multipleIds, 0);
      if (Array.isArray(element) && element.length === 2) {
        element = `${element[0]}/${element[1]}`;
      }
      return element;
    }

    switch (id) {
      case "typology": {
        return getLabelRepresentation(PartnerTypologyEnum, data[id]);
      }
      case "businessLine": {
        return getLabelRepresentation(BusinessLineEnum, data[id]);
      }
      case "salesChannel": {
        return getLabelRepresentation(SalesChannelEnum, data[id]);
      }
      default:
        return data[id] === null ? data[id] : String(data[id]);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ position: "relative" }}>
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: "74px",
            zIndex: 1,
          }}
        >
          <Loader sx={{ background: "rgba(255,255,255,0.5)" }} />
        </Box>
      )}
      <Table
        aria-label={`${caption} triée par ${sorting.sortByLabel} de manière ${sorting.order}endante`}
      >
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: (theme) =>
                theme.palette["secondary-light-background"],
            }}
          >
            {headCells &&
              headCells.length > 0 &&
              headCells.map((headCell) => (
                <StyledCell
                  key={headCell.id}
                  scope="col"
                  role="columnheader"
                  aria-sort={
                    // eslint-disable-next-line no-nested-ternary
                    sorting.sortBy === headCell.id
                      ? sorting.order === "asc"
                        ? `ascending`
                        : `descending`
                      : "none"
                  }
                >
                  {headCell.sortable ? (
                    <TableSortLabel
                      {...getSortProps(headCell)}
                      onKeyUp={(e) =>
                        handleEnterKeyPress(handleClickHeadCell(e, headCell, e))
                      }
                      aria-label={
                        // eslint-disable-next-line no-nested-ternary
                        headCell.sortable
                          ? sorting.sortBy === headCell.id
                            ? `Colonne ${headCell.label} triée de manière ${sorting.order}endante`
                            : `Trier la colonne ${headCell.label}`
                          : headCell.label
                      }
                      sx={{
                        borderRadius: "8px",
                        ":focus": {
                          backgroundColor: (theme) => theme.palette["grey-20"],
                        },
                      }}
                    >
                      {headCell.label}
                      <Box sx={visuallyHidden} aria-live="assertive">
                        {sorting.sortBy === headCell.id
                          ? `Cette colonne a été triée de manière ${sorting.order}endante`
                          : ""}
                      </Box>
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </StyledCell>
              ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {liste &&
            liste.length > 0 &&
            liste.map((data) => (
              <TableRow
                key={data.id}
                sx={
                  clickOnIcon
                    ? null
                    : {
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: (theme) => theme.palette["grey-20"],
                        },
                      }
                }
                onClick={() => (clickOnIcon ? null : onClick(data))}
              >
                {headCells.map((attribut) =>
                  data[attribut.id] !== undefined ||
                  attribut.id.includes(".") ? (
                    <StyledCell
                      key={attribut.id}
                      style={{
                        wordBreak:
                          attribut.id === "email" ? "break-all" : "inherit",
                      }}
                    >
                      {handleDataTypeToDisplay(
                        data,
                        attribut.id,
                        attribut.isDate,
                      ) || <span style={visuallyHidden}>Aucun donnée</span>}
                    </StyledCell>
                  ) : (
                    <StyledCell key={attribut.id} aria-label="Aucun donnée">
                      coming soon
                    </StyledCell>
                  ),
                )}
                {icon && (
                  <StyledCell>
                    <IconButton
                      onClick={() => (clickOnIcon ? onClick(data) : null)}
                      aria-label={`Modifier ${
                        data.companyName
                          ? `le partenaire ${data.companyName}`
                          : `l'utilisateur ${data.lastName} ${data.firstName}`
                      }`}
                    >
                      {icon}
                    </IconButton>
                  </StyledCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {pagination !== null && (
        <TablePagination
          labelRowsPerPage={`${rowsPerPageLabel} par page`}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          sx={(theme) => ({
            minWidth: "370px",
            ".MuiTablePagination-displayedRows": {
              color: theme.palette.primary.dark,
            },
            ".MuiTablePagination-selectLabel": {
              color: theme.palette.primary.dark,
            },
          })}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={() =>
            `Page ${page + 1} sur ${Math.ceil(totalElements / rowsPerPage)}`
          }
          backIconButtonProps={{
            "aria-label": "Aller à la page précédente",
          }}
          nextIconButtonProps={{
            "aria-label": "Aller à la page suivante",
          }}
        />
      )}
    </TableContainer>
  );
};

TabListing.propTypes = {
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      sortable: PropTypes.bool,
      isDate: PropTypes.bool,
      label: PropTypes.string,
    }),
  ).isRequired,
  liste: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  clickOnIcon: PropTypes.bool,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }),
  setPagination: PropTypes.func,
  sorting: PropTypes.shape({
    sortBy: PropTypes.string,
    sortByLabel: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
  setSorting: PropTypes.func.isRequired,
  totalElements: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPageLabel: PropTypes.string.isRequired,
  partnersTable: PropTypes.bool.isRequired,
  caption: PropTypes.string,
};

TabListing.defaultProps = {
  clickOnIcon: false,
  icon: null,
  isLoading: false,
  onClick: null,
  pagination: null,
  setPagination: null,
  rowsPerPageOptions: [5, 10, 25],
  caption: "",
};

export default withAccess(
  AclBack.PARTNERS_LISTING_INDIRECT_SALE,
  Acl.READ,
)(TabListing);
