import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import {
  Box,
  IconButton,
  Snackbar as MuiSnackbar,
  SnackbarContent as MuiSnackbarContent,
} from "@mui/material";

import SuccessOutlinedIcon from "icons/approved_02";
import CloseIcon from "icons/close";
import ActiveAccountIcon from "icons/illu-compte-active";
import InfoOutlinedIcon from "icons/info_stroke";
import WarningOutlinedIcon from "icons/warning";
import ErrorOutlinedIcon from "icons/warning_02";

const iconClass = {
  fontSize: "24px",
  "&.MuiSvgIcon-root": {
    fill: (theme) => theme.palette["secondary-white"],
  },
};

const StyledMessageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  paddingRight: theme.spacing(2),
  fontWeight: 500,
}));

const variantIcon = {
  success: SuccessOutlinedIcon,
  warning: WarningOutlinedIcon,
  error: ErrorOutlinedIcon,
  info: InfoOutlinedIcon,
  activationSuccess: ActiveAccountIcon,
};

const variantClasses = {
  success: {
    backgroundColor: (theme) => theme.palette["status-success"],
  },
  error: {
    backgroundColor: (theme) => theme.palette["status-error"],
  },
  info: {
    backgroundColor: (theme) => theme.palette["primaire-bleu"],
  },
  warning: {
    backgroundColor: (theme) => theme.palette["status-warning"],
  },
  activationSuccess: {
    backgroundColor: (theme) => theme.palette["bleu-background"],
    maxWidth: (theme) => theme.spacing(51),
  },
};

const SnackbarContent = React.forwardRef(function SnackbarContent(props, ref) {
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <MuiSnackbarContent
      ref={ref}
      message={
        <Box
          sx={{
            ...variantClasses[variant],
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            color: (theme) => theme.palette["secondary-white"],
          }}
        >
          <Box
            sx={{
              borderRadius: "10px 0px 0px 10px",
              padding: (theme) => theme.spacing(2),
              display: "flex",
            }}
          >
            <Icon
              sx={
                variant === "activationSuccess"
                  ? { fontSize: "48px" }
                  : iconClass
              }
            />
          </Box>
          <StyledMessageContainer>
            <Box
              component="span"
              sx={(theme) =>
                variant === "activationSuccess"
                  ? {
                      paddingRight: theme.spacing(1.5),
                      color: theme.palette["primaire-bleu"],
                    }
                  : { paddingRight: theme.spacing(1.5) }
              }
            >
              {message}
            </Box>
            <IconButton
              key="close"
              aria-label="Fermer"
              color="inherit"
              onClick={onClose}
              size="large"
            >
              <CloseIcon
                sx={
                  variant === "activationSuccess"
                    ? {
                        "&.MuiSvgIcon-root": {
                          fill: (theme) => theme.palette["primaire-bleu"],
                        },
                      }
                    : iconClass
                }
              />
            </IconButton>
          </StyledMessageContainer>
        </Box>
      }
      {...other}
    />
  );
});

SnackbarContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    "error",
    "info",
    "success",
    "warning",
    "activationSuccess",
  ]),
};

SnackbarContent.defaultProps = {
  className: "",
  message: "",
  variant: "success",
};

export default function Snackbar(props) {
  const { open, onClose, message, variant, duration, anchorOrigin } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose(event, reason);
  };

  return (
    <MuiSnackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
    >
      <SnackbarContent
        onClose={handleClose}
        variant={variant}
        message={message}
      />
    </MuiSnackbar>
  );
}

Snackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    "error",
    "info",
    "success",
    "warning",
    "activationSuccess",
  ]),
  duration: PropTypes.number,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom"]).isRequired,
    horizontal: PropTypes.oneOf(["left", "center", "right"]).isRequired,
  }),
};

Snackbar.defaultProps = {
  open: false,
  message: "",
  variant: "success",
  duration: 10000,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
