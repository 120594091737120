import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import ChartLabel from "components/ChartLabel";

const BarSeriesChartLabel = (props) => {
  const { info, series, data, stacks } = props;
  const { point } = info.targetItem;

  const getSerieByName = useCallback(
    (name) => series.find((serie) => serie.name === name),
    [series],
  );

  const currentSerie = useMemo(
    () => getSerieByName(info.targetItem.series),
    [info, getSerieByName],
  );

  const title = useMemo(
    () => data[point][currentSerie.labelField] || currentSerie.label,
    [data, currentSerie, point],
  );

  const getSerieStat = useCallback(
    (serie) => ({
      label: data[point][serie.argumentLabelField] || serie.valueLabel,
      value: data[point][serie.valueField],
    }),
    [data, point],
  );

  const stats = useMemo(() => {
    if (Array.isArray(stacks)) {
      const stack = stacks.find(
        (s) => s.series && s.series.includes(currentSerie.name),
      );
      if (stack) {
        return stack.series
          .map((serieName) => {
            const serie = getSerieByName(serieName);
            if (!serie) {
              return null;
            }
            return getSerieStat(serie);
          })
          .filter((s) => !!s);
      }
    }
    return [getSerieStat(currentSerie)];
  }, [getSerieStat, currentSerie, stacks, getSerieByName]);

  return (
    <ChartLabel
      title={title}
      stats={stats}
      backgroundColor={currentSerie.labelBackground}
      color={currentSerie.labelColor}
    />
  );
};

BarSeriesChartLabel.propTypes = {
  info: PropTypes.shape({
    targetItem: PropTypes.shape({
      series: PropTypes.string.isRequired,
      point: PropTypes.number.isRequired,
    }),
  }).isRequired,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      valueField: PropTypes.string.isRequired,
      argumentField: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      labelField: PropTypes.string,
      label: PropTypes.string,
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  stacks: PropTypes.arrayOf(
    PropTypes.shape({ series: PropTypes.arrayOf(PropTypes.string) }),
  ),
};

BarSeriesChartLabel.defaultProps = {
  data: [],
  stacks: [],
};

export default BarSeriesChartLabel;
