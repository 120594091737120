import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Router } from "react-router";
import AppRouter from "AppRouter";
import ScrollToTop from "ScrollToTop";
import history from "historyApp";
import theme from "theme";
import { StyledEngineProvider } from "@mui/material";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <ScrollToTop />
          <AppRouter />
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
