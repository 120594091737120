import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AclBack from "apps/back/AclBack";
import Acl, { withAccess } from "utils/Acl";
import BillingTypeEnum from "models/enums/BillingTypeEnum";
import StatShape from "models/shapes/StatShape";
import PieChartCard from "components/stats/PieChartCard";

const StatBilling = (props) => {
  const { stats, ...otherProps } = props;

  const [billingTypeDistributions, setBillingTypeDistributions] =
    useState(null);

  useEffect(() => {
    if (Array.isArray(stats)) {
      const newBillingTypeDistributions = stats.map((stat) => {
        const { applications } = stat;
        if (Array.isArray(applications) && applications.length > 0) {
          const applicationsNb = applications.length;
          const licenceBillingApplications = applications.filter(
            (application) =>
              application.type_of_billing === BillingTypeEnum.LICENCE.name,
          );
          const percentLicenceBilling =
            Math.round(
              (licenceBillingApplications.length * 10000) / applicationsNb,
            ) / 100;
          const utilisationBillingApplications = applications.filter(
            (application) =>
              application.type_of_billing === BillingTypeEnum.USE.name,
          );
          const percentUtilisationBilling =
            Math.round(
              (utilisationBillingApplications.length * 10000) / applicationsNb,
            ) / 100;
          return [
            {
              argument: `${BillingTypeEnum.LICENCE.label} ${percentLicenceBilling}%`,
              label: BillingTypeEnum.LICENCE.label,
              value: percentLicenceBilling,
            },
            {
              argument: `${BillingTypeEnum.USE.label} ${percentUtilisationBilling}%`,
              label: BillingTypeEnum.USE.label,
              value: percentUtilisationBilling,
            },
          ];
        }
        return null;
      });
      setBillingTypeDistributions(newBillingTypeDistributions);
    }
  }, [stats]);

  return (
    <PieChartCard
      chartTitle="Répartition par mode de facturation"
      chartsData={billingTypeDistributions}
      {...otherProps}
    />
  );
};

StatBilling.propTypes = {
  stats: PropTypes.arrayOf(StatShape).isRequired,
};

export default withAccess(
  AclBack.DASHBOARD_INDIRECT_SALE,
  Acl.READ,
)(StatBilling);
