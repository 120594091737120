import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Link from "components/Link";
import keycloak from "keycloak";
import {
  Box,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IsAssociatedBadge from "components/IsAssociatedBadge";
import Button from "components/Button";
import logo from "media/logo.svg";
import RouteShape from "models/shapes/RouteShape";
import CurrentRouteContext from "components/CurrentRouteContext";
import Footer from "components/Footer";
import LogoutIcon from "icons/log-out";
import ProfileIcon from "icons/profile_1";
import { getIconByRoleName, roleIsValid } from "utils/role";
import UserRoleEnum from "models/enums/UserRoleEnum";
import UserService from "services/UserService";
import Snackbar from "components/Snackbar";
import TcWrapper from "react-tag-commander";
import { handleEnterKeyPress } from "utils/enableEnterNavigation";
import PartnerService from "services/PartnerService";

const StyledNavBarToggler = styled(Grid)(({ theme, menuexpanded }) => ({
  display: "none",
  [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
    display: "inherit",
    order: 1,
  },
  position: "relative",
  width: "1.25rem",
  height: "1.25rem",
  "&> div": {
    position: "absolute",
    transition: "all 0.2s",
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette["primaire-bleu"],
  },
  "&> div:nth-of-type(1)": {
    top: menuexpanded ? "calc(50% - 1px)" : 0,
    transform: `rotate(${menuexpanded ? 45 : 0}deg)`,
  },
  "&> div:nth-of-type(2)": {
    top: "calc(50% - 1px)",
    opacity: menuexpanded ? 0 : 1,
  },
  "&> div:nth-of-type(3)": {
    bottom: menuexpanded ? "calc(50% - 1px)" : 0,
    transform: `rotate(-${menuexpanded ? 45 : 0}deg)`,
  },
}));

const StyledMenuContainer = styled(Grid)(({ theme, menuexpanded }) => ({
  height: "100%",
  zIndex: 1,
  [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
    order: 4,
    position: "absolute",
    left: 0,
    top: "80px",
    backgroundColor: "inherit",
    display: menuexpanded ? "inherit" : "none",
    transition: "all 0.2s",
    width: "100%",
    height: "auto",
  },
}));

const StyledNav = styled(Grid)(({ theme }) => ({
  maxWidth: "1440px",
  padding: theme.spacing(0, 6),
  height: "104px",
  [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
    height: "80px",
    padding: "0 16px",
    background: "#e0eaff",
  },
}));

const StyledMenu = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  justifyItems: "center",
  justifyContent: "flex-end",
  [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
    flexDirection: "column",
    width: "100%",
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  padding: "0 16px",
  display: "flex",
  alignItems: "stretch",
  [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
    alignItems: "center",
    padding: "16px",
  },
  "& span": {
    display: "flex",
    alignItems: "center",
    borderWidth: "30px 0 4px 0",
    borderStyle: "solid",
    borderColor: "transparent",
    [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
      borderTopWidth: 0,
    },
  },
  "&:hover": {
    fontWeight: "bold",
    textDecoration: "none",
    "& span": {
      borderBottom: `6px solid ${theme.palette.secondary.main}`,
    },
  },
}));

const resourceName = process.env.REACT_APP_keycloak_resource;

const Layout = (props) => {
  const { children, menu } = props;

  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const [mainHeight, setMainHeight] = useState(0);

  const [profile = {}, setProfile] = useState(keycloak?.profile);
  const { firstName, lastName, email } = profile;

  const { resourceAccess } = keycloak;
  const { [resourceName]: resource } = resourceAccess || {};
  const { roles = [] } = resource || {};
  const [linkedLINId, setLinkedLINId] = useState(false);

  const [profileAnchorElement, setProfileAnchorElement] = useState(null);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [apiLayout, setApiLayout] = useState(false);

  const { currentRoute } = useContext(CurrentRouteContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [snackbar, setSnackbar] = useState({
    open: false,
    variant: null,
    message: "",
  });

  const handleResize = useCallback(() => {
    setMainHeight(
      window.innerHeight -
        headerRef?.current?.offsetHeight -
        footerRef?.current?.offsetHeight || 0,
    );
  }, []);

  const wrapper = TcWrapper.getInstance();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const isDocAPI = window.location.href.includes("/api");
    if (isDocAPI) {
      setApiLayout(true);
    } else {
      setApiLayout(false);
    }
  }, []);

  useEffect(() => {
    if (keycloak.authenticated) {
      const loadProfile = async () => {
        await keycloak.loadUserProfile();
        setProfile(keycloak?.profile);
        const identityProviders = await UserService.getUserIdentityProviders();
        const linIdentityProvier =
          Array.isArray(identityProviders) &&
          identityProviders.find(
            (ip) =>
              ip.providerAlias === process.env.REACT_APP_identity_provider,
          );
        const accountIsLinked = linIdentityProvier?.connected;
        setLinkedLINId(accountIsLinked);
      };
      loadProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak]);

  // Get partner in order to add partner name to tc_vars for tag_commander
  const getPartner = useCallback(async () => {
    if (profile.attributes?.partnerId) {
      await PartnerService.get(profile.attributes.partnerId)
        .then((result) => {
          if (result && result.companyName) {
            window.tc_vars.partner_name = result.companyName
              .toLowerCase()
              .replace(/ /g, "_");
          }
        })
        .catch(() => {
          setSnackbar({
            open: true,
            message:
              "Une erreur est survenue lors de la récupération du compte partenaire.",
            variant: "error",
          });
        });
    }
  }, [profile.attributes]);

  useEffect(() => {
    getPartner();
  }, [getPartner]);

  const handleLogout = useCallback(
    (event) => {
      wrapper.triggerEvent("click", event.target, {
        clic_type: "exit",
        name: "deconnexion",
        chapter1: "modale",
      });
      keycloak.logout();
    },
    [wrapper],
  );

  const handleClickProfile = useCallback(
    (event) => {
      if (!profileAnchorElement) {
        setProfileAnchorElement(event.target);
        wrapper.triggerEvent("click", event.target, {
          clic_type: "action",
          name: "ouverture",
          chapter1: "modale",
        });
      } else {
        setProfileAnchorElement(null);
      }
    },
    [profileAnchorElement, wrapper],
  );

  const handleCloseProfile = useCallback(() => {
    setProfileAnchorElement(null);
  }, []);

  const handleClickToggler = useCallback(
    (event) => {
      setMenuExpanded(!menuExpanded);
      if (menuExpanded === true) {
        wrapper.triggerEvent("click", event.target, {
          clic_type: "navigation",
          name: "ouverture",
          chapter1: "menu",
        });
      }
    },
    [menuExpanded, wrapper],
  );

  const handleClickAwayNav = useCallback(() => {
    setMenuExpanded(false);
  }, []);

  const isActive = useCallback(
    (menuItem) =>
      currentRoute &&
      [currentRoute.path, currentRoute.parent?.path].includes(menuItem.path),
    [currentRoute],
  );

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <Box sx={{ margin: "0 auto" }}>
      <ClickAwayListener onClickAway={handleClickAwayNav}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            background: "#e0eaff",
            position: "relative",
            zIndex: 2,
          }}
          ref={headerRef}
          role="banner"
          aria-roledescription="Bandeau de navigation"
        >
          <StyledNav
            container
            alignItems="center"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <StyledNavBarToggler
              item
              data-toggle="collapse"
              data-target="#menu"
              role="button"
              tabIndex="0"
              aria-expanded={menuExpanded}
              style={{ cursor: "pointer" }}
              aria-label="Ouvrir le menu"
              onClick={(event) => handleClickToggler(event)}
              onKeyUp={(e) => handleEnterKeyPress(e, handleClickToggler, e)}
              menuexpanded={menuExpanded ? 1 : 0}
            >
              <div />
              <div />
              <div />
            </StyledNavBarToggler>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
                  order: 2,
                },
              }}
            >
              <img
                src={logo}
                title="Logo L'Identité Numérique La Poste"
                alt="Logo L'Identité Numérique La Poste"
                width={isMobile ? "188" : "228"}
                height={isMobile ? "40" : "48"}
              />
            </Grid>
            <StyledMenuContainer
              item
              id="menu"
              role="navigation"
              menuexpanded={menuExpanded ? 1 : 0}
            >
              <StyledMenu>
                {menu.map((menuItem) => (
                  <StyledLink
                    key={menuItem.path}
                    sx={
                      isActive(menuItem)
                        ? {
                            fontWeight: "bold",
                            textDecoration: "none",
                            "& span": {
                              borderBottom: `6px solid ${theme.palette.secondary.main}`,
                            },
                          }
                        : {}
                    }
                    to={menuItem.path}
                    onClick={(event) => {
                      wrapper.triggerEvent("click", event.target, {
                        clic_type: "navigation",
                        name: menuItem.title.toLowerCase().replace(/ /g, "_"),
                        chapter1: "menu",
                      });
                      handleClickAwayNav();
                    }}
                    underline="none"
                    aria-current={isActive(menuItem) ? "page" : null}
                  >
                    <Typography
                      variant={isActive(menuItem) ? "h4" : "inherit"}
                      component="span"
                      color="inherit"
                    >
                      {menuItem.title}
                    </Typography>
                  </StyledLink>
                ))}
              </StyledMenu>
            </StyledMenuContainer>
            <Grid
              item
              sx={{
                display: "none",
                cursor: "pointer",
                fontSize: 24,
                color: theme.palette["primaire-bleu"],
                [theme.breakpoints.down("lg")]: {
                  display: "inherit",
                  order: 3,
                },
              }}
            >
              <IconButton
                color="inherit"
                onClick={handleClickProfile}
                aria-label="Profil"
                aria-haspopup="menu"
              >
                <ProfileIcon style={{ fontSize: "1.45em" }} />
              </IconButton>
            </Grid>
            <Grid
              item
              sx={{
                [theme.breakpoints.down("lg")]: {
                  display: "none",
                },
              }}
            >
              <Button
                sx={{ fontSize: "18px", padding: "0 20px" }}
                variant="outlined"
                color="primary"
                onClick={handleClickProfile}
                startIcon={
                  <Grid
                    component="span"
                    container
                    style={{ fontSize: "1.5em" }}
                  >
                    <ProfileIcon />
                  </Grid>
                }
                aria-label="Profil"
                aria-haspopup="menu"
              >
                <Typography variant="h5" component="div" color="primary">
                  {firstName}
                </Typography>
              </Button>
            </Grid>
            <Popover
              open={!!profileAnchorElement}
              anchorEl={profileAnchorElement}
              onClose={handleCloseProfile}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: -20,
                horizontal: "center",
              }}
              PaperProps={{
                sx: {
                  borderRadius: "32px",
                  boxShadow: "none",
                  border: `1px solid ${theme.palette.divider}`,
                  textAlign: "center",
                  width: 320,
                },
              }}
              role="dialog"
              /* eslint-disable-next-line jsx-a11y/aria-props */
              aria-label="Informations et actions relatives au profil de l'utilisateur"
            >
              <Box pt={3} px={2}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "16px",
                  }}
                  columnSpacing={1}
                  component={Box}
                >
                  {Array.isArray(roles) &&
                    roles.map((role) => {
                      if (roleIsValid(role)) {
                        const Icon = getIconByRoleName(role);
                        return (
                          !!Icon && (
                            <Grid
                              item
                              key={role}
                              title={UserRoleEnum[role]?.label || role}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                color="secondary"
                                role="img"
                                aria-label={`Rôle: ${
                                  UserRoleEnum[role]?.label || role
                                }`}
                                sx={{
                                  cursor: "default",
                                  pointerEvents: "none",
                                }}
                                tabIndex={-1}
                                size="large"
                              >
                                <Icon />
                              </IconButton>
                            </Grid>
                          )
                        );
                      }
                      return <span key={role} />;
                    })}
                  <IsAssociatedBadge
                    style={{ marginLeft: theme.spacing(2) }}
                    isAssociated={linkedLINId}
                  />
                </Grid>
                <Box mb={2}>
                  <Typography
                    variant="h5"
                    component="div"
                    color="text.primary"
                    sx={{ mt: 4, mb: 2, fontWeight: 600, fontSize: "1rem" }}
                  >
                    <span style={{ textTransform: "uppercase" }}>
                      {lastName}
                    </span>{" "}
                    {firstName}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {email}
                  </Typography>
                </Box>
              </Box>
              <Box px={1.5} pt={3} pb={1}>
                <Divider />
                <Box
                  mt={3}
                  mb={3}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <IconButton onClick={handleLogout}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <LogoutIcon sx={{ fontSize: "0.875em", fill: "none" }} />
                    </Box>
                    <Typography
                      variant="h5"
                      component="div"
                      py={0}
                      px={1}
                      sx={{
                        fontWeight: 600,
                        color: theme.palette["primaire-bleu"],
                      }}
                    >
                      Déconnexion
                    </Typography>
                  </IconButton>
                </Box>
              </Box>
            </Popover>
          </StyledNav>
        </Box>
      </ClickAwayListener>
      <Box
        sx={{
          backgroundColor: theme.palette["bleu-3"],
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
            justifyContent: "flex-start",
          },
        }}
      >
        <Box
          sx={{
            minHeight: mainHeight,
            ...(apiLayout
              ? {
                  width: "100%",
                }
              : {
                  [theme.breakpoints.up(theme.breakpoints.mainViewWidth)]: {
                    padding: theme.spacing(6.25, 2, 0, 2),
                    width: theme.breakpoints.mainViewWidth,
                  },
                  [theme.breakpoints.down(theme.breakpoints.mainViewWidth)]: {
                    padding: theme.spacing(3, 2),
                    width: "100%",
                  },
                }),
          }}
          role="main"
        >
          {children}
        </Box>
        <div
          ref={footerRef}
          style={{ width: "100%" }}
          role="contentinfo"
          aria-roledescription="Bandeau de bas de page"
        >
          <Footer apiLayout={apiLayout} />
        </div>
      </Box>
      <Snackbar {...snackbar} onClose={handleCloseSnackbar} duration={15000} />
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  menu: PropTypes.arrayOf(RouteShape),
};

Layout.defaultProps = {
  menu: [],
};

export default Layout;
