import * as Yup from "yup";
import { siretLength, validateSiret } from "utils/SiretUtil";
import ContractSchema from "models/schemas/ContractSchema";
import AddressSchema from "models/schemas/AddressSchema";
import ContactSchema from "models/schemas/ContactSchema";
import BillingFormCompleteSchema from "models/schemas/BillingFormCompleteSchema";

const PartnerFormCompleteSchema = Yup.object().shape({
  companyName: Yup.string().nullable().required("Champ requis"),
  siretNumber: Yup.string().nullable()
    .required("Champ requis")
    .length(
      siretLength,
      `Le siret doit comporter ${siretLength} chiffres (sans espace)`,
    )
    .test("is-siret", "Le siret de l'entreprise est invalide", validateSiret),
  salesChannel: Yup.string().nullable().required("Champ requis"),
  businessLine: Yup.string().nullable().required("Champ requis"),
  enterpriseType: Yup.string().nullable(),
  typology: Yup.string().nullable().required("Champ requis"),
  address: AddressSchema,
  contact: ContactSchema,
  contract: ContractSchema,
  billing: BillingFormCompleteSchema,
});

export default PartnerFormCompleteSchema;
