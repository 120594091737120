import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AclFront from "apps/front/AclFront";
import AclBack from "apps/back/AclBack";
import Acl, { withAccess } from "utils/Acl";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Paper from "components/Paper";
import BarSeriesChartLabel from "components/BarSeriesChartLabel";
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Tooltip,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import {
  Animation,
  EventTracker,
  Stack,
  ValueScale,
} from "@devexpress/dx-react-chart";
import BusinessLineEnum from "models/enums/BusinessLineEnum";
import StatShape from "models/shapes/StatShape";
import { getLabelRepresentation } from "utils/enum";

const modifyDomain = (domain) => [
  domain[0],
  Math.max(8, domain[domain.length - 1]),
];

const ApplicationsActivities = (props) => {
  const { stats, label, ...otherProps } = props;

  const theme = useTheme();
  const colors = useMemo(
    () => [
      {
        color: theme.palette["bleu-80"],
        labelBackground: theme.palette["bleu-80"],
        labelColor: theme.palette["secondary-white"],
      },
      {
        color: theme.palette["primaire-jaune"],
        labelBackground: theme.palette["primaire-jaune"],
        labelColor: theme.palette["secondary-ultramarine"],
      },
    ],
    [theme],
  );

  const distributionByActivities = useMemo(() => {
    if (stats.length) {
      const businessLinesTitles = [];
      stats.forEach((stat) => {
        const { business_lines: businessLines } = stat;
        businessLines.forEach((businessLine) => {
          const { title } = businessLine;
          if (!businessLinesTitles.includes(title)) {
            businessLinesTitles.push(title);
          }
        });
      });
      return businessLinesTitles.map((title) => {
        const data = {
          activity:
            getLabelRepresentation(BusinessLineEnum, title) || "Non renseigné",
        };
        stats.forEach((stat, index) => {
          const { business_lines: businessLines } = stat;
          const businessLine = businessLines.find(
            (line) => line.title === title,
          );
          let value = 0;
          if (businessLine) {
            const { applicationIds } = businessLine;
            value = applicationIds.length;
          }
          data[`period_${index}`] = value;
        });
        return data;
      });
    }
    return null;
  }, [stats]);

  const barSeries = useMemo(
    () =>
      stats.map((stat, index) => ({
        valueField: `period_${index}`,
        argumentField: "activity",
        argumentLabelField: "activity",
        name: `period_${index}`,
        label: `Période ${index + 1}`,
        ...colors[index % colors.length],
      })),
    [stats, colors],
  );

  const BarWithLabel = ({
    index,
    label: statLabel,
    valueField,
    ...restProps
  }) => {
    const currentData = distributionByActivities[index];
    return (
      <BarSeries.Point
        aria-label={
          currentData[valueField]
            ? `${statLabel}, nombre de partenaires par secteur ${currentData.activity}, ${currentData[valueField]}`
            : ""
        }
        {...restProps}
      />
    );
  };

  BarWithLabel.propTypes = {
    valueField: PropTypes.string,
    label: PropTypes.string,
    index: PropTypes.number,
  };

  BarWithLabel.defaultProps = {
    label: "",
    valueField: "",
    index: 0,
  };

  return (
    <Paper {...otherProps}>
      {label && (
        <Box p={3}>
          <Typography component="h2" variant="h3">
            {label}
          </Typography>
        </Box>
      )}
      <Divider />
      {distributionByActivities ? (
        <div style={{ padding: theme.spacing(2, 5, 2, 1) }}>
          <Chart
            key={stats.length}
            data={distributionByActivities}
            height={360}
            rotated
            aria-label="Graphique en barres"
            style={{ marginLeft: theme.spacing(1) }}
          >
            <ArgumentAxis
              rootComponent={(rootProps) => (
                <ArgumentAxis.Root {...rootProps} aria-hidden="true" />
              )}
            />
            <ValueAxis
              rootComponent={(rootProps) => (
                <ValueAxis.Root {...rootProps} aria-hidden="true" />
              )}
            />
            <ValueScale modifyDomain={modifyDomain} />

            <EventTracker />
            <Tooltip
              overlayComponent={(overlayProps) => (
                <Tooltip.Overlay {...overlayProps}>
                  {overlayProps.children &&
                    overlayProps.children.props &&
                    overlayProps.children.props.children}
                </Tooltip.Overlay>
              )}
              contentComponent={(info) => (
                <BarSeriesChartLabel
                  info={info}
                  series={barSeries}
                  data={distributionByActivities}
                />
              )}
            />
            {barSeries.map((serie) => (
              <BarSeries
                key={serie.name}
                argumentField={serie.argumentField}
                valueField={serie.valueField}
                name={serie.name}
                color={serie.color}
                pointComponent={(values, index) => {
                  return (
                    <BarWithLabel
                      index={index}
                      valueField={serie.valueField}
                      label={serie.label}
                      {...values}
                    />
                  );
                }}
              />
            ))}
            <Animation />
            <Stack />
          </Chart>
        </div>
      ) : (
        <div aria-label="Aucune donnée" />
      )}
    </Paper>
  );
};

ApplicationsActivities.propTypes = {
  stats: PropTypes.arrayOf(StatShape).isRequired,
  label: PropTypes.string,
};

ApplicationsActivities.defaultProps = {
  label: "",
};

export const DashboardApplicationsActivities = withAccess(AclFront.DASHBOARD)(
  ApplicationsActivities,
);
export const PartnerApplicationsActivities = withAccess(
  AclBack.PARTNERS_CONSO,
  Acl.READ,
)(ApplicationsActivities);
export const BackDashboardApplicationsActivities = withAccess(
  AclBack.DASHBOARD_INDIRECT_SALE,
  Acl.READ,
)(ApplicationsActivities);
