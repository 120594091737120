export default class TechDataFactory {
  constructor({
    useName = "",
    environmentType = "test",
    urls = [],
    scopes = [
      "ccu_id",
      "profile",
      "gender",
      "given_name",
      "family_name",
      "preferred_username",
      "birthdate",
      "birth",
      "birthplace",
      "birthcountry",
      "nationality",
      "email",
      "phone",
      "splitted_phone",
      "birthplace_label",
      "birthcountry_iso",
      "splitted_given_name",
      "digital_identity_metadata",
      "digital_identity_creation_date",
      "digital_identity_expiration_date",
      "majority",
    ],
    signingAlgorithm = "none",
    userInfoAlgorithm = "none",
    flow = "partenaire Flow",
    hideLinCreateLink = false,
    status = "Actif",
    logo = null,
    hideReturnUrl = false,
    tradingName = "",
    actionLabel = "Demande de connexion",
    ...additionalAttributes
  } = {}) {
    this.useName = useName;
    this.environmentType = environmentType;
    this.urls = urls;
    this.scopes = scopes;
    this.signingAlgorithm = signingAlgorithm;
    this.userInfoAlgorithm = userInfoAlgorithm;
    this.flow = flow;
    if (process.env.REACT_APP_with_additional_personalisation === "true") {
      this.logo = logo;
      this.actionLabel = actionLabel;
    }
    this.hideReturnUrl = hideReturnUrl;
    this.hideLinCreateLink = hideLinCreateLink;
    this.status = status;
    this.tradingName = tradingName;
    Object.keys(additionalAttributes).forEach((key) => {
      this[key] = this[key] || additionalAttributes[key];
    });
  }
}
