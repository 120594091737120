import ApplicationStatFactory from "models/factories/ApplicationStatFactory";
import BusinessLineStatFactory from "models/factories/BusinessLineStatFactory";

export default class StatFactory {
  /**
   * Array of ApplicationStatFactory
   */
  applications = [];

  /**
   * Array of BusinessLineStatFactory
   */
  // eslint-disable-next-line camelcase
  business_lines = [];

  total = {
    applications_number: this.applications.length,
    unique_users: 0,
    uses: 0,
  };

  constructor({
    applications = [],
    business_lines: businessLines = [],
    total = {},
    ...otherAttributes
  } = {}) {
    if (typeof applications === "object") {
      Object.keys(applications).forEach((appId) => {
        this.applications.push(
          new ApplicationStatFactory({ ...applications[appId], id: appId }),
        );
      });
    } else if (Array.isArray(applications)) {
      this.applications = applications.map(
        (app) => new ApplicationStatFactory(app),
      );
    }
    if (typeof businessLines === "object") {
      Object.keys(businessLines).forEach((businessLineTitle) => {
        this.business_lines.push(
          new BusinessLineStatFactory({
            ...businessLines[businessLineTitle],
            title: businessLineTitle,
          }),
        );
      });
    } else if (Array.isArray(businessLines)) {
      this.businessLines = businessLines.map(
        (businessLine) => new BusinessLineStatFactory(businessLine),
      );
    }

    this.total = {
      ...this.total,
      ...(typeof total === "object" ? total : {}),
    };

    Object.keys(otherAttributes).forEach((key) => {
      this[key] = otherAttributes[key];
    });
  }
}
