import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default (props) => (
  <SvgIcon {...props} width="16px" height="16px" viewBox="0 0 16 16">
    <g clipPath="url(#clip0_52463_2585)">
      <path
        d="M0.195262 0.195262C0.455612 -0.0650874 0.877722 -0.0650874 1.13807 0.195262L15.8047 14.8619C16.0651 15.1223 16.0651 15.5444 15.8047 15.8047C15.5444 16.0651 15.1223 16.0651 14.8619 15.8047L11.7653 12.7081C10.7151 13.8401 9.43099 14.7787 8.26261 15.2794C8.09491 15.3513 7.90509 15.3513 7.73739 15.2794C6.46284 14.7332 5.05002 13.6657 3.95339 12.3936C2.86672 11.1331 2 9.56513 2 8V3.33333C2 3.22031 2.02813 3.11385 2.07777 3.02058L0.195262 1.13807C-0.0650874 0.877722 -0.0650874 0.455612 0.195262 0.195262ZM3.33333 4.27614V8C3.33333 9.10154 3.96662 10.3669 4.96327 11.523C5.86953 12.5743 7.00114 13.4467 8 13.9335C8.92102 13.4846 9.95527 12.7077 10.8217 11.7646L3.33333 4.27614Z"
        fill="#00008C"
      />
      <path
        d="M7.7661 0.709114C7.91702 0.652518 8.08334 0.652518 8.23426 0.709114L13.5676 2.70911C13.8278 2.80669 14.0002 3.05544 14.0002 3.33333V8C14.0002 8.5209 13.9038 9.03921 13.7397 9.54074C13.6251 9.89066 13.2486 10.0815 12.8987 9.96692C12.5488 9.85237 12.3579 9.47585 12.4725 9.12593C12.6007 8.73438 12.6668 8.35686 12.6668 8V3.79533L8.00018 2.04533L6.23426 2.70755C5.88952 2.83683 5.50524 2.66216 5.37596 2.31742C5.24668 1.97267 5.42135 1.58839 5.7661 1.45911L7.7661 0.709114Z"
        fill="#00008C"
      />
    </g>
    <defs>
      <clipPath id="clip0_52463_2585">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
